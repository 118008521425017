import { InquiryListPayload, Listing } from '@/core/types/Inquiry';
import { GENERIC } from '@/core/types/Etc';
import Inquiry from '@/core/services/Callable/Inquiry';


export type State = {
  inquiry: any
  inquiry_loading: Boolean
  inquiry_list_payload: InquiryListPayload
}

export const state: State = {
  inquiry: null,
  inquiry_loading: false,
  inquiry_list_payload: {
    end_date: "",
    index: 1,
    per_page: 50,
    start_date: "",
  },
};
