
import { defineComponent, ref, computed } from 'vue'
import { store } from '@/store';
import Policy from '@/core/services/Callable/Policy';
import { notification } from '@/store/stateless';

export default defineComponent({

    setup() {

        const clickLoading = ref(false)
        const isError = ref(false)

        const changeUnderwriter = computed(() => {
            return store.state.policy.transfer_underwriter;
        })


        const underwriters = ref<any>([]);
        const newUnderwriter = ref(null);

        const appendUnderwriter = async () => {
            let underwriter = store.state.etc.underwriters;
            underwriter = underwriter.filter((x) => x.value != changeUnderwriter.value.underwriter)
            // underwriter.unshift({ label: 'Select Policy Sales Agent', value: "all" });
            underwriters.value = underwriter;

        }

        const closeModal = () => {
            newUnderwriter.value = null;
            isError.value = false;
            clickLoading.value = false;
            changeUnderwriter.value.open = false;
            changeUnderwriter.value.policy_id = null;
            changeUnderwriter.value.underwriter = null;
            changeUnderwriter.value.is_group = false;
        }

        const filter = computed(() => {
            return store.state.policy.filter;
        });
        
        const submitChangeUnderwriter = () => {
            if (!newUnderwriter.value) {
                isError.value = true;
                return;
            }

            clickLoading.value = true;

            const payload = {
                policy_id: changeUnderwriter.value.policy_id,
                underwriter: newUnderwriter.value
            }

            

            if(!changeUnderwriter.value.is_group) {
                Policy.changeUnderwriter(payload).then((data) => {
                    notification.success("Policy Transfer", "You have successfully change the underwriter");
                    closeModal()
                    Policy.policyLists(filter.value)
                })
            }
            else 
            {
                Policy.groupChangeUnderwriter(payload).then((data) => {
                    notification.success("Policy Transfer", "You have successfully change the underwriter");
                    closeModal()
                    Policy.groupPolicyLists(filter.value)
                })
            }
        }

        return {
            closeModal,
            changeUnderwriter,
            underwriters,
            appendUnderwriter,
            newUnderwriter,
            submitChangeUnderwriter,
            isError,
            clickLoading
        }
    },
})
