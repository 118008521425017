
import { defineComponent, ref } from 'vue'
import { genders } from '@/core/utils'

export default defineComponent({
    props: {
        modelValue: {
            type: String,
            required: false,
            default: ''
        },
        genderName: {
            type: String,
            required: true,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    setup(_, context) {


        const updateValue = (e) => {
            context.emit("update:modelValue", e)
            context.emit("gender", e)
        }

        const inputRef = ref()
        const focus = () => {
        setTimeout(() => {
                inputRef.value.focus()
            }, 100);
        }

        return {
            focus,
            genders,
            inputRef,
            updateValue
        }
    },
})
