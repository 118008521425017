import { ActionTree, ActionContext } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';
import { State } from './state';
import { Mutations } from './mutations';
import { Mutations as etcMutation } from '@/store/modules/Etc/mutations';
import { QuoteMutationTypes } from '@/store/enums/Quote/Mutations';
import { EtcMutationTypes } from '@/store/enums/Etc/Mutations';
import { QuoteActionTypes } from '@/store/enums/Quote/Actions';
import ApiService from "@/core/services/ApiService";
import { Links } from '@/store/enums/Links';
import { notification } from '@/store/stateless'

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
  commit<K extends keyof etcMutation>(
    key: K,
    payload: Parameters<etcMutation[K]>[1],
  ): ReturnType<etcMutation[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  
}

export const actions: ActionTree<State, RootState> & Actions = {
  
  async [QuoteActionTypes.GET_QUOTES]({commit}, payload) {
    // commit(QuoteMutationTypes.SET_Quote_LOADING, true);
    try {
      await commit(QuoteMutationTypes.SET_POLICIES,[]);

      const data = await ApiService.post(Links.GET_QUOTES, payload);
      commit(QuoteMutationTypes.SET_POLICIES, data.data.data.policies);
      commit(QuoteMutationTypes.SET_INVOICE_BASMAH, data.data.data.basmah);
      // commit(QuoteMutationTypes.SET_Quote_LOADING, false);
      return data;
    }
    catch (err: any) {
      // commit(QuoteMutationTypes.SET_Quote_LOADING, false);
      return err;
    }
  },

  async [QuoteActionTypes.GET_GENERATE_QUOTES]({commit}, payload) {
    // commit(QuoteMutationTypes.SET_Quote_LOADING, true);
    try {
      const data = await ApiService.post(Links.GET_GENERATE_QUOTES, payload);
      commit(QuoteMutationTypes.SET_POLICIES, data.data.data.policies);
      // commit(QuoteMutationTypes.SET_Quote_LOADING, false);
      return data;
    }
    catch (err: any) {
      // commit(QuoteMutationTypes.SET_Quote_LOADING, false);
      return err;
    }
  },

  async [QuoteActionTypes.GENERATE_QUOTES_PDF]({commit}, payload) {
    // commit(QuoteMutationTypes.SET_Quote_LOADING, true);
    try {
      const data = await ApiService.post(Links.GENERATE_QUOTES_PDF, payload);
      commit(QuoteMutationTypes.SET_QUOTE_PDF_LINK, data.data.data.link);
      // commit(QuoteMutationTypes.SET_Quote_LOADING, false);
      return data;
    }
    catch (err: any) {
      // commit(QuoteMutationTypes.SET_Quote_LOADING, false);
      return err;
    }
  },

  async [QuoteActionTypes.SEND_MANUAL_QUOTE]({commit}, payload) {
    // commit(QuoteMutationTypes.SET_Quote_LOADING, true);
    try {
      const data = await ApiService.post(Links.SEND_MANUAL_QUOTE, payload);
      // commit(QuoteMutationTypes.SET_QUOTE_PDF_LINK, data.data.data.link);
      // commit(QuoteMutationTypes.SET_Quote_LOADING, false);
      return data;
    }
    catch (err: any) {
      // commit(QuoteMutationTypes.SET_Quote_LOADING, false);
      return err;
    }
  },

  async [QuoteActionTypes.GET_VIEW_QUOTES]({commit}, payload) {
    // commit(QuoteMutationTypes.SET_Quote_LOADING, true);
    try {
      const data = await ApiService.post(Links.GET_VIEW_QUOTES, payload);
      commit(QuoteMutationTypes.SET_QUOTES, data.data.data);
      // commit(QuoteMutationTypes.SET_Quote_LOADING, false);
      return data;
    }
    catch (err: any) {
      // commit(QuoteMutationTypes.SET_Quote_LOADING, false);
      return err;
    }
  },


  
};

