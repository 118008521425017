
import { defineComponent, computed } from 'vue'
import { store } from "@/store";
import Etcs from '@/core/services/Callable/Etcs';
import router from '@/router/clean';

export default defineComponent({
    props: {
        // isOpen: Boolean,
    },
    setup(_,context) {

        const isExistCustomer = computed(() => {
            return store.state.etc.customerAlreadyExist;
        })


        const isModalOpen = computed(() => {
            if (Object.keys(isExistCustomer.value).length > 0) {
                return true;
            }
            return false;
        })

        const health = computed(() => {
            return store.state.customer.health;
        })

        const toCustomerDetail = async () => {
            await router.push({ name: 'CustomerDetails', params: { customer_id: isExistCustomer.value.id } });
            Etcs.setExistCustomer({}); // closing exist customer dialog

            context.emit('redirectToCustomerDetail', isExistCustomer.value.id)
        }

        const clearIt = () => {
            health.value.phone_number = '';
            Etcs.setExistCustomer({}); // closing exist customer dialog
            context.emit('clearRedirect')
        }

        return {
            isExistCustomer,
            isModalOpen,
            toCustomerDetail,
            clearIt
        }
    },
})
