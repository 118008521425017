// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum DocumentActionTypes {
    DOCUMENT_LISTS = 'DOCUMENT_LISTS',
    DOCUMENT_TYPES = 'DOCUMENT_TYPES',
    GROUP_DOCUMENT_TYPES = 'GROUP_DOCUMENT_TYPES',
    DOCUMENT_ID_LOGS = 'DOCUMENT_ID_LOGS',
    DOCUMENT_UPDATE_TYPE = 'DOCUMENT_UPDATE_TYPE',
    DOCUMENT_DELETE_TYPE = 'DOCUMENT_DELETE_TYPE',
    DOWNLOAD_ZIP_DOCUMENTS = 'DOWNLOAD_ZIP_DOCUMENTS',
    AMEND_DOCUMENT_LISTS = 'AMEND_DOCUMENT_LISTS',
    UPDATE_AMEND_DOCUMENTS = 'UPDATE_AMEND_DOCUMENTS'
  }
  