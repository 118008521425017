
import { defineComponent, computed, getCurrentInstance } from 'vue'
import Customers from '@/core/services/Callable/Customers';
import { rule, common } from '@/store/stateless';
import Etcs from '@/core/services/Callable/Etcs';
import { store } from "@/store";

export default defineComponent({
    props: {
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        disabledInsuranceFor: {
            type: Boolean,
            required: false,
            default: false
        },
        disabledInsuranceType: {
            type: Boolean,
            required: false,
            default: false
        },
        disabledInsuranceForself: {
            type: Boolean,
            required: false,
            default: false
        },
    },

    setup(_, context) {

        const health = computed(() => {
            return store.state.customer.health;
        })

     
        const minStartDate = computed(() => {
            const datenow = new Date()
            const year = datenow.getFullYear();
            const month = datenow.getMonth() + 1;
            const day = ('0' + datenow.getDate()).slice(-2);
            return year + '-' + month + '-' + day;
        })

        const currentdate = computed(() => {
            const datenow = new Date()
            const day = datenow.getDate();
            const month = datenow.getUTCMonth();
            const year = datenow.getFullYear();
            return new Date(year, month, day);
        })

        const disabledDate = (date: any) => {
            return date.getTime() < new Date(currentdate.value)
        }

        const inst = getCurrentInstance()
        const focus = () => {
            const toFocus:any = inst?.refs[rule.customerDetails]
            if(toFocus !== undefined) {
                toFocus.focus()
                window.scrollTo(0, common.getY('middle'))
            }
        }


        return {
            rule,
            focus,
            health,
            disabledDate,
            minStartDate,
        }
    },
})
