
import { defineComponent, computed, onMounted, ref } from 'vue'
import Etcs from '@/core/services/Callable/Etcs';
import { store } from "@/store";

export default defineComponent({
    props: {
        modelValue: {
            type: String,
            required: false,
            default: ''
        },
        placeholder: {
            type: String,
            required: false,
            default: "Please Select"
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false
        },
        updatedClass: {
            type: String,
            required: false,
        },
        label: {
            type: String,
            required: false,
        },
        isTransfer: {
            type: Boolean,
            required: false,
            default: false
        },
        isQuick: {
            type: Boolean,
            required: false,
            default: false
        },
        options: {
            type: Array,
            required: true,
        }
    },

    setup(props, context) {
        // Current Inst for get Role for logged in User

        const agents = computed(() => {
            return store.state.etc.agents;
        })

        const updateValue = (event) => {
            context.emit("update:modelValue", event)
            context.emit("agent", event)
        }

        onMounted(async () => {
            await Etcs.getAgentLists();
        })

        const inputRef = ref()
        const focus = () => {
        setTimeout(() => {
                inputRef.value.focus()
            }, 100);
        }

        return {
            focus,
            inputRef,
            agents,
            updateValue,
        }
    }
})
