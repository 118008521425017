
import { computed, defineComponent, onMounted, ref } from 'vue'
import { store } from "@/store";
import Etcs from '@/core/services/Callable/Etcs';

export default defineComponent({
    props: {
        modelValue: {
            type: String,
            required: false,
            default: ''
        }
    },

    setup(props, context) {
        const providers = computed(() => {
            return store.state.etc.providers
        });
        
        onMounted(() => {
            Etcs.getInsuranceProviders({
                isDropdown: true
            });
        })

        const updateValue = (event) => {
            context.emit("update:modelValue", event)
            context.emit("health", event)
        }

        const inputRef = ref()
        const focus = () => {
        setTimeout(() => {
                inputRef.value.focus()
            }, 100);
        }

        return {
            providers,
            updateValue,
            inputRef,
            focus
        }
    },
})
