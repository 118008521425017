import { MutationTree } from 'vuex';

import { Listing } from '@/core/types/Inquiry'

import { State } from './state';
import { InquiryMutationTypes } from '@/store/enums/Inquiry/Mutations';

export type Mutations<S = State> = {
  [InquiryMutationTypes.SET_INQUIRY](state: S, payload: Array<Listing>): void;
  [InquiryMutationTypes.SET_INQUIRY_LOADING](state: S, payload: Boolean): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [InquiryMutationTypes.SET_INQUIRY](state: State, payload: Array<Listing>) {
    if(payload != undefined)
    state.inquiry = payload;
  },
  [InquiryMutationTypes.SET_INQUIRY_LOADING](state: State, payload: Boolean) {
    state.inquiry_loading = payload;
  },
};
