
import { defineComponent, ref } from 'vue'

export default defineComponent({
    props: {
        modelValue: {
            type: String,
            required: false,
            default: ''
        }
    },

    setup(props, context) {
        const groups = ref ([
            {
                label: "18-45",
                value: 1
            },
            {
                label: "46-59",
                value: 2
            },
            {
                label: "60-65",
                value: 3
            }
        ])

        const updateValue = (event) => {
            context.emit("update:modelValue", event)
            context.emit("groups", event)
        }

        return {
            groups,
            updateValue
        }
    },
})
