
import { defineComponent, computed, h } from 'vue'
import { ElDivider } from 'element-plus';
import { store } from '@/store';

export default defineComponent({
    name: 'policy-edit',

    data() {
        return {
            size: 10,
            spacer: h(ElDivider, { direction: "vertical" }),
        };
    },
    setup() {
        const policy = computed(() => {
            return store.state.policy.policy
        })
        
        return {
            policy
        }
    },
})
