
import { computed, defineComponent, ref } from 'vue'
import { store } from '@/store'
import { emails } from '@/core/utils'
export default defineComponent({
    setup() {

        const email_content = ref()

        const loading = computed(() => {
            return store.state.customer.email_loading
        })

        const email_content_data = computed(() => {
            return store.state.customer.email_content_data
        })
        // const email_content_data = computed(() => {
        //     return store.state.customer.email_content_data
        // })

        const lists = computed(() => {
            return store.getters.getEmailLists
        })

        const viewDetails = (content) => {
            email_content_data.value.open = true
            email_content_data.value.content = content
        }
        
        return {
            email_content,
            loading,
            emails,
            lists,
            viewDetails
        }
    },
})
