import { reactive } from "vue";

const loading = reactive({
    signin: false,
    quicklead: false,
    leadsAndTasks: false
});

export {
    loading
};