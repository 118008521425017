
  import Documents from '@/core/services/Callable/Documents'
  import { store } from '@/store';
  import { defineComponent, computed, ref } from 'vue'
  import { notification, constant } from "@/store/stateless"
  import { DocumentMutationTypes } from '@/store/enums/Document/Mutations';
import router from '@/router/clean';

  export default defineComponent({
    props: {
      policy: {
        type: Object,
        required: false,
      },
    isView: {
        type: Boolean,
        required: false,
        default: false
      },
      isCustomerDetails: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    setup(_) {

      console.log(_.isView)
      const permissions = computed(() => {
        return store.getters.getUserPermissions
      })

      const transferSale = computed(() => {
        return store.state.policy.transfer_sale;
      })

      const changeUnderwriter = computed(() => {
        return store.state.policy.transfer_underwriter;
      })

      const policy_documents = computed(() => {
            return store.state.policy.policy_documents
        })

      const fetchDocument = async () => {
        let data = <any>{
            is_loaded: true,
            categories: ['standard'],
            lead_id: _?.policy?.lead_id
          }
        if(_.isCustomerDetails) {
          // store.commit(DocumentMutationTypes.SET_DOCUMENT_LISTS, _.policy?.health_lead_documents)
          data = {
            ...data,
            policy_id: _?.policy?.policy_id,
            lead_employee_category_id: _?.policy?.lead_employee_category_id
          }
        } 
          await Documents.getDocuments(data)
        

        policy_documents.value.open = true;
      }

      const transferSaleAgent = () => {
        transferSale.value.open = true;
        transferSale.value.policy_id = _?.policy?.id;
        transferSale.value.agent = _?.policy?.policy_sales_agent?.id;
      }

      const transferUnderwriter = () => {
        changeUnderwriter.value.open = true;
        changeUnderwriter.value.policy_id = _?.policy?.id;
        changeUnderwriter.value.underwriter = _?.policy?.policy_underwriter?.id;
      }

      const viewPolicyDocument = async(policy) => {
        if(_.isCustomerDetails) {
          let data = <any>{
            is_loaded: true,
            categories: ['standard'],
            lead_id: _?.policy?.lead_id,
            policy_id: _?.policy?.policy_id,
            lead_employee_category_id: _?.policy?.lead_employee_category_id
          }
          const document = await Documents.getDocuments(data)
          console.log(document) 
          const certificate = document.find(x => x.type == 21)
          console.log(certificate)
          if(certificate)
            return window.open(certificate.file_path, '_blank');

          return

        }

        return window.open(policy.file_path, '_blank');
      }

      const redirect = (policy_id) => {
          const toRoute = router.resolve({
            name: 'editPolicy',
            params: {
              policy_id
            }
          })

          window.location.href = toRoute.href

          setTimeout(() => {
            window.location.reload()
          }, 200)
        }

      return {
        redirect,
        fetchDocument,
        transferSaleAgent,
        transferUnderwriter,
        store,
        constant,
        permissions,
        viewPolicyDocument
      }
    },
  })
