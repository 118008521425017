
    import { defineComponent, computed } from "vue";
    import { store } from "@/store";
    import { common } from "@/store/stateless";
    import { underwriter } from "@/core/utils/dashboard.js";
    import { team_summaries } from '@/core/utils/dashboard'

    export default defineComponent({
        name: "dashboard-underwriter-overview",
        props: {
            userRoleId : {
                type: Boolean,
                required: true
            }
        },
        setup() {

            const salesOverview = computed(() => {
                return store.getters.getUnderwriterData
            })

            const getTextWidth = (text) => {
                const canvas = document.createElement("canvas");
                const context: any = canvas.getContext("2d");

                context.font = getComputedStyle(document.body).font;

                return context.measureText(text).width;
            };

            const getFontSize = (value) => {
                const width = getTextWidth(value);
                return width > 92 ? '22px' : width > 82 ? '24px' : width > 70 ? '26px' : width > 60 ? '28px' : '34px'
            }

            function concatData(data_1, data_2) {
                if(data_2)
                    return `${data_1} / ${data_2}`

                return data_1
            }

            return {
                concatData,
                getTextWidth,
                getFontSize,
                common,
                salesOverview,
                team_summaries,
                underwriter
            };
        },
    });
