
import { defineComponent, ref } from "vue";
import { view_tasks } from "@/core/utils";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const close_task = ref();
    const openTask = (e) => {
      close_task.value.modal(true, e);
    };
    return {
      close_task,
      view_tasks,
      openTask,
    };
  },
});
