
import { defineComponent, computed, ref, onMounted, getCurrentInstance, watch } from 'vue';
import { store } from "@/store";
import { rule, common,registration, notification } from '@/store/stateless';
import Quote from '@/core/services/Callable/Quote';
import router from '@/router';
import { useRoute } from 'vue-router';
import Etcs from '@/core/services/Callable/Etcs';
import { ElMessage } from 'element-plus'
import Validations from '@/core/services/etc/Validations';


export default defineComponent({
    props:{
        isAmend:{
            type:Boolean,
            required:false,
            default:false
        },
        categoryAddition: {
            type:Boolean,
            required:false,
            default:false
        }
    },
    setup(_, context) {
        const currency = registration.currency;

        const is_discount_given_disabled = ref(true)
        const route = useRoute()
        const health = computed(() => {
            return store.state.customer.health;
        })

        const invoice = computed(() => {
            return store.state.invoice.groupInvoice;
        })

        const vat = computed(() => {
            return store.state.etc.vat  / 100;
        })
        

        const amend_quote_ref_no = computed(() => {
            if(_.categoryAddition)
                return invoice.value.quote_ref_no + '-AC' + invoice.value?.all_invoices?.length;
            else
                return invoice.value.quote_ref_no + '-AA' + invoice.value?.all_invoices?.length;
        })

        
        const agents = ref<any>([]);

        onMounted(async () => {
            await Etcs.getAgentLists();
            
            const agent = store.state.etc.agents;
            agent.unshift({ label: 'Select sales agent', value: "" });
            agents.value = agent;

            
            // invoice.value.basmah = store.state.quote.basmah;

            // console.log('basmah',store.state.etc.basmah)
        })


        const inst = getCurrentInstance()
        const focus = () => {
            const toFocus: any = inst?.refs[rule.customerDetails]
            if (toFocus !== undefined) {
                console.log('in fcss')
                toFocus.focus()
                // window.scrollTo(0, common.getY('invoiceRef'))
            }
        }

        watch(() => invoice.value, async (newValue) => {
            // console.log('inside watchs')
            if(newValue) {
                updatePolicyPrice(invoice.value.orginal_policy_price)
                updateIcp(invoice.value.icp)
                updateNonTaxableFee(invoice.value.non_taxable_fee)
            }
        }, { deep: true })

        const isNumber = (evt) => {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        }
        const uploadFile = (event) => {
            Validations.is_doc_limit = false
            // formData = new FormData();
            // rules.file_data.show = false;
            let file = event.target.files[0];
            // if (file == "" && addInvoiceBodyParam.value.payment_type != "1") {
            //     rules.value.file_data.show = false;
            //     isFormValid.value = false;
            // }
            //
            // console.log('file',file);
            // alert(file.size);
            if (file.size / 1024 / 1024 > 5) {
                // ElMessage.error('Maximum allowed file size is 5MB')
                // notification.warning(
                //     'File',
                //     'Maximum allowed file size is 5MB'
                // );
                // invoice.value.file_receipt = null;
                // event.target.value = null
                // return false
                Validations.is_doc_limit = true
            }
            invoice.value.file_receipt = file;
            context.emit('uploadFile', file);
            // context.emit('re-validate')
        };

        const uploadChequeFile = (event) => {
            
            let file = event.target.files[0];

            if (file.size / 1024 / 1024 > 5) {
                ElMessage.error('Maximum allowed file size is 5MB')
                event.target.value = null
                return false
            }
            
            context.emit('uploadChequeFile', file);
        };

        const updateInvoiceAmount = () => {
            // rules.value.policy_price.show = false
            // const val = parseFloat(e.target.value.toString().replace(',', ''))
            const val = roundUp(invoice.value.policy_price);
            if (isNaN(val)) {
                invoice.value.invoice_amount = 0
            } else {
                invoice.value.invoice_amount = roundUp(val + invoice.value.basmah + invoice.value.icp + invoice.value.icp_vat + invoice.value.non_taxable_fee);
                invoice.value.sales_price = roundUp(invoice.value.invoice_amount)
            }

        }

        const updateSalePriceValue = (e) => {
            // rules.value.policy_price.show = false
            const val = parseFloat(e.target.value.toString().replace(',', ''))
            if (isNaN(val)) {
                invoice.value.sales_price = 0
            } else {
                invoice.value.sales_price = roundUp(val) ;
            }
        }

        const calculateDiscountAmount = () => {
            const val = parseFloat(invoice.value.sales_price.toString().replace(',', ''))
            if (invoice.value.policy_price > 0 && val > 0) {

                if (val >= invoice.value.policy_price) {
                    invoice.value.discount_amount = 0;
                    invoice.value.is_discount_given = false;
                }
                else {
                    invoice.value.is_discount_given = true;
                    let discountAmount = invoice.value.policy_price - val;

                    invoice.value.discount_amount = roundUp(discountAmount);
                }

            }

        }

        const roundUp = (num: number) => {
            var p = Math.pow(10, 2);
            return Math.round(num * p) / p;
        }

        const updateSplitPayment = () => {
            if(invoice.value.is_split_payment)
            {
                invoice.value.pending_payment_type = 4;
            }
            else{
                invoice.value.pending_payment_type = null;
            }
        }

        const chgPaymentType = () => {
            if(invoice.value.payment_type == '3')
                invoice.value.order_description = '';
            // context.emit('re-validate')
        }

        const updatePolicyPrice = (e) => {
            // console.log(e)

            const val = parseFloat(e.toString().replace(',', ''))
            // invoice.value.orginal_policy_price = roundUp(val);
            if (isNaN(val)) {
                invoice.value.policy_price = 0
            } else {
                invoice.value.orginal_policy_price_vat = roundUp(val * vat.value);
                invoice.value.policy_price = roundUp(val + invoice.value.orginal_policy_price_vat);
                // invoice.value.sales_price = roundUp(invoice.value.invoice_amount)
            }
            // console.log(invoice.value.policy_price)
            updateInvoiceAmount()
        }

        const updateIcp = (e) => {
            // console.log(e)

            const val = parseFloat(e.toString().replace(',', ''))
            // invoice.value.orginal_policy_price = roundUp(val);
            if (isNaN(val)) {
                invoice.value.icp = 0
            } else {
                invoice.value.icp = roundUp(val);
                invoice.value.icp_vat = roundUp(val * vat.value);
            }
            // console.log(invoice.value.policy_price)
            updateInvoiceAmount()
        }

        const updateNonTaxableFee = (e) => {
            // console.log(e)

            const val = parseFloat(e.toString().replace(',', ''))
            // invoice.value.orginal_policy_price = roundUp(val);
            if (isNaN(val)) {
                invoice.value.non_taxable_fee = 0
            } else {
                invoice.value.non_taxable_fee = roundUp(val);
            }
            // console.log(invoice.value.policy_price)
            updateInvoiceAmount()
        }

        return {
            rule,
            focus,
            health,
            common,
            invoice,
            isNumber,
            uploadFile,
            agents,
            updateInvoiceAmount,
            updateSalePriceValue,
            calculateDiscountAmount,
            is_discount_given_disabled,
            updateSplitPayment,
            uploadChequeFile,
            amend_quote_ref_no,
            Validations,
            currency,
            chgPaymentType,
            updatePolicyPrice
        }
    },
})
