class Currency{
    public static currencyFormater(val: number) {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'AED',
            maximumFractionDigits: 2,
        });

        return formatter.format(val)
    }

}
/**
 * create custom currency formatter
 * @created vincent carabbacan
 */
export default Currency;