import { createStore } from "vuex";
// import { config } from "vuex-module-decorators";

// import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";

/**
 * @description import files
 */
 import { store as auth, AuthStore, State as AuthState } from '@/store/modules/Login';
 import { store as etc, EtcStore, State as EtcState } from '@/store/modules/Etc';
 import { store as customer, CustomerStore, State as CustomerState } from '@/store/modules/Customer';
 import { store as user, UserStore, State as UserState } from '@/store/modules/User';
 import { store as document, DocumentStore, State as DocumentState } from '@/store/modules/Document';
 import { store as quote, QuoteStore, State as QuoteState } from '@/store/modules/Quote';
 import { store as invoice, InvoiceStore, State as InvoiceState } from '@/store/modules/Invoice';
 import { store as policy, PolicyStore, State as PolicyState } from '@/store/modules/Policy';
 import { store as inquiry, InquiryStore, State as InquiryState } from '@/store/modules/Inquiry';
 import { store as dashboard, DashboardStore, State as DashboardState } from '@/store/modules/Dashboard';
 import { store as report, ReportStore, State as ReportState } from '@/store/modules/Report';

 /**
  * @description design of RootState
  */
 export type RootState = {
   auth: AuthState;
   etc: EtcState;
   customer: CustomerState;
   user: UserState;
   document: DocumentState;
   quote: QuoteState;
   invoice: InvoiceState;
   policy: PolicyState;
   inquiry: InquiryState;
   dashboard: DashboardState;
   report: ReportState;
 };

 /**
  * @description design your store
  */
 export type Store = AuthStore<Pick<RootState, 'auth'>>
 & EtcStore<Pick<RootState, 'etc'>>
 & CustomerStore<Pick<RootState, 'customer'>>
 & DocumentStore<Pick<RootState, 'document'>>
 & QuoteStore<Pick<RootState, 'quote'>>
 & InvoiceStore<Pick<RootState, 'invoice'>>
 & PolicyStore<Pick<RootState, 'policy'>>
 & InquiryStore<Pick<RootState, 'inquiry'>>
 & DashboardStore<Pick<RootState, 'dashboard'>>
 & ReportStore<Pick<RootState, 'report'>>
 & UserStore<Pick<RootState, 'user'>>;

 export const store = createStore({
  modules: {
    auth,
    customer,
    quote,
    invoice,
    policy,
    inquiry,
    dashboard,
    document,
    etc,
    user,
    report,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule
  },
});

export function useStore(): Store {
  return store as Store;
}
