// TODO: Remove no-shadow eslint disable after fixed: https://github.com/typescript-eslint/typescript-eslint/issues/2484
// eslint-disable-next-line no-shadow
export enum EtcMutationTypes {
    SET_APPS = 'SET_APPS',
    SET_PLANS = 'SET_PLANS',
    SET_ROUTES = 'SET_ROUTES',
    SET_EMIRATES = 'SET_EMIRATES',
    SET_COUNTRIES = 'SET_COUNTRIES',
    SET_WORK_TYPE = 'SET_WORK_TYPE',
    SET_PARENT_TYPE = 'SET_PARENT_TYPE',
    SET_EMPLOYEE_CATEGORIES = 'SET_EMPLOYEE_CATEGORIES',
    SET_AGENT_LISTS = 'SET_AGENT_LISTS',
    SET_COUNTRY_CODE = 'SET_COUNTRY_CODE',
    SET_INSURED_TYPES = 'SET_INSURED_TYPES',
    SET_RELATIONSHIPS = 'SET_RELATIONSHIPS',
    SET_ROLES = 'SET_ROLES',
    SET_NOTIFICATIONS = 'SET_NOTIFICATIONS',
    SET_LIST_USER_TYPES = 'SET_LIST_USER_TYPES',
    SET_CAR_TASK_DUE_DATE_COUNT = 'SET_CAR_TASK_DUE_DATE_COUNT',
    SET_LEAD_SOURCES = 'SET_LEAD_SOURCES',
    SET_CUSTOMER_ALREADY_EXIST = 'SET_CUSTOMER_ALREADY_EXIST',
    SET_INSURANCE_PROVIDERS = 'SET_INSURANCE_PROVIDERS',
    SET_INSURANCE_PLANS = 'SET_INSURANCE_PLANS',
    SET_TASK_LISTS = 'SET_TASK_LISTS',
    SET_LEAD_REASONS = 'SET_LEAD_REASONS',
    SET_BASMAH = 'SET_BASMAH',
    SET_UNDERWRITERS_LISTS = 'SET_UNDERWRITERS_LISTS',
    SET_NOTIFICATION_LIST = 'SET_NOTIFICATION_LIST',
    SET_NOTIFICATION_COUNT = 'SET_NOTIFICATION_COUNT',
    SET_ALL_NOTIFICATIONS = 'SET_ALL_NOTIFICATIONS',
    SET_VAT = 'SET_VAT',
    SET_GROUP_SIZE = 'SET_GROUP_SIZE',
    SET_HEALTH_SETTING = 'SET_HEALTH_SETTING',
    SET_EMPLOYEE_TYPE = 'SET_EMPLOYEE_TYPE',
    SET_OTHER_TYPE = 'SET_OTHER_TYPE'
  }
  