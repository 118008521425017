import { store } from "@/store";

const genders = [
    {
        value: "MALE",
        label: "Male"
    },
    {
        value: "FEMALE",
        label: "Female"
    },
    // {
    //     value: "OTHER",
    //     label: "Rather not say"
    // },
]

const yesAndNo = [
    {
        value: true,
        label: "Yes"
    },
    {
        value: false,
        label: "No"
    },
]

const agentType = [
    {
        value: 1,
        label: 'New Leads'
    },
    {
        value: 2,
        label: 'Renewal'
    },
    {
        value: 3,
        label: 'Both'
    },
]

const renewalDeals = [
    {
        value: 1,
        label: 'Self Deals'
    },
    {
        value: 2,
        label: 'All Deals'
    },
    {
        value: 3,
        label: 'Lost Lead'
    },
]

const assignLeads = [
    {
        value: 1,
        label: 'Car'
    },
    {
        value: 2,
        label: 'Health'
    },
    {
        value: 3,
        label: 'Home'
    },
    {
        value: 4,
        label: 'Travel'
    },
]

const customerStatus = [
    {
        value: 1,
        label: 'Active'
    },
    {
        value: 0,
        label: 'Inactive'
    },
]

const insurance_type = [
    {
        value: 0,
        label: 'Select Insurance Type'
    },
    {
        value: 1,
        label: 'Basic'
    },
    {
        value: 2,
        label: 'Enhanced'
    },
    
    {
        value: 3,
        label: 'Basic 2'
    }
]


const payment_type = [
    {
        value: 0,
        label: 'Select Payment Type'
    },
    // {
    //     value: 1,
    //     label: 'Online'
    // },
    {
        value: 2,
        label: 'COD'
    },
    {
        value: 3,
        label: 'Direct'
    },
    {
        value: 4,
        label: 'Cheque'
    },
    {
        value: 5,
        label: 'Bank Transfer'
    }
]

const AllInvoiceTypes = [
    {
        value: 0,
        label: 'New'
    },
    {
        value: 1,
        label: 'Amend'
    },
    // {
    //     value: 2,
    //     label: 'Subtract Amend'
    // },
    // {
    //     value: 3,
    //     label: 'Add Categories'
    // },
]

const pending_payment_type = [
    // {
    //     value: 0,
    //     label: 'Select Payment Type'
    // },
    {
        value: 4,
        label: 'Cheque'
    },
]

const insurance_payment = [
    {
        value: 0,
        label: 'Select Insurance Payment'
    },
    {
        value: 1,
        label: 'Payment Link Customer'
    },
    {
        value: 2,
        label: 'Direct Deposit to insurance company'
    },
    {
        value: 3,
        label: 'Direct transfer to insurance company'
    }

]


const languages = [
    {
        value: 'en',
        label: 'English'
    },
    {
        value: 'ar',
        label: 'Arabic'
    },
]

const healthDefault = {
    id: null,
    name: null,
    email: null,
    contact_person: null,
    phone_number: null,
    phone_country_code: "+971",
    insured_city: null,
    insured_type_id: 0,
    insurance_type: 0,
    is_salary: null,
    expected_insurance_start_date: null,
    customer_source: null,
    is_health: 1,
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_content: null,
    dob: null,
    nationality_id: null,
    gender: null,
    company_name: null,
    is_self: null,
    agent: null,
    health_lead_map_id: 0,
    deleted_members: [],
    members: [{
        id: null,
      insured_type_id:  null,
      insured_city: null,
      member_name: null,
      relationship: null,
      gender: null,
      dob: null,
      nationality_id: null,
      is_salary: null,
      declaration_answers: [],
      is_married: false,
    }],
    categories: [{
        id: null,
        name: null,
        groups: [{
            id: null,
            insurance_provider_id: null,
            insurance_providers: store.state.etc.providers,
            insurance_policy_id: null,
            insurance_policies: [],
            group_count: 1,
            visa_emirates: null
        }]
    }]
}

const healthTop = ['name', 'phone_number', 'email', 'dob', 'nationality_id', 'gender', 'is_salary']
const healthMiddle = ['insured_city', 'expected_insurance_start_date', 'is_self','insured_type_id','insurance_type']
const healthLast = ['agent']

const corporateTop = ['name', 'phone_number', 'email',  'contact_person','group_size','expected_insurance_start_date']
const corporateMiddle = ['insured_city', 'expected_insurance_start_date']


const client_classification = [
    {
        value: 0,
        label: 'Select Client Classification'
    },
    {
        value: 1,
        label: 'Basic Individual'
    },
    {
        value: 2,
        label: 'Enhance Individual'
    },
    {
        value: 3,
        label: 'Basic Group'
    },
    {
        value: 4,
        label: 'Basic Enhance'
    },
    {
        value: 5,
        label: 'SME'
    }
]

const per_pages = [1,50, 100, 150, 200, 500]

export {
    per_pages,
    customerStatus,
    languages,
    assignLeads,
    renewalDeals,
    agentType,
    genders,
    yesAndNo,
    healthTop,
    healthMiddle,
    healthLast,
    healthDefault,
    insurance_type,
    payment_type,
    corporateTop,
    corporateMiddle,
    client_classification,
    pending_payment_type,
    insurance_payment,
    AllInvoiceTypes
}