
import { computed, defineComponent, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { UploadFilled } from '@element-plus/icons-vue'
import type { UploadProps } from 'element-plus'
import Documents from '@/core/services/Callable/Documents';
import { notification } from '@/store/stateless';

interface condition {
    is_loaded: boolean,
    categories: string[],
    lead_id: number,
    lead_employee_category_id?: number,
}
interface dataValue {
    lead_id: number,
    policy_id: number,
    is_temp: number,
    lead_employee_category_id?: number,
}

export default defineComponent({
    props: {
        lead_id: {
            type: Number || null,
            required: false,
            default: null
        },
        policy_id: {
            type: Number || null,
            required: false,
            default: null
        },
        lead_employee_category_id: {
            type: Number,
            required: false,
            default: 0
        },
    },

    setup(_) {
        const url = ref(process.env.VUE_APP_API_URL)
        const bearer = ref(window.localStorage.getItem('id_token'))

        const beforeUpload: UploadProps['beforeUpload'] = (rawFile) => {
            if(!['application/pdf', 'image/png', 'image/jpeg'].includes(rawFile.type)) {
                setTimeout(() => {
                    ElMessage.error('Allowed file types: pdf, jpeg, jpg and png')
                    return false
                }, 100);
            } else if (rawFile.size / 1024 / 1024 > 5) {
                setTimeout(() => {
                    ElMessage.error('Maximum allowed file size is 5MB')
                    return false
                }, 100);
            }
        }

        const onProgress: UploadProps['onProgress'] = (rawFile) => {
            console.log(rawFile)
        }

        const dataValue = computed(() => {
            const data = <dataValue> { 
                lead_id: _.lead_id, 
                policy_id: _.policy_id, 
                is_temp: 1 
            }
            if(_.lead_employee_category_id > 0) data.lead_employee_category_id = _.lead_employee_category_id

            return data
        })

        const onSuccess: UploadProps['onSuccess'] = async(response) => {
            
            const condition = <condition>{
                is_loaded: true,
                categories: ['standard'],
                lead_id: _.lead_id,
                policy_id:_.policy_id,
                lead_employee_category_id: _.lead_employee_category_id
            }

            if(condition.lead_employee_category_id == 0) delete condition.lead_employee_category_id

            await Documents.getDocuments(condition)
        }

        const onError = async (response) => {
            const error = JSON.parse(response.message)
            console.log(error.message)
            notification.warning(
                                'Upload',
                                error.message
                            )
        }
        return {
            dataValue,
            UploadFilled,
            bearer,
            url,
            onSuccess,
            onProgress,
            beforeUpload,
            onError
        }
    },
})
