
import { defineComponent, ref } from 'vue'


export default defineComponent({
    props: {
        modelValue: {
            type: String,
            required: false,
            default: ''
        },
        options: {
            type: Array,
            required: true,
        }
    },

    setup(props, context) {

        const updateValue = (event) => {
            context.emit("update:modelValue", event)
            context.emit("health", event)
        }

        const inputRef = ref()
        const focus = () => {
        setTimeout(() => {
                inputRef.value.focus()
            }, 100);
        }

        return {
            updateValue,
            inputRef,
            focus
        }
    },
})
