const customers = [
    {
        label: 'S no.',
        value: 'index',
        width: '80'
    },
    {
        label: 'Name',
        value: 'name',
        width: '250'
    },
    {
        label: 'Email',
        value: 'email',
        width: '250'
    },
    {
        label: 'Role',
        value: 'role',
        width: '150'
    },
    {
        label: 'Status',
        value: 'status',
        width: '150'
    },
    {
        label: 'Created On <br /> Updated On',
        value: 'dates',
        width: '280'
    },
    // {
    //     label: 'Actions',
    //     value: 'actions',
    //     width: '100'
    // },
]

export {
    customers
}