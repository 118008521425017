import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = {
  key: 0,
  class: "fa fa-check text-success",
  "aria-hidden": "true"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_customer_view_email_content = _resolveComponent("vc-customer-view-email-content")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    data: _ctx.lists,
    style: {"width":"100%"}
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sms, (row, i) => {
        return (_openBlock(), _createBlock(_component_el_table_column, {
          key: i,
          width: row.width
        }, {
          header: _withCtx(() => [
            _createElementVNode("span", {
              innerHTML: row.label
            }, null, 8, _hoisted_1)
          ]),
          default: _withCtx((scope) => [
            (row.value === 'index')
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(scope.$index + 1), 1))
              : (row.value === 'email_body')
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                    _createVNode(_component_vc_customer_view_email_content, {
                      content: scope.row[row.value],
                      ref: "sms_content"
                    }, null, 8, ["content"]),
                    _createElementVNode("i", {
                      class: "cursor-pointer fas fa-eye",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.sms_content.modal(true)))
                    })
                  ]))
                : (row.isClick)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                      (scope.row[row.value])
                        ? (_openBlock(), _createElementBlock("i", _hoisted_5))
                        : (_openBlock(), _createElementBlock("span", _hoisted_6, " - "))
                    ]))
                  : (_openBlock(), _createElementBlock("span", {
                      key: 3,
                      innerHTML: scope.row[row.value]
                    }, null, 8, _hoisted_7))
          ]),
          _: 2
        }, 1032, ["width"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["data"]))
}