// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum UserActionTypes {
    USERS = 'USERS',
    USER = 'USER',
    USER_UNDERWRITERS = 'USER_UNDERWRITERS',
    USER_TEAM_LEADS = 'USER_TEAM_LEADS',
    CREATE_USER = 'CREATE_USER',
    UPDATE_USER = 'UPDATE_USER',
    FETCH_USER = 'FETCH_USER',
    UPDATE = 'UPDATE',
  }
  