
import { computed, defineComponent, getCurrentInstance, onMounted, ref } from "vue";
import { useStore } from "vuex";
import Invoice from '@/core/services/Callable/Invoice'
import { registration, common } from '@/store/stateless';
import { invoice_details_group_columns } from '@/core/utils/invoice'

export default defineComponent({
    props: {
        // isError: {
        //     type: Boolean,
        //     : true,
        //     default: false,
        // }
    },

    setup(props) {
        const store = useStore();
        const currency = registration.currency
        const invoiceDetails = computed(() => {
            return store.state.invoice.invoiceDetails;
        });

        const invoice = computed(()=>{
            // console.log(invoiceDetails.value.invoice)
            let invoice = store.state.invoice.invoiceDetails.invoice;
            // invoice.categories.map(x => {
            //     return {
            //         ...x,
            //         counts: 12
            //     }
            // })
            if(invoice){
                invoice.is_split_payment = invoice.is_split_payment == 1 ? true : false;
                return invoice;
            }

            return null;
            // if(invoiceDetails.value.invoice){
            //     invoiceDetails.value.invoice.is_split_payment = invoiceDetails.value.invoice.is_split_payment == 1 ? true : false; 
            //     return invoiceDetails.value.invoice;
            // }
            // return null;
        })

        const closeModal = () => {
            const data = {
                show: false,
                invoice: null
            }
            Invoice.setInvoiceDetailsModal(data);
        }

        const policySalesAgent = computed(() => {
            return invoice.value.policy_sales_agent?.name
        })


        return {
            common,
            invoiceDetails,
            invoice,
            closeModal,
            currency,
            invoice_details_group_columns,
            policySalesAgent
        };
    },
});
