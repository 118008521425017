
import Customers from '@/core/services/Callable/Customers'
import router from '@/router/clean'
import { store } from '@/store'
import { defineComponent, ref, computed, onMounted } from 'vue'

export default defineComponent({
    setup() {
        const open = ref<Boolean>(false)
        const compose_emails = ref()
        const customer_id:any = router.currentRoute.value.params.customer_id?.toString()

        const emails = computed(() => {
            return store.state.customer.emails
        })

        const loading = computed(() => {
            return store.state.customer.email_loading
        })

        const modal = async(value: Boolean) => {
            open.value = value
            if(value) {
                await Customers.getCustomerEmail({ customer_id })
            }
        }


        const from = computed(() => {
            return store.getters.myEmail
        })
        const to = computed(() => {
            return store.getters.getCustomer.email
        })

        

        return {
            compose_emails,
            customer_id,
            loading,
            emails,
            open,
            modal,
            from,
            to
        }
    },
})
