
import { View, Hide } from '@element-plus/icons-vue';
import { defineComponent, ref, computed, onMounted } from "vue";
import Etcs from '@/core/services/Callable/Etcs';
import { store } from "@/store";

export default defineComponent({
    props: {
        label: {
            type: String,
            required: true,
            default: '',
        },
        prop: {
            type: String,
            required: true,
            default: 'User'
        },
        modelValue: {
            type: String,
            required: true,
            default: ''
        },
        code: {
            type: String,
            required: true,
            default: ''
        },
        placeholder: {
            type: [String, Boolean],
            required: false,
            default: ''
        },
        maxlength: {
            type: String,
            required: false,
            default: ""
        },
        minlength: {
            type: [String, Boolean],
            required: false,
            default: false
        },
        keydown: {
            type: String,
            required: false,
            default: ''
        },
        size: {
            type: String,
            required: false,
            default: 'large'
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        isRequired: {
            type: Boolean,
            required: false,
            default: false
        },
        rules: {
            type: Array,
            required: false,
            default: false,
        },
    }, 

    setup(props, context) {
        const show = ref(false);

        const updateValue = (event) => {
            context.emit("update:modelValue", event)
        }

        const updateCode = (event) => {
            context.emit("update:code", event)
        }

        const inputRef = ref()
        const focus = () => {
            setTimeout(() => {
                inputRef.value.focus()
            }, 500);
        }

        const countryCodes = computed(() => {
            return store.state.etc.countryCodes;
        })

        onMounted(() => {
            Etcs.getCountryCode();
        })

        return {
            focus,
            show,
            View,
            Hide,
            inputRef,
            countryCodes,
            updateValue,
            updateCode
        }
    }
})
