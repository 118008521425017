
import { defineComponent, onMounted, computed, ref } from 'vue'
import { store } from "@/store";
import Etcs from '@/core/services/Callable/Etcs';

interface IPayload {
    isDropdown: boolean;
}

export default defineComponent({
    props: {
        modelValue: {
            type: String,
            required: true,
            default: ''
        },
        label: {
            type: String,
            required: true,
            default: 'Select countries'
        }
    },

    setup(props, context) {

        let bodyParam = ref<IPayload>({
            isDropdown: true,
        });

        onMounted(() => {
            Etcs.getCountries(bodyParam.value);
        })

        const countries = computed(() => {
            return store.state.etc.countries
        })

        const updateValue = (event) => {
            context.emit("update:modelValue", event)
            context.emit("countries", event)
        }

        const inputRef = ref()
        const focus = () => {
        setTimeout(() => {
                inputRef.value.focus()
            }, 100);
        }

        return {
            focus,
            inputRef,
            countries,
            updateValue
        }
    },
})
