import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import router from "@/router/clean";
import { store } from "@/store";
import Auths from '@/core/services/Callable/Auths';

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Token ${JwtService.getToken()}`;
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    ApiService.vueInstance.axios.interceptors.request.use(
      config => {
        const token = store.state.auth.token;
        if (token && config.headers != undefined) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config
      },
      error => {
        return Promise.reject(error)
      }
    )

    ApiService.vueInstance.axios.interceptors.response.use((res) => {
      console.log(res)
      // path save to local storage if path !=== sign in
      // path will be use to return to original state once token expired
      if(router.currentRoute.value.fullPath !== '/sign-in' && router.currentRoute.value.fullPath !== '/') {
          window.localStorage.setItem('currentRoute', JSON.stringify(router.currentRoute.value.fullPath));
      }
      return res;
      }, ((error) => {
        if((typeof(error.response) !== 'undefined') && error.response.status === 403) {
          // localStorage.clear();
          // window.location.reload();
          // setTimeout(() => {
          //   router.push('/sign-in');
          // }, 1000);
        }
          return Promise.reject(error);
      })
    )

    return ApiService.vueInstance.axios.get(resource, params).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {

    ApiService.vueInstance.axios.interceptors.request.use(
      config => {
        const token = store.state.auth.token;
        if (token && config.headers != undefined) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config
      },
      error => {
        return Promise.reject(error)
      }
    )

    ApiService.vueInstance.axios.interceptors.response.use((res) => {
      // path save to local storage if path !=== sign in
      // path will be use to return to original state once token expired
      if(router.currentRoute.value.fullPath !== '/sign-in' && router.currentRoute.value.fullPath !== '/') {
          window.localStorage.setItem('currentRoute', JSON.stringify(router.currentRoute.value.fullPath));
      }
      return res;
      }, ((error) => {
        if((typeof(error.response) !== 'undefined') && error.response.status === 403) {
          // localStorage.clear();
          // window.location.reload();
          // setTimeout(() => {
          //   router.push('/sign-in');
          // }, 1000);
        }
          return Promise.reject(error);
      })
    )

    return ApiService.vueInstance.axios
      .get(`${resource}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig | null
  ): Promise<AxiosResponse> {
    
    ApiService.vueInstance.axios.interceptors.request.use(
      config => {
        const token = store.state.auth.token;
        const uuid = window.localStorage.getItem('uid')

        if (token && config.headers != undefined) {
          config.headers['Authorization'] = `Bearer ${token}`;
          config.headers['Content-Type'] = 'application/json';
          config.headers['uid'] = uuid ?? ''
        }
        
        return config
      },
      error => {
        return Promise.reject(error)
      }
    )

    ApiService.vueInstance.axios.interceptors.response.use((res) => {
          // path save to local storage if path !=== sign in
          // path will be use to return to original state once token expired
          if(router.currentRoute.value.fullPath !== '/sign-in' && router.currentRoute.value.fullPath !== '/') {
              window.localStorage.setItem('currentRoute', JSON.stringify(router.currentRoute.value.fullPath));
          }
          return res;
      }, ((error) => {
        if((typeof(error.response) !== 'undefined') && error.response.status === 403) {
          // localStorage.clear();
          // window.location.reload();
          // setTimeout(() => {
          //   router.push('/sign-in');
          // }, 1000);
        }
          return Promise.reject(error);
      })
    )

    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static post_get(
    resource: string,
  ): Promise<AxiosResponse> {
    
    ApiService.vueInstance.axios.interceptors.request.use(
      config => {
        const token = store.state.auth.token;
        if (token && config.headers != undefined) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config
      },
      error => {
        return Promise.reject(error)
      }
    )

    ApiService.vueInstance.axios.interceptors.response.use((res) => {
          // path save to local storage if path !=== sign in
          // path will be use to return to original state once token expired
          if(router.currentRoute.value.fullPath !== '/sign-in' && router.currentRoute.value.fullPath !== '/') {
              window.localStorage.setItem('currentRoute', JSON.stringify(router.currentRoute.value.fullPath));
          }
          return res;
      }, ((error) => {
        if((typeof(error.response) !== 'undefined') && error.response.status === 403) {
          // localStorage.clear();
          // window.location.reload();
          // setTimeout(() => {
          //   router.push('/sign-in');
          // }, 1000);
        }
          return Promise.reject(error);
      })
    )

    return ApiService.vueInstance.axios.post(`${resource}`);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
}

export default ApiService;
