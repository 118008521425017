import { GENERIC } from '@/core/types/Etc';

const defaultOverview = {
  total_customers_leads:0,
  total_customer_deals:0,
  total_deals:0,
  total_leads:0,
  closing_ratio:0,
  basic:0,
  enhance:0,
  group:0,
  total_revenue:0,
  wip_leads:0,
  untouched_hot_enquiry:0,
  untouched_renewal_hot_leads:0,
  customer_renewal_leads:0,
  customer_renewal_deals:0,
  customer_llr_leads:0,
  customer_llr_deals:0,
  lifetime_renewal_deals:0,
  total_refund:0,
  partial_refund:0,
  full_refund:0,
  total_refund_amount:0,
  partial_refund_amount:0,
  full_refund_amount:0,
  total_cancellation:0,
  partial_cancellation:0,
  full_cancellation:0,
  total_cancellation_amount:0,
  partial_cancellation_amount:0,
  full_cancellation_amount:0,
}


const defaultSalesOverview = {
  over_due_tasks:0,
  todays_task_count:0,
  revenue_made:0,
  revenue_target:0,
  total_deals:0,
  sales_target:0,
  variance:0,
  closing_ratio_total:0,
  new_leads:0,
  quick_leads:0,
  pending_leads:0,

  renewal_leads:0,
  llr_leads:0,
  lost_leads:0,
  lrl_leads:0,
  current_month_tasks:0,
  previous_month_tasks:0,

  cancellation_amount:0,
  cancellation_count:0,
  total_refund_count:0,
  total_refund:0,
}

const underwriter = {
  assigned: 0,
  assigned_in_month_until_yesterday: 0,
  assigned_prev_month: 0,
  assigned_prev_months: 0,
  assigned_this_month: 0,
  assigned_today: 0,
  average_policy_time_hours: 0,
  cancellation: 0,
  cancellation_amount: 0,
  completed: 0,
  full_cancellation: 0,
  full_cancellation_amount: 0,
  id: 0,
  name: "",
  overdue: 0,
  partial_cancellation: 0,
  partial_cancellation_amount: 0,
  pending_quote_requests: 0,
  refunds: 0,
  sold: 0,
}

const defaultTopSalesPerson = {
  agent: 0,
  agent_name: "",
  revenue_made: 0,
  cancellation_amount: 0,
  cancellation_count: 0,
  closing_ratio_llr: "",
  closing_ratio_new: "",
  closing_ratio_renewal: "",
  closing_ratio_total: "",
  current_month_tasks: 0,
  is_target_flag: true,
  leads_this_month: 0,
  llr_deals: 0,
  llr_leads: 0,
  lost_leads: 0,
  lrl_leads: 0,
  new_deals: 0,
  new_leads: 0,
  over_due_tasks: 0,
  pending_leads: 0,
  previous_month_tasks: 0,
  quick_leads: 0,
  renewal_deals: 0,
  renewal_leads: 0,
  revenue_target: "",
  revenue_target_achieved: "",
  revenue_target_no_of_days: 0,
  sales_target: 0,
  target_percentage_met: "",
  todays_task_count: 0,
  total_deals: 0,
  total_leads: 0,
  total_refund: 0,
  total_refund_count: 0,
  total_revenue: "",
  untouched_customers: 0,
  untouched_leads: 0,
  variance: "",
  wip_deals: 0,
  yesterdays_task_count: 0
}

const defaultSalesTarget = {
  id: 0,
  name: "",
  refund: 0,
  revenue_made: 0,
  revenue_target: 0,
  sales_target: 0,
  total_deals: 0,
}

const defaultLeadsTarget = {
  achieved: 0,
  id: 0,
  marketing_spend: 0,
  name: "",
  no_of_leads: 0,
}

const defaultInvoice = {
  cod: 0,
  cod_direct_intransact: 0,
  direct: 0,
  intransact: 0,
  manual_order: 0,
  online: 0,
  premium_discrepancy: 0
}

const defaultPolicies = {
  assigned: 0,
  cancellation_approval: 0,
  cancelled: 0,
  cancelled_amount: 0,
  cancelled_full: 0,
  cancelled_full_amount: 0,
  cancelled_partial: 0,
  cancelled_partial_amount: 0,
  completed: 0,
  total_deals: 0,
  total_deals_cancelled: 0,
  total_deals_cancelled_revenue: 0,
  total_deals_revenue: 0
}

const defaultRefunds = {
  completed: 0,
  completed_amount: 0,
  completed_closed: 0,
  completed_closed_amount: 0,
  completed_full: 0,
  completed_full_amount: 0,
  completed_partial: 0,
  completed_partial_amount: 0,
  pending: 0
}

const defaultMarkerting = {
    colors: [],
    count: [],
    names: []
}

export type State = {
  filter:any;
  overview:any;
  salesPersonInfo:any;
  salesOverview:any;
  leadSourceGraph:any;
  statGraph:any;
  tableStat:any;
  otherStat:any;
  leadsNew:any;
  leadsPending:any;
  leadsLost:any;
  codTransactions:any;
  directTransactions:any;
  chequeTransactions:any;
  bankTransferTransactions:any;
  groupCodTransactions:any;
  groupDirectTransactions:any;
  groupChequeTransactions:any;
  groupBankTransferTransactions:any;
  otherAveragePoliciesData:any;
  otherClosingRatio:any;
  underwriterInfo: any;
  accountsInfo: any
  marketingInfo: any
}


export const state: State = {
  filter: {
    url:"",
    start_date: "",
    end_date: "",
    year: "",
    type: "today",
    tab: 0
  },

  overview : {
      total_sales : defaultOverview,
      new_sales : defaultOverview,
      wip_sales : defaultOverview,
      renewal_sales : defaultOverview,
      llr_sales : defaultOverview,
      lrl_sales : defaultOverview,
      lr_sales : defaultOverview,
      untouched_sales : defaultOverview,
      refunds : defaultOverview,
      cancellation : defaultOverview,
  },

  salesPersonInfo: {
    topSalesAgent:[defaultTopSalesPerson],
    salesTarget:[defaultSalesTarget],
    leadsTarget:[defaultLeadsTarget],
    topPerformance: [defaultTopSalesPerson],
  },

  underwriterInfo: [underwriter],
  accountsInfo: {
    invoices: defaultInvoice,
    policies: defaultPolicies,
    refunds: defaultRefunds
  },
  marketingInfo: {
    lead_source_deals: {
      new: defaultMarkerting,
      total: defaultMarkerting
    },
    lead_source_leads: {
      deleted: defaultMarkerting,
      new: defaultMarkerting,
      total: defaultMarkerting,
    },
    organic_utm_campaign_deals: {
      wip: defaultMarkerting
    },
    organic_utm_content_deals: {
      wip: defaultMarkerting
    },
    organic_utm_source_deals: {
      new: defaultMarkerting,
      total: defaultMarkerting
    },
    organic_utm_source_leads: {
      new: defaultMarkerting,
      total: defaultMarkerting
    },
    paid_utm_campaign_deals: {
      llr: defaultMarkerting,
      new: defaultMarkerting,
      renewal: defaultMarkerting,
      wip: defaultMarkerting
    },
    paid_utm_campaign_leads: {
      deleted: defaultMarkerting,
      llr: defaultMarkerting,
      new: defaultMarkerting,
      renewal: defaultMarkerting
    },
    paid_utm_content_deals: {
      wip: defaultMarkerting,
      llr: defaultMarkerting,
      new: defaultMarkerting,
      renewal: defaultMarkerting
    },
    paid_utm_content_leads: {
      llr: defaultMarkerting,
      new: defaultMarkerting,
      renewal: defaultMarkerting
    },
    paid_utm_source_deals: {
      new: defaultMarkerting,
      total: defaultMarkerting
    },
    paid_utm_source_leads: {
      new: defaultMarkerting,
      total: defaultMarkerting
    },
    paid_vs_organic_deals: {
      new: defaultMarkerting,
      total: defaultMarkerting,
      wip: defaultMarkerting
    },
    paid_vs_organic_leads: {
      new: defaultMarkerting,
      total: defaultMarkerting
    }
    
  },

  salesOverview: defaultSalesOverview,

  leadSourceGraph: {},

  statGraph: {
    paid_vs_organic_leads:{new:{}},
    paid_utm_source_leads:{new:{}},
    organic_utm_source_leads:{new:{}},
    paid_utm_campaign_leads:{new:{}},
    paid_vs_organic_deals:{new:{},wip:{}},
    paid_utm_campaign_deals:{new:{}},
    paid_utm_source_deals:{new:{}},
    organic_utm_source_deals:{new:{}},
    lead_source_leads:{new:{}},
    lead_source_deals:{new:{}},
    paid_utm_content_leads:{new:{}},
    paid_utm_content_deals:{new:{}},
    organic_utm_content_deals:{new:{}},
    organic_utm_campaign_deals:{new:{}},
  },

  tableStat: {
    top_provider:[],
    top_emirate:[],
    top_group_provider:[],
    top_group_emirate:[],
    top_brand_plans:[],
    top_insured:[],
  },

  otherStat: {
    leadsNew: {},
    leadsLost: {},
    leadsPending: {},
  },

  leadsNew : null,
  leadsPending: null,
  leadsLost:null,
  codTransactions:null,
  directTransactions:null,
  chequeTransactions:null,
  bankTransferTransactions:null,
  groupCodTransactions:null,
  groupDirectTransactions:null,
  groupChequeTransactions:null,
  groupBankTransferTransactions:null,
  otherAveragePoliciesData:null,
  otherClosingRatio:null

};
