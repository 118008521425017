import { App } from 'vue';
// import filter from '@/core/functions/filters';
import { auth } from '@/core/functions/auth';
import { customer } from '@/core/functions/customer';
import { roles } from '@/core/functions/roles';

export function initGlobalFunction (app: App<Element>) {
    // filter(app);
    auth(app);
    roles(app);
    customer(app);
}