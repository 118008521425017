import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "container ps-2 pe-2" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_pie_chart = _resolveComponent("vc-pie-chart")!
  const _component_vc_table_stats = _resolveComponent("vc-table-stats")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.utm_stats, (card, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: _normalizeClass(["mb-6 p-1", card.div_class])
        }, [
          _createVNode(_component_vc_pie_chart, {
            chartOptions: _ctx.graph[card.value][card.type].options,
            series: _ctx.graph[card.value][card.type].series || [],
            title: card.label
          }, null, 8, ["chartOptions", "series", "title"])
        ], 2))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.table_stats, (card, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: _normalizeClass(["mb-6 p-1", card.div_class])
        }, [
          _createVNode(_component_vc_table_stats, {
            items: _ctx.tableStat[card.value],
            columns: card.columns,
            showCount: card.showCount,
            title: card.label,
            backgroundColor: card.backgroundColor,
            fontColor: card.fontColor
          }, null, 8, ["items", "columns", "showCount", "title", "backgroundColor", "fontColor"])
        ], 2))
      }), 128))
    ])
  ]))
}