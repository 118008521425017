import { Filter, Listing, Counts } from '@/core/types/Policy';
import moment from 'moment';

export type State = {
  filter: Filter;
  loading: boolean;
  policyLists: any;
  policyListCounts: Array<Counts>;
  policy:any;
  previous_start_days:number;
  upcoming_start_days:number;
  transfer_sale:any;
  transfer_underwriter:any;
  accepted_debit_amount_per:number;
  purchased_policies:Array<any>;
  policy_logs:Array<any>;
  policy_documents:{
    open:boolean
  },
  email_activity: Array<any>;
  activeTab: number;
  groupPolicyLists: any;
  groupPolicyListCounts: Array<Counts>;
  groupLeadPolicies: any,
}

export const state: State = {
  
  filter: {
    type:'all',
    task_due_dates:'all',
    agent: "all",
    policy_sales_agent: "all",
    underwriter:"all",
    end_date: "",
    index: 1,
    keyword: "",
    page: 1,
    per_page: 50,
    start_date: "",
    payment_type: -1,
    status: 1
  },
  loading:false,
  policyLists: null,
  policyListCounts : [],
  policy:null,
  previous_start_days:0,
  upcoming_start_days:0,
  transfer_sale:{
    open:false,
    policy_id:null,
    agent:null,
    is_group:false
  },
  transfer_underwriter:{
    open:false,
    policy_id:null,
    underwriter:null,
    is_group:false
  },
  accepted_debit_amount_per:100,
  purchased_policies:[],
  policy_logs:[],
  policy_documents:{
    open:false
  },
  email_activity:[],
  activeTab: 0,
  groupPolicyLists: null,
  groupPolicyListCounts : [],
  groupLeadPolicies: []
};
