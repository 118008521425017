
  import { defineComponent, computed, ref, onMounted } from "vue";
  import { lead_tabs } from "@/core/utils";
  import { store } from "@/store";
  import { customer, notification } from '@/store/stateless';
  import router from "@/router/clean";

  export default defineComponent({
    props: {
      lead_count: {
        type: Number,
        required: true,
      },
      customer_id: {
        type: Number,
        required: true,
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    setup(_) {

      const leads = computed(() => {
            return store.getters.getLeads(false)
      })  

      const onlyGroup = computed(() => {
        return store.getters.getOnlyGroup
      })
      const permissions = computed(() => {
            return store.getters.getUserPermissions
        })
      
      const cd = computed(() => {
            return store.getters.getCustomer
        })
      const transfer = ref()

      const openRoute = () => {
        if(_.customer_id)
          router.push({ name: 'NewGroupDetail', params:{ customer_id:_.customer_id } }).then(() => router.go(0))

        // window.location.href = corrected.href
      };
      
      onMounted(() => {
        customer.checkedTransfer = [_.customer_id]
      })

        
      return {
        onlyGroup,
        lead_tabs,
        transfer,
        customer,
        cd,
        permissions,
        leads,
        openRoute
      };
    },
  });
