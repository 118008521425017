import { GetterTree } from 'vuex';
import router from "@/router/clean";

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { UserData } from '@/core/types/User'


import { State } from './state';

export type Getters = {
  getUser(state: State): any | null;
}


export const getters: GetterTree<State, RootState> & Getters = {
  getUser : (state) => state.user,
  getUserLoaded: (state) => {
    return Object.keys(state.users).length > 0 ? true : false
  },
  currentRole: () => {
    const user = JSON.parse(window.localStorage.getItem('user') || '')
    if(user)
      return user.role_id

    return null
  },
  getUsers: (state) => {
    const { data } = state.users
    
    if(Object.keys(state.users).length > 0) {
      return data.map((x, i) => {
        return {
          index: i + 1,
          user_id: x.id,
          name:  x.name,
          email: x.email,
          role: x.role.name,
          status: x.user_status,
          dates: `${x.created_at} <br /> ${x.updated_at}`,
          actions: true
        }
      })
    }
    
    return false
  }
};


