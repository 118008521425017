import { store } from "@/store";
import { App } from "vue";
import { InquiryActionTypes } from '@/store/enums/Inquiry/Actions';
import { InquiryListPayload } from '@/core/types/Inquiry';

class Inquiry {
    public static vueInstance: App;

    public static init(app: App<Element>) {
        Inquiry.vueInstance = app;
    }

    public static async getInquiry(payload: InquiryListPayload) {
        return await store.dispatch(InquiryActionTypes.INQUIRIES, payload)
    }


}


export default Inquiry