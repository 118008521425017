
import { defineComponent, computed, h, onMounted } from "vue";
import { ElDivider } from "element-plus";
import { store } from "@/store";
import Documents from "@/core/services/Callable/Documents";
import { amend_doc_columns, amend_doc_actions } from "@/core/utils/policy.js";

export default defineComponent({
  // name: 'policy-edit',

  setup() {
    const policy = computed(() => {
      return store.state.policy.policy;
    });

    const amend_documents = computed(() => {
      return store.state.document.amend_documents;
    });

    const makeAction = (value, action) => {
          switch (action) {
            
              case 2:
                  window.open(value.encrypted_file_path); break;
              case 3:
                  window.open(value.file_path); break;
                  
              default:
                  return '';
          }
      }

    return {
      policy,
      amend_documents,
      amend_doc_columns,
      amend_doc_actions,
      makeAction
    };
  },
});
