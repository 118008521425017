import { store } from "@/store";
import { App } from "vue";
import { UserActionTypes } from '@/store/enums/User/Actions';
import { UserMutationTypes } from '@/store/enums/User/Mutations';
import { UserListPayload, User } from '@/core/types/User';

class Users {
    public static vueInstance: App;

    public static init(app: App<Element>) {
        Users.vueInstance = app;
    }

    public static async getUsers(payload: UserListPayload) {
        return await store.dispatch(UserActionTypes.USERS, payload)
    }

    public static async getUnderwriters(payload) {
        return await store.dispatch(UserActionTypes.USER_UNDERWRITERS, payload)
    }

    public static async getTeamLeads(payload) {
        return await store.dispatch(UserActionTypes.USER_TEAM_LEADS, payload)
    }

    public static async createUser(payload: User) {
        return await store.dispatch(UserActionTypes.CREATE_USER, payload)
    }

    public static async updateUser(payload: User) {
        return await store.dispatch(UserActionTypes.UPDATE_USER, payload)
    }

    public static async fetchUser(payload: any) {
        return await store.dispatch(UserActionTypes.FETCH_USER, payload)
    }

    public static setUserDefault() {
        return store.commit(UserMutationTypes.SET_USER, {
            id: 0,
            name: "",
            email: "",
            customerCount: false,
            roles: [],
            role_id: 0,
            password: "",
            password_confirmation: "",
            other_mobile_number: "",
            office_mobile_number: "",
            is_round_robin: null,
            policy_agent_id: 0,
            assign_lang: [],
            assign_en_leads: 0,
            assign_ar_leads: 0,
            agent_type: 0,
            is_new: 0,
            is_renewal: 0,
            is_both: 0,
            renewal_deal: 0,
            is_llr: 0,
            is_all_deals: 0,
            assign: 0,
            assign_car: 0,
            assign_health: 0,
            assign_home: 0,
            assign_travel: 0,
            status: 1,
            skip_round: 0,
            agents: [],
            team_leader: 0,
            is_archived: 0,
            agentCount: 0,
            is_edit: false
        })
    }


}


export default Users