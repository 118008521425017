
import { defineComponent, computed } from 'vue'
import { store } from "@/store";
import { customer } from '@/store/stateless/store';

export default defineComponent({
    setup() {

        const health = computed(() => {
            return store.state.customer.health;
        })

        const addMeToSponsor = () => {
            if(health.value?.is_self) {
                health.value?.members?.unshift({
                    id: null,
                    insured_type_id: null,
                insured_city: null,
                    member_name: health.value.name,
                    relationship: 'Sponsor',
                    gender: health.value.gender,
                    dob: health.value.dob,
                    nationality_id: health.value.nationality_id,
                    is_salary: health.value.is_salary,
                    declaration_answers: [],
                    is_married: false
                })
            } else {
                health.value?.members?.splice(0, 1);
            }
        }

        return {
            customer,
            health,
            addMeToSponsor
        }
    },
})
