
import { computed, defineComponent, onMounted, getCurrentInstance } from 'vue';
import { store } from "@/store";
import { Search } from '@element-plus/icons-vue';
import Customers from '@/core/services/Callable/Customers';
import Etcs from '@/core/services/Callable/Etcs';
import { customer, filter as filters } from '@/store/stateless';
import router from '@/router';


export default defineComponent({
    setup() {
        const currentInst = getCurrentInstance();
        const roles = currentInst?.appContext.config.globalProperties.roles;

        const filter = computed(() => {
            return store.state.customer.filter
        })

        const userTypes = computed(() => {
            return store.state.etc.user_types;
        })

        const updateValue = async () => {
            await Customers.customerLists(filter.value)
        }

        const searchCustomer = async () => {
            Customers.setFilter({
                ...filter.value,
                page: 1,
                task_due_dates: -1,
            })

            router.replace({ query: {  page: filter.value.page } })
            await Customers.customerLists(filter.value)
        }

        const onChangeHotEnquiry = async () => {
            if (filter.value.hot_enquiry == -1) {
                filter.value.hot_enquiry = 1;
            } else {
                filter.value.hot_enquiry = -1;
            }
            await Customers.customerLists(filter.value)
        }

        onMounted(async () => {
            await Etcs.getUserType();

            const user_types = store.state.etc.user_types;
            const exist = user_types.some((e) => { return e.value == -1 });
            if (!exist) {
                user_types.unshift({ label: "Please select user/Customer Type", value: -1 });
            }
        })

        const resetFilter = async () => {
            let agent:any = 'all';
                if(store.getters.showByRole([1])) 
                    agent = 'all';
                else if(store.getters.showByRole([9,13])) 
                    agent = -1;
                else 
                    agent = store.getters.myId;
            Customers.setFilter({
                agent: agent,
                customer_status_id: -1,
                end_date: "",
                index: 1,
                keyword: "",
                lead_status_id: -1,
                per_page: 50,
                page: 1,
                start_date: "",
                task_due_dates: -1,
                hot_enquiry: -1
            })
            console.log(filter.value)
            customer.colors = {
                btnBgColor: "#fff",
                btnColor: "#000",
                slcBgColor: filter.value.agent === 'all' ? "#fff" : '#222261',
                slcColor: filter.value.agent === 'all' ? "#5E6278" : '#fff'
            }

            filters.customerDateRangeLT = '';

            await Customers.customerLists(filter.value)
        }

        return {
            filter,
            filters,
            Search,
            userTypes,
            resetFilter,
            updateValue,
            searchCustomer,
            onChangeHotEnquiry,
        }
    },
})
