
import { computed, defineComponent, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router';
import { store } from "@/store";
import Auths from '@/core/services/Callable/Auths';

export default defineComponent({
    setup() {

        const apps = ref([
            {
                label: 'Car SKYE',
                value: 'car'
            },
            {
                label: 'Misk SKYE',
                value: 'misk'
            },
        ])

        
        const value = ref<string>('Health SKYE');
        const router = useRouter()

        const redirect = async(switchTo) => {
            
            const refresh_token = window.localStorage.getItem('refresh_token') || ''
            const access_token  = window.localStorage.getItem('id_token') || ''
            const user          = JSON.parse(window.localStorage.getItem('user') || '')
            
            if(access_token && refresh_token && user){
                const link = await url(refresh_token,switchTo)
                const payload = {
                        'switch_to':switchTo,
                        'user_id':user.id,
                        'switch_path':link.href,
                    };  
                
                /**
                 * Awaiting to complete the switch app API request
                 * From switch app api, switchapp-{userid} websocket 
                 * is firing. This websocket is responsible for Page redirection
                 */
                await Auths.switchAppReload(payload);
            }else{
                window.location.reload;
            }
        }

        const url = async(refreshToken,switchTo) => {
            return router.resolve({ name: 'switch-app', params: { refreshToken,switchTo  } })
        }

        // 1-admin, 9-salesManager, 4-u/w, 6-accountant, 11-marketingAdmin
        // const disable = computed(()=>!store.getters.showByRole([1, 9, 4, 6, 11]));
        const switchApp = computed(() => {
            let currentUser =  store.getters.getAuthUser;
            /**
             * 1  : Admin
             * 9  : Sales Manager
             * 6  : Accountant
             * 4  : Underwriter
             * 11 : Marketing Manger
             */
            return (currentUser.can_switch_app !== undefined) ? currentUser.can_switch_app : false;
        })

        return {
            apps,
            value,
            redirect,
            switchApp
        }
    },
})
