import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "svg-icon svg-icon-5 svg-icon-gray-500 me-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.onlyIcon)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          type: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.nav && _ctx.nav(...args))),
          class: _normalizeClass(_ctx.className)
        }, [
          (_ctx.isIcon)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                _createElementVNode("i", {
                  class: _normalizeClass(_ctx.icon)
                }, null, 2)
              ]))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.text), 1)
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.onlyIcon)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          class: _normalizeClass(_ctx.className),
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.nav && _ctx.nav(...args))),
          style: {"cursor":"pointer"}
        }, null, 2))
      : _createCommentVNode("", true)
  ], 64))
}