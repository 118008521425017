// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum 
PolicyActionTypes {
  POLICY_LISTS = "POLICY_LISTS",
  GET_POLICY = "GET_POLICY",
  UPDATE_POLICY = "UPDATE_POLICY",
  SUBMIT_POLICY = "SUBMIT_POLICY",
  GET_EDIT_POLICY_SETTING = "GET_EDIT_POLICY_SETTING",
  TRANSFER_SALE = "TRANSFER_SALE",
  CHANGE_UNDERWRITER = "CHANGE_UNDERWRITER",
  GET_PURCHASED_POLICIES = "GET_PURCHASED_POLICIES",
  GET_POLICY_LOG = "GET_POLICY_LOG",
  SEND_CUSTOMER_EMAIL = 'SEND_CUSTOMER_EMAIL',
  SEND_BROKER_EMAIL = 'SEND_BROKER_EMAIL',
  GET_CHILD_POLICIES= 'GET_CHILD_POLICIES',
  GET_EMAIL_ACTIVITY = 'GET_EMAIL_ACTIVITY',

  GROUP_POLICY_LISTS = "GROUP_POLICY_LISTS",
  GROUP_GET_POLICY = "GROUP_GET_POLICY",
  GROUP_UPDATE_POLICY = "GROUP_UPDATE_POLICY",
  GROUP_SUBMIT_POLICY = "GROUP_SUBMIT_POLICY",
  GROUP_UPLOAD_TRADE_LICENSE = "GROUP_UPLOAD_TRADE_LICENSE",
  GROUP_GET_EDIT_POLICY_SETTING = "GROUP_GET_EDIT_POLICY_SETTING",
  GROUP_TRANSFER_SALE = "GROUP_TRANSFER_SALE",
  GROUP_CHANGE_UNDERWRITER = "GROUP_CHANGE_UNDERWRITER",
  GROUP_LEAD_POLICY_DETAILS = "GROUP_LEAD_POLICY_DETAILS",
  GROUP_GET_PURCHASED_POLICIES = "GROUP_GET_PURCHASED_POLICIES",
  GROUP_GET_POLICY_LOG = "GROUP_GET_POLICY_LOG",
  GROUP_SEND_CUSTOMER_EMAIL = 'GROUP_SEND_CUSTOMER_EMAIL',
  GROUP_SEND_BROKER_EMAIL = 'GROUP_SEND_BROKER_EMAIL',
  GROUP_GET_CHILD_POLICIES= 'GROUP_GET_CHILD_POLICIES',
  GROUP_GET_EMAIL_ACTIVITY = 'GROUP_GET_EMAIL_ACTIVITY',
  SEND_GROUP_BROKER_EMAIL = 'SEND_GROUP_BROKER_EMAIL',
  GET_GROUP_EMAIL_ACTIVITY = 'GET_GROUP_EMAIL_ACTIVITY'
}
  