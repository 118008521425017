<template>
  <div class="card">
    <div class="card-header boder-0 pt-5 w-100 d-flex" :style="`background-color: ${backgroundColor} !important`">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1" :style="`color: ${fontColor} !important`">
          {{ title }}
          <span v-if="showCount" class="badge badge-primary bold uppercase">Total: {{ items.length }}</span>
          <span v-if="lists && lists.total" style="color: red; font-size: 1.25rem;">({{ lists.total }})</span>

        </span>
      </h3>

    </div>
    <div class="card-body w-100">
      <!-- <div v-if="lists && lists.total">
        <h1 style="color: red; font-size: 1.75rem;">{{ lists.total }}</h1>
      </div> -->

      <div class="container" v-if="isBodyFields">
        <div class="row d-flex justify-content-center" v-if="!twoAndDown">
          <div
            :class="
              Object.keys(BodyFields).length == 3
                ? 'col-sm-4'
                : Object.keys(BodyFields).length == 5
                ? 'col-sm-2'
                : 'col-sm-3'
            "
            v-for="(item, index) in BodyFields"
            :key="index"
          >
            <p :style="`text-align: center; font-size: ${textSize}`">
              {{ index }}
            </p>
            <h2
              :style="`text-align: center; font-size: ${textValue}; color: green`"
            >
              {{ item }}
            </h2>
          </div>
        </div>
        <div class="row d-flex justify-content-center" v-else>
          <div
            class="col-sm-6"
            v-for="(item, index) in BodyFields"
            :key="index"
          >
            <p :style="`text-align: center; font-size: ${textSize}`">
              {{ index }}
            </p>
            <h3
              :style="`text-align: center; font-size: ${textValue}; color: blue`"
              v-if="index"
            >
              {{ item }}
            </h3>
            <h3
              style="text-align: center; font-size: 2.5rem; color: red"
              v-else
            >
              {{ item }}%
            </h3>
          </div>
        </div>
      </div>
      <div class="table-custom-invoice" v-else>

        <el-table :data="items">

          <template v-for="(row, i) in columns" :key="i">
            <el-table-column  >
              <template #header>
                <span class="d-block fs-6 fw-bolder" v-html="row.label"></span>
              </template>
              <template #default="scope">
                <span v-if="row.isAmount">
                {{ common.formatCurrency(scope.row[row.value]) }}

                </span>
                <span v-else>
                {{ scope.row[row.value] }}
                </span>
              </template>
            </el-table-column>
          </template>
        </el-table>

      </div>
    </div>
    <div class="mb-4" v-if="lists && lists.last_page > 1">
      <el-pagination 
                :page-size="5"
                :background="false" 
                layout="prev, pager, next"
                :total="lists.total" 
                @size-change="getLists" 
                @current-change="getLists" 
            />
    </div>
  </div>
</template>

<script>
  import { defineComponent } from "vue";
  import { common } from "@/store/stateless";

  export default defineComponent({
    props: {
      lists: {
        type:Object,
        default:null
      },
      page:{
        required:false,
        default:1
      },
      items: [],
      columns: [],
      title: String,
      backgroundColor: {
        type: String,
        required: false,
        default: "#fff"
      },
      fontColor: {
        type: String,
        required: false,
        default: "#000"
      },
      showCount: {
        type: Boolean,
        required: false,
        default: false
      },
      key: {
        type:String,
        default:''
      },
      isBodyFields: {
        type: Boolean,
        required: false,
        default: false
      },
      BodyFields: {
        Type: Array,
        required: false,
        default: []
      },
    },
    setup(_,{ emit }) {
      
      const getLists = (page) => {
        console.log('click',page)
        emit('pagination',page)
      }

      return {
        getLists,
        common
      }
    }


  });
</script>