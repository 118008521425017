
import { computed, defineComponent, getCurrentInstance, watchEffect } from 'vue'
import { store } from "@/store";

export default defineComponent({
    props: {
        firstError: {
            type: String,
            required: false,
            default: ''
        }
    },
    setup(_, context) {

        const user = computed(() => {
            return store.state.user.user
        })

        const showByRole = computed(() => {
            return [2, 9, 13].includes(user.value.role_id)
        })

        const resetByRole = () => {
            user.value.policy_agent_id = 0
            user.value.team_leader = 0
            user.value.is_round_robin = null
            user.value.skip_round = 0
            user.value.is_new = 0
            user.value.is_renewal = 0
            user.value.is_both = 0
            user.value.agent_type = 0
            user.value.renewal_deal = 0
            user.value.is_all_deals = 0
            user.value.is_llr = 0
            user.value.assign = 0
            user.value.assign_car = 0
            user.value.assign_health = 0
            user.value.assign_home = 0
            user.value.assign_travel = 0
            user.value.assign_lang = []
            user.value.assign_en_leads = 0
            user.value.assign_ar_leads = 0
            user.value.agents = []
        }

        watchEffect(() => {
            if(user.value.assign_lang.length > 0) {
                user.value.assign_en_leads = user.value.assign_lang.filter(x => x === 'en').length > 0 ? 1 : 0
                user.value.assign_ar_leads = user.value.assign_lang.filter(x => x === 'ar').length > 0 ? 1 : 0
            } else {
                user.value.assign_en_leads = 0
                user.value.assign_ar_leads = 0
            }

            if(user.value.roles.length > 0) {
                user.value.role_id = user.value.roles[0]
            }
        })

        const selectedAgent = (e: number) => {
            user.value.is_new = 0
            user.value.is_renewal = 0
            user.value.is_both = 0

            user.value.renewal_deal = 1

            switch(e) {
                case 1: user.value.is_new = 1; break;
                case 2: user.value.is_renewal = 1; break;
                case 3: user.value.is_both = 1; break
                default: break;
            }
        }

        const selectedDeals = (e: number) => {
            user.value.is_all_deals = 0
            user.value.is_llr = 0

            switch(e) {
                case 1: user.value.is_all_deals = 0; break
                case 2: user.value.is_all_deals = 1; break
                case 3: user.value.is_llr = 1; break
                default: break;
            }
        }

        const selectedAssign = (e: number) => {
            user.value.assign_car = 0
            user.value.assign_health = 0
            user.value.assign_home = 0
            user.value.assign_travel = 0

            switch(e) {
                case 1: user.value.assign_car = 1; break
                case 2: user.value.assign_health = 1; break
                case 3: user.value.assign_home = 1; break
                case 4: user.value.assign_travel = 1; break
                default: break;
            }
        }

        const inst = getCurrentInstance()
        const focus = () => {
            setTimeout(() => {
                const toFocus:any = inst?.refs[_.firstError]
                    if(toFocus) {
                        toFocus.focus()
                        }
                }, 100);
        }

        return {
            showByRole,
            user,
            resetByRole,
            selectedDeals,
            selectedAgent,
            selectedAssign,
            focus
        }
    },
})
