
import { defineComponent, ref, computed, getCurrentInstance, watchEffect } from 'vue'
import { store } from '@/store';
import { common, constant, notification } from '@/store/stateless'
import { Close } from '@element-plus/icons-vue'
import Customers from '@/core/services/Callable/Customers'
import { ElLoading } from 'element-plus'

export default defineComponent({
    setup(_,{ emit }) {
        const { view_declarations } = constant
        const loading = ref(false);
        const declarations = computed(() => {
            return store.state.customer.declarations;
        })
        const lead_information_id = ref(0);


        const leadInformations = computed(() => {
            if (view_declarations.lead_id > 0) {
                const data = store.getters.getLeadInformation(true, view_declarations.lead_id)
                return data;
            }
            return [];
        })

        const activeTab = ref<Number>(0)
        const selectedlead = ref()

        // const fetchData = async (value: number) => {

        //     loading.value = true;
        //     lead_information_id.value = value;
        //     const answers = leadInformations.value.find(x => x.id === value).declaration_answers;
        //     declarations.value = answers;
        //     loading.value = false;
        // }

        const fetchData = async (value: number) => {
            activeTab.value = value
            lead_information_id.value = value
            loading.value = true;
            await Customers.getDeclarations({
                // customer_id: view_task.customer_id,
                lead_information_id: value
            })
            loading.value = false;
        }

        const close = () => {
            view_declarations.open = false
            view_declarations.lead_id = 0
            view_declarations.lead_information_id = 0
            view_declarations.is_fullscreen = false
            activeTab.value = 0
        }

        const saveDeclarations = async () => {

            const result = await Customers.declarations({
                lead_information_id: lead_information_id.value,
                declarations: declarations.value
            })

            if (result.status == 200) {
                // notification.success(
                //     'Health Declaration',
                //     'Declaration successfully save'
                // );
            } else {
                notification.warning(
                    'Health - Error',
                    result.response.data.message,
                );
            }
            emit('update')

        }


        const inst = getCurrentInstance()
        watchEffect(() => {
            if (view_declarations.open) {
                let c_id = (leadInformations.value.find(x => !x.tab_disabled)).id
                if (view_declarations.lead_information_id > 0)
                    c_id = view_declarations.lead_information_id

                if (activeTab.value > 0)
                    c_id = activeTab.value

                setTimeout(() => {
                    const listRef: any = inst?.refs[`Dlead_${c_id}`]
                    if (activeTab.value === 0 && listRef) {
                        // listRef.click()
                        console.log(listRef)
                        if(listRef !== undefined) Array.isArray(listRef) ? listRef[0].click() : listRef.click()
                    }
                }, 500);
            }
        })

        return {
            Close,
            selectedlead,
            activeTab,
            view_declarations,
            common,
            leadInformations,
            fetchData,
            close,
            loading,
            declarations,
            saveDeclarations
        }
    },
})
