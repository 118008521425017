const notifications = [
    
    {
        label: 'Message',
        value: 'description',
        width:'450',
        isLink:true

    },
    {
        label: 'Notified by',
        value: 'notified_by',
        // width:'200'

    },
    {
        label: 'Notified To',
        value: 'notified_to',
        // width:'200'

    },
    {
        label: 'Date',
        value: 'show_date',
        // width:'200'
    },
   
]

export {
    notifications
}