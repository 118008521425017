
import { computed, defineComponent, ref } from 'vue'
import { store } from '@/store'
import { sms } from '@/core/utils'
export default defineComponent({
    setup() {

        const sms_content = ref()

        const loading = computed(() => {
            return store.state.customer.sms_loading
        })

        const lists = computed(() => {
            return store.getters.getSMSLists
        })
        
        return {
            sms_content,
            loading,
            sms,
            lists
        }
    },
})
