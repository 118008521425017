import { reactive } from "vue";
import useClipboard from "vue-clipboard3";

let { toClipboard } = useClipboard();

const copyText = reactive({
    copyLink(link) {
        try {
            toClipboard(link);
        } catch (e) {
            console.error(e);
        }
    }
})

export {
    copyText
};