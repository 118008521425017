// TODO: Remove no-shadow eslint disable after fixed: https://github.com/typescript-eslint/typescript-eslint/issues/2484
// eslint-disable-next-line no-shadow
export enum PolicyMutationTypes {
    SET_POLICY_FILTER = "SET_POLICY_FILTER",
    SET_POLICY_LISTS = "SET_POLICY_LISTS",
    SET_POLICY_LIST_COUNTS = "SET_POLICY_LIST_COUNTS",
    SET_POLICY_LOADING = "SET_POLICY_LOADING",
    SET_POLICY = "SET_POLICY",
    SET_POLICY_START_DATE_DAYS_RESTRICT = "SET_POLICY_START_DATE_DAYS_RESTRICT",
    SET_POLICY_START_DATE_DAYS_RESTRICT_UPCOMING = "SET_POLICY_START_DATE_DAYS_RESTRICT_UPCOMING",
    SET_POLICY_ACCEPTED_DEBIT_AMOUNT_PER = "SET_POLICY_ACCEPTED_DEBIT_AMOUNT_PER",
    SET_PURCHASED_POLICIES = "SET_PURCHASED_POLICIES",
    SET_POLICY_LOG = "SET_POLICY_LOG",
    SET_POLICY_EMAIL_ACTIVITY = 'SET_POLICY_EMAIL_ACTIVITY',
    SET_GROUP_POLICY_LISTS = 'SET_GROUP_POLICY_LISTS',
    SET_GROUP_POLICY_LIST_COUNTS = 'SET_GROUP_POLICY_LIST_COUNTS',
    SET_GROUP_LEAD_POLICY_DETAILS = 'SET_GROUP_LEAD_POLICY_DETAILS'
}
  