
  import { defineComponent, computed } from 'vue'
  import { store } from "@/store";
  import Policy from '@/core/services/Callable/Policy';
  
  export default defineComponent({
    setup() {
  
      const filter = computed(() => {
        return store.state.policy.filter
      })
  
      const policyCount = computed(() => {
        return store.state.policy.groupPolicyListCounts
      })
  
      const lists = computed(() => {
        return store.state.policy.groupPolicyLists
      })
  
     
      
      const tabChange = (tab) => {
        filter.value.page = 1;
        filter.value.task_due_dates = tab;
        getPolicyListFor(filter.value)
      };
  
      const getPolicyListFor = async (e) => {
        await Policy.groupPolicyLists(filter.value)
      }
  
  
  
      return {
        store,
        filter,
        policyCount,
        lists,
        getPolicyListFor,
        tabChange
      }
    },
  })
  