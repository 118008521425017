
import { defineComponent, ref } from 'vue'
import { insurance_payment } from '@/core/utils'


export default defineComponent({
    props: {
        modelValue: {
            type: String,
            required: true,
            default: ''
        },
        label: {
            type: String,
            required: true,
            default: 'Select Insurance Payment'
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
       
        listFilter: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    setup(props, context) {

        const updateValue = (event) => {
            context.emit("update:modelValue", event)
            context.emit("insurance_payment", event)
        }

        const inputRef = ref()
        const focus = () => {
            setTimeout(() => {
                inputRef.value.focus()
            }, 100);
        }

        if (props.listFilter) {
            insurance_payment.shift()
            insurance_payment.unshift({
                value: -1,
                label: 'All Transaction Type'
            })
        }

        return {
            focus,
            insurance_payment,
            inputRef,
            updateValue,
        }
    },
})
