import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_button_navigator = _resolveComponent("vc-button-navigator")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    data: _ctx.users,
    style: {"width":"100%"}
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customers, (customer, i) => {
        return (_openBlock(), _createBlock(_component_el_table_column, {
          key: i,
          width: customer.width
        }, {
          header: _withCtx(() => [
            _createElementVNode("span", {
              innerHTML: customer.label
            }, null, 8, _hoisted_1)
          ]),
          default: _withCtx((scope) => [
            (customer.value !== 'actions')
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: "text-truncate",
                  innerHTML: scope.row[customer.value]
                }, null, 8, _hoisted_2))
              : (_openBlock(), _createBlock(_component_el_tooltip, {
                  key: 1,
                  class: "box-item",
                  effect: "light",
                  content: "Edit",
                  placement: "bottom"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_vc_button_navigator, {
                      "only-icon": "",
                      className: "icon-svg icon-edit-pencil-new",
                      value: "UpdateUser",
                      params: {user_id: scope.row.user_id}
                    }, null, 8, ["params"])
                  ]),
                  _: 2
                }, 1024))
          ]),
          _: 2
        }, 1032, ["width"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["data"]))
}