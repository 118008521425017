import { Menu, GENERIC, Countries, HealthCustomer, TaskLists, LeadReasons, Notification  } from '@/core/types/Etc';


export type State = {
  menus: Array<Menu>;
  countryCodes: GENERIC | null;
  agents: Array<GENERIC>;
  user_types: Array<GENERIC>;
  taskCount: Object | null;
  countries: Array<Countries> | null;
  relationships: Array<GENERIC>;
  others: Array<GENERIC>;
  workType: Array<GENERIC>;
  emirates: Array<GENERIC>;
  parentType: Array<GENERIC>;
  employeeType: Array<GENERIC>;
  insuredTypes: Array<GENERIC>;
  plans: Array<GENERIC>;
  providers: Array<GENERIC>;
  policies: Array<GENERIC>;
  apps: Array<GENERIC>;
  leadSources: Array<GENERIC>;
  roles: Array<GENERIC>;
  customerAlreadyExist : HealthCustomer;
  employeeCategories: Array<GENERIC> | null
  task_lists: TaskLists;
  lead_reasons: LeadReasons;
  notifications: Array<Notification>;
  basmah:number;
  vat:number;
  underwriters: Array<GENERIC>;
  notificationList: any;
  allNotificationList: any;
  notificationCount:number;
  notificationPayload: {
    index: number,
    page: number,
    per_page: number,
    type:string
  },
  groupSize: Array<GENERIC>;

  settings:any;
}

export const state: State = {
  menus: window.localStorage.getItem('myRoute') ? JSON.parse(window.localStorage.getItem('myRoute') || '') : [] as Array<Menu>,
  countryCodes: null,
  agents: [],
  user_types: [],
  taskCount: {},
  countries: [],
  relationships: [],
  others: [],
  workType: [],
  parentType: [],
  employeeType:[],
  emirates: [],
  insuredTypes: [],
  plans: [],
  providers: [],
  policies: [],
  apps: [],
  leadSources: [],
  roles: [],
  customerAlreadyExist: {} as HealthCustomer,
  employeeCategories: [],
  task_lists: {} as TaskLists,
  lead_reasons: {} as LeadReasons,
  notifications: [] as Array<Notification>,
  basmah:0,
  vat:0,
  underwriters: [],
  notificationList:null,
  allNotificationList:null,
  notificationCount:0,
  notificationPayload: {
    index: 1,
    page: 1,
    per_page: 50,
    type:'health'
  },
  groupSize: [],
  settings: {
    hl_renewal_policy_days:0,
    hl_q_lead_converted_days:0,
  }

};
