import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "container ps-0 p-0" }
const _hoisted_2 = { class: "row fs-6 ps-0 fw-boldest accordion-header-custom" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 1,
  class: "cursor-pointer text-truncate data-display"
}
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accordions, (accordion, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(accordion.cclass)
        }, [
          (accordion.label)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                innerHTML: accordion.label
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true),
          (_ctx.common.getTextWidth(_ctx.data[accordion.key]) > 560)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                _createVNode(_component_el_tooltip, {
                  class: "box-item",
                  effect: "light",
                  content: _ctx.data[accordion.key],
                  placement: "bottom",
                  "show-after": 500,
                  "hide-after": 0
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", {
                      class: _normalizeClass(accordion.cn),
                      innerHTML: _ctx.data[accordion.key]
                    }, null, 10, _hoisted_5)
                  ]),
                  _: 2
                }, 1032, ["content"])
              ]))
            : (_openBlock(), _createElementBlock("span", {
                key: 2,
                class: _normalizeClass(accordion.cn),
                style: _normalizeStyle(`${accordion.isMixin ? _ctx.common.leadStatus(_ctx.data.lead_status_id): ''}`),
                innerHTML: _ctx.data[accordion.key]
              }, null, 14, _hoisted_6))
        ], 2))
      }), 128))
    ])
  ]))
}