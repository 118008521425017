import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-center mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_input = _resolveComponent("vc-input")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_vc_button = _resolveComponent("vc-button")!
  const _component_vc_button_save = _resolveComponent("vc-button-save")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.merge_lead.open,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.merge_lead.open = $event)),
    "append-to-body": "",
    onClose: _ctx.clear,
    width: "25%",
    title: "Merge Lead"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref: "ruleFormRef",
        model: _ctx.merge_lead,
        "status-icon": "",
        rules: _ctx.rule.merge,
        "scroll-to-error": "",
        id: "lead",
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.submitMerge(_ctx.ruleFormRef)), ["prevent"]))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, {
            gutter: 10,
            class: "lead"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, { md: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_vc_input, {
                    label: "Leads",
                    isInput: "select",
                    placeholder: "Select Lead",
                    modelValue: _ctx.merge_lead.merge_lead_id,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.merge_lead.merge_lead_id = $event)),
                    options: _ctx.leads,
                    prop: "merge_lead_id",
                    size: "large"
                  }, null, 8, ["modelValue", "options"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_divider),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_vc_button, {
              text: "Cancel",
              className: "btn btn-light me-3",
              onVcClick: _ctx.clear,
              "is-regular": ""
            }, null, 8, ["onVcClick"]),
            _createVNode(_component_vc_button_save, { loading: _ctx.loading }, null, 8, ["loading"])
          ])
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["modelValue", "onClose"]))
}