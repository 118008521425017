import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "dashboard-section"
}
const _hoisted_2 = { class: "col-md-12" }
const _hoisted_3 = { class: "card mb-5 rounded-2 full-width" }
const _hoisted_4 = { class: "row g-4 w-100 untouched-row" }
const _hoisted_5 = {
  key: 0,
  class: "card card-flush"
}
const _hoisted_6 = { class: "row w-100" }
const _hoisted_7 = { class: "col-sm-4 p-0" }
const _hoisted_8 = { class: "card-title d-flex flex-column" }
const _hoisted_9 = { class: "d-flex align-items-center digit-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.salesOverview)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.underwriter, (uw, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "row",
            key: i
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(uw.items, (card, j) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "col-sm-3 d-flex",
                      key: j
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["col-md-2 col-lg-2 col-xl-2 col-xxl-2", `${ card.hide
                            ? 'hide-card'
                            : '' }`])
                      }, [
                        (!card.hide)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                              _createElementVNode("div", _hoisted_6, [
                                _createElementVNode("div", _hoisted_7, [
                                  _createElementVNode("i", {
                                    class: _normalizeClass(["icon-svg", card.icon])
                                  }, null, 2)
                                ])
                              ]),
                              _createElementVNode("div", {
                                class: _normalizeClass(["card-header w-100", `bg-light-white`])
                              }, [
                                _createElementVNode("div", _hoisted_8, [
                                  _createElementVNode("span", {
                                    class: _normalizeClass([`${`text-gray-700`}`, "text-dark-400 pt-1 fw-bold fs-5"])
                                  }, _toDisplayString(card.title), 1),
                                  _createElementVNode("div", _hoisted_9, [
                                    _createElementVNode("span", {
                                      style: _normalizeStyle(`color: ${card.color} !important;`),
                                      class: "fw-bold"
                                    }, [
                                      _createElementVNode("span", {
                                        style: _normalizeStyle(`font-size: ${ _ctx.getFontSize(_ctx.salesOverview[card.value]) }`)
                                      }, _toDisplayString(_ctx.salesOverview[card.value]), 5)
                                    ], 4)
                                  ])
                                ])
                              ])
                            ]))
                          : _createCommentVNode("", true)
                      ], 2)
                    ]))
                  }), 128))
                ])
              ])
            ])
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}