
import { defineComponent, ref, computed } from 'vue'
import { store } from '@/store'

export default defineComponent({
    setup() {

        const email_content_data = computed(() => {
            return store.state.customer.email_content_data
        })
        const modal = (value) => {
            email_content_data.value.open = value
        }

        return {
            modal,
            email_content_data
        }
    }
})
