import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vc" }
const _hoisted_2 = { class: "custom-date-picker" }
const _hoisted_3 = { class: "datepicker-icon" }
const _hoisted_4 = ["min", "max", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form_item, {
      prop: _ctx.prop,
      class: "el-form-item-custom-date-picker"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_input, {
            type: "text",
            placeholder: "DD/MM/YYYY HH:SS",
            modelValue: _ctx.firstDate,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.firstDate = $event)),
            onInput: _ctx.updateValue,
            onChange: _ctx.changeValue,
            maxlength: "16",
            disabled: _ctx.disabled,
            ref: "inputRef",
            size: "large"
          }, {
            append: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _withDirectives(_createElementVNode("input", {
                  style: {"visibility":"hidden"},
                  class: "form-check-input",
                  type: "date",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.firstDate = $event)),
                  onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setDateFormat && _ctx.setDateFormat(...args))),
                  onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.setDateFormatInput && _ctx.setDateFormatInput(...args))),
                  min: _ctx.min,
                  max: _ctx.max,
                  disabled: _ctx.disabled,
                  ref: "calRef"
                }, null, 40, _hoisted_4), [
                  [_vModelText, _ctx.firstDate]
                ]),
                _createElementVNode("span", {
                  class: "svg-icon svg-icon-1 icon-cal bg-white",
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.openCalendar && _ctx.openCalendar(...args)))
                }, [
                  _createVNode(_component_inline_svg, { src: "media/background/calendar-blue.svg" })
                ])
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "onInput", "onChange", "disabled"])
        ])
      ]),
      _: 1
    }, 8, ["prop"])
  ]))
}