
import { defineComponent, ref, computed } from 'vue'
import Inquiry from '@/core/services/Callable/Inquiry'
import { store } from "@/store";
import router from '@/router/clean';

export default defineComponent({
    setup() {
        const pages = ref([50, 100, 150, 200, 500])

        const filter = computed(() => {
            return store.state.inquiry.inquiry_list_payload
        })

        const selectedPage = async () => {
            getLists(1)
        }
        const allInquiry = computed(() => {
            return store.state.inquiry.inquiry
        })

        const getLists = async (e: any) => {
            const page = e;
            router.replace({ query: { page } })
                .catch(error => {
                    if (
                        error.name !== 'NavigationDuplicated' &&
                        !error.message.includes('Avoided redundant navigation to current location')
                    ) {
                        console.log(error)
                    }
                });
            filter.value.index = page;

            await Inquiry.getInquiry(filter.value);

            console.log('allInquiry',allInquiry.value)
        }

        return {
            filter,
            pages,
            allInquiry,
            selectedPage,
            getLists
        }
    },
})
