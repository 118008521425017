
import { computed, defineComponent } from 'vue'
import { inquiries } from '@/core/utils/inquiry'
import { store } from '@/store'

export default defineComponent({
    setup() {

        const allInquiry = computed(() => {
            return store.getters.getInquiry
        })
        
        return {
            allInquiry,
            inquiries
        }
    },
})
