
import { defineComponent, onMounted, computed, ref } from 'vue'
import { store } from "@/store";
import Users from '@/core/services/Callable/Users';

export default defineComponent({
    props: {
        modelValue: {
            type: String,
            required: true,
            default: ''
        },
        label: {
            type: String,
            required: true,
            default: 'Select team leader'
        }
    },

    setup(props, context) {

        onMounted(() => {
            Users.getTeamLeads({
                role_ids: [13],
                assign: 'health'
            })
        })

        const team_leads = computed(() => {
            const data = store.state.user.team_leads
            const exist = data.find(x => x.value === 0)
            if(!exist)
                data.unshift({ label: props.label, value: 0 })

            return data
        })

        const updateValue = (event) => {
            context.emit("update:modelValue", event)
            context.emit("teamLeads", event)
        }

        const inputRef = ref()
        const focus = () => {
        setTimeout(() => {
                inputRef.value.focus()
            }, 100);
        }

        return {
            focus,
            inputRef,
            team_leads,
            updateValue
        }
    },
})
