
import { defineComponent, computed, ref } from 'vue'
import { store } from "@/store";
import { rule, common } from '@/store/stateless'
import Customers from '@/core/services/Callable/Customers';
import { healthDefault } from '@/core/utils'

export default defineComponent({
    setup(_) {
        const open = ref<Boolean>(false)
        const loading = ref<Boolean>(false)

        const health = computed(() => {
            return store.state.customer.health;
        })


        const close = () => {
            Customers.setHealth(healthDefault)
            loading.value = false
            modal(false)
        }

        const top = ref()
        const modal = (value: Boolean) => {
            open.value = value
        }

        return {
            top,
            rule,
            open,
            common,
            health,
            loading,
            modal,
            close,
        }
    },
})
