
import { computed, defineComponent, ref } from 'vue'
import { constant } from '@/store/stateless'
import { FormInstance } from 'element-plus';
import { rule } from '@/store/stateless'
import Customers from '@/core/services/Callable/Customers';
import { store } from '@/store'
import vcButtonSave from '@/components/reusables/vcButtonSave.vue';

export default defineComponent({
  components: { vcButtonSave },
    setup(_, {emit}) {
        const { merge_lead } = constant
        const ruleFormRef = ref<FormInstance>()
        const loading = ref<boolean>(false)

        const submitMerge = async (formEl: FormInstance | undefined) => {
            loading.value = true
            if(!formEl) return
            formEl.validate(async(valid) => {
                if(valid) {
                    await Customers.mergeLead(merge_lead)
                    clear()
                    loading.value = false
                    emit('update')
                } else {
                    loading.value = false
                }
            })
        }

        const leads = computed(() => {
            const myLeads = store.getters.getLeads(true)
            const filteredLeads = myLeads.filter(x => x.lead_id !== merge_lead.quick_lead_id)
            const data = filteredLeads.map(x => {
                return {
                    label: x.name + ` ( Lead Id: ${x.lead_id})`,
                    value: x.lead_id
                }
            })

            const exist = data.find(x => x.value === 0)
            if(!exist) data.unshift({ label: 'Select Lead', value: 0 })

            return data
        })
        
        const clear = () => {
            merge_lead.open = false
            merge_lead.merge_lead_id = 0
            merge_lead.quick_lead_id = 0
            ruleFormRef.value?.clearValidate()
        }

        return {
            merge_lead,
            ruleFormRef,
            loading,
            leads,
            rule,
            clear,
            submitMerge,
        }
    },
})
