import { PolicyData } from '@/core/types/Quote';
import moment from 'moment';

export type State = {
  policies: [];
  manual_quotes: [];
  quote_policies:Array<any>;
  quote_pdf_link:string|null;
  basmah:number
}

export const state: State = {
  policies:[],
  quote_policies:[],
  quote_pdf_link:'',
  manual_quotes:[],
  basmah:0
};
