import { ActionTree, ActionContext } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';
import { State } from './state';
import { Mutations } from './mutations';
import { UserMutationTypes} from '@/store/enums/User/Mutations';
import { UserActionTypes } from '@/store/enums/User/Actions';
import ApiService from "@/core/services/ApiService";
import { Links } from '@/store/enums/Links';
import Validations from '@/core/services/etc/Validations';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [UserActionTypes.USERS] ({ commit }: AugmentedActionContext, payload:any)
}

export const actions: ActionTree<State, RootState> & Actions = {
  
  async [UserActionTypes.USERS]({commit} , payload: any) {
    commit(UserMutationTypes.SET_USER_LOADING, true)
    
    try {
      const data = await ApiService.post(`${Links.GET_ALL_USERS}?page=${payload.index}`, payload);
      commit(UserMutationTypes.SET_USERS, data.data.data.users)
      commit(UserMutationTypes.SET_USER_LOADING, false)
    } catch (err) {
      commit(UserMutationTypes.SET_USER_LOADING, false)
      return err
    }
  },

  async [UserActionTypes.USER_UNDERWRITERS]({commit}, payload: any) {
    try {
      const data = await ApiService.post(Links.GET_USERS_BY_APP_AND_ROLE, payload)
      const users = data.data.data.users.map(x => { 
          delete x.user_status 
          return { ...x } 
        })
      commit(UserMutationTypes.SET_UNDERWRITERS, users)
    } catch (err) {
      return err
    }
  },

  async [UserActionTypes.USER_TEAM_LEADS]({commit}, payload: any) {
    try {
      const data = await ApiService.post(Links.GET_USERS_BY_APP_AND_ROLE, payload)
      const users = data.data.data.users.map(x => { 
          delete x.user_status 
          return { ...x } 
        })
      commit(UserMutationTypes.SET_TEAM_LEADS, users)
    } catch (err) {
      return err
    }
  },

  async [UserActionTypes.CREATE_USER]({commit}, payload) {
    commit(UserMutationTypes.SET_USER_LOADING, true)

    try {
      const data = await ApiService.post(Links.POST_CREATE_USER, payload)
      commit(UserMutationTypes.SET_USER_LOADING, false)
      return data
    } catch (err) {
      commit(UserMutationTypes.SET_USER_LOADING, false)
      return err
    }
  },

  async [UserActionTypes.UPDATE_USER]({commit}, payload) {
    commit(UserMutationTypes.SET_USER_LOADING, true)

    try {
      const data = await ApiService.post(`${Links.POST_UPDATE_USER}/${payload.id}`, payload)
      commit(UserMutationTypes.SET_USER_LOADING, false)
      return data
    } catch (err) {
      commit(UserMutationTypes.SET_USER_LOADING, false)
      return err
    }
  },

  async [UserActionTypes.FETCH_USER]({commit}, payload) {
    commit(UserMutationTypes.SET_USER_LOADING, true)

    try {
      const data = await ApiService.post(`${Links.GET_USER_BY_ID}/${payload.id}`, payload)
      commit(UserMutationTypes.SET_USER_LOADING, false)
      const { id, name, email, role_id, office_mobile_number, other_mobile_number, round_robin_setting, policy_agent_id, status, team_leader_id, roles } = data.data.data.user

      const assign_lang:any = []
      let assign_en_leads = 0
      let assign_ar_leads = 0
      let agent_type = 0
      let is_new = 0
      let is_renewal = 0
      let is_both = 0
      let renewal_deal = 0
      let is_all_deals = 0
      let is_llr = 0
      let assign = 0
      let assign_car = 0
      let assign_health = 0
      let assign_home = 0
      let assign_travel = 0
      let skip_round = 0
      let is_archived = 0
      let is_round_robin = false
      if(round_robin_setting && Object.keys(round_robin_setting).length > 0) {

        // language
        if(round_robin_setting.assign_en_leads === 1) {
          assign_lang.push('en')
          assign_en_leads = 1
        }

        if(round_robin_setting.assign_ar_leads === 1) {
          assign_lang.push('ar')
          assign_ar_leads = 1
        }

        // agent type
        if(round_robin_setting.is_new === 1) {
          agent_type = 1
        }
        if(round_robin_setting.is_renewal === 1) {
          agent_type = 2
        }
        if(round_robin_setting.is_both === 1) {
          agent_type = 3
        }

        is_new = round_robin_setting.is_new
        is_renewal = round_robin_setting.is_renewal
        is_both = round_robin_setting.is_both

        renewal_deal = 1
        if(round_robin_setting.is_all_deals === 1) {
          renewal_deal = 2
        }
        if(round_robin_setting.is_llr === 1) {
          renewal_deal = 3
        }
        is_all_deals = round_robin_setting.is_all_deals
        is_llr = round_robin_setting.is_llr

        if(round_robin_setting.assign_car === 1) {
          assign = 1
        }
        if(round_robin_setting.assign_health === 1) {
          assign = 2
        }
        if(round_robin_setting.assign_home === 1) {
          assign = 3
        }
        if(round_robin_setting.assign_travel === 1) {
          assign = 4
        }
        assign_car = round_robin_setting.assign_car
        assign_health = round_robin_setting.assign_health
        assign_home = round_robin_setting.assign_home
        assign_travel = round_robin_setting.assign_travel

        skip_round = round_robin_setting.skip_round
        is_archived = round_robin_setting.is_archived
        is_round_robin = round_robin_setting.is_round_robin === 0 ? false : true
      } 

      Validations.password_required = false

      // customerCount
      // agentCount
      const is_edit = status === 0 ? true : false
      
      let setRole:any = []
      if(!roles) {
        setRole.push(role_id)
      }

      const user = {
        id,
        name,
        email,
        roles: roles ? roles : setRole,
        role_id,
        password: '',
        password_confirmation: '',
        office_mobile_number,
        other_mobile_number,
        is_round_robin,
        policy_agent_id,
        assign_lang,
        assign_en_leads,
        assign_ar_leads,
        agent_type,
        is_new,
        is_renewal,
        is_both,
        renewal_deal,
        is_llr,
        is_all_deals,
        assign,
        assign_car,
        assign_health,
        assign_home,
        assign_travel,
        status,
        skip_round,
        agents: [],
        team_leader: team_leader_id,
        is_archived,
        is_edit
      }
      commit(UserMutationTypes.SET_USER, user)
      return data
    } catch (err) {
      commit(UserMutationTypes.SET_USER_LOADING, false)
      return err
    }
  },

};
