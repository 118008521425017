import { User, UserListPayload, Listing } from '@/core/types/User';
import { GENERIC } from '@/core/types/Etc';


export type State = {
  user: User;
  users: Listing
  user_loading: Boolean
  user_list_payload: UserListPayload
  underwriters: Array<GENERIC>
  team_leads: Array<GENERIC>
}

export const state: State = {
  users: {} as Listing,
  user_loading: false,
  user: {
    id: 0,
    name: "",
    email: "",
    customerCount: false,
    roles: [],
    role_id: 0,
    password: "",
    password_confirmation: "",
    other_mobile_number: "",
    office_mobile_number: "",
    is_round_robin: null,
    policy_agent_id: 0,
    assign_lang: [],
    assign_en_leads: 0,
    assign_ar_leads: 0,
    agent_type: 0,
    is_new: 0,
    is_renewal: 0,
    is_both: 0,
    renewal_deal: 0,
    is_llr: 0,
    is_all_deals: 0,
    assign: 0,
    assign_car: 0,
    assign_health: 0,
    assign_home: 0,
    assign_travel: 0,
    status: 1,
    skip_round: 0,
    agents: [],
    team_leader: 0,
    is_archived: 0,
    agentCount: 0,
    is_edit: false
  },
  user_list_payload: {
    agent: "all,",
    end_date: "",
    index: 1,
    keyword: "",
    per_page: 50,
    role_id: 0,
    start_date: "",
    status: "",
  },
  underwriters: [],
  team_leads: [],
};
