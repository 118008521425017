<template>
  <div class="card-body card-custom-spacing-tblf-10 bg-white">
    <div
      class="d-flex search-block lead-filter-buttons mb-5 justify-content-end"
    >
      <a
        class="
          leads
          nav-link
          alert
          bg-light-danger
          align-items-center
          leads-btn
        "
      >
        {{ theTable.total }}
        Record Count
      </a>
    </div>
    <div class="pt-0 p-0" v-if="approvedInvoices && approvedInvoices.length > 0">
      <div id="displayResponseData">
        <!-- class="table table-hover bg-bai-table-header table-bai-striped table-row-bordered border gy-7 gs-7 mb-0" -->
        <el-table :data="approvedInvoices">
          <el-table-column
            v-for="list in approved_group_invoices_table"
            :key="list.label"
            :fixed="list.isFixed"
            :width="list.width"
          >
            <template #header>
              <span v-html="list.label" />
            </template>
            <template #default="scope">
              <span v-if="list.isIndex">
                {{ scope.$index + (srs + 1) }}
              </span>
              <span v-else-if="list.isFormat">
                {{ common.setDateTimeOnly(scope.row[list.value]) }}
              </span>
              <span v-else-if="list.isCount">
                <span v-for="(n, index) in scope.row[list.value]" :key="index">
                  {{ index + "(" + n + "), " }}
                </span>
              </span>
              <span
                v-else
                :class="list.cn"
                :style="
                  list.isMixin ? common.leadStatus(scope.row.lead_status) : ''
                "
                v-html="scope.row[list.value]"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div v-else>
      <div v-if="loading">
        <span>Loading Information Please Wait...</span>
      </div>
      <div v-else class="container-fluid p-5">
        <div class="row">
          <div class="col-12">
            <el-empty description="No Records Found" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card-footer bg-white">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-4">
          <div
            class="
              col-sm-12 col-md-5
              d-flex
              align-items-center
              justify-content-center justify-content-md-start
            "
          >
            <div class="dataTables_length" id="kt_customers_table_length">
              <label>
                <el-select
                  v-model="filter.per_page"
                  @change="filter.page = 1"
                  size="large"
                  style="width: 100%"
                >
                  <el-option
                    v-for="page in per_pages"
                    :key="page"
                    :value="page"
                  >
                    {{ page }}
                  </el-option>
                </el-select>
              </label>
            </div>
          </div>
        </div>
        <div class="col-4 text-center">
          <span class="text-muted d-block mt-2">
            Showing
            <span v-if="theTable.total > 0">
              {{ theTable.from }} to {{ theTable.to }} of
            </span>
            <span v-else> {{ theTable.total }} of </span>
            {{ theTable.total }}
          </span>
        </div>
        <div class="col-4">
          <div style="float: right">
            <el-pagination
              v-if="theTable"
              v-model:currentPage="filter.page"
              :page-size="parseInt(theTable.per_page)"
              :small="large"
              layout="prev, pager, next"
              :total="theTable.total"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { approved_group_invoices_table } from "@/core/utils/reports";
import Report from "@/core/services/Callable/Report";
import { store } from "@/store";
import { per_pages } from "@/core/utils/common";
import moment from "moment";
import { common } from "@/store/stateless";

const filter = computed(() => {
  return store.state.report.approved_invoices_filter;
});

const srs = computed(() => {
  return (filter.value.page - 1) * filter.value.per_page;
});

const approvedInvoices = computed(() => {
  return store.getters.getApprovedGroupInvoices;
});

const theTable = computed(() => {
  return store.state.report.approved_group_invoices;
});
</script>
