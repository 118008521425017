import { ActionTree, ActionContext } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';
import { State } from './state';
import { Mutations } from './mutations';
import { InquiryMutationTypes} from '@/store/enums/Inquiry/Mutations';
import { InquiryActionTypes } from '@/store/enums/Inquiry/Actions';
import ApiService from "@/core/services/ApiService";
import { Links } from '@/store/enums/Links';
import Validations from '@/core/services/etc/Validations';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [InquiryActionTypes.INQUIRIES] ({ commit }: AugmentedActionContext, payload:any)
}

export const actions: ActionTree<State, RootState> & Actions = {
  
  async [InquiryActionTypes.INQUIRIES]({commit} , payload: any) {
    commit(InquiryMutationTypes.SET_INQUIRY_LOADING, true)
    
    try {
      const data = await ApiService.post(`${Links.GET_ALL_INQUIRY}?page=${payload.index}`, payload);
      commit(InquiryMutationTypes.SET_INQUIRY, data.data.data)
      commit(InquiryMutationTypes.SET_INQUIRY_LOADING, false)
    } catch (err) {
      commit(InquiryMutationTypes.SET_INQUIRY_LOADING, false)
      return err
    }
  },
};
