
import { computed, defineComponent, onMounted, ref, watchEffect } from 'vue'
import Etcs from '@/core/services/Callable/Etcs';
import { store } from "@/store";
import { constant, loading, disable, rule, formQuickLead, modal } from '@/store/stateless';
import checkNumberExist from "@/components/modals/CheckNumberExist.vue";
import router from '@/router/clean';

export default defineComponent({
    components: {
        checkNumberExist,
    },
    setup() {

        const countryCodes = computed(() => {
            return store.state.etc.countryCodes;
        })

        const leadSources = computed(() => {
            return store.state.etc.leadSources;
        })

        const agents = ref<any>([]);
        onMounted(async () => {
            constant.quicklead.salesAgents = store.state.auth.user.role_id != 1 ? store.state.auth.user.id : ""

        })


        const getSetup = () => {
            Etcs.getCountryCode();
            Etcs.getLeadSources();
        }

        const checkPhoneNo = () => {
            Etcs.checkExistNumber({
                phone_country_code: constant.quicklead.phone_country_code,
                phone_number: constant.quicklead.phone_number,
            });
        }

        const isExistCustomer = computed(() => {
            return store.state.etc.customerAlreadyExist;
        })

        const isOpen = computed(() => {
            if (Object.keys(isExistCustomer.value).length > 0) {
                return true;
            }
            return false;
        })

        const toCustomerDetail = async (id: number) => {
            modal.quicklead = false; // closing quick lead dialog
            await router.push({ name: 'Details', params: { customer_id: id } });
            Etcs.setExistCustomer({}); // closing exist customer dialog
        }

        const clearIt = () => {
            constant.quicklead.phone_number = '';
            Etcs.setExistCustomer({}); // closing exist customer dialog
        }

        watchEffect(async() => {
            if(modal.quicklead) {
                await Etcs.getAgentLists();
                const agent = store.state.etc.agents;
                agent.unshift({ label: 'Select sales agent', value: "" });
                agents.value = agent;
            }
        })

        return {
            countryCodes,
            getSetup,
            constant,
            loading,
            formQuickLead,
            rule,
            disable,
            modal,
            leadSources,
            checkPhoneNo,
            checkNumberExist,
            isOpen,
            toCustomerDetail,
            clearIt,
            agents,
        }
    },
})
