
import { defineComponent, ref } from 'vue'
import { client_classification } from '@/core/utils'


export default defineComponent({
    props: {
        modelValue: {
            type: String,
            required: true,
            default: ''
        },
        label: {
            type: String,
            required: true,
            default: 'Select Client Classification'
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    setup(props, context) {
        
        const updateValue = (event) => {
            context.emit("update:modelValue", event)
            context.emit("client_classification", event)
        }

        const inputRef = ref()
        const focus = () => {
        setTimeout(() => {
                inputRef.value.focus()
            }, 100);
        }

        return {
            focus,
            client_classification,
            inputRef,
            updateValue
        }
    },
})
