
import { defineComponent, onMounted, computed, ref } from 'vue'
import { constant } from "@/store/stateless";
import Etcs from '@/core/services/Callable/Etcs';

export default defineComponent({
    props: {
        modelValue: {
            type: String,
            required: true,
            default: ''
        },
        label: {
            type: String,
            required: true,
            default: 'Select user status'
        }
    },

    setup(props, context) {

        onMounted(() => {
            Etcs.getAllRoles(); 
        })

        const statuses = computed(() => {
            return constant.user_status
        })

        const updateValue = (event) => {
            context.emit("update:modelValue", event)
            context.emit("status", event)
        }

        const inputRef = ref()
        const focus = () => {
        setTimeout(() => {
                inputRef.value.focus()
            }, 100);
        }

        return {
            focus,
            inputRef,
            statuses,
            updateValue
        }
    },
})
