
import { defineComponent, ref, computed } from 'vue'
import { store } from "@/store";
import router from '@/router/clean';
import Invoice from '@/core/services/Callable/Invoice';

export default defineComponent({
    setup() {
        const pages = ref([50, 100, 150, 200, 500])

        const filter = computed(() => {
            return store.state.invoice.filter
        })

        const selectedPage = async () => {
            await Invoice.invoiceLists(filter.value);
        }
        
        const lists = computed(() => {
            return store.state.invoice.invoiceLists
        })


        const getLists = async (e: any) => {
            const page = parseInt(e ? e : 1);
            
            filter.value.page = page;
            // filter.value.status = 1;
            // filter.value.payment_type = [2,3,4];
            router.replace({ query: { page } })
                .catch(error => {
                    if (
                        error.name !== 'NavigationDuplicated' &&
                        !error.message.includes('Avoided redundant navigation to current location')
                    ) {
                        console.log(error)
                    }
                });
            await Invoice.invoiceLists(filter.value)
        }

        return {
            filter,
            pages,
            lists,
            selectedPage,
            getLists
        }
    },
})
