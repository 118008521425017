
import moment from 'moment'
import { defineComponent, ref, computed } from 'vue'
import { common, rule } from '@/store/stateless'
import { Lead_task } from '@/core/types/Customer'
import { FormInstance } from 'element-plus';
import Customers from '@/core/services/Callable/Customers';
import { store } from '@/store';

export default defineComponent({
    props: {
        customer_id: {
            type: Number,
            required: true
        },
        agent: {
            type: Number,
            required: true
        },
        lead_id: {
            type: Number,
            required: true
        },
        lead_status_id: {
            type: Number,
            required: true
        },
        isModal: {
            type: Boolean,
            required: false,
            default: false
        }

    },

    setup(_, context) {
        const ruleFormRef = ref<FormInstance>()
        const isModalOpen = ref(false)
        const closedLead = ref(false)
        const modalLoading = ref(false)
        const tasks = ref<Lead_task>({
            customer_id: _.customer_id,
            agent: _.agent,
            lead_id: _.lead_id,
            disposition_id: 0,
            lost_lead_reason_id: 0,
            due_date: moment().format('YYYY-MM-DD HH:mm'),
            task_notes: '',
            converted_leads:[]
        })

        const lead = computed(() => {
            const leads = store.getters.getLeads(false).filter(x => x.lead_id == _.lead_id);
            if(leads.length > 0)
                return leads[0];
            
            return null
        })  

        const convertedDeals = computed(() => {
            const deals = common.getConvertedDeals(_.lead_id);
            console.log('deals',deals)
            return deals
        })
        
        const loading = ref<Boolean>(false)

        const reset = (value) => {
            tasks.value = {
                customer_id: _.customer_id,
                agent: _.agent,
                lead_id: _.lead_id,
                disposition_id: value,
                lost_lead_reason_id: 0,
                due_date: moment().format('YYYY-MM-DD HH:mm'),
                task_notes: '',
                converted_leads:[]

            }
            ruleFormRef.value?.clearValidate()
        }

        const submitTask = async (formEl: FormInstance | undefined) => {
            loading.value = true
            if (!formEl) return
            formEl.validate(async (valid, fields: any) => {
                if (valid) {

                    if (tasks.value.disposition_id == 11) {
                        isModalOpen.value = true;
                    }
                    else {
                        await proceedTaskDetails()
                        reset(0)
                        loading.value = false
                        formEl.resetFields()
                    }

                } else {
                    loading.value = false
                }
            })
        }

        const proceed = async () => {
            modalLoading.value = true
            await proceedTaskDetails()
            modalLoading.value = false
            isModalOpen.value = false
        }

        const cancelModal = () => {
            isModalOpen.value = false
            loading.value = false

        }

        

        const proceedTaskDetails = async () => {

            if(closedLead.value == true)
                tasks.value.disposition_id = 19;

            const data = await Customers.addLeadTask(tasks.value)
            reset(0)
            if (_.isModal) {
                await Customers.getAllTaskByLeadAndCustomerId({
                    customer_id: tasks.value.customer_id,
                    lead_id: tasks.value.lead_id
                })
                context.emit('closeModal')
            }

        }

        const changeCloseLead = (event) => {
            // closedLead.value = event.target.value;
            // console.log("changeCloseLead", taskParameters);
            console.log("event.target.value", event.target.value);
            // taskRules.value.closed_lead.show = false;
            if(event.target.value == 1 ){
                closedLead.value = true
                isModalOpen.value = true
            }
            else {
                closedLead.value = false
                isModalOpen.value = false
            }
        };


        return {
            ruleFormRef,
            rule,
            loading,
            common,
            tasks,
            reset,
            submitTask,
            proceedTaskDetails,
            isModalOpen,
            modalLoading,
            proceed,
            cancelModal,
            lead,
            changeCloseLead,
            closedLead,
            convertedDeals
        }
    },
})
