import { store } from "@/store";
import { App } from "vue";
import { AuthActionTypes } from '@/store/enums/Auth/Actions';
import { AuthMutationTypes } from '@/store/enums/Auth/Mutations';

class Auths {
    
    public static vueInstance: App;

    public static init(app: App<Element>) {
        Auths.vueInstance = app;
    }

    public static async Login(payload: any) {
        payload.app = 'health';
        return await store.dispatch(AuthActionTypes.LOGIN, payload);
    }

    public static async VerifyToken(payload: any = store.state.auth.token) {
        return await store.dispatch(AuthActionTypes.VERIFY_TOKEN, payload);
    }

    public static assignUnverifiedToken(payload) {
        store.commit(AuthMutationTypes.SET_AUTH_TOKEN, payload);
    }

    public static async Signout() {
        return await store.dispatch(AuthActionTypes.LOGOUT);
    }

    public static setAuthNull() {
        window.localStorage.removeItem('id_token');
        window.localStorage.removeItem('user');
        store.commit(AuthMutationTypes.SET_AUTH_USER, {});
        store.commit(AuthMutationTypes.SET_AUTH_TOKEN, null);
        store.commit(AuthMutationTypes.SET_AUTH_ERROR, null);
        store.commit(AuthMutationTypes.SET_AUTH_LOADING, null);
        window.location.reload();

    }

    public static async switchRole() {
        return await store.dispatch(AuthActionTypes.SWITCH_ROLE);
    }

    public static async switchApp(payload: any) {
        return await store.dispatch(AuthActionTypes.SWITCH_APP,payload);
    }

    public static async switchAppReload(payload: any) {
        return await store.dispatch(AuthActionTypes.SWITCH_APP_RELOAD,payload);
    }


}

export default Auths;