
import { defineComponent, computed, h, onMounted } from 'vue'
import { ElDivider } from 'element-plus';
import { store } from '@/store';
import Documents from '@/core/services/Callable/Documents';
import { leadEmployeeCategoryId } from '@/store/stateless';

interface dataValue {
    is_loaded: boolean,
    categories: unknown,
    lead_id: number,
    lead_employee_category_id?: number,
}

export default defineComponent({
    // name: 'policy-edit',

    setup() {

        const policy = computed(() => {
            return store.state.policy.policy
        })

        const documents = computed(() => {
            return store.getters.getAllDocuments(2)
        })

        const fetchDocument = async() => {
            const condition = <dataValue>{
                is_loaded: true,
                categories: ['standard'],
                lead_id: policy.value.lead_id,
                policy_id: policy.value.policy_purchase_id
            }

            if(leadEmployeeCategoryId.value > 0) condition.lead_employee_category_id = leadEmployeeCategoryId.value

            await Documents.getDocuments(condition)
        }
        
        const downloadZip = () => {
            let policyDocArray = Array < any > ();
            let tempPolicyDocArray = Array < any > ();

            documents.value?.map((doc) => {
                // console.log(doc)
                if (doc?.is_temp == 1 && (doc?.document_type == 'verification' || (doc.document_type_master &&  doc.document_type_master.document_type == 'verification'))) {
                    tempPolicyDocArray.push(doc.document_id);
                }
                else if (doc?.is_temp == 0 && (doc?.document_type == 'verification' || (doc.document_type_master &&  doc.document_type_master.document_type == 'verification'))) {
                    policyDocArray.push(doc.document_id);
                }
            })


            const payload = {
                policy: policyDocArray,
                temp: tempPolicyDocArray,
            }
            // console.log(payload);
            Documents.downloadZip(payload);
        };

        return {
            leadEmployeeCategoryId,
            policy,
            documents,
            fetchDocument,
            downloadZip
        }
    },
})
