import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container sales-dashboard accounts-dashboard" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-sm-12" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "card" }
const _hoisted_8 = { class: "card-body me-0" }
const _hoisted_9 = {
  key: 1,
  class: "row sales-dashboard-row"
}
const _hoisted_10 = { class: "card" }
const _hoisted_11 = { class: "card-body me-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accountants, (ac, i) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                _createElementVNode("h2", _hoisted_6, _toDisplayString(ac.title), 1),
                (ac.isLoop)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(ac.items, (card, j) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "row sales-dashboard-row",
                        key: j
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(card.children, (child, j) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "col-sm-2 sales-dashboard-item pe-0",
                            key: j
                          }, [
                            _createElementVNode("div", _hoisted_7, [
                              _createElementVNode("div", _hoisted_8, [
                                _createElementVNode("i", {
                                  class: _normalizeClass(child.icon)
                                }, null, 2),
                                _createElementVNode("h3", null, _toDisplayString(child.title), 1),
                                _createElementVNode("h2", {
                                  style: _normalizeStyle(`color: ${child.color} !important; font-size: ${ _ctx.getFontSize(_ctx.salesOverview[card.value]) }`),
                                  class: "fw-bold"
                                }, _toDisplayString(_ctx.salesOverview[child.value]), 5)
                              ])
                            ])
                          ]))
                        }), 128))
                      ]))
                    }), 128))
                  : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ac.items, (card, j) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "col-sm-2 sales-dashboard-item pe-0",
                          key: j
                        }, [
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("div", _hoisted_11, [
                              _createElementVNode("i", {
                                class: _normalizeClass(card.icon)
                              }, null, 2),
                              _createElementVNode("h3", null, _toDisplayString(card.title), 1),
                              _createElementVNode("h2", {
                                style: _normalizeStyle(`color: ${card.color} !important; font-size: ${ _ctx.getFontSize(_ctx.salesOverview[card.value]) }`),
                                class: "fw-bold"
                              }, _toDisplayString(_ctx.salesOverview[card.value]), 5)
                            ])
                          ])
                        ]))
                      }), 128))
                    ]))
              ], 64))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}