import { GetterTree } from 'vuex';
import router from "@/router/clean";

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';

import { InquiryData } from '@/core/types/Inquiry'


import { State } from './state';

export type Getters = {
  getInquiry(state: State): any;
}


export const getters: GetterTree<State, RootState> & Getters = {
  getInquiryLoaded: (state) => {
    return Object.keys(state.inquiry).length > 0 ? true : false
  },
  getInquiry: (state) => {
    if(state.inquiry != undefined) {
      console.log(state.inquiry)
      return state.inquiry?.data.map((x: any, i) => {
        return {
          index: i + 1,
          company_email:  x.company_email,
          company_name:  x.company_name,
          contact_person: x.contact_person,
          contact_number: `(${x.phone_country_code})${x.contact_number}`,
          no_of_members: x.no_of_member_text,
          dates: `${x.created_at} <br /> ${x.updated_at}`,
          actions: true
        }
      })
    }
    
    
    return []
  }
};


