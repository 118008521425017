
    import { computed, defineComponent, ref, reactive } from 'vue'
    import Customers from '@/core/services/Callable/Customers';
    import { store } from "@/store";
    import Validations from '@/core/services/etc/Validations';
    import { FormInstance } from 'element-plus';
    import { customer, notification } from '@/store/stateless';
    import Etcs from '@/core/services/Callable/Etcs';
    import router from "@/router/clean";

    export default defineComponent({
        setup() {
            const ruleFormRef = ref < FormInstance > ()
            const open = ref < Boolean > (false)

            const transfer = computed(() => {
                return store.state.customer.transfer;
            })

            const loading = ref(false)

            const filter = computed(() => {
                return store.state.customer.filter
            })

            const checkedTransfer = computed(() => {
                return store.state.customer.checkedTransfer;
            })

            const rules = reactive({
                agent: [{ validator: Validations.agent, trigger: ['blur', 'change'] }],
            })

            const agents = ref < any > ([]);

            const modal = async (value: Boolean) => {
                open.value = value

                if (value) {
                    await Etcs.getAgentLists();
                    const agent = store.state.etc.agents;
                    agents.value = agent;
                }
            }

            const onSubmitTransfer = async (formEl: FormInstance | undefined) => {
                if (!formEl) return
                formEl.validate(async (valid) => {
                    if (valid) {
                        loading.value = true;
                        const result = await Customers.transferAgent({
                            agent: transfer.value.agent,
                            customer_ids: customer.checkedTransfer,
                            notes: transfer.value.notes
                        });
                        if (result.status == 200) {
                            notification.success(
                                'Transfer',
                                'Lead has been transferred successfully!',
                            )

                            if(router.currentRoute.value.name == 'CustomerDetails'){
                                const params = { customer_id: parseInt(router.currentRoute.value.params.customer_id.toString()) }
                                params['is_loaded'] = true
                                await Customers.details(params)
                            }
                            else {
                                await Customers.customerLists(filter.value);
                                customer.checkedTransfer = [];
                                Customers.checkedTransfer([]);
                            }
                            closeModal(ruleFormRef.value);
                            formEl.resetFields()
                        } else {
                            notification.error(
                                'Error',
                                'result.data.message',
                            )
                        }

                        loading.value = false;

                    }
                })
            }

            const closeModal = (formEl: FormInstance | undefined) => {
                transfer.value.agent = "";
                transfer.value.notes = "";
                formEl?.resetFields()
                modal(false)
            }

            return {
                open,
                rules,
                filter,
                loading,
                customer,
                transfer,
                ruleFormRef,
                checkedTransfer,
                modal,
                closeModal,
                onSubmitTransfer,
                agents
            }
        },
    })
