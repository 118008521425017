// TODO: Remove no-shadow eslint disable after fixed: https://github.com/typescript-eslint/typescript-eslint/issues/2484
// eslint-disable-next-line no-shadow
export enum ReportMutationTypes {
  SET_REPORT_DASHBOARD_DETAILS = "SET_REPORT_DASHBOARD_DETAILS",
  SET_REPORT_LEAD_BY_SOURCE_FILTER = "SET_REPORT_LEAD_BY_SOURCE_FILTER",
  SET_REPORT_LEAD_SOURCE_FILTER = "SET_REPORT_LEAD_SOURCE_FILTER",
  SET_AGENT_SALES_REPORT = "SET_AGENT_SALES_REPORT",
  SET_AGENT_GROUP_SALES_REPORT = "SET_AGENT_GROUP_SALES_REPORT",
  SET_REPORT_DELETED_LEADS = "SET_REPORT_DELETED_LEADS",
  SET_POLICIES_ASSIGNED_VS_COMPLETED_REPORT = "SET_POLICIES_ASSIGNED_VS_COMPLETED_REPORT",
  SET_POLICIES_GROUP_ASSIGNED_VS_COMPLETED_REPORT = "SET_POLICIES_GROUP_ASSIGNED_VS_COMPLETED_REPORT",
  SET_ADMIN_REPORT = "SET_ADMIN_REPORT",
  SET_APPROVED_INVOICES_REPORT = "SET_APPROVED_INVOICES_REPORT",
  SET_APPROVED_GROUP_INVOICES_REPORT = "SET_APPROVED_GROUP_INVOICES_REPORT",
  SET_CLOSED_LEADS_REPORT = "SET_CLOSED_LEADS_REPORT",
  SET_CONVERTED_LEADS_REPORT = "SET_CONVERTED_LEADS_REPORT",
  SET_LEAD_BY_SOURCE_REPORT = "SET_LEAD_BY_SOURCE_REPORT",
  SET_PRODUCTION_REPORT = "SET_PRODUCTION_REPORT",
  SET_ACCOUNT_MASTER_REPORT = "SET_ACCOUNT_MASTER_REPORT"
}
  