
  import { defineComponent, computed } from 'vue'
  import { store } from "@/store";
  import Invoice from '@/core/services/Callable/Invoice';
  
  export default defineComponent({
    setup() {
  
      const filter = computed(() => {
        return store.state.invoice.filter
      })
  
      const invoiceCount = computed(() => {
        return store.state.invoice.groupInvoiceListCounts
      })
  
      const lists = computed(() => {
        return store.state.invoice.groupInvoiceLists
      })
  
      const getAllInvoices = () => {
        filter.value.status = 2;
        filter.value.is_amend = -1;
        getInvoiceListFor(filter.value);
      };
  
      const getNewInvoices = () => {
        filter.value.status = 2;
        filter.value.is_amend = 0;
        getInvoiceListFor(filter.value);
      };
  
      const getAmendInvoices = () => {
        filter.value.status = 2;
        filter.value.is_amend = 1;
  
        getInvoiceListFor(filter.value);
      };
  
      const tabChange = (tab) => {
        switch (tab) {
          case 'new':
            getNewInvoices()
            break;
  
          case 'amend':
            getAmendInvoices()
            break;
  
          default:
            getAllInvoices()
            break;
        }
      };
  
      const getInvoiceListFor = async (e) => {
        filter.value.page = 1;
        await Invoice.groupInvoiceLists(filter.value)
      }
  
  
  
      return {
        store,
        filter,
        invoiceCount,
        lists,
        getInvoiceListFor,
        tabChange
      }
    },
  })
  