import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_date_picker = _resolveComponent("el-date-picker")!

  return (_openBlock(), _createBlock(_component_el_date_picker, {
    class: "p-2",
    modelValue: _ctx.myDate,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.myDate = $event)),
    "unlink-panels": "",
    align: "right",
    type: "daterange",
    "range-separator": "-",
    "start-placeholder": "Start date",
    "end-placeholder": "End date",
    onChange: _ctx.updateValue,
    format: "DD/MM/YYYY",
    "value-format": "YYYY-MM-DD",
    shortcuts: _ctx.shortcuts,
    size: "large"
  }, null, 8, ["modelValue", "onChange", "shortcuts"]))
}