import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["placeholder", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    type: "text",
    class: "form-control",
    placeholder: _ctx.placeholder,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.currentAmount = $event)),
    onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateDecimal && _ctx.updateDecimal(...args))),
    onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.correctValue && _ctx.correctValue(...args))),
    disabled: _ctx.disabled,
    maxlength: "13",
    oninput: "this.value = this.value.replace(/[^0-9\\.\\,]/g, '')",
    ref: "focus"
  }, null, 40, _hoisted_1)), [
    [_vModelText, _ctx.currentAmount]
  ])
}