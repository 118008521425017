const subHeading = [
    {
        label: 'Relationship',
        value: 'relationship'
    },
    {
        label: 'Name',
        value: 'member_name'
    },
    {
        label: 'DOB',
        value: 'dob'
    },
    {
        label: 'Gender',
        value: 'gender'
    },
    {
        label: 'Is Married',
        value: 'is_married'
    },
]

const quoteButtons = [
    {
        icon: 'icon-svg icon-download',
        tip: 'Download',
        value: 'download'
    },
    {
        icon: 'icon-svg icon-inbox',
        tip: 'Send Quote',
        value: 'send-email'
    },
    {
        icon: 'la la-lg la-whatsapp text-info',
        tip: 'Whatsapp',
        value: 'whatsapp'
    },
    
]


export {
    subHeading,
    quoteButtons
}