
import { defineComponent, computed, ref, getCurrentInstance, onMounted } from 'vue'
import Invoice from '@/core/services/Callable/Invoice';
import { store } from "@/store";
import moment from 'moment';
import { customer, showHide, filter as filters, common } from '@/store/stateless';
import { Search } from '@element-plus/icons-vue';
import router from '@/router';

const __default__ = defineComponent({
    name: 'customer-first-layer',
    setup() {

        const currentInst = getCurrentInstance();
        const roles = currentInst?.appContext.config.globalProperties.roles;
        const transfer = ref()
        // const value2 = ref('')

        const shortcuts = [
            {
                text: 'Last week',
                value: () => {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                    return [start, end]
                },
            },
            {
                text: 'Last month',
                value: () => {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                    return [start, end]
                },
            },
            {
                text: 'Last 3 months',
                value: () => {
                    const end = new Date()
                    const start = new Date()
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                    return [start, end]
                },
            },
        ]

        onMounted(async () => {
        })

        const dates = ref([])

        const invoiceLists = computed(() => {
            return store.state.invoice.invoiceLists;
        })

        const filter = computed(() => {
            return store.state.invoice.filter;
        })

        const getDates = async (e) => {
            var start = moment(e[0]).format('YYYY-MM-DD');
            var end = moment(e[1]).format('YYYY-MM-DD');

            // filter.value.task_due_dates = -1
            filter.value.start_date = start;
            filter.value.end_date = end;
            getApprovedInvoiceListFor(filter.value);
        }

        const getApprovedInvoiceListFor = async (e) => {
            await Invoice.invoiceLists(filter.value)
        }
        const resetFilter = () => {
            //   search.value = "";
            filter.value.per_page = 50;
            filter.value.payment_type = -1;
            filter.value.is_amend = -1;
            filter.value.is_discrepancy = 0;
            filter.value.status = 2;
            filter.value.keyword = "";
            filter.value.start_date = "";
            filter.value.end_date = "";
            filter.value.is_manual_order = 0;
            filter.value.tab = 'all'
            dates.value = []
            //   activeLink.value = 'all';
            //   showAction.value = true;

            getApprovedInvoiceListFor(filter.value);
        };

        const searchFilter = () => {
            Invoice.setFilter({
                ...filter.value,
                page: 1,
                tab: 'all'
            })

            router.replace({ query: {  page: filter.value.page } })
            getApprovedInvoiceListFor(filter.value);
        };

        

        return {
            dates,
            common,
            store,
            customer,
            filter,
            filters,
            transfer,
            shortcuts,
            invoiceLists,
            getDates,
            showHide,
            Search,
            resetFilter,
            searchFilter
        }
    },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "bda7baca": (_ctx.customer.colors.btnBgColor),
  "402c3720": (_ctx.customer.colors.btnColor),
  "4bc31f61": (_ctx.customer.colors.btnBgColorHover),
  "10a415fc": (_ctx.customer.colors.btnColorHover),
  "7d877fe6": (_ctx.customer.colors.slcBgColor),
  "6c290912": (_ctx.customer.colors.slcColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__