
import { defineComponent, ref } from 'vue'
import { FormInstance } from 'element-plus';
import { rule } from '@/store/stateless'
import Customers from '@/core/services/Callable/Customers';

export default defineComponent({
    props: {
        lead_id: {
            type: Number,
            required: true
        }
    },
    setup(_) {
        const open = ref(false)
        const ruleFormRef = ref<FormInstance>()
        const notes = ref({
            lead_id: 0,
            notes: ''
        })

        const loading = ref<Boolean>(false)

        const submitTask = async (formEl: FormInstance | undefined) => {
            loading.value = true
            notes.value.lead_id = _.lead_id
            if (!formEl) return
            formEl.validate(async (valid, fields: any) => {
                if(valid) {
                    await Customers.addLeadNote(notes.value)
                    close()
                    loading.value = false
                } else {
                    loading.value = false
                }
            })
        }

        const close = () => {
            modal(false)
            notes.value = {
                lead_id: 0,
                notes: ''
            }
            ruleFormRef.value?.clearValidate()
        }

        const modal = (value) => {
            open.value = value
        }

        return {
            ruleFormRef,
            loading,
            notes,
            rule,
            open,
            close,
            modal,
            submitTask
        }
    },
})
