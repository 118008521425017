
import { computed, defineComponent, onMounted, ref } from 'vue'
import { store } from "@/store";
import Etcs from '@/core/services/Callable/Etcs';


export default defineComponent({
    props: {
        label: {
            type: String,
            required: true,
            default: '',
        },
        modelValue: {
            type: String,
            required: true,
            default: ''
        },
        lead_status_id: {
            type: Number,
            required: true
        },
        prop: {
            type: String,
            required: true,
            default: 'response'
        },
        isRequired: {
            type: Boolean,
            required: false,
            default: false
        },
        lead_id: {
            type: Number,
            required: true
        },
        
    },

    setup(props, context) {


        onMounted(async() => {
            await Etcs.getAllTasks()
        })

        const updateValue = (event) => {
            context.emit("update:modelValue", event)
            context.emit("response", event)
        }

        const tasks = computed(() => {
            const data = store.getters.getTasks(props.lead_status_id , props.lead_id)

            if(data !== undefined) {
                const exist = data.find(x => x.value === 0)
                if(!exist) data.unshift({ label: 'Select Response', value: 0 })
            }

            return data
        })

        const inputRef = ref()
        const focus = () => {
        setTimeout(() => {
                inputRef.value.focus()
            }, 100);
        }

        return {
            tasks,
            focus,
            inputRef,
            updateValue
        }
    },
})
