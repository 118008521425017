
import { defineComponent, computed } from 'vue'
import { store } from '@/store';

export default defineComponent({
    name: 'notesData',
    setup() {
        const loading = computed(() => {
            return store.state.customer.notes_loading
        })

        const notes = computed(() => {
            return store.state.customer.notes
        })

        const task_details = computed(() => {
            return store.state.customer.task_details
        })

        return {
            task_details,
            loading,
            notes
        }
    }
})
