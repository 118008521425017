
import { defineComponent, computed, onMounted, getCurrentInstance  } from 'vue'
import { store } from "@/store";
import { rule, common, notification } from '@/store/stateless';
import { group_columns } from '@/core/utils/invoice'
import Validations from '@/core/services/etc/Validations';

export default defineComponent({
    props:{
        allDisabled:{
            default:false,
            required:true
        }
    },
    setup() {

        const health = computed(() => {
            return store.state.customer.health;
        })

        const vat = computed(() => {
            return store.state.etc.vat  / 100;
        })
        
        const invoice = computed(() => {
            return store.state.invoice.groupInvoice;
        })

        // const categories = computed(() => {
        //     const invoice =  store.state.invoice.groupInvoice;
        //     if(invoice)
        //         return invoice.categories;
        //     return [];
        // })
    
        onMounted(() => {
            // invoice.value.categories = health.value.categories;
            if(invoice.value.categories.length == 0){
                // setting categories in make invoice
                invoice.value.categories = store.getters.getGroupInvoiceCategories
            }
            updateBasmah()

            // console.log('catsdsdegories',invoice.value.categories)

        })

        const updateBasmah = () => {
            // invoice.value.orginal_policy_price
            const basmah = invoice.value.categories.reduce((sum, obj) => {
                const basmah = Number(obj['basmah']);
                if(isNaN(basmah))
                    return sum + 0
                else
                    return sum + basmah
            }, 0);

            invoice.value.basmah = common.roundUp(basmah)

        }

        const updateIcp = () => {
            // invoice.value.orginal_policy_price
            const icp = invoice.value.categories.reduce((sum, obj) => {
                const icp = Number(obj['icp']);
                if(isNaN(icp))
                    return sum + 0
                else
                    return sum + icp
            }, 0);

            invoice.value.icp = common.roundUp(icp)

        }

        const updateNonTaxableFee = () => {
            // invoice.value.orginal_policy_price
            const non_taxable_fee = invoice.value.categories.reduce((sum, obj) => {
                const non_taxable_fee = Number(obj['non_taxable_fee']);
                if(isNaN(non_taxable_fee))
                    return sum + 0
                else
                    return sum + non_taxable_fee
            }, 0);

            invoice.value.non_taxable_fee = common.roundUp(non_taxable_fee)

        }
        
        const updatePolicyPrice = (val) => {
            // invoice.value.orginal_policy_price
            const amount = invoice.value.categories.reduce((sum, obj) => {
                const amount = Number(obj['amount']);
                if(isNaN(amount))
                    return sum + 0
                else
                    return sum + amount
            }, 0);

            val.amount_vat = common.roundUp(val.amount * vat.value)
            if(isNaN(val.amount_vat)) val.amount_vat = 0

            invoice.value.orginal_policy_price = common.roundUp(amount)

        }


        const inst = getCurrentInstance()
        const focus = () => {
            const toFocus:any = inst?.refs[rule.customerDetails]
            console.log('toFocus',toFocus)
            if(toFocus !== undefined) {
                toFocus.focus()
                // window.scrollTo(0, common.getY('top'))
            }
        }

        return {
            invoice,
            focus,
            health,
            group_columns,
            Validations,
            updatePolicyPrice,
            common,
            updateIcp,
            updateNonTaxableFee
        }
    },
})
