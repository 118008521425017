import { MutationTree } from 'vuex';

import { State } from './state';
import { QuoteMutationTypes } from '@/store/enums/Quote/Mutations';

export type Mutations<S = State> = {
    [QuoteMutationTypes.SET_POLICIES](state: S, payload: any | null): void;
}

export const mutations: MutationTree<State> & Mutations = {
      [QuoteMutationTypes.SET_POLICIES](state: State, payload: any) {
        state.policies = payload;
      },

      [QuoteMutationTypes.SET_QUOTE_PDF_LINK](state: State, payload: any) {
        state.quote_pdf_link = payload;
      },

      [QuoteMutationTypes.SET_QUOTES](state: State, payload: any) {
        state.manual_quotes = payload;
      },

      [QuoteMutationTypes.SET_INVOICE_BASMAH](state: State, payload: any) {
        state.basmah = parseFloat(payload);
      },

      [QuoteMutationTypes.SET_QUOTE_POLICIES](state: State, payload: any) {
        state.quote_policies = payload;
      },
};
