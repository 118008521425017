import { App } from "vue";
import { FormInstance } from 'element-plus';
import Auths from '@/core/services/Callable/Auths';
import router from "@/router/clean";
import { loading, disable, constant, notification } from '@/store/stateless';
import { store } from "@/store";
import Etcs from "../services/Callable/Etcs";

/**
 * Initialize auth
 * @param app vue instance
 * @created vincent carabbacan
 */

export const auth = (app: App<Element>) => {
  app.config.globalProperties.auth = {
    async signin(formEl: FormInstance) {
      this.signinTasks(true);
      if (!formEl) return
      formEl.validate(async (valid) => {
        if (valid) {
          const result = await Auths.Login(constant.signin);
          if (result.status == 200) {
            notification.success('Login', 'Login successfully');
            await this.getRoutes()
            await this.signinTasks(false);
            // setTimeout(() => {
            //   window.location.reload();
            // }, 1000);
          } else {
            notification.warning('Login', result.data.message);
            await this.signinTasks(false);
          }
        } else {
          // notification.error('Login', 'Try again!')
          await this.signinTasks(false);
        }
      })
    },

    signinTasks(e: Boolean) {
      loading.signin = e;
      disable.signin = e;
    },

    async getRoutes() {
      await Etcs.getRoutes()
    },

    async signOut() {
      await Auths.Signout()
    },

    user() {
      return store.state.auth.user;
    },

    currentRouteName() {
      return router.currentRoute.value.name;
    }
  }
}