
import { defineComponent, ref, computed } from 'vue'
import { insurance_type } from '@/core/utils'


export default defineComponent({
    props: {
        modelValue: {
            type: String,
            required: true,
            default: ''
        },
        label: {
            type: String,
            required: true,
            default: 'Select Insurance Type'
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        isllrNa:{
            type: Boolean,
            required: false,
            default: false
        },
        insuredFor:{
            type: Number,
            required: true,
            default: 0
        }
    },

    setup(props, context) {

        const insuranceType = computed(() => {
            console.log(props.isllrNa)
            if(props.isllrNa)
                return insurance_type.filter(x => x.value != 1)

            if(props.insuredFor && ![3,5,7].includes(props.insuredFor))
                return insurance_type.filter(x => x.value != 3)

            return insurance_type
        })
        
        const updateValue = (event) => {
            context.emit("update:modelValue", event)
            context.emit("insurance_type", event)
        }

        // onMounted(() => {

        // })
        

        const inputRef = ref()
        const focus = () => {
        setTimeout(() => {
                inputRef.value.focus()
            }, 100);
        }

        return {
            focus,
            insuranceType,
            inputRef,
            updateValue
        }
    },
})
