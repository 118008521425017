import { GetterTree } from 'vuex';
import router from "@/router/clean";

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';



import { State } from './state';
import { common } from "@/store/stateless";

import {
    total_sales,
    new_sales,
    wip_sales,
    renewal_sales,
    llr_sales,
    lrl_sales,
    lr_sales,
    untouched_sales,
    refunds,
    cancellation,
    total_revenue
} from "@/core/utils/dashboard.js";

export type Getters = {
}

const chart = {
    "series": [30, 40],
    "options": {
        "chart": {
            "width": 400,
            "height": 250,
            "type": "pie"
        },
        "colors": [],
        "labels": ['Paid','Organic'],
        "responsive": [{
            "breakpoint": 400,
            "options": {
                "chart": {
                    "width": 400,
                    "height": 250,
                },
                "legends": {
                    "position": "bottom"
                }
            }
        }]
    }
}

export const getters: GetterTree<State, RootState> & Getters = {

    getOverviewData: (state) => {
        const overview = state.overview

        if (overview) {

            // overview.total_sales

            const data = [
                { container: total_sales, values: overview.total_sales },
                { container: new_sales, values: overview.new_sales },
                { container: wip_sales, values: overview.wip_sales },
                { container: renewal_sales, values: overview.renewal_sales },
                { container: llr_sales, values: overview.llr_sales },
                { container: lrl_sales, values: {...overview.lrl_sales, untouched_hot_enquiry: overview.untouched_sales.untouched_hot_enquiry } },
                { container: lr_sales, values: overview.lr_sales },
                { container: untouched_sales, values: overview.untouched_sales },
                { container: refunds, values: overview.refunds },
                { container: cancellation, values: overview.cancellation },
                { container: total_revenue, values: overview.total_sales },
            ]

            // console.log(data);
            return data
        }

        return [];
    },

    getTopSalesAgent: (state) => {
        const datas = state.salesPersonInfo.topSalesAgent;


        if (datas) {
            const value = datas.map((data) => {
                return {
                    agent_name: data.agent_name,
                    total_leads: data.total_leads,
                    total_deals: data.total_deals,
                    new_leads: data.new_leads,
                    renewal_leads: data.renewal_leads,
                    llr_leads: data.llr_leads,
                    pending_leads: data.pending_leads,
                    untouched_customers: data.untouched_customers,
                    untouched_leads: data.untouched_leads,
                    over_due_tasks: data.over_due_tasks,
                    new_deals: data.new_deals,
                    renewal_deals: data.renewal_deals,
                    lrl_leads: data.lrl_leads,
                    lost_leads: data.lost_leads,
                    total_deals_cancelled: 0,
                    deals_cancellation_payable_amount: 0,
                    total_revenue: common.formatCurrency(data.total_revenue),
                    closing_ratio_total: data.closing_ratio_total + '%', // (total_deals / total_leads) * 100
                    closing_ratio_new: data.closing_ratio_new + '%', // (new_deals / new_leads) * 100
                    closing_ratio_renewal: data.closing_ratio_renewal + '%', // (renewal_deals / renewal_leads) * 100
                    llr_deals: data.llr_deals,
                    closing_ratio_llr: data.closing_ratio_llr + '%',
                    wip_deals: data.wip_deals,
                }
            })
            const total = totalTopSalesAgent(datas)
            if (Object.keys(total).length > 0) {
                value.push(total)
            }

            return value

        } else {
            return []
        }
    },

    getSalesTargetDashboard: (state) => {
        const datas = state.salesPersonInfo.salesTarget;
        if(datas){
            const value = datas.map((data) => {
                data.revenue_made = common.formatCurrency(data.revenue_made)
                data.revenue_target = common.formatCurrency(data.revenue_target)
                return data;
            });
            return value;
        }
        return [];
    },

    getLeadsTargetDashboard: (state) => {
        const datas = state.salesPersonInfo.leadsTarget;
        if(datas){
            const value = datas.map((data) => {
                data.marketing_spend = common.formatCurrency(data.marketing_spend)
                return data;
            });
            return value;
        }
        return [];
    },

    getSalesOverviewData: (state) => {
        const user = JSON.parse(window.localStorage.getItem('user') || '')
        if(user) {
            if(user.role_id == 2 && state.salesPersonInfo && state.salesPersonInfo.topSalesAgent)
                return state.salesPersonInfo.topSalesAgent.find(x => x.agent === user.id)
            
            return state.salesOverview
        }

        return {
            over_due_tasks: 0,
            todays_task_count: 0
        }
    },

    getTeamSummary: (state) => {
        if(state.salesPersonInfo) {
            const data = state.salesPersonInfo.topSalesAgent
            const total = {
                id: 99999,
                agent_name: 'Total',
                cancellation_amount: calculateTotal(data, 'cancellation_amount'),
                cancellation_count: calculateTotal(data, 'cancellation_count'),
                closing_ratio_llr: calculateTotal(data, 'closing_ratio_llr'),
                closing_ratio_new: calculateTotal(data, 'closing_ratio_new'),
                closing_ratio_renewal: calculateTotal(data, 'closing_ratio_renewal'),
                current_month_tasks: calculateTotal(data, 'current_month_tasks'),
                is_target_flag: true,
                leads_this_month: calculateTotal(data, 'leads_this_month'),
                llr_deals: calculateTotal(data, 'llr_deals'),
                llr_leads: calculateTotal(data, 'llr_leads'),
                lost_leads: calculateTotal(data, 'lost_leads'),
                lrl_leads: calculateTotal(data, 'lrl_leads'),
                new_deals: calculateTotal(data, 'new_deals'),
                new_leads: calculateTotal(data, 'new_leads'),
                over_due_tasks: calculateTotal(data, 'over_due_tasks'),
                pending_leads: calculateTotal(data, 'pending_leads'),
                previous_month_tasks: calculateTotal(data, 'previous_month_tasks'),
                quick_leads: calculateTotal(data, 'quick_leads'),
                renewal_deals: calculateTotal(data, 'renewal_deals'),
                renewal_leads: calculateTotal(data, 'renewal_leads'),
                revenue_target: calculateTotal(data, 'revenue_target'),
                revenue_target_achieved: calculateTotal(data, 'revenue_target_achieved'),
                revenue_target_no_of_days: calculateTotal(data, 'revenue_target_no_of_days'),
                sales_target: calculateTotal(data, 'sales_target'),
                target_percentage_met: calculateTotal(data, 'target_percentage_met'),
                todays_task_count: calculateTotal(data, 'todays_task_count'),
                total_deals: calculateTotal(data, 'total_deals'),
                total_leads: calculateTotal(data, 'total_leads'),
                total_refund: calculateTotal(data, 'total_refund'),
                total_refund_count: calculateTotal(data, 'total_refund_count'),
                total_revenue: calculateTotal(data, 'total_revenue'),
                untouched_customers: calculateTotal(data, 'total_refund'),
                untouched_leads: calculateTotal(data, 'untouched_leads'),
                variance: calculateTotal(data, 'variance'),
                wip_deals: calculateTotal(data, 'wip_deals'),
                yesterdays_task_count: calculateTotal(data, 'yesterdays_task_count'),
                closing_ratio_total: common.formatCurrency(calculateRatio(data, 'total_deals', 'total_leads')),
            }

            data.push(total)

            return data
        }
        return []
    },


    getSalesAgentOverviewData: (state) => {
        const defaultData = {
            agent: 0,
            agent_name: "",
            revenue_made: 0,
            cancellation_amount: 0,
            cancellation_count: 0,
            closing_ratio_llr: "",
            closing_ratio_new: "",
            closing_ratio_renewal: "",
            closing_ratio_total: "",
            current_month_tasks: 0,
            is_target_flag: true,
            leads_this_month: 0,
            llr_deals: 0,
            llr_leads: 0,
            lost_leads: 0,
            lrl_leads: 0,
            new_deals: 0,
            new_leads: 0,
            over_due_tasks: 0,
            pending_leads: 0,
            previous_month_tasks: 0,
            quick_leads: 0,
            renewal_deals: 0,
            renewal_leads: 0,
            revenue_target: "",
            revenue_target_achieved: "",
            revenue_target_no_of_days: 0,
            sales_target: 0,
            target_percentage_met: "",
            todays_task_count: 0,
            total_deals: 0,
            total_leads: 0,
            total_refund: 0,
            total_refund_count: 0,
            total_revenue: "",
            untouched_customers: 0,
            untouched_leads: 0,
            variance: "",
            wip_deals: 0,
            yesterdays_task_count: 0
        }
        
        const user = JSON.parse(window.localStorage.getItem('user') || '')
        if(state.salesPersonInfo) {
            const data = state.salesPersonInfo.topSalesAgent.find(x => x.agent === user.id)
            if(data) {
                return {
                    ...data,
                    revenue_made: data.total_revenue
                }
            }

            return defaultData
        }
        return defaultData
    },


    getUnderwriterData: (state) => {
        const user = JSON.parse(window.localStorage.getItem('user') || '')
        if(state.underwriterInfo) {
            const data = state.underwriterInfo.find(x => x.id === user.id)
            if(data) {
                return {
                    ...data,
                    revenue_made: data.total_revenue
                }
            }

            return state.underwriterInfo[0]
        }
        return state.underwriterInfo[0]
    },

    getSalesPie: (state) => {
        let series = []
        let labels = [];

        if(state.salesPersonInfo.topPerformance && state.salesPersonInfo.topPerformance.length > 0) {
            series = state.salesPersonInfo.topPerformance.map(x => parseFloat(x.total_revenue))
            labels = state.salesPersonInfo.topPerformance.map(x => x.agent_name)
        }
        return {
            "series": series,
            "options": {
                "chart": {
                    "width": 400,
                    "type": "pie"
                },
                "labels": labels,
                "responsive": [{
                    "breakpoint": 480,
                    "options": {
                        "chart": {
                            "width": 300,
                        },
                        "legends": {
                            "position": "bottom"
                        }
                    } 
                }]
            }
        }
    },

    getAccountsPreview: (state) => {
        return {
            ...state.accountsInfo.invoices,
            ...state.accountsInfo.policies,
            ...state.accountsInfo.refunds,
            policies_completed: state.accountsInfo.policies.completed,
            refunds_completed: state.accountsInfo.refunds.completed,
            refunds_pending: state.accountsInfo.refunds.pending
        }
    },
    
    getHasSalesPersonPieValue: (state) => {
        const sum = state.salesPersonInfo.topPerformance.reduce((total, item) => {
            return total += parseFloat(item.total_revenue)
        },0)

        if(sum > 0) return true

        return false
    },

    getMarketTotal: (state) => {
        let lead_by_source  = 0
        let lead_by_source_deleted  = 0
        let deal_by_source  = 0

        lead_by_source = state.marketingInfo.lead_source_leads.new.count.reduce((total, item) => {
            return total += parseInt(item)
        }, 0)

        lead_by_source_deleted = state.marketingInfo.lead_source_leads.deleted.count.reduce((total, item) => {
            return total += parseInt(item)
        }, 0)
        deal_by_source = state.marketingInfo.lead_source_deals.new.count.reduce((total, item) => {
            return total += parseInt(item)
        }, 0)

        return {
            lead_by_source,
            lead_by_source_deleted,
            deal_by_source
        }
    },

    getStatGraph : (state) => {
        let leadSourceGraph = state.leadSourceGraph
        if(Object.keys(leadSourceGraph).length == 0) {
            leadSourceGraph = state.marketingInfo
        }
        // console.log('leadSourceGraph',leadSourceGraph)
        // console.log('chart',chart)
        const graphs = state.statGraph

        for(const key in leadSourceGraph){
            if(graphs[key]) {
                for(const type in leadSourceGraph[key]){
                    const data = leadSourceGraph[key][type];

                    // const newChart = {...chart}
                    // data.count.filter(x => x).map(x=> parseInt(x))
                    const series =  data.count.filter(x => x).map( (x) =>  Number(x));
                    // newChart.options.labels = Object.values(data.names) ;
                    // newChart.options.colors = Object.values(data.colors) ;

                    graphs[key][type] = {
                        "series": series,
                        "options": {
                            "chart": {
                                "width": 400,
                                "height": 250,
                                "type": "pie"
                            },
                            "colors":  Object.values(data.colors),
                            "labels":  Object.values(data.names),
                            "responsive": [{
                                "breakpoint": 400,
                                "options": {
                                    "chart": {
                                        "width": 400,
                                        "height": 250,
                                    },
                                    "legends": {
                                        "position": "bottom"
                                    }
                                }
                            }]
                        }
                    }

                    // console.log(key, type, newChart.series,  newChart.options.labels, newChart.options.colors)

                    // console.log('graphs[key]',key,graphs[key])
                    // graphs[key][type] = {...newChart};
                    // break;
                }
            }
        }
        // console.log('graphs',graphs)

        return graphs;
    },
    
    getTableStat: (state) => {
        return state.tableStat
    },

    getNewLeads: (state) => {

        if(state.leadsNew) {
            const data = state.leadsNew.data

            // console.log('gget',data)
            if(data) {
                return data.map((x) => {
                    return {
                        name: x.customer.name,
                        email: x.customer.email,
                        phone_number: x.customer.phone_number,
                        created_at: x.customer.created_at,
                    }
                });
            }
        }
        return [];

    },

    getPendingLeads: (state) => {

        if(state.leadsPending) {
            const data = state.leadsPending.data

            // console.log('gget',data)
            if(data) {
                return data.map((x) => {
                    return {
                        name: x.customer.name,
                        email: x.customer.email,
                        phone_number: x.customer.phone_number,
                        created_at: x.customer.created_at,
                    }
                });
            }
        }
        return [];

    },
    getLostLeads: (state) => {

        if(state.leadsLost) {
            const data = state.leadsLost.data

            // console.log('gget',data)
            if(data) {
                return data.map((x) => {
                    return {
                        name: x.customer.name,
                        email: x.customer.email,
                        phone_number: x.customer.phone_number,
                        created_at: x.customer.created_at,
                    }
                });
            }
        }
        return [];

    }



};

const  totalTopSalesAgent = (datas) => {
    if(datas) {
        const total_deals = calculateTotal(datas, 'total_deals')
        const total_leads = calculateTotal(datas, 'total_leads')
        const new_deals = calculateTotal(datas, 'new_deals')
        const new_leads = calculateTotal(datas, 'new_leads')
        const renewal_deals = calculateTotal(datas, 'renewal_deals')
        const renewal_leads = calculateTotal(datas, 'renewal_leads')
        const closing_total = common.roundUp((total_deals / total_leads) * 100)
        const closing_new = common.roundUp((new_deals / new_leads) * 100)
        const closing_renewal = common.roundUp((renewal_deals / renewal_leads) * 100)
        const lost_leads = calculateTotal(datas, 'lost_leads')
        const lrl_leads = calculateTotal(datas, 'lrl_leads')
        const llr_deals = calculateTotal(datas, 'llr_deals')
        const closing_llr = common.roundUp((llr_deals / lrl_leads) * 100)
        const wip_deals = calculateTotal(datas, 'wip_deals')
        const revenue_target = calculateTotal(datas, 'revenue_target')
        const revenue_target_achieved = common.roundUp((calculateTotal(datas, 'total_revenue') / revenue_target) * 100)
        const revenue_target_no_of_days = calculateTotal(datas, 'revenue_target_no_of_days')
        const total_target_percentage_met = common.roundUp((calculateTotal(datas, 'total_revenue') / revenue_target_no_of_days) * 100)
        const is_target_flag = isFlagTrue(datas, 'is_target_flag')
        return {
            agent_name: 'Total',
            total_leads,
            total_deals,
            new_leads,
            renewal_leads,
            llr_leads: calculateTotal(datas, 'llr_leads'),
            pending_leads: calculateTotal(datas, 'pending_leads'),
            untouched_customers: calculateTotal(datas, 'untouched_customers'),
            untouched_leads: calculateTotal(datas, 'untouched_leads'),
            over_due_tasks: calculateTotal(datas, 'over_due_tasks'),
            new_deals,
            renewal_deals,
            total_deals_cancelled: 0,
            deals_cancellation_payable_amount: 0,
            total_revenue: common.formatCurrency(common.roundUp(calculateTotal(datas, 'total_revenue'))),
            closing_ratio_total: isFinite(closing_total) ? `${common.roundUp(closing_total)}%` : `0.00%`,
            closing_ratio_new: isFinite(closing_new) ? `${common.roundUp(closing_new)}%` : `0.00%`,
            closing_ratio_renewal: isFinite(closing_renewal) ? `${common.roundUp(closing_renewal)}%` : `0.00%`,
            lost_leads,
            lrl_leads,
            llr_deals,
            closing_ratio_llr: isFinite(closing_llr) ? `${common.roundUp(closing_llr)}%` : `0.00%`,
            wip_deals,
            revenue_target_achieved: isFinite(revenue_target_achieved) ? `${common.roundUp(revenue_target_achieved)}%` : `0.00%`,
            target_percentage_met: isFinite(total_target_percentage_met) ? `${common.roundUp(total_target_percentage_met)}%` : (is_target_flag ? `0.00%` : '-'),
            revenue_target_no_of_days: isFinite(revenue_target_no_of_days) ? `${common.formatCurrency(common.roundUp(revenue_target_no_of_days))}` : `-`,
            is_target_flag:is_target_flag
        }
    }

    return {}
}


const calculateTotal = (data, column) => {
    var format = /,/;

    return data.reduce((total, item) => {
            if(format.test(item[column])){
                item[column] = item[column].replaceAll(',','');
            } 
            if(typeof item[column] === 'string') {
                return total += parseFloat(item[column].replaceAll(',',''))
            } else {
                return total += item[column]
            }
    }, 0)
}

const calculateRatio = (data, value, total) => {
    const ratio = calculateTotal(data, value) > 0 ? (calculateTotal(data, value)  / calculateTotal(data, total) ) * 100 : 0

    return common.formatCurrency(ratio.toFixed(2));
}


const isFlagTrue = (data, column) => {
    return data.map((item) => {
       return item[column]
    }, false)
}