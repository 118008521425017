
import { defineComponent, ref, computed, getCurrentInstance } from 'vue'
import { store } from '@/store';
import router from '@/router/clean';
import { common } from '@/store/stateless'
import { lead_activity } from '@/core/utils'
import Customers from '@/core/services/Callable/Customers';

export default defineComponent({
    setup() {
        const open = ref<Boolean>(false)
        const activeTab = ref<Number>(0)
        const activityTab = ref<Number>(0)

        const leads = computed(() => {
            return store.getters.getLeads(true)
        })

        const modal = (value: Boolean) => {
            open.value = value
            if(!value) {
                Customers.resetLeadActivity()
            }
        }

        const loading = computed(() => {
            const lead_activity_log_loading = store.state.customer.lead_activity_log_loading
            const lead_status_log_loading = store.state.customer.lead_status_log_loading

            if(lead_activity_log_loading || lead_status_log_loading) return true

            return false
        })

        const activeValue = ref(0);

        const changeValue = () => {
            fetchData(activeValue.value);
        };

        const fetchData = async (value: number) => {
            activeTab.value = value
            activityTab.value = 1
            await fetch()
        }

        const fetchActivity = async (value: number) => {
            activityTab.value = value
            await fetch()
        }

        const fetch = async() => {
            if(activityTab.value === 1) {
                await Customers.getLeadActivityLog({ lead_id: activeTab.value })
            } else {
                await Customers.getLeadStatusLog({ lead_id: activeTab.value, customer_id: parseInt(router.currentRoute.value.params.customer_id.toString()) })
            }
        }

        const inst = getCurrentInstance()
        const clickOpen = () => {
            const lead_id = leads.value[0].lead_id
            
            activeValue.value = lead_id;
            changeValue();
            setTimeout(() => {
                const listRef:any = inst?.refs[`lead_${lead_id}`]
                activityTab.value = 1
                // listRef.click()
            }, 500);

        }

        return {
            lead_activity,
            activityTab,
            activeTab,
            loading,
            common,
            store,
            leads,
            open,
            modal,
            clickOpen,
            fetchData,
            fetchActivity,
            activeValue,
            changeValue
        }
    },
})
