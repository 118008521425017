<template>
  <el-table 
    :data="items" 
    :stripe="stripe" 
    style="width: 100%"
     :header-cell-style="{ background: '#f4f3fb ' }"
>

      <!-- slots for button menu -->
        <el-table-column width="100" >
                <template #header>
                    <slot name="button-menu-header"></slot>
                </template>
                <template #default="props">
                    <slot name="button-menu-item" :value="props.row"></slot>
                </template>
        </el-table-column>
    <!-- end slot -->

    <!-- index -->
    <el-table-column
        width="100"
        v-if="isIndex"
    >
        <template #header>
        {{ indexName }}
      </template>
        <template #default="scope">
            {{ scope.$index + 1 }}
        </template>
    </el-table-column>
    <!-- end index -->

    <!-- start item data -->
    <el-table-column 
        v-for="(column, index) in columns"
        :key="index"
        :prop="column.prop" 
        :label="column.label" 
        :width="column.width"
        :align="column.align"
        :fixed="fixed"
    >

        <template #default="props">
            <div v-if="column.prop == 'file_path'">
                <div v-if="props.row.file_extension === 'jpg'">
                    <el-image style="width: 50px; height: 50px" :src="props.row.file_path" fit="contain" />
                    <!-- <img :src="props.row.file_path" :alt="props.row.title" width="250" height="250" contain /> -->
                </div>
                <div v-else>
                    <img src="media/svg/files/pdf.svg" alt="svg" />
                </div>
            </div>
            
            <div v-if="column.prop == 'drop_menu'">
                <span v-if="!allowSelect"> {{ props.row.drop_menu }} </span>
                <slot v-else name="dropMenu" :value="props.row">
                </slot>
            </div>

            <div v-if="column.prop == 'price'">
                <span> {{ $filters.currency(props.row.price) }} </span>
            </div>
        </template>
        
    </el-table-column>
    <!-- end item date -->

    <el-table-column
        :fixed="actionFixed"
        width="100"
        v-if="isACtion"
    >
        <template #header>
            {{ ActionName }}
        </template>
        <template #default="props">
            <el-button 
                circle
                v-if="showEdit"
                @click="$emit('EditItem', props.row)"
            >
                <i class="la la-pen"></i>
            </el-button>
            <el-button 
                circle
                v-if="showDelete"
                @click="$emit('DeleteItem', props.row)"
            >
                <i class="la la-trash"></i>
            </el-button>
            <a
                circle
                :href="props.row.file_path"
                target="_blank"
                class="btn-outline-brand"
                v-if="showView"
            >
                <i class="la la-eye"></i>
            </a>
        </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        items: {
            type: Array,
            required: true,
            default: []
        },
        columns: {
            type: Array,
            required: true,
            default: [{
                fixed: false,
                label: "",
                prop: "",
                width: ""
            }]
        },
        isACtion: {
            type: Boolean,
            required: false,
            default: false
        },
        ActionName: {
            type: String,
            required: false,
            default: 'Action'
        },
        actionFixed: {
            type: [Boolean , String],
            required: false,
            default: false
        },
        indexName: {
            type: String,
            required: false,
            default: "Sr.#"
        },
        isIndex: {
            type: Boolean,
            required: false,
            default: false
        },
        allowSelect: {
            type: Boolean,
            required: false,
            default: false
        },
        showView: {
            type: Boolean,
            required: false,
            default: false
        },
        showDelete: {
            type: Boolean,
            required: false,
            default: false
        },
        showEdit: {
            type: Boolean,
            required: false,
            default: false
        },
        stripe: {
            type: Boolean,
            required: false,
            default: false
        },
    },

})
</script>
