import { MutationTree } from 'vuex';
import { DashboardMutationTypes } from '@/store/enums/Dashboard/Mutations';


import { State } from './state';

export type Mutations<S = State> = {
  
}

const conatiner = [
    { url: 'total-sales' , key: 'total_sales' },
    { url: 'total-new-sales' , key: 'new_sales' },
    { url: 'total-renewal-sales' , key: 'renewal_sales' },
    { url: 'total-llr-sales' , key: 'llr_sales' },
    { url: 'total-lrl-sales' , key: 'lrl_sales' },
    { url: 'lifetime-renewal-leads' , key: 'lr_sales' },
    { url: 'total-untouched-leads' , key: 'untouched_sales' },
    { url: 'total-wip-sales', key: 'wip_sales' },
];

export const mutations: MutationTree<State> & Mutations = {
    [DashboardMutationTypes.SET_OVERVIEW_DATA](state: State, payload: any) {
        // const data = conatiner.filter(x => x.url == payload.url)[0];
        // state.overview[data.key] = payload.value;

        for(const url in payload){
            const data = conatiner.filter(x => x.url == url)[0];
            state.overview[data.key] = payload[url];
        }

    },

    [DashboardMutationTypes.SET_SALESPERSON_INFO](state: State, payload: any) {
        const input = {
            topSalesAgent: payload.topSalesPerson.result,
            topPerformance: payload.topSalesPerson.resultPerformance,
            salesTarget: payload.salesTargetSummary,
            leadsTarget: payload.leadTargetSummary,
        }
        state.salesPersonInfo = input;
    },

    [DashboardMutationTypes.SET_DASHBOARD_STATISTICS](state: State, payload: any) {
        state.leadSourceGraph = payload.leadSourceGraph;
        state.tableStat.top_provider = payload.topProviders.result;
        state.tableStat.top_emirate = payload.topEmirate.result;
        state.tableStat.top_group_provider = payload.topGroupProviders.result;
        state.tableStat.top_group_emirate = payload.topGroupEmirate.result;
        // state.tableStat.top_brand_plans = payload.topProviders.result;
        state.tableStat.top_insured = payload.topInsured.result;
    },

    [DashboardMutationTypes.SET_DASHBOARD_OTHERS](state: State, payload: any) {
        // state.otherStat = payload;
        state.otherAveragePoliciesData = payload.getPolicyData['policy-data']['result']
        state.otherClosingRatio = payload.getClosingRatio['closing-ratio']['result']
        state.leadsNew = payload.leadsNew;
        state.leadsPending = payload.leadsPending;
        state.leadsLost = payload.leadsLost;
        state.codTransactions = payload.listCodTransactions
        state.directTransactions = payload.listDirectTransactions
        state.chequeTransactions = payload.listChequeTransactions
        state.bankTransferTransactions = payload.listBankTransferTransactions
        state.groupCodTransactions = payload.listGroupCodTransactions
        state.groupDirectTransactions = payload.listGroupDirectTransactions
        state.groupChequeTransactions = payload.listGroupChequeTransactions
        state.groupBankTransferTransactions = payload.listGroupBankTransferTransactions
        console.log('otherStat',state.otherAveragePoliciesData)
    },


    [DashboardMutationTypes.SET_DASHBOARD_LEADS](state: State, payload: any) {
        // console.log('pauloaf',payload)

        if(payload.status == 'new')
            state.leadsNew = payload;

        else if(payload.status == 'pending')
            state.leadsPending = payload;

        else if(payload.status == 'lost')
            state.leadsLost = payload;
    },

    [DashboardMutationTypes.SET_DASHBOARD_TRANSACTIONS](state: State, payload: any) {
        // console.log('pauloaf',payload)
        if(payload.type == 'group') {
            if(payload.paymentType == 'cod')
                state.groupCodTransactions = payload;

            else if(payload.paymentType == 'direct')
                state.groupDirectTransactions = payload;

            else if(payload.paymentType == 'cheque')
                state.groupChequeTransactions = payload;

            else if(payload.paymentType == 'bank_transfer')
                state.groupBankTransferTransactions = payload;
        }else {
            if(payload.paymentType == 'cod')
                state.codTransactions = payload;

            else if(payload.paymentType == 'direct')
                state.directTransactions = payload;

            else if(payload.paymentType == 'cheque')
                state.chequeTransactions = payload;

            else if(payload.paymentType == 'bank_transfer')
                state.bankTransferTransactions = payload;
        }
    },

    [DashboardMutationTypes.SET_DASHBOARD_UNDERWRITER](state: State, payload: any) {
        state.underwriterInfo = payload
    },

    [DashboardMutationTypes.SET_DASHBOARD_ACCOUNTS](state: State, payload: any) {
        state.accountsInfo = payload
    },

    [DashboardMutationTypes.SET_DASHBOARD_MARKETING](state: State, payload: any) {
        state.marketingInfo = payload
    }
    
};
