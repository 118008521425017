import { App } from "vue";
import Currency from '@/core/services/etc/Currency';
import moment from 'moment';

/**
 * Initialize custom filters
 * @param app vue instance
 * @created vincent carabbacan
 */
export function initVincentFilters(app: App<Element>) {
    app.config.globalProperties.$filters = {
        currency(value: number) {
          return Currency.currencyFormater(value);
        },

        filteredDate(value: any) {
          return moment(value).format('Y/m/d HH:MM A');
        }
      };

}
