import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mt-5" }
const _hoisted_2 = { class: "d-flex justify-content-center mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_health_top = _resolveComponent("vc-health-top")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_vc_button = _resolveComponent("vc-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_ctx.open)
    ? (_openBlock(), _createBlock(_component_el_dialog, {
        key: 0,
        modelValue: _ctx.open,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.open = $event)),
        "append-to-body": "",
        width: _ctx.common.isMobile() ? '100%': '35%',
        title: "Sponsor Details",
        onClosed: _cache[2] || (_cache[2] = ($event: any) => (_ctx.close())),
        draggable: "",
        "close-on-click-modal": false
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_vc_health_top, {
              ref: "top",
              "is-show-header": false,
              "is-edit": "",
              "is-disbaled": true
            }, null, 512)
          ]),
          _createVNode(_component_el_divider),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_vc_button, {
              text: "Close",
              className: "btn btn-light me-3",
              onVcClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.close())),
              "is-regular": ""
            })
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "width"]))
    : _createCommentVNode("", true)
}