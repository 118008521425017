
import { defineComponent, computed } from 'vue'
import { store } from "@/store";
import Customers from '@/core/services/Callable/Customers';
import { UserTypesMixin } from '@/core/plugins/vcMixins';
import { customer, copyText } from '@/store/stateless';
import router from '@/router/clean';

export default defineComponent({
    setup() {

        const lists = computed(() => {
            return store.state.customer.customerLists;
        })

        const loading = computed(() => {
            return store.state.customer.loading
        })

        const checked = () => {
            Customers.checkedTransfer(customer.checkedTransfer)
        }

        const toDetailed = (e) => {
                const route = router.resolve({ name: 'CustomerDetails', params: { customer_id: e.customer_id } })
                if(route) {
                    window.open(route.href)
                }
        }

        return {
            lists,
            loading,
            customer,
            UserTypesMixin,
            toDetailed,
            checked,
            copyText
        }
    },
})
