
import { defineComponent, computed } from 'vue'
import { Search } from '@element-plus/icons-vue'
import Users from '@/core/services/Callable/Users'
import { store } from "@/store";
import router from '@/router/clean';

export default defineComponent({
    setup() {
        const filter = computed(() => {
            return store.state.user.user_list_payload
        })

         const getLists = async (e: any) => {
            const page = e;
            router.replace({ query: { page } })
                .catch(error => {
                    if (
                        error.name !== 'NavigationDuplicated' &&
                        !error.message.includes('Avoided redundant navigation to current location')
                    ) {
                        console.log(error)
                    }
                });
            filter.value.index = page;

            await Users.getUsers(filter.value);
        }

        const reset = async() => {
            filter.value.agent = 'all'
            filter.value.end_date = ''
            filter.value.keyword = ''
            filter.value.per_page = 50
            filter.value.role_id = 0
            filter.value.start_date = ''
            filter.value.status = ''
            await getLists(1)
        }

        return {
            Search,
            filter,
            getLists,
            reset
        }
    },
})
