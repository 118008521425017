import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = { class: "flex-grow-1 ms-4" }
const _hoisted_3 = { class: "text-gray-800 text-hover-primary fw-bolder fs-6" }
const _hoisted_4 = { class: "text-muted fw-bold d-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: _normalizeClass(["bullet bullet-vertical h-50px", `bg-${
            _ctx.color[Math.floor(Math.random() * _ctx.color.length)]
            }`])
    }, null, 2),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.item.notes), 1),
      _createElementVNode("span", _hoisted_4, " Created By - " + _toDisplayString(_ctx.item.user.name) + " at " + _toDisplayString(_ctx.item.created_at_format), 1)
    ])
  ]))
}