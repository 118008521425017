
import { defineComponent, ref } from 'vue'
import { activity_logs, status_logs } from '@/core/utils'

export default defineComponent({
    props: {
        value: {
            type: Object,
            required: true
        },
        property: {
            type: String,
            required: true
        },
        activeTab: {
            type: Number,
            required: true
        },
        isPolicy: {
            type: Boolean,
            required: false
        },

    },
    setup() {
        const color = ref<Array<String>>(["success", "primary", "warning", "danger", "info"])
        const close_task = ref()
        const openTask = (e) => {
            close_task.value.modal(true, e)
        }
        return {
            activity_logs,
            status_logs,
            close_task,
            color,
            openTask
        }
    },
})
