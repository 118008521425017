import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "table-custom-invoice" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 2 }
const _hoisted_6 = {
  key: 0,
  class: "fa fa-check text-success",
  "aria-hidden": "true"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_vc_customer_view_email_content = _resolveComponent("vc-customer-view-email-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_table, {
        data: _ctx.lists,
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.emails, (email, i) => {
            return (_openBlock(), _createBlock(_component_el_table_column, {
              key: i,
              width: email.width
            }, {
              header: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass(email.cn)
                }, [
                  _createElementVNode("span", {
                    class: "d-block fs-6 fw-bolder",
                    innerHTML: email.label
                  }, null, 8, _hoisted_2)
                ], 2)
              ]),
              default: _withCtx((scope) => [
                (email.value === 'index')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(scope.$index + 1), 1))
                  : (email.value === 'email_body')
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        class: _normalizeClass(email.cn)
                      }, [
                        _createElementVNode("i", {
                          class: "cursor-pointer fas fa-eye",
                          onClick: ($event: any) => (_ctx.viewDetails(scope.row[email.value]))
                        }, null, 8, _hoisted_4)
                      ], 2))
                    : (email.isClick)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                          (scope.row[email.value])
                            ? (_openBlock(), _createElementBlock("i", _hoisted_6))
                            : (_openBlock(), _createElementBlock("span", _hoisted_7, " - "))
                        ]))
                      : (_openBlock(), _createElementBlock("span", {
                          key: 3,
                          innerHTML: scope.row[email.value]
                        }, null, 8, _hoisted_8))
              ]),
              _: 2
            }, 1032, ["width"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["data"])
    ]),
    _createVNode(_component_vc_customer_view_email_content, { ref: "email_content" }, null, 512)
  ], 64))
}