
    import Documents from '@/core/services/Callable/Documents'
    import { store } from '@/store';
    import { defineComponent, computed, ref } from 'vue'
    import { notification, constant } from "@/store/stateless"
import router from '@/router/clean';
import Invoice from '@/core/services/Callable/Invoice';
  
    export default defineComponent({
      props: {
        policy: {
          type: Object,
          required: false,
        },
      isView: {
          type: Boolean,
          required: false,
          default: false
        }
      },
      setup(_) {
  
        console.log(_.isView)
        const permissions = computed(() => {
          return store.getters.getUserPermissions
        })
  
        const transferSale = computed(() => {
          return store.state.policy.transfer_sale;
        })
  
        const changeUnderwriter = computed(() => {
          return store.state.policy.transfer_underwriter;
        })
  
        const policy_documents = computed(() => {
              return store.state.policy.policy_documents
          })
  
        const fetchDocument = async () => {
          await Documents.getDocuments({
            is_loaded: true,
            categories: ['standard'],
            lead_id: _?.policy?.lead_id
          })
  
          policy_documents.value.open = true;
        }
  
        const transferSaleAgent = () => {
          transferSale.value.open = true;
          transferSale.value.policy_id = _?.policy?.id;
          transferSale.value.agent = _?.policy?.policy_sales_agent?.id;
          transferSale.value.is_group = true;
        }
  
        const transferUnderwriter = () => {
          changeUnderwriter.value.open = true;
          changeUnderwriter.value.policy_id = _?.policy?.id;
          changeUnderwriter.value.underwriter = _?.policy?.policy_underwriter?.id;
          changeUnderwriter.value.is_group = true;
        }

        const redirect = (policy_id) => {
          const toRoute = router.resolve({
            name: 'groupEditPolicy',
            params: {
              policy_id
            }
          })

          window.location.href = toRoute.href

          setTimeout(() => {
            window.location.reload()
          }, 200)
        }
  
        const viewPolicyDocument = (policy) => {
          window.open(policy.file_path, '_blank');
        }

        const viewDocument = (documnet) => {
          window.open(documnet, '_blank');
        }

        const invoiceLogModal = async () => {
          await Invoice.getGroupInvoiceLog({
            invoice_id: _.policy?.main_invoice?.id,
            lead_id: _.policy?.lead_id
          });
      }
  
        return {
          invoiceLogModal,
          viewDocument,
          redirect,
          fetchDocument,
          transferSaleAgent,
          transferUnderwriter,
          store,
          constant,
          permissions,
          viewPolicyDocument
        }
      },
    })
  