
import { defineComponent, computed, getCurrentInstance } from 'vue'
import { store } from "@/store";
import { common, rule } from "@/store/stateless";
import { ElMessage, ElMessageBox } from 'element-plus'
import { notification } from '@/store/stateless';

export default defineComponent({
    props: {
        
        isDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    setup() {

        const health = computed(() => {
            return store.state.customer.health;
        })

        const deleteMember = (e: number, lead_id: number) => {
            // health.value?.members?.splice(e, 1);
            ElMessageBox.confirm(
                '<div class="p-5 fs-4">Do you want delete the member. Continue?</div>',
                'Warning',
                {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    // type: 'warning',
                    buttonSize:'large',
                    customClass:'delete-member-popup',
                    // center:true,
                    dangerouslyUseHTMLString: true,
                }
            )
                .then(() => {
                    console.log(health.value)
                    health.value.deleted_members.push(health.value.members[e])
                    health.value?.members?.splice(e, 1);
                    if (lead_id > 0) {
                        // Customers.deleteLead({ lead_id })
                    }
                    notification.success(
                            'Member',
                            'Delete Completed'
                    );
                })
                .catch(() => {
                    // notification.info(
                    //         'Member',
                    //         'Delete Canceled'
                    // );
                })

        }

        const disabledDate = (time: any) => {
            return time.getTime() > new Date()
        }

        const UpdateIsMarried = (member) => {
            if (member.gender === 'MALE')
                member.is_married = false
        }

        const inst = getCurrentInstance()

        const focus = () => {
            const toFocus: any = inst?.refs[rule.customerDetails]
            if (toFocus !== undefined) {
            console.log('in focus')
                toFocus.focus()
                // toFocus.scrollIntoView({ behavior: "smooth" });
                window.scrollTo(0, common.getY('familyRef'))
            }
        }
        return {
            common,
            health,
            disabledDate,
            deleteMember,
            UpdateIsMarried,
            focus
        }
    },
})
