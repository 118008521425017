class Validations {

    public static email_taken = false;
    public static phone_taken = false;
    public static password_required = true;
    public static decimalPlaces = /^[0-9]*(\.[0-9]{0,2})?$/;
    public static is_doc_limit = false;
    public static is_group = false;
    public static is_reference_no_exist = false

    public static email(rule: any, value: any, callback: any) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!value) {
            callback(new Error('Please enter email'));
        } else if (!re.test(String(value).toLowerCase())) {
            callback(new Error('Email address must be valid'));
        } else if (Validations.email_taken) {
            callback(new Error('Email already taken'))
        } else {
            callback();
        }
    }

    public static username(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Username is required'));
        } else {
            callback();
        }
    }

    public static password(rule: any, value: any, callback: any) {

        if (!value && Validations.password_required) {
            callback(new Error('Password is required'));
        } else if (value.length < 8 && Validations.password_required) {
            callback(new Error('Password minimum of 8 characters'));
        } else {
            callback();
        }
    }

    public static names(rule: any, value: any, callback: any) {
        let isvalidtext = /^[a-zA-Zء-ي\s'-]+$/;
        let singleCount = value ? value.match(/[']/g) : false;
        let minusCount = value ? value.match(/[-]/g) : false;

        let singleCountChecker = true;
        let minusCountChecker = true;

        if (singleCount) {
            singleCountChecker = singleCount.length > 1 ? false : true
        }
        if (minusCount) {
            minusCountChecker = minusCount.length > 1 ? false : true
        }

        if (!value) {
            callback(new Error('Please enter full name'));
        } else if (!isvalidtext.test(String(value).toLowerCase()) || !singleCountChecker || !minusCountChecker) {
            callback(new Error(`Only character a-z, ء-ي, - and ' allowed`))
        } else {
            callback();
        }
    }



    public static phone_number(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter phone number'));
        } else if (value.length < 8) {
            callback(new Error('Phone number must not be less than 8 digits'));
        } else if (Validations.phone_taken) {
            callback(new Error('Phone number already taken'))
        } else {
            callback();
        }
    }

    public static lead_source(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Lead source is required'));
        } else {
            callback();
        }
    }

    public static dob(rule: any, value: any, callback: any) {

        if (!value) {
            callback(new Error('Please enter date of birth'));
        } else {
            callback();
        }
    }

    public static gender(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please select the gender'));
        } else {
            callback();
        }
    }

    public static nationality(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please select the nationality'));
        } else {
            callback();
        }
    }

    public static insurance_provider(rule: any, value: any, callback: any) {
        // console.log(value);
        if (!value) {
            callback(new Error('Please select the insurance policy'));
        } else {
            callback();
        }
    }

    public static age_group(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please select age group'));
        } else {
            callback();
        }
    }

    public static insured_city(rule: any, value: any, callback: any) {
        if (value == null) {
            callback(new Error('Please select the visa issued emirate'));
        } else {
            callback();
        }
    }



    public static insured_type_id(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please select insurance For'));
        } else {
            callback();
        }
    }

    public static insurance_type(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please select insurance type'));
        } else {
            callback();
        }
    }

    public static company_name(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter company name'));
        } else {
            callback();
        }
    }

    public static agent(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please select sales agent'));
        } else {
            callback();
        }
    }

    public static notes(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter notes'));
        } else {
            callback();
        }
    }

    public static expected_insurance_start_date(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter expected insurance start date'));
        } else {
            callback();
        }
    }

    public static is_salary(rule: any, value: any, callback: any) {
        if (value === null) {
            callback(new Error('Please select about your salary'));
        } else {
            callback();
        }
    }

    public static is_self(rule: any, value: any, callback: any) {
        if (value === null) {
            callback(new Error('Please select about your insurance'));
        } else {
            callback();
        }
    }

    public static response(rule: any, value: any, callback: any) {
        if (value === 0) {
            callback(new Error('Please select response from customer'));
        }
        else {
            callback();
        }
    }

    public static reason(rule: any, value: any, callback: any) {
        if (value === 0) {
            callback(new Error('Please select lost lead reason'));
        }
        else {
            callback();
        }
    }

    public static follow_up(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter follow up due date & time'));
        }
        else {
            callback();
        }
    }

    public static task_notes(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter task notes'));
        }
        else {
            callback();
        }
    }

    public static role_id(rule: any, value: any, callback: any) {
        if (value === 0) {
            callback(new Error('Please select role'))
        } else {
            callback()
        }
    }

    public static policy_agent_id(rule: any, value: any, callback: any) {
        if (value === 0) {
            callback(new Error('Please select underwriter'))
        } else {
            callback()
        }
    }

    public static team_leader(rule: any, value: any, callback: any) {
        if (value === 0) {
            callback(new Error('Please select team leader'))
        } else {
            callback()
        }
    }

    public static is_round_robin(rule: any, value: any, callback: any) {
        if ([undefined, null, ''].includes(value)) {
            callback(new Error('Please select in round robin'))
        } else {
            callback()
        }
    }

    public static skip_round(rule: any, value: any, callback: any) {
        if ([undefined, null, ''].includes(value)) {
            callback(new Error('Please enter skip round'))
        } else {
            callback()
        }
    }

    public static agent_type(rule: any, value: any, callback: any) {
        if (value === 0) {
            callback(new Error('Please select agent type'))
        } else {
            callback()
        }
    }

    public static renewal_deal(rule: any, value: any, callback: any) {
        if (value === 0) {
            callback(new Error('Please select renewal deal'))
        } else {
            callback()
        }
    }

    public static assign(rule: any, value: any, callback: any) {
        if (value === 0) {
            callback(new Error('Please select assign leads of'))
        } else {
            callback()
        }
    }

    public static assign_lang(rule: any, value: any, callback: any) {

        if (value.length === 0) {
            callback(new Error('Please select language'))
        } else {
            callback()
        }
    }

    public static agents(rule: any, value: any, callback: any) {
        if (value.length === 0) {
            callback(new Error('Please select transfer agent/s'))
        } else {
            callback()
        }
    }

    public static from(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter from email address'))
        } else {
            callback()
        }
    }

    public static to(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter to email address'))
        } else {
            callback()
        }
    }

    public static subject(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter subject'))
        } else {
            callback()
        }
    }

    public static body(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter message'))
        } else {
            callback()
        }
    }

    public static merge_lead_id(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please select a lead'))
        } else {
            callback()
        }
    }

    public static insurance_plan(rule: any, value: any, callback: any) {
        // console.log('valid', value)
        if (!value) {
            callback(new Error('Please select a Insurance plan'))
        } else {
            callback()
        }
    }

    public static policy_price(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter policy price'))
        } else {
            // if (value > 0 && !Validations.decimalPlaces.test(String(value))) {
            //     callback(new Error('Please enter upto 2 decimal places only'))
            // }
            callback()
        }
    }

    public static sales_price(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter sales price'))
        } else {
            callback()
        }
    }

    public static invoice_amount(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter invoice amount'))
        } else {
            callback()
        }
    }


    public static discount_amount(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter discount amount'))
        } else {
            callback()
        }
    }



    public static file_receipt(rule: any, value: any, callback: any) {
        // console.log(value)
        var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;
        if (!value) {
            callback(new Error('Please select file receipt'))
        } else if(value && Validations.is_doc_limit) {
            callback(new Error('Document size exceed limit of 5mb'))
        } else if (!allowedExtensions.exec(value?.name)) {
            callback(new Error('Allowed file types are .png, .jpg, .jpeg, .pdf'))
        } else {
            callback()
        }
    }
    public static expiry_date_time(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter expiry date'))
        } else {
            callback()
        }
    }

    public static installments(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter installments'))
        } else {
            if (Number(value) > 6)
                callback(new Error('max allowed installments are 6 '))
            else
                callback()
        }
    }

    public static client_classification(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please select client classification'))
        } else {
            callback()
        }
    }

    public static payment_type(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please select payment type'))
        } else {
            callback()
        }
    }


    public static order_description(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter order description'))
        } else {
            callback()
        }
    }

    public static reference_no(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter reference number'))
        } else if(Validations.is_reference_no_exist) {
            callback(new Error('Reference no. already exist'))
        } else {
            callback()
        }
    }

    public static payment_date(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter payment date'))
        } else {
            callback()
        }
    }

    public static tax_invoice_date(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter tax invoice date'))
        } else {
            callback()
        }
    }

    public static endorsement_no(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter endorsement no'))
        } else {
            callback()
        }
    }

    public static emirates_id(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error( !Validations.is_group ? 'Please enter Emirates ID' : 'Please enter trade license no'))
        } else {
            callback()
        }
    }

    public static policy_number(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter policy number'))
        } else {
            callback()
        }
    }

    public static policy_start_date(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter policy start date'))
        } else {
            callback()
        }
    }

    public static policy_end_date(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please select policy end date'))
        } else {
            callback()
        }
    }

    public static insurance_payment(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please select insurance payment'))
        } else {
            callback()
        }
    }

    public static entity_type(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please select entity type'))
        } else {
            callback()
        }
    }

    public static passport(rule: any, value: any, callback: any, emirates_id) {
        if (emirates_id == '111-1111-1111111-1') {
            callback(new Error('Please enter passport'))
        } else {
            callback()
        }
    }

    public static trade_license(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please upload trade license'))
        } else {
            callback()
        }
    }

    public static trade_license_issue_date(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter trade license issue date'))
        } else {
            callback()
        }
    }

    public static trade_license_expiry_date(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter trade license expiry date'))
        } else {
            callback()
        }
    }

    public static credit_amount(rule: any, value: any, callback: any) {
        if (value <= 0) {
            callback(new Error('Please enter credit note amount'))
        } else {
            if (value > 0 && !Validations.decimalPlaces.test(String(value))) {
                callback(new Error('Please enter upto 2 decimal places only'))
            }
            else
                callback()
        }
    }

    public static credit_notes_no(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter credit notes no.'))
        } else {
            callback()
        }
    }

    public static debit_amount(rule: any, value: any, callback: any , min , max , amend_type) {
        if (value <= 0) {
            callback(new Error('Please enter debit note amount'))
        } else {
            if (value > 0 && !Validations.decimalPlaces.test(String(value))) {
                callback(new Error('Please enter upto 2 decimal places only'))
            }
            else if(amend_type != 2 && value < min)
            {
                callback(new Error('Incorrect debit amount entered'))
            }
            else if(amend_type != 2 && value > max)
            {
                callback(new Error('Incorrect debit amount entered'))
            }
            else
                callback()
        }
    }

    public static sub_group_count(rule: any, value: any, callback: any , max, amount) {
            if(value > max)
            {
                callback(new Error(`Member exceed (${max})`))
            } else if(Number(amount) > 0 && value == 0) {
                callback(new Error('Enter member count'))
            }
            else
                callback()
    }

    public static group_count(rule: any, value: any, callback: any , amount) {
        // console.log({
        //     amount,value
        // })
            if(Number(amount) > 0 && value == 0) {
                callback(new Error('Enter member count'))
            }
            else
                callback()
    }

    public static sub_group_refund_amount(rule: any, value: any, callback: any , max) {
            if(Number(value) == 0 && max > 0) {
                callback(new Error('Enter refund amount'))
            }
            else
                callback()
    }

    public static sub_members_greater_60(rule: any, value: any, callback: any , max) {
            if(value > max)
            {
                callback(new Error(`Member exceed (${max})`))
            }
            else
                callback()
    }

    public static amendAmount(rule: any, value: any, callback: any, count: number) {
        if(value == undefined) {
            callback(new Error('amount')) 
        }
        else if(count > 0 && value == 0) {
            callback(new Error('amount'))
        } else {
            callback()
        }
    }

    public static amendGroupCount(rule: any, value: any, callback: any, amount: number) {
        if(!value) {
            callback(new Error('member')) 
        } else if (Number(amount) > 0 && value == 0) {
            callback(new Error('member'))
        } else if(Number(value) > 999999) {
            callback(new Error('member'))
        } else {
            callback()
        }
    }

    public static amendNonTaxable(rule: any, value: any, callback: any, count: number) {
        if(count > 0) {
            callback(new Error('Please enter non taxable fee'))
        } else {
            callback()
        }
    }

    public static debit_notes_no(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter debit notes no.'))
        } else {
            callback()
        }
    }


    public static amendment_start_date(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter start date'))
        } else {
            callback()
        }
    }

    public static edit_policy_start_date(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter policy start date'))
        } else {
            callback()
        }
    }

    public static amendment_end_date(rule: any, value: any, callback: any, start_date, is_completed = false) {
        // && !is_completed
        if (!value) {
            callback(new Error('Please enter end date'))
        } else if(Date.parse(value) < Date.parse(start_date)) {
            callback(new Error('End date must not less than start date'))
        } else {
            callback()
        }
    }

    public static refund_amount(rule: any, value: any, callback: any,min) {
        if (!value) {
            callback(new Error('Please enter refund amount'))
        } else {
            // if (value > 0)
                // callback(new Error('refund amount must be in negative'))
            if (value > 0 && !Validations.decimalPlaces.test(String(value))) {
                callback(new Error('Please enter upto 2 decimal places only'))
            }
            if (value > min)
                callback(new Error('refund amount must be smaller than policy price'))
            else
                callback()
        }
    }

    public static custom_order_description(rule: any, value: any, callback: any , payment_type) {
        if (payment_type != 3) {
            if (!value) {
                callback(new Error('Please enter order description'))
            } else {
                callback()
            }
        } else {
            
                callback()
        }
    }

    public static group_size(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please select group size'));
        } else {
            callback();
        }
    }

    public static category_name(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please select category'));
        } else {
            callback();
        }
    }

    public static category_visa_issued(rule: any, value: any, callback: any) {
        if (value == null) {
            callback(new Error('Please select  visa issued '));
        } else {
            callback();
        }
    }

    public static category_insurance_plan(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please select insurance plan'));
        } else {
            callback();
        }
    }

    public static amount(rule: any, value: any, callback: any) {
        if (!value) {
            callback(new Error('Please enter amount'))
        } else {
            if (value > 0 && !Validations.decimalPlaces.test(String(value))) {
                callback(new Error('Please enter upto 2 decimal places only'))
            }
            if(value == 0){
                callback(new Error('Please enter amount'))
            }
            callback()
        }
    }

    public static members_above_60(rule: any, value: any, callback: any , group_count, message = 'Members > 60 must be less than members count') {
        if (group_count < value) {
                callback(new Error(message))
            
        } else {
            
                callback()
        }
    }

    public static members_count(rule: any, value: any, callback: any) {

        if(!value) {
            callback(new Error('Enter members count'))
        }
        else if (Number(value) > 999999) {
                callback(new Error('Enter members count'))
        } else {
            
                callback()
        }
    }

    public static converted_leads(rule: any, value: any, callback: any) {

        if(!value) {
            callback(new Error('Please select lead'))
        }
        else if (value.length == 0) {
            callback(new Error('Please select lead'))
        } else {
            
            callback()
        }
    }
    
    
    public static basmah(rule: any, value: any, callback: any) {

        if(value = "") {
            callback(new Error('Enter Basmah'))
        }else {
            
                callback()
        }
    }


}
/**
 * create custom validations
 * @created vincent carabbacan
 */
export default Validations;