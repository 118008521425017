
import { defineComponent, computed } from "vue";
import { Search } from "@element-plus/icons-vue";
import Inquiry from "@/core/services/Callable/Inquiry";
import { store } from "@/store";
import moment from "moment";
import router from "@/router/clean";
import { filter as filters, common } from "@/store/stateless";

export default defineComponent({
  setup() {
    const filter = computed(() => {
      return store.state.user.user_list_payload;
    });

    const getLists = async (e: any) => {
      const page = e;
      router.replace({ query: { page } }).catch((error) => {
        if (
          error.name !== "NavigationDuplicated" &&
          !error.message.includes(
            "Avoided redundant navigation to current location"
          )
        ) {
          console.log(error);
        }
      });
      filter.value.index = page;

      await Inquiry.getInquiry(filter.value);
    };

    const shortcuts = [
      {
        text: "Last week",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          return [start, end];
        },
      },
      {
        text: "Last month",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          return [start, end];
        },
      },
      {
        text: "Last 3 months",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
          return [start, end];
        },
      },
    ];

    const getDates = async (e) => {
      var start = moment(e[0]).format("YYYY-MM-DD");
      var end = moment(e[1]).format("YYYY-MM-DD");

      // filter.value.task_due_dates = -1
      filter.value.start_date = start;
      filter.value.end_date = end;
      getLists(1);
    };

    const reset = async () => {
      filter.value.keyword = "";
      filter.value.per_page = 50;
      filter.value.start_date = "";
      filter.value.end_date = "";
      await getLists(1);
    };

    return {
      Search,
      filter,
      filters,
      getLists,
      getDates,
      shortcuts,
      common,
      reset,
    };
  },
});
