import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import {store} from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
import Auths from '@/core/services/Callable/Auths';
import Etcs from '@/core/services/Callable/Etcs';

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "test",
        name: "test",
        component: () => import("@/views/test.vue"),
      },
    ],
  },
  {
    path: "/customers",
    redirect: "/customers/list",
    component: () => import("@/layout/Layout.vue"),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'list',
        name: 'CustomerLists',
        component: () => import("@/views/Customers/index.vue")
      },
      {
        path: 'add-detail',
        name: 'AddDetail',
        component: () => import("@/views/Customers/Store.vue")
      },
      {
        path: 'details/:customer_id',
        name: 'Details',
        component: () => import("@/views/Customers/Detailed.vue")
      },
      {
        path: 'edit-detail/:id',
        name: 'EditDetail',
        component: () => import("@/views/Customers/Edit.vue")
      },
      {
        path: 'generate-quote/:id',
        name: 'GenerateQuote',
        component: () => import("@/views/Customers/GenerateQuote.vue")
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    meta: {
      requiresVisitor: true
    },
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
      {
        path: "/auth/token/:token",
        name: "fetchToken",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/Authentication.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.auth.token) {
        next({
          path: 'sign-in'
        });
    } else {
        next();
    }
} else if(to.matched.some(record => record.meta.requiresVisitor)) {
  if(store.state.auth.token) {
    next({
      path: '/dashboard'
    })
  } else {
    next();
  }
} else {
  next();
}
store.commit(Mutations.RESET_LAYOUT_CONFIG);

if(store.state.auth.token) {
  // Auths.VerifyToken();
  Etcs.getRoutes();
}



});

export default router;
