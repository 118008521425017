
import { defineComponent, computed, h, onMounted, toRef, ref } from "vue";
import { ElDivider } from "element-plus";
import { store } from "@/store";
import Documents from "@/core/services/Callable/Documents";
import { amend_doc_columns, amend_doc_actions } from "@/core/utils/policy.js";
import { leadEmployeeCategoryId } from '@/store/stateless';

interface dataValue {
  policy_id: number
  lead_employee_category_id?: number

}
export default defineComponent({
  // name: 'policy-edit',
  props:{
    document:{
        type: Object,
        required: true
    }
  },
  setup(_) {
    const opt = toRef(_, 'document');

    const fileUpload = ref()
    const openFile = () => {
        fileUpload.value.click()
    }

    const policy = computed(() => {
      return store.state.policy.policy;
    });

    const fetchAmendDocument = async () => {
    //   console.log('amends')
    const condition = <dataValue> {
        policy_id: policy.value.policy_purchase_id,

      }
      if(leadEmployeeCategoryId.value > 0) condition.lead_employee_category_id = leadEmployeeCategoryId.value
      
      await Documents.getAmendDocuments(condition);
    };

    const uploadImage = async(e) => {
        const payload = new FormData()
        payload.append("id", opt.value.id)
        payload.append("file", e.target.files[0])

        await Documents.updateAmendDocument(payload)
        await fetchAmendDocument()
    }
    
    return {
      leadEmployeeCategoryId,
        fileUpload,
        openFile,
        uploadImage
    };
  },
});
