
import { defineComponent, onMounted, computed, ref } from 'vue'
import { store } from "@/store";
import Etcs from '@/core/services/Callable/Etcs';

interface IPayload {
    isDropdown: boolean;
}

export default defineComponent({
    props: {
        modelValue: {
            type: String,
            required: true,
            default: ''
        },
        label: {
            type: String,
            required: true,
            default: 'Select Emirates'
        }
    },

    setup(props, context) {
        
        let bodyParam = ref<IPayload>({
            isDropdown: true,
        });

        onMounted(() => {
            Etcs.getEmirates(bodyParam.value);
        })

        const emirates = computed(() => {
            return store.state.etc.emirates
        })

        const updateValue = (event) => {
            context.emit("update:modelValue", event)
            context.emit("emirates", event)
        }

        const inputRef = ref()
        const focus = () => {
        setTimeout(() => {
                inputRef.value.focus()
            }, 100);
        }

        return {
            focus,
            inputRef,
            emirates,
            updateValue
        }
    },
})
