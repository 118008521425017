// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum DashboardActionTypes {
  GET_DASHBOARD_OVERVIEW = "GET_DASHBOARD_OVERVIEW",
  GET_DASHBOARD_SALESPERSON_INFO = "GET_DASHBOARD_SALESPERSON_INFO",
  GET_SALES_DASHBOARD = "GET_SALES_DASHBOARD",
  GET_DASHBOARD_STATISTICS = "GET_DASHBOARD_STATISTICS",
  GET_DASHBOARD_OTHERS = "GET_DASHBOARD_OTHERS",
  GET_DASHBOARD_LEADS = "GET_DASHBOARD_LEADS",
  GET_DASHBOARD_TRANSACTIONS = "GET_DASHBOARD_TRANSACTIONS",
  GET_DASHBOARD_UNDERWRITER = "GET_DASHBOARD_UNDERWRITER",
  GET_DASHBOARD_ACCOUNTS = "GET_DASHBOARD_ACCOUNTS",
  GET_DASHBOARD_MARKETING = "GET_DASHBOARD_MARKETING"
}
  