import { reactive } from "vue";
import { ElNotification  } from 'element-plus';

const notification = reactive({
    success(title, message, duration = 4500) {
        ElNotification({
            title,
            message,
            type: 'success',
            dangerouslyUseHTMLString: true,
            duration: duration,
            customClass: "notification-success",
        });
    },

    warning(title, message, duration = 4500) {
        ElNotification({
            title,
            message,
            type: 'warning',
            dangerouslyUseHTMLString: true,
            duration: duration,
            customClass: "notification-warning",
        });
    },

    info(title, message, duration = 4500) {
        ElNotification({
            title,
            message,
            type: 'info',
            dangerouslyUseHTMLString: true,
            duration: duration,
            customClass: "notification-info",
        });
    },

    error(title, message, duration = 4500) {
        ElNotification({
            title,
            message,
            type: 'error',
            dangerouslyUseHTMLString: true,
            duration: duration,
            customClass: "notification-error overlay-center",
        });
    },

    none(title, message, duration = 4500) {
        ElNotification({
            title,
            message,
            dangerouslyUseHTMLString: true,
            duration: duration,
        });
    }
});

export {
    notification
};
