import { GetterTree } from 'vuex';
import router from "@/router/clean";

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';


import { State } from './state';

export type Getters = {
  getAuthUser(state: State): any | null;
  getAuthToken(state: State): string | null;
  getAuthError(state: State): string | null;
  getAuthLoading(state: State): Boolean | null;
}

const admin = 1;
const agent = 2;
const policyUser = 3;
const underwriter = 4;
const documentChaser = 5;
const accountant = 6;
const marketingAgent = 7;
const offlineSalesAgent = 8;
const salesManager = 9;
const onlineMarketing = 10;
const marketingAdmin = 11;
const salesAdmin = 12;
const teamLeader = 13;
const qca = 14;

export const getters: GetterTree<State, RootState> & Getters = {
  getAuthUser: (state) => state.user,
  getAuthToken: (state) => state.token,
  getAuthError: (state) => state.error,
  getAuthLoading: (state) => state.loading,

  isLoggedin: (state) => {
    return state.user.id ? true : false
  },

  // Toolbar item roles
  isCondition: (state) => (value: number) => {
    return roleConditions(value)?.includes(state.user.role_id)
  },

  // current route name roles
  isRoute: () => (value: number) => {
    return pageConditions(value)?.includes(currentRoute())
  },

  showByRole: (state) => (value: any) => {
    return value.includes(state.user.role_id)

    // let roles = state.user.roles
    // if(!roles) {
    //   let data:any = []
    //   data.push(state.user.role_id)
    //   roles = data
    // } 
    // return value.some(x => roles.includes(x))
  },

  myRole: (state) => {
    return state.user.role_id
  },

  roleCheck: (state) => (roles) => {
    return roles.includes(state.user.role_id)
  },
  myRoles: (state) => {
    // let roles = state.user.roles
    // if(!roles) {
    let data: any = []
    data.push(state.user.role_id)
    let roles = data
    // } 

    return roles
  },

  myId: (state) => {
    return state.user.id
  },

  myName: (state) => {
    return state?.user?.name ?? ''
  },

  myEmail: (state) => {
    return state.user.email
  },

  getUserPermissions: (state) => {
    const permission = {
      is_admin: false,
      is_sales_agent: false,
      is_team_leader: false,
      is_underwritter: false,
      is_marketing: false,
      is_accountant: false,
      is_manager: false,
      is_qca: false,
    }
    switch (state.user.role_id) {
      case 1:
        permission.is_admin = true;
        break;
      case 2:
        permission.is_sales_agent = true;
        break;
      case 13:
        permission.is_team_leader = true;
        break;
      case 4:
        permission.is_underwritter = true;
        break;
      case 7:
        permission.is_marketing = true;
        break;
      case 6:
        permission.is_accountant = true;
        break;
      case 9:
        permission.is_manager = true;
        break;
      case 11:
        permission.is_marketing = true;
        break;
      case 14:
        permission.is_qca = true;
        break;
    }
    return permission;
  }
};
const currentRoute = (): string => {
  if (router.currentRoute !== undefined) return router.currentRoute.value.name?.toString() || ''
  return 's'
}


const roleConditions = (val: number) => {
  switch (val) {
    case 1:
    case 2:
    case 3:
      return [admin, agent, salesManager, teamLeader]; break;
    case 4:return [admin,marketingAdmin]; break;
    
    default: [0]
  }
}

const pageConditions = (val: number) => {
  switch (val) {
    case 1: return ['dashboard', 'CustomerLists']
    default: ['']
  }
}
