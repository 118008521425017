const inquiries = [
    {
        label: 'Sr no.',
        value: 'index',
        width: '80'
    },
    {
        label: 'Company Email',
        value: 'company_email'
    },
    {
        label: 'Company Name',
        value: 'company_name'
    },
    {
        label: 'Contact Person',
        value: 'contact_person'
    },
    {
        label: 'Contact Number',
        value: 'contact_number'
    },
    {
        label: 'No of Members',
        value: 'no_of_members',
        width: '170'
    },
    {
        label: 'Created On <br /> Updated On',
        value: 'dates',
        width: '250'
    },
    // {
    //     label: 'Actions',
    //     value: 'actions',
    //     width: '100'
    // },
]

export {
    inquiries
}