// TODO: Remove no-shadow eslint disable after fixed: https://github.com/typescript-eslint/typescript-eslint/issues/2484
// eslint-disable-next-line no-shadow
export enum InvoiceMutationTypes {
    SET_INVOICE = "SET_INVOICE",
    SET_INVOICE_FILTER = "SET_INVOICE_FILTER",
    SET_INVOICE_LOADING = "SET_INVOICE_LOADING",
    SET_INVOICE_LISTS = "SET_INVOICE_LISTS",
    SET_INVOICE_LIST_COUNTS = "SET_INVOICE_LIST_COUNTS",
    SET_INVOICE_MODAL = 'SET_INVOICE_MODAL',
    SET_INVOICE_MODAL_VALUE = 'SET_INVOICE_MODAL_VALUE',
    SET_INTERESTED_POLICY_MODAL = 'SET_INTERESTED_POLICY_MODAL',
    SET_INTERESTED_POLICY_MODAL_VALUE = 'SET_INTERESTED_POLICY_MODAL_VALUE',
    SET_INOVICE_APPROVAL_ACTION_MODAL = 'SET_INOVICE_APPROVAL_ACTION_MODAL',
    SET_INVOICE_DETAILS_MODAL = 'SET_INVOICE_DETAILS_MODAL',
    SET_INVOICE_DETAILS = 'SET_INVOICE_DETAILS',
    SET_INVOICE_DATA = 'SET_INVOICE_DATA',
    SET_GROUP_INVOICE_LIST = "SET_GROUP_INVOICE_LIST",
    SET_GROUP_INVOICE_LIST_COUNT = "SET_GROUP_INVOICE_LIST_COUNT",
    SET_GROUP_INVOICE_DETAILS = 'SET_GROUP_INVOICE_DETAILS',
    SET_GROUP_INVOICE_LOG = "SET_GROUP_INVOICE_LOG"
}
  