
import { defineComponent, computed, ref, h } from "vue";
import { ElDivider } from "element-plus";
import { store } from "@/store";
import Policy from '@/core/services/Callable/Policy';
import router from '@/router/clean';
import { notification } from '@/store/stateless';
import { email_columns } from "@/core/utils/policy.js";

export default defineComponent({
  name: "policy-edit",
  setup() {
    const policy = computed(() => {
      return store.state.policy.policy;
    });
    
    const email_activity = computed(() => {
      return store.state.policy.email_activity;
    });
    

    return {
      policy,
      email_activity,
      email_columns
    };
  },
});
