
import { defineComponent, computed, onMounted, getCurrentInstance, watch  } from 'vue'
import { store } from "@/store";
import { rule, common, notification } from '@/store/stateless';
import { sub_amend_group_columns } from '@/core/utils/invoice'
import Validations from '@/core/services/etc/Validations';

export default defineComponent({
    props:{
        allDisabled:{
            default:false,
            required:true
        }
    },
    setup(_, {emit}) {

        const health = computed(() => {
            return store.state.customer.health;
        })
        
        const invoice = computed(() => {
            return store.state.invoice.groupInvoice;
        })

        const basmah = computed(() => {
            return store.state.etc.basmah;
        })

        // const categories = computed(() => {
        //     const invoice =  store.state.invoice.groupInvoice;
        //     if(invoice)
        //         return invoice.categories;
        //     return [];
        // })

        const invoiceDetails = computed(() => {
            return store.state.invoice.invoiceData
        })
    
        onMounted(() => {
            // invoice.value.categories = health.value.categories;
            if(invoice.value.categories.length == 0){
                // setting categories in make invoice
                invoice.value.categories = store.getters.getGroupInvoiceCategories
                invoice.value.categories = invoice.value.categories.map(element => {
                    element.group_count = 0;
                    element.members_above_60 = 0;                   
                    
                    return element;
                });
            }
            // updateBasmah()

            // console.log('catsdsdegories',invoice.value.categories)

        })

        const total_counts = (obj, target) => {
            return obj.reduce((item, data) => {
                        if(data.amend_type != 2) {
                            item += Number(data[target])
                        } else {
                            item -= Number(data[target])
                        }
                        return item
                    },0)
        }

        watch([() => invoice.value.categories, () => health.value], async ([newValue, newInvoiceDetails]) => {
            // console.log('inside cat watch')
            if(newValue) {
                newValue = newValue.map(element => {
                    const group_count = Number(element.group_count);
                    // const basmahVal = element.visa_emirates == 2 ? Number(basmah.value) : 0;
                    // element.basmah = group_count * basmahVal;
                    // if(group_count == 0)
                        // element.amount = 0;
                    // console.log(element.group_count)
                    // console.log(element.basmah)
                    return element;

                });

                updateBasmah()
            }

            
                    if(newInvoiceDetails) {
                        newValue = newValue.map(element => {
                            const cate = newInvoiceDetails.categories.find(x => x.id == element.id)
                            if(cate) {
                                // element.all_group_counts = total_counts(cate.invoice_based_categories, 'group_count')
                                // element.all_members_above_60s = total_counts(cate.invoice_based_categories, 'members_above_60')
                                element.all_group_counts = cate.group_count
                                element.all_members_above_60s = cate.members_above_60
                            }                  
                            return element;
                        });
                        
                    }
        }, { deep: true })

        const updateBasmah = () => {
            // invoice.value.orginal_policy_price
            const basmah = invoice.value.categories.reduce((sum, obj) => {
                const basmah = Number(obj['basmah']);
                if(isNaN(basmah))
                    return sum + 0
                else
                    return sum + basmah
            }, 0);

            invoice.value.basmah = common.roundUp(basmah)

        }

        const updateIcp = () => {
            // invoice.value.orginal_policy_price
            const icp = invoice.value.categories.reduce((sum, obj) => {
                const icp = Number(obj['icp']);
                if(isNaN(icp))
                    return sum + 0
                else
                    return sum + icp
            }, 0);

            invoice.value.icp = common.roundUp(icp)

        }
        
        const updatePolicyPrice = () => {
            // invoice.value.orginal_policy_price
            const amount = invoice.value.categories.reduce((sum, obj) => {
                const amount = Number(obj['amount']);
                if(isNaN(amount))
                    return sum + 0
                else
                    return sum + amount
            }, 0);

            invoice.value.policy_price = common.roundUp(amount)

        }


        const inst = getCurrentInstance()
        const focus = () => {
            const toFocus:any = inst?.refs[rule.customerDetails]
            console.log('toFocus',toFocus)
            if(toFocus !== undefined) {
                toFocus.focus()
                // window.scrollTo(0, common.getY('top'))
            }
        }

        
        function updateValue(val, obj, index = 0) {
            if(val[obj] == '' || val[obj] == null) {
                val[obj] = 0
                if(obj == 'group_count') {
                    val.amount = 0
                    val.members_above_60 = 0
                    emit('resetValidation', `categories.${index}.amount`)
                }
            } else {
                val[obj] = parseInt(val[obj])
            }
        }

        return {
            invoiceDetails,
            updateValue,
            invoice,
            focus,
            health,
            sub_amend_group_columns,
            Validations,
            updatePolicyPrice,
            common,
            updateIcp
        }
    },
})
