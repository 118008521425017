import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_socket = _resolveComponent("vc-socket")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_BrowserModal = _resolveComponent("BrowserModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.user_id)
      ? (_openBlock(), _createBlock(_component_vc_socket, {
          key: 0,
          channel: `refresh-${_ctx.user_id}`,
          listen: "RefreshEvent",
          onAction: _ctx.updateToken
        }, null, 8, ["channel", "onAction"]))
      : _createCommentVNode("", true),
    (_ctx.user_id)
      ? (_openBlock(), _createBlock(_component_vc_socket, {
          key: 1,
          channel: `change-role-${_ctx.user_id}`,
          listen: "ChangeRoleEvent",
          onAction: _ctx.changeRole
        }, null, 8, ["channel", "onAction"]))
      : _createCommentVNode("", true),
    (_ctx.user_id)
      ? (_openBlock(), _createBlock(_component_vc_socket, {
          key: 2,
          channel: `switchapp-${_ctx.user_id}`,
          listen: "SwitchApp.SwitchAppEvent",
          onAction: _ctx.switchAppReloadWS
        }, null, 8, ["channel", "onAction"]))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view),
    _createVNode(_component_BrowserModal)
  ], 64))
}