import { App } from "vue";
import { store } from "@/store";

/**
 * Initialize roles ids
 * Role will get effected if you change role id in DB!
 * @param app vue instance
 * @created vincent carabbacan
 */
const admin = 1;
const agent = 2;
const policyUser = 3;
const underwriter = 4;
const documentChaser = 5;
const accountant = 6;
const marketingAgent = 7;
const offlineSalesAgent = 8;
const salesManager = 9;
const onlineMarketing = 10;
const marketingAdmin = 11;
const salesAdmin = 12;
const teamLeader = 13;

/**
 * Preparing functions for the roles.
 * How to use? check README.md
 * @param app 
 */
export const roles = (app: App<Element>) => {
  const auth = app.config.globalProperties.auth;

  app.config.globalProperties.roles = {
    // Default Roles
    isAdmin() {
      return [admin].includes(auth.user().role_id);
    },
    isAgent() {
      return [agent].includes(auth.user().role_id);
    },
    isPolicyUser() {
      return [policyUser].includes(auth.user().role_id);
    },
    isUnderwriter() {
      return [underwriter].includes(auth.user().role_id);
    },
    isDocumentChaser() {
      return [documentChaser].includes(auth.user().role_id);
    },
    isAccountant() {
      return [accountant].includes(auth.user().role_id);
    },
    isMarketingAgent() {
      return [marketingAgent].includes(auth.user().role_id);
    },
    isOfflineSalesAgent() {
      return [offlineSalesAgent].includes(auth.user().role_id);
    },
    isSalesManager() {
      return [salesManager].includes(auth.user().role_id);
    },
    isOnlineMarketing() {
      return [onlineMarketing].includes(auth.user().role_id);
    },
    isMarketingAdmin() {
      return [marketingAdmin].includes(auth.user().role_id);
    },
    isSalesAdmin() {
      return [salesAdmin].includes(auth.user().role_id);
    },
    isTeamLeader() {
      return [teamLeader].includes(auth.user().role_id);
    },

    // Toolbar item Roles
    isToolbarQuickLead() {
      return [admin, agent, salesManager, teamLeader].includes(auth.user().role_id);
    },
    isToolbarAddCustomer() {
      return [admin, agent, salesManager, teamLeader].includes(auth.user().role_id);
    },
    isToolbarImportCustomer() {
      return [admin, agent, salesManager, teamLeader].includes(auth.user().role_id);
    },

    // Current Route Name Roles 
    isDashboard() {
      return auth.currentRouteName() === 'dashboard' ? true : false;
    },
    isLeadTasks() {
      return auth.currentRouteName() === 'CustomerLists' ? true : false;
    },

  }
}
