
  import {
    defineComponent,
    ref,
    computed,
    getCurrentInstance,
    watchEffect,
  } from "vue";
  import { store } from "@/store";
  import { common, constant } from "@/store/stateless";
  import { Close } from "@element-plus/icons-vue";
  import Customers from "@/core/services/Callable/Customers";
  
  export default defineComponent({
    setup(_) {
      const { view_task } = constant;
  
      const tasks = computed(() => {
        return store.getters.getTaskDetails;
      });
  
      const leads = computed(() => {
        const data = store.getters.getLeads(true);
        if (view_task.lead_id > 0)
          return data.filter((x) => x.lead_id === view_task.lead_id);
  
        return data;
      });
  
      const activeTab = ref<Number>(0);
      const selectedlead = ref();
  
      const activeValue = ref(0);
  
      const changeValue = () => {
        fetchData(activeValue.value);
      };
      const fetchData = async (value: number) => {
        activeTab.value = value;
        lead_id.value = value;
        await Customers.getAllTaskByLeadAndCustomerId({
          customer_id: view_task.customer_id,
          lead_id: lead_id.value,
        });
      };
  
      const close = () => {
        view_task.open = false;
        view_task.lead_id = 0;
        view_task.lead_status_id = 0;
        view_task.customer_id = 0;
        view_task.agent = 0;
        view_task.is_fullscreen = false;
  
        activeTab.value = 0;
        Customers.clearTaskDetails();
      };
  
      const lead_id = ref(0);
  
      const inst = getCurrentInstance();
      watchEffect(() => {
        if (view_task.open) {
          let c_lead_id = leads.value.find((x) => !x.tab_disabled).lead_id;
          if (view_task.lead_id > 0) c_lead_id = view_task.lead_id;
  
          if (activeTab.value > 0) c_lead_id = activeTab.value;
  
          activeValue.value = c_lead_id;
          changeValue();
  
          setTimeout(() => {
            const listRef: any = inst?.refs[`lead_${c_lead_id}`];
            if (activeTab.value === 0) {
              // listRef.click();
            }
          }, 500);
        }
      });
  
      return {
        tasks,
        Close,
        selectedlead,
        activeTab,
        view_task,
        common,
        leads,
        fetchData,
        close,
        activeValue,
        changeValue,
      };
    },
  });
  