
import { computed, defineComponent, getCurrentInstance, onMounted, ref } from "vue";
import { useStore } from "vuex";
import router from "@/router/clean";
import { onBeforeRouteLeave } from "vue-router";
import { notification } from '@/store/stateless';
import { ElNotification } from "element-plus";
import { InvoiceMutationTypes } from "@/store/enums/Invoice/Mutations";
import Invoice from "@/core/services/Callable/Invoice";

export default defineComponent({
  props: {
    isAmend: {
      type: Boolean,
      required: false,
      default: false
    },
    moduleType: {
      type: String,
      required: false,
      default: "invoice",
    },
    isGroup: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  setup(props) {
    const store = useStore();
    let loading = ref(false);

    const openModal = computed(() => {
      return store.state.invoice.openInvoiceModal;
    });

    const openModalValue = computed(() => {
      return store.state.invoice.openInvoiceModalValue;
    });


    const redirectToLeadDetails = async() => {
      store.commit(InvoiceMutationTypes.SET_INVOICE_MODAL, false);

      if (!props.isAmend) {

        await callEvent()

      }
      else{

        router.push({
          name: props.isGroup ? "groupInvoiceApproval":"invoiceApproval"
        });
      }
    };

    const callEvent = async() => {
      await Invoice.makeInvoiceEvent({
        driver_id: openModalValue.value.lead_id,
        type: 'health'
      })

      router.push({
          name: "CustomerDetails",
          params: { customer_id: openModalValue.value.customer_id },
        });
    }

    const redirectToWhatsapp = () => {
      let message =
        "This is the your payment url:  " + openModalValue.value.payment_link + " -BuyAnyInsurance";
      window.open(
        "https://wa.me/" +
        openModalValue.value.customer?.whatsapp_number +
        "?text=" +
        message,
        "_target"
      );

      redirectToLeadDetails();
    };

    const sendEmailItTeam = () => {
      loading.value = true;

      Invoice.sendInvoiceGenerationIssueEmail({
        customer_id: openModalValue.value.customer_id,
      })
        .then((data) => {
          loading.value = false;
          notification.success('success', 'Email has been sent successfully!');
          redirectToLeadDetails();

        })
        .catch(() => {
          loading.value = false;
          notification.warning('warning', 'Email has not been sent!');
          redirectToLeadDetails();
        });

    };

    const sendEmailPaymentCustomer = () => {
      loading.value = true;
      Invoice.sendPaymentLinkEmail({
        invoice_id: openModalValue.value.id,
      })
        .then((data) => {
          console.log(data);
          loading.value = false;
          notification.success('Payment link email', 'Email has been sent successfully!');
          redirectToLeadDetails();
        })
        .catch((err) => {
          console.log(err);

          loading.value = false;
          notification.warning('warning', 'Email has not been sent!');
          redirectToLeadDetails();
        });


    };

    onBeforeRouteLeave(() => {
      ElNotification.closeAll();
    })

    const inst = getCurrentInstance()
    const toFirst = () => {
      setTimeout(() => {
        const okRef: any = inst?.refs.okRef
        okRef.focus()
      }, 500);
    }

    return {
      toFirst,
      ElNotification,
      loading,
      sendEmailPaymentCustomer,
      sendEmailItTeam,
      redirectToWhatsapp,
      redirectToLeadDetails,
      callEvent,
      openModal,
      openModalValue,
    };
  },
});
