
import { computed, defineComponent, onMounted, ref } from 'vue'
import { store } from "@/store";
import Etcs from '@/core/services/Callable/Etcs';

export default defineComponent({
    props: {
        modelValue: {
            type: String,
            required: false,
            default: ''
        }
    },

    setup(props, context) {
        const plans = computed(() => {
            return store.state.etc.plans
        })
        
        onMounted(() => {
            Etcs.getPlans();
        })

        const updateValue = (event) => {
            context.emit("update:modelValue", event)
            context.emit("health", event)
        }

        return {
            plans,
            updateValue
        }
    },
})
