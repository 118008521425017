
import { defineComponent, computed } from 'vue';
import { store } from "@/store";

export default defineComponent({
    setup() {

        const leadInformation = computed(() => {
            return store.state.customer.leadInformation;
        })

        return {
            leadInformation
        }
    },
})
