
import { computed, defineComponent, getCurrentInstance, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
    props: {
        // isError: {
        //     type: Boolean,
        //     required: true,
        //     default: false,
        // }
    },

    setup(props) {
        const store = useStore();

        let loading = ref(false);

        const actionData = computed(() => {
            return store.state.invoice.invoiceApprovalActionModal;
        });


        return {
            loading,
            actionData,
        };
    },
});
