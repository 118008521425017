import { MutationTree } from 'vuex';

import { State } from './state';
import { PolicyMutationTypes } from '@/store/enums/Policy/Mutations';

export type Mutations<S = State> = {
}

export const mutations: MutationTree<State> & Mutations = {
    [PolicyMutationTypes.SET_POLICY_FILTER](state: State, payload: any) {
        state.filter = payload;
    },

    [PolicyMutationTypes.SET_POLICY_LOADING](state: State, payload: any) {
        state.loading = payload;
    },

    [PolicyMutationTypes.SET_POLICY_LISTS](state: State, payload: any) {
        state.policyLists = payload;
    },

    [PolicyMutationTypes.SET_POLICY_LIST_COUNTS](state: State, payload: any) {
        state.policyListCounts = payload;
    },

    [PolicyMutationTypes.SET_POLICY](state: State, payload: any) {
        state.policy = payload;
    },

    [PolicyMutationTypes.SET_POLICY_START_DATE_DAYS_RESTRICT](state: State, payload: any) {
        state.previous_start_days = payload;
    },

    [PolicyMutationTypes.SET_POLICY_START_DATE_DAYS_RESTRICT_UPCOMING](state: State, payload: any) {
        state.upcoming_start_days = payload;
    },

    [PolicyMutationTypes.SET_POLICY_ACCEPTED_DEBIT_AMOUNT_PER](state: State, payload: any) {
        state.accepted_debit_amount_per = payload;
    },

    [PolicyMutationTypes.SET_PURCHASED_POLICIES](state: State, payload: any) {
        state.purchased_policies = payload;
    },

    [PolicyMutationTypes.SET_POLICY_LOG](state: State, payload: any) {
        state.policy_logs = payload;
    },

    [PolicyMutationTypes.SET_POLICY_EMAIL_ACTIVITY](state: State, payload: any) {
        state.email_activity = payload;
    },

    
    [PolicyMutationTypes.SET_GROUP_POLICY_LISTS](state: State, payload: any) {
        state.groupPolicyLists = payload;
    },

    [PolicyMutationTypes.SET_GROUP_POLICY_LIST_COUNTS](state: State, payload: any) {
        state.groupPolicyListCounts = payload;
    },

    [PolicyMutationTypes.SET_GROUP_LEAD_POLICY_DETAILS](state: State, payload: any) {
        state.groupLeadPolicies = payload;
    },


};
