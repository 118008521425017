
import { defineComponent, computed, onMounted, getCurrentInstance } from "vue";
import { store } from "@/store";
import { rule, common, notification } from "@/store/stateless";
import { group_invoice_log } from "@/core/utils/invoice";
import Validations from "@/core/services/etc/Validations";
import Invoice from '@/core/services/Callable/Invoice'

export default defineComponent({
  props: {
    allDisabled: {
      default: false,
      required: true,
    },
  },
  setup() {
    
    const groupInvoiceLog = computed(() => {
            return store.state.invoice.groupInvoiceLog;
    });

    onMounted(() => {
     
    });

    const log = computed(() => {
        const log  = store.state.invoice.groupInvoiceLog.log;
        console.log(log)

        if(log && log.length > 0){

            const summary = {
                merchant_reference:'Total Count',
                group_count:0,
                members_above_60:0,
                basmah: 0,
                icp: 0,
                amount: 0,
                amount_vat: 0,
            }
            log.forEach(x => {
                if(x.invoice){
                if(x.invoice.amend_type != 2){
                    summary.group_count += Number(x.group_count)
                    summary.members_above_60 += Number(x.members_above_60)
                    summary.basmah += Number(x.basmah)
                    summary.icp += Number(x.icp)
                    summary.amount += Number(x.amount)
                    summary.amount_vat += Number(x.amount_vat)
                }
                else {
                    summary.group_count -= Number(x.group_count)
                    summary.members_above_60 -= Number(x.members_above_60)
                    summary.basmah -= Number(x.basmah)
                    summary.icp -= Number(x.icp)
                    summary.amount -= Number(x.amount)
                    summary.amount_vat -= Number(x.amount_vat)
                }
            }
            });
            log.push({
              ...summary,
              basmah: common.formatCurrency(summary.basmah),
              icp: common.formatCurrency(summary.icp),
              amount: common.formatCurrency(summary.amount),
              amount_vat: common.formatCurrency(summary.amount_vat)
            })
            console.log(log)
            return log;
        }
        return [];
    });


    const closeModal = () => {
            const data = {
                show: false,
                log: null
            }
            Invoice.setGroupInvoiceLogModal(data);
    }

    const counts = computed(() => {

      const logs = log.value.filter((obj, index) => {
          return index === log.value.findIndex(o => obj.group_invoice_id === o.group_invoice_id);
      }).filter(x => x.merchant_reference !== 'Total Count')

      if(logs && logs.length > 0) {

        return [
          {
            label: 'AA',
            value: logs.filter(x => x.invoice.amend_type === 1).length
          },
          {
            label: 'AD',
            value: logs.filter(x => x.invoice.amend_type === 2).length
          },
          {
            label: 'AC',
            value: logs.filter(x => x.invoice.amend_type === 3).length
          },
        ]
      }

      return [
          {
            label: 'AA',
            value: 0
          },
          {
            label: 'AD',
            value: 0
          },
          {
            label: 'AC',
            value: 0
          },
        ]
    })

    return {
      counts,
      groupInvoiceLog,
      group_invoice_log,
      common,
      closeModal,
      log
    };
  },
});
