
import { defineComponent } from 'vue'
import { accordions } from '@/core/utils'
import { common } from '@/store/stateless'

export default defineComponent({
    props: {
        data: {
            type: Object,
            required: true
        }
    },

    setup(_) {
        // console.log(_.data);
        return { accordions, common }
    }
})
