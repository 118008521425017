
import { defineComponent, computed, ref, h } from "vue";
import { ElDivider } from "element-plus";
import { store } from "@/store";
import Policy from '@/core/services/Callable/Policy';
import router from '@/router/clean';
import { notification } from '@/store/stateless';

export default defineComponent({
  name: "policy-edit",
  setup() {
    const policy = computed(() => {
      return store.state.policy.policy;
    });

    const sendEmailTo = ref({
      email_customer: false,
      email_broker: false,
      policy_id: null,
    });

    const emailLoading = ref(false);

    const sendEmail = async () => {
        emailLoading.value = true;
        let success = true;
        sendEmailTo.value.policy_id = policy.value.policy_purchase_id;
        if(sendEmailTo.value.email_customer){
            await Policy.sendEmailToCustomer(sendEmailTo.value).then((data) => {
                notification.success(
                    'Policy',
                    'Email sent to customer'
                );
                router.push({name:'groupCompletedPolicies'});

            }).catch((err) => {
                notification.warning(
                    'Policy',
                    'Email sent failed'
                );
                success = false;
            })
        }

        if(sendEmailTo.value.email_broker){
            await Policy.sendGroupEmailToBroker(sendEmailTo.value).then((data) => {
                notification.success(
                    'Policy',
                    'Email sent to broker'
                );

            }).catch((err) => {
                notification.warning(
                    'Policy',
                    'Email sent failed'
                );
                success = false;
            })
        }

        if(success)
            router.push({name:'groupCompletedPolicies'});

        emailLoading.value = false;

    };

    return {
      policy,
      sendEmailTo,
      emailLoading,
      sendEmail,
    };
  },
});
