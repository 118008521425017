
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        index: {
            type: Number,
            required: true
        },
        items: {
            type: Array,
            required: true
        }
    }
})
