
import { computed, defineComponent, onMounted, ref } from 'vue'
import { store } from "@/store";
import Etcs from '@/core/services/Callable/Etcs';

interface IPayload {
    isDropdown: boolean;
}

export default defineComponent({
    props: {
        modelValue: {
            type: String,
            required: false,
            default: ''
        },
        type: {
            type: String,
            required: false,
            default: ''
        },
        allowInsuredTypeUpdate:{
            type: Boolean,
            required: false,
            default: false
        },
        ql:{
            type: Boolean,
            required: false,
            default: false
        },
        hideEmployeeType: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    setup(props, context) {

        let bodyParam = ref<IPayload>({
            isDropdown: true,
        });

        const healths = computed(() => {
            const data = store.state.etc.insuredTypes
            let filtered = data;
            
            if(props.type !== 'employee')
                 filtered = data.filter(x => x.value !== 4)

            if(props.hideEmployeeType)
                filtered = data.filter(x => x.value !== 6)


            const exist = filtered.find(x => x.value === 0)
            if(!exist) filtered.unshift({ value: 0, label: 'Select Insurance For' })

            if(props.allowInsuredTypeUpdate && props.modelValue == '1' && !props.ql){
                filtered = filtered.filter(x => x.value == 1 || x.value == 3) // show only myself and family
            }

            return filtered
        })
        
        onMounted(() => {
            // console.log(props.modelValue)
            Etcs.getInsuredTypes(bodyParam.value);
        })

        const updateValue = (event) => {
            context.emit("update:modelValue", event)
            context.emit("health", event)
        }

        const inputRef = ref()
        const focus = () => {
        setTimeout(() => {
                inputRef.value.focus()
            }, 100);
        }

        return {
            healths,
            updateValue,
            inputRef,
            focus
        }
    },
})
