import { store } from "@/store";
import { App } from "vue";
import { DashboardActionTypes } from '@/store/enums/Dashboard/Actions';

class Dashboard {
    public static vueInstance: App;

    public static init(app: App<Element>) {
        Dashboard.vueInstance = app;
    }

    public static async getDashboardOverview(payload) {
        return await store.dispatch(DashboardActionTypes.GET_DASHBOARD_OVERVIEW, payload)
    }

    public static async getDashboardSalesPersonInfo(payload) {
        return await store.dispatch(DashboardActionTypes.GET_DASHBOARD_SALESPERSON_INFO, payload)
    }

    public static async getSalesDashBoard() {
        return await store.dispatch(DashboardActionTypes.GET_SALES_DASHBOARD, {})
    }

    public static async getDashboardStatistics(payload) {
        return await store.dispatch(DashboardActionTypes.GET_DASHBOARD_STATISTICS, payload)
    }

    public static async getDashboardOthers(payload) {
        return await store.dispatch(DashboardActionTypes.GET_DASHBOARD_OTHERS, payload)
    }

    public static async getDashboardLeads(payload) {
        return await store.dispatch(DashboardActionTypes.GET_DASHBOARD_LEADS, payload)
    }

    public static async getDashboardTransactions(payload) {
        return await store.dispatch(DashboardActionTypes.GET_DASHBOARD_TRANSACTIONS, payload)
    }

    public static async getUnderwriterDashboard(payload) {
        return await store.dispatch(DashboardActionTypes.GET_DASHBOARD_UNDERWRITER, payload)
    } 

    public static async getAccountsDashboard(payload) {
        return await store.dispatch(DashboardActionTypes.GET_DASHBOARD_ACCOUNTS, payload)
    } 

    public static async getMarketingDashboard(payload) {
        return await store.dispatch(DashboardActionTypes.GET_DASHBOARD_MARKETING, payload)
    } 




}


export default Dashboard