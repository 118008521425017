// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum EtcActionTypes {
    ROUTES = 'ROUTES',
    COUNTRY_CODE = 'COUNTRY_CODE',
    AGENT_LISTS = 'AGENT_LISTS',
    COUNTRIES = 'COUNTRIES',
    RELATIONSHIPS = 'RELATIONSHIPS',
    EMIRATES = 'EMIRATES',
    INSURED_TYPES = 'INSURED_TYPES',
    USER_TYPES = 'USER_TYPES',
    PLANS = 'PLANS',
    APPS = 'APPS',
    LEAD_SOURCES = 'LEAD_SOURCES',
    CUSTOMER_ALREADY_EXIST = 'CUSTOMER_ALREADY_EXIST',
    INSURANCE_PROVIDERS = 'INSURANCE_PROVIDERS',
    INSURANCE_PLANS = 'INSURANCE_PLANS',
    EMPLOYEE_CATEGORIES = 'EMPLOYEE_CATEGORIES',
    TASK_LISTS = 'TASK_LISTS',
    LEAD_REASONS = 'LEAD_REASONS',
    ROLES = 'ROLES',
    NOTIFICATIONS = 'NOTIFICATIONS',
    BASMAH = 'BASMAH',
    UNDERWRITERS_LISTS = "UNDERWRITERS_LISTS",
    GLOBAL_SEARCH = "GLOBAL_SEARCH",
    GET_RECENT_NOTIFICATIONS = "GET_RECENT_NOTIFICATIONS",
    UPDATE_NOTIFICATIONS_CLICK = 'UPDATE_NOTIFICATIONS_CLICK',
    READ_NOTIFICATION = 'READ_NOTIFICATION',
    GET_NOTIFICATION_COUNT = 'GET_NOTIFICATION_COUNT',
    GET_ALL_NOTIFICATIONS = 'GET_ALL_NOTIFICATIONS',
    GET_VAT = 'GET_VAT',
    GET_GROUP_SIZE = 'GET_GROUP_SIZE',
    GET_HEALTH_SETTING = "GET_HEALTH_SETTING"
    
  }
  
