
import { defineComponent, onMounted, computed, ref } from 'vue'
import { store } from "@/store";
import { common } from '@/store/stateless';

import Etcs from '@/core/services/Callable/Etcs';

export default defineComponent({
    props: {
        modelValue: {
            type: String,
            required: true,
            default: ''
        },
        label: {
            type: String,
            required: true,
            default: 'Select category'
        }
    },

    setup(props, context) {

        onMounted(() => {
            // Etcs.getEmployeeCategories();
        })

        const health = computed(() => {
            return store.state.customer.health;
        })

        const categories = computed(() => {
            return store.state.etc.employeeCategories
        })

        const updateValue = (event) => {
            context.emit("update:modelValue", event)
            context.emit("category", event)
        }

        const inputRef = ref()
        const focus = () => {
        setTimeout(() => {
                inputRef.value.focus()
            }, 100);
        }

       

        return {
            focus,
            inputRef,
            categories,
            updateValue,
            common
        }
    },
})
