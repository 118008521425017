
import { defineComponent } from 'vue'
import { CUSTOM_ACTION } from '@/core/types/Etc'

export default defineComponent({
    props: {
        items: {
            type: Array,
            required: true,
            default: [] as Array<CUSTOM_ACTION>
        }
    },
})
