
import { defineComponent, computed, ref, reactive } from 'vue'
import { FormInstance } from 'element-plus';
import { store } from "@/store";
import { rule, notification, common } from '@/store/stateless'
import Customers from '@/core/services/Callable/Customers';
import { healthDefault } from '@/core/utils'
import Validations from '@/core/services/etc/Validations';

export default defineComponent({
    setup(_, { emit }) {
        const ruleFormRef = ref<FormInstance>()
        const open = ref<Boolean>(false)
        const loading = ref<Boolean>(false)


        const cd = computed(() => {
            return store.getters.getCustomer
        })

        const contact_info_payload = ref({
                other_contact_info: null
            })

        const rules = reactive({
            other_contact_info: [{ required: true, message: 'Please enter contact information', trigger: ['blur', 'change'] }],
        })

        const submitCustomer = async (formEl: FormInstance | undefined) => {
            loading.value = true
            if (!formEl) return

            formEl.validate(async (valid, fields: any) => {
                console.log(fields)
                if (valid) {
                    const payload = {
                        id:cd.value.customer_id,
                        other_contact_info:contact_info_payload.value.other_contact_info
                    }
                    console.log(payload,cd.value)
                    const result = await Customers.updateCustomerOtherContactInfo(payload)

                    if (result.status === 200) {
                        close(formEl)
                        notification.success('Customer Details', 'You have updated the customer details successfully')
                        emit('update')
                    } else {
                    }
                    loading.value = false
                } else {
                    loading.value = false
                    // rule.customerDetails =  Object.keys(fields)[0]
                }
            })
        }

        const close = (formEl: FormInstance | undefined) => {
            if (!formEl) return
            formEl.clearValidate()
            Customers.setHealth(healthDefault)
            loading.value = false
            modal(false)
        }

        const top = ref()
        const modal = (value: Boolean) => {
            open.value = value
            if (top.value !== undefined)
                top.value.setfnfocus(false)


        }

        const setFocusTofn = () => {
            top.value.setfnfocus(true)
        }



        return {
            top,
            rule,
            open,
            common,
            contact_info_payload,
            loading,
            ruleFormRef,
            submitCustomer,
            setFocusTofn,
            modal,
            close,
            cd,
            rules
        }
    },
})
