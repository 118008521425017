
import { defineComponent, computed, getCurrentInstance, watch, onMounted  } from 'vue'
import { store } from "@/store";
import { rule, common, activeTab, leadEmployeeCategoryId } from '@/store/stateless';
import { edit_policy_group_columns } from '@/core/utils/policy'
import Validations from '@/core/services/etc/Validations';
import Documents from '@/core/services/Callable/Documents';

export default defineComponent({
    props:{
        allDisabled:{
            default:false,
            required:true
        },
        isEnableAllFields: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup(_, {emit}) {


        const policy = computed(() => {
            return store.state.policy.policy
        })     
       
        const inst = getCurrentInstance()

        const focus = () => {
            const toFocus: any = inst?.refs[rule.customerDetails]

            console.log({toFocus})
            if (toFocus !== undefined) {
            console.log('in focus')
                toFocus.focus()
            }
        }
        watch([() => activeTab.value, () => leadEmployeeCategoryId.value], async([newTab, newId], [oldTab, oldId]) => {
            if(newTab != oldTab) {
                const theId = document.getElementById(`group-details-${newTab}`)
                if(theId) theId.click()
            }

            if(newId != oldId) {
                await Documents.getDocuments({
                    is_loaded: true,
                    categories: ['standard'],
                    lead_id: policy.value.lead_id,
                    lead_employee_category_id: leadEmployeeCategoryId.value,
                    policy_id: policy.value.policy_purchase_id

                })

                await Documents.getAmendDocuments({
                    policy_id: policy.value.policy_purchase_id,
                    lead_employee_category_id: leadEmployeeCategoryId.value
                });
            }
        })

        function updateTab(value, index) {
            leadEmployeeCategoryId.value = value
            activeTab.value = index
            // emit('clearValidations')
        }

        return {
            updateTab,
            activeTab,
            leadEmployeeCategoryId,
            policy,
            focus,
            edit_policy_group_columns,
            Validations,
            common,
        }
    },
})
