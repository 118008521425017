
    import {
        defineComponent,
        computed,
        ref,
        getCurrentInstance,
        onMounted,
    } from "vue";
    import { store } from "@/store";
    import moment from "moment";
    import router from "@/router";
    import { common } from "@/store/stateless";
    import { top_sales_person, leads_target_summary, sales_target_summary } from "@/core/utils/dashboard.js";

    export default defineComponent({
        name: "dashboard-overview",
        setup() {

            const user = computed(() => {
                const users = window.localStorage.getItem("user");
                const user = users ? JSON.parse(users || "") : "";
                console.log('user', user)
                return user;
            })


            const topSalesAgent = computed(() => {
                return store.getters.getTopSalesAgent
            })

            const salesTarget = computed(() => {
                return store.getters.getSalesTargetDashboard
            })

            const leadsTarget = computed(() => {
                return store.getters.getLeadsTargetDashboard
            })


            return {
                user,
                topSalesAgent,
                common,
                top_sales_person,
                sales_target_summary,
                leads_target_summary,
                salesTarget,
                leadsTarget
            };
        },
    });
