
import { defineComponent, computed, h, onMounted, getCurrentInstance } from 'vue'
import { ElDivider } from 'element-plus';
import { store } from '@/store';
import Validations from '@/core/services/etc/Validations';
import { registration, rule, common } from '@/store/stateless';

export default defineComponent({
    // name: 'policy-edit',

    props:{
        isEnableAllFields: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    setup() {

        const currency = registration.currency;
        const policy = computed(() => {
            return store.state.policy.policy
        })

        const credit_debit_details = computed(() => {
            return store.state.policy.policy
        })

       

        const minStartDate = computed(() => {
            const today = new Date()
            const currentDate = new Date()
            currentDate.setDate(currentDate.getDate() - 60)
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth() + 1;
            const day = currentDate.getDate();
            return year + '-' + month + '-' + day;

            // return editPolicyObj.value.status !== 4 ? moment(currentDate).format('YYYY-MM-DD') : moment(today).format('YYYY-MM-DD')
        })

        const maxStartDate = computed(() => {
            const today = new Date()
            const currentDate = new Date()
            currentDate.setDate(currentDate.getDate() + 60)
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth() + 1;
            const day = currentDate.getDate();
            return year + '-' + month + '-' + day;

            // return editPolicyObj.value.status !== 4 ? moment(currentDate).format('YYYY-MM-DD') : moment(today).format('YYYY-MM-DD')
        })

        const minDebitAmount = (sales_price) => {
            const perc = store.state.policy.accepted_debit_amount_per;
            return sales_price * (perc / 100)
        }

        const inst = getCurrentInstance()
        const focus = () => {
            console.log('iqwn focus')

            const toFocus: any = inst?.refs[rule.customerDetails]

            console.log(toFocus)
            if (toFocus !== undefined) {
            console.log('in focus')
                toFocus.focus()
                // window.scrollTo(0, common.getY('top'))
            }
        }
        return {
            common,
            policy,
            minStartDate,
            maxStartDate,
            Validations,
            minDebitAmount,
            currency,
            focus
        }
    },
})
