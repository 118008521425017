
import { defineComponent, ref, watchEffect } from 'vue'

export default defineComponent({
    name: 'custom-amount',
    props: {
        modelValue: {
            type: String,
            required: true,
            default: ''
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    setup(_, context) {  
        
        const currentAmount = ref(0);

        watchEffect(() => {
            currentAmount.value = parseFloat(_.modelValue)
        })

        const updateValue = () => {
            // const val = parseFloat(currentAmount.value.toString().replace(' ', '').replace(',', ''))
            // currentAmount.value = roundUp(val);
            // if(isNaN(currentAmount.value)) {
            //     currentAmount.value = 0;
            //     context.emit("update:modelValue", 0)    
            // }
            
            // if(!currentAmount.value) {
            //     currentAmount.value = 0;
            //     context.emit("update:modelValue", 0) 
            // }
            
            // if(isNaN(currentAmount.value)) {
            //     currentAmount.value = 0;
            //     context.emit("update:modelValue", 0)    
            // }
            
            // context.emit("update:modelValue", currentAmount.value)
            
        }

        const correctValue = () => {
            const val = parseFloat(currentAmount.value.toString().replace(' ', '').replace(',', ''))
            currentAmount.value = roundUp(val);
            if(isNaN(currentAmount.value)) {
                currentAmount.value = 0;
                context.emit("update:modelValue", 0)    
            }
            
            if(!currentAmount.value) {
                currentAmount.value = 0;
                context.emit("update:modelValue", 0) 
            }
            
            if(isNaN(currentAmount.value)) {
                currentAmount.value = 0;
                context.emit("update:modelValue", 0)    
            }
            
            context.emit("update:modelValue", currentAmount.value)
            context.emit('change')
        }

        const updateDecimal = () => {
            const val = parseFloat(currentAmount.value.toString().replace(',', ''))
            if(isNaN(currentAmount.value)) {
                currentAmount.value = 0;
                context.emit("update:modelValue", 0)    
            }
            if(currentAmount.value > 9999999999.99) {
                currentAmount.value = 0;
                context.emit("update:modelValue", 0) 
            }
            
            currentAmount.value = roundUp(val);
            context.emit("update:modelValue", val)
        }

        const roundUp = (num: number) => {
            var p = Math.pow(10, 2);
            return Math.round(num * p) / p;
        }

        const focus = ref()

        const isFocus = () => {
            focus.value.focus()
        }

        return {
            correctValue,
            isFocus,
            focus,
            roundUp,
            updateValue,
            updateDecimal,
            currentAmount
        }
    },
})
