
import { defineComponent, computed } from "vue";
import { store } from "@/store";
import { UserTypesMixin } from "@/core/plugins/vcMixins";
import { copyText, common } from "@/store/stateless";
import moment from "moment";
import { policy_columns } from "@/core/utils/policy.js";
import Policy from '@/core/services/Callable/Policy';

export default defineComponent({
  props:{
    isView:{
      type: Boolean,
      required: false,
      default: false
    },
    isCustomerDetails:{
      type: Boolean,
      required: false,
      default: false
    }

  },
  setup() {
    const filter = computed(() => {
      return store.state.policy.filter;
    });

    const loading = computed(() => {
      return store.state.policy.loading;
    });

    const policyData = computed(() => {
      return store.getters.getPolicyList
    })

    const lists = computed(() => {
      return store.state.policy.policyLists;
    });

    console.log("lists", lists.value);

    const formatDate = (date) => {
      return moment(date, "YYYY-MM-DD HH:ss").format("DD/MM/YYYY HH:ss");
    };

    async function load(row, treeNode: unknown, resolve) {
        const result = await Policy.getChildPolicies({ parent_invoice_id: row.main_invoice ? row.main_invoice.id : null })
        // console.log('child',result.data.data.policies)
        const rs = result.data.data.policies

        resolve(rs.map(x => {
          const invoice = x.invoices.find(y => y.merchant_reference == x.merchant_reference)
          // console.log(x)
          if(invoice.amendment_start_date && invoice.amendment_end_date)
            x.start_end_date = invoice.amendment_start_date + '<br/>' + invoice.amendment_end_date;
          else 
            x.start_end_date = ''

          return {
            ...x,
            sales_price: `${common.formatCurrency(invoice.sales_price)} <br /> ${common.formatCurrency(invoice.sales_price_vat)}`,
            transaction_type_text: invoice.payment_type_text
          }
        }));
        
        // resolve(store.getters.PolicyData(result.data.data.policies, route.name, true));
    }

    return {
      policyData,
      lists,
      common,
      loading,
      copyText,
      formatDate,
      UserTypesMixin,
      filter,
      policy_columns,
      load
    };
  },
});
