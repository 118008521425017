import { store } from "@/store";
import { App } from "vue";
import { QuoteActionTypes } from '@/store/enums/Quote/Actions';
import { QuoteMutationTypes } from '@/store/enums/Quote/Mutations';

class Quote {
    
    public static vueInstance: App;

    public static init(app: App<Element>) {
        Quote.vueInstance = app;
    }

    public static async getQuotes(payload: any) {
        return await store.dispatch(QuoteActionTypes.GET_QUOTES, payload);
    }

    public static async getGenerateQuotes(payload: any) {
        return await store.dispatch(QuoteActionTypes.GET_GENERATE_QUOTES, payload);
    }


    public static async generateQuotesPdf(payload: any) {
        return await store.dispatch(QuoteActionTypes.GENERATE_QUOTES_PDF, payload);
    }

    public static async getViewQuotes(payload: any) {
        return await store.dispatch(QuoteActionTypes.GET_VIEW_QUOTES, payload);
    }
    
    public static async sendManualQuotes(payload: any) {
        return await store.dispatch(QuoteActionTypes.SEND_MANUAL_QUOTE, payload);
    }
    

    public static async setQuotePolicies(payload: any) {
        store.commit(QuoteMutationTypes.SET_QUOTE_POLICIES, payload);
    }

    public static async setGenerateQuotePolicies(payload: any) {
        store.commit(QuoteMutationTypes.SET_POLICIES, payload);
    }

    

}

export default Quote;