import { reactive } from "vue";

const filter = reactive({
    quicklead: {
        agent: -1,
        customer_status_id: -1,
        end_date: "",
        index: 1,
        keyword: "",
        lead_status_id: -1,
        page: 1,
        per_page: 50,
        start_date: "",
        task_due_dates: -1
    },
    customerDateRangeLT: ''
});

export {
    filter
};