<template>
<div class="vc" v-if="withProp">
        <label v-if="isShowLabel" class="form-label" :class="isRequired ? 'required' : ''"> {{ label }} 
            <span class="fw-light text-gray-700 fs-6">{{ subLabel2 }}</span> 
        <span class="fw-light text-gray-700 fs-8">{{ subLabel }}</span> </label>
        
        <i v-if="tooltip" class="fas fa-exclamation-circle ms-1 fs-7"
        data-bs-toggle="tooltip"
        :title="tooltip"
        ></i>
            <el-form-item
                :prop="prop"
                :rules="rules"
                >
    <el-input
        id="customer-original-price"
        v-model="amount"
        oninput="this.value = this.value.replace(/[^0-9.]/g, '')"
        maxlength="12"
        size="large"
        ref="inputRef"
        @blur="fixValue"
        @focus="removeComma"
        @input="updateModel"
        :disabled="disabled"
    />
        <!-- @blur="updateDecimal" -->
        </el-form-item>
    </div>
    <div v-else>
        <el-input
            id="customer-original-price"
            v-model="amount"
            oninput="this.value = this.value.replace(/[^0-9.]/g, '')"
            maxlength="12"
            size="large"
            ref="inputRef"
            @blur="fixValue"
            @focus="removeComma"
            @input="updateModel"
            :disabled="disabled"
        />
    </div>
</template>

<script setup>
import { defineProps, defineEmits, defineExpose, watch, ref, onMounted, watchEffect } from 'vue'
import { common } from '@/store/stateless'
    const props = defineProps({
        label: {
            type: String,
            required: true,
            default: '',
        },
        subLabel: {
            type: String,
            required: false,
            default: '',
        },
        subLabel2: {
            type: String,
            required: false,
            default: '',
        },
        withProp: {
            type: Boolean,
            required: false,
            default: true
        },
        modelValue: {
            type: String,
            required: true,
            default: ''
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        cancellationAmount: {
            type: Boolean,
            required: false,
            default: false
        },
        resetAmount: {
            type: Boolean,
            required: false,
            default: false
        },
        isShowLabel: {
            type: Boolean,
            required: false,
            default: true
        },
        isRequired: {
            type: Boolean,
            required: false,
            default: false
        },
        tooltip: {
            type: String,
            required: false,
        },
        prop: {
            type: String,
            required: true,
            default: 'User'
        },
        rules: {
            type: Array,
            required: false,
            default: false,
        }
    })
       
const amount = ref(0)
const isFocus = ref(false)
const inputRef = ref()
const focus = () => {
    setTimeout(() => {
    inputRef.value.focus()
    }, 250);
}

const emit = defineEmits(['update:modelValue', 'change', 'press'])

onMounted(() => {
    if(typeof props.modelValue == 'number') {
        amount.value = props.modelValue
        if(amount.value) amount.value = common.formatCurrency(amount.value)
    }

    if(typeof props.modelValue == 'string' && (props.modelValue == 'NaN' || isNaN(props.modelValue))) {
        amount.value = 0
        if(amount.value) amount.value = common.formatCurrency(amount.value)

        let fixedValue = common.useFixFigure(0)
        emit("update:modelValue", fixedValue)
    }


    if(typeof props.modelValue == 'string' && props.modelValue != 'NaN') {
        amount.value = props.modelValue.replaceAll(',', '')
        if(typeof Number(amount.value) == 'number') {
            if(amount.value) amount.value = common.formatCurrency(amount.value)
        }
    }
})

watchEffect(() => {
    if(typeof props.modelValue == 'string' && props.modelValue !== '' && !isFocus.value) amount.value = common.formatCurrency(props.modelValue)
})

watch(() => props.modelValue, newValue => {
    if(newValue == '' || newValue == null) amount.value = 0

    
    if(newValue == 'NaN') {
        amount.value = 0
        let fixedValue = common.useFixFigure(0)
        emit("update:modelValue", fixedValue)
    }

    if(typeof newValue == 'number' && isFocus.value === false) {
        amount.value = newValue
        if(amount.value) amount.value = common.formatCurrency(amount.value)
    }
})

function updateModel() {
    const fixedValue = common.useFixFigure(amount.value)
    emit("update:modelValue", fixedValue)
    emit('press', fixedValue)
}

function removeComma() {
    isFocus.value = true
    let currentValue = amount.value
    if(!currentValue) currentValue = props.modelValue
    if(amount.value == 'NaN') amount.value = 0
    if(typeof currentValue == 'number') amount.value = currentValue
    if(amount.value) amount.value = currentValue.replaceAll(',', '')
    setTimeout(() => {
        inputRef.value.select()
    }, 100);
}

function fixValue() {
    isFocus.value = false
    let fixedValue = common.useFixFigure(amount.value)
    let formatedValue = common.formatCurrency(fixedValue)
    if(formatedValue == 'NaN') formatedValue = 0
    if(fixedValue == undefined) fixedValue = 0

    amount.value = formatedValue
    emit("update:modelValue", fixedValue)
    emit('change', fixedValue)
}


defineExpose({ focus })

</script>
