
import { defineComponent, ref } from "vue";
import router from "@/router/clean";
import Invoice from "@/core/services/Callable/Invoice";

export default defineComponent({
  props: {
    lead_id: {
      type: Number,
      required: true,
    },
    customer_id: {
        type: Number,
        required: true,
    },
    merchant_reference: {
        type: String,
        required: true,
    }
    
  },

  setup(props) {

    const isOpen = ref(false)


    const callEvent = async() => {
      await Invoice.makeInvoiceEvent({
        driver_id: props.lead_id,
        type: 'health'
      })

      router.push({
          name: "CustomerDetails",
          params: { customer_id: props.customer_id },
        });

        open(false)
    }

    const open = (value = false) => {
        isOpen.value = value
    }

    return {
        open,
        isOpen,
      callEvent,
    };
  },
});
