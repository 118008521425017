
import { computed, defineComponent, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { registration } from "@/store/stateless";
import Invoice from '@/core/services/Callable/Invoice';
export default defineComponent({
  setup(props, context) {
    const store = useStore();

    const domain = ref(process.env.VUE_APP_ASSETS_DOMAIN)
    const url = ref(process.env.VUE_APP_INSURANCE_ASSETS_URL)
    const folder = ref(process.env.VUE_APP_ASSETS_FOLDER)
    const readMore = ref(false);

    const interestedPolicyModal = computed(() => {
      return store.state.invoice.openInterestedPolicyModal;
    });

    const policy = computed(() => {
      console.log(store.state.invoice.interestedPolicy);
      return store.state.invoice.interestedPolicy;
    });

    const premiums = policy.value?.memberPremiums
    const selectedPremium = ref(premiums?.[0])
    const totalPremium = ref(0)
    const basmah = ref(0)
    const vatper = ref(0)

    const vat = computed(() => {
      if (vatper.value > 0) {
        return (selectedPremium.value.totalPremium * (vatper.value / 100));
      }

      return 0
    });

    const basmah_vat = computed(() => {
      if (vatper.value > 0 && basmah.value > 0) {
        return (basmah.value * (vatper.value / 100));
      }

      return 0
    });


    onMounted(() => {
      // PolicyService.resetPolicyError();
      selectedPremium.value = premiums.filter((x) => x.id == policy.value.selected_premium)[0]

      basmah.value = parseFloat(policy.value.basmah);
      vatper.value = parseFloat(policy.value.vat);
      basmah.value = parseFloat(policy.value.basmah) * selectedPremium.value.members.length;
      vatper.value = parseFloat(policy.value.vat);


      totalPremium.value = selectedPremium.value.totalPremium + vat.value + basmah.value + basmah_vat.value;
      console.log(totalPremium.value)
    });

   
    const close = () => {
      Invoice.setInteredPolicyModal(false);
    };

    const currency = (e) => {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: registration.currency
      });

      return formatter.format(e);
    };

    const roundValue = (value) => {
      return value.toFixed(2);
    }



    return {
      registration,
      currency,
      roundValue,
      interestedPolicyModal,
      policy,
      close,
      domain,
      url,
      selectedPremium,
      premiums,
      totalPremium,
      vatper,
      vat,
      basmah,
      basmah_vat,
      readMore,
      folder
    };
  }
});
