
import Customers from '@/core/services/Callable/Customers'
import router from '@/router/clean'
import { store } from '@/store'
import { defineComponent, ref, computed, onMounted } from 'vue'

export default defineComponent({
    setup() {
        const open = ref<Boolean>(false)
        const customer_id:any = router.currentRoute.value.params.customer_id?.toString()

        const sms = computed(() => {
            return store.state.customer.sms
        })

        const loading = computed(() => {
            return store.state.customer.sms_loading
        })

        const modal = async(value: Boolean) => {
            open.value = value
            if(value) {
                await Customers.getCustomerSMS({ customer_id })
            }

            // console.log('sms',sms.value)
        }


        

        return {
            customer_id,
            loading,
            sms,
            open,
            modal,
        }
    },
})
