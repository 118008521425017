
    import { computed, defineComponent } from 'vue'
    import { notifications } from '@/core/utils/notification'
    import { store } from '@/store'

    export default defineComponent({
        setup() {

            const allNotifications = computed(() => {
                return store.getters.getAllNotifications
            })

            return {
                allNotifications,
                notifications
            }
        },
    })
