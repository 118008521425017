import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createBlock(_component_el_select, {
    modelValue: _ctx.modelValue,
    onChange: _ctx.updateValue,
    placeholder: _ctx.placeholder,
    filterable: "",
    size: "large",
    ref: "inputRef",
    "no-match-text": "No underwriters found",
    clearable: _ctx.clearable,
    class: _normalizeClass(["el-h-44", _ctx.updatedClass])
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item, index) => {
        return (_openBlock(), _createBlock(_component_el_option, {
          key: index,
          label: item.label,
          value: item.value
        }, null, 8, ["label", "value"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["modelValue", "onChange", "placeholder", "clearable", "class"]))
}