
import { defineComponent, ref } from 'vue';
import { agentType } from '@/core/utils'

export default defineComponent({
    props: {
        modelValue: {
            type: Boolean,
            required: false
        },
        agentName: {
            type: String,
            required: true,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
    },

    setup(props, context) {
        const updateValue = (event) => {
            context.emit("update:modelValue", event)
            context.emit("agent", event)
        }

        const inputRef = ref()
        const focus = () => {
            setTimeout(() => {
                inputRef.value.focus()
            }, 100);
        }

        return {
            focus,
            agentType,
            inputRef,
            updateValue
        }
    },
})
