import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card-header"
}
const _hoisted_2 = { class: "card-title" }
const _hoisted_3 = {
  class: "fs-6",
  style: {"font-style":"italic","font-weight":"200"}
}
const _hoisted_4 = { class: "text-gray-600" }
const _hoisted_5 = {
  class: "fs-6",
  style: {"font-style":"italic","font-weight":"200"}
}
const _hoisted_6 = { class: "text-gray-600" }
const _hoisted_7 = {
  class: "fs-6",
  style: {"font-style":"italic","font-weight":"200"}
}
const _hoisted_8 = { class: "text-gray-600" }
const _hoisted_9 = {
  class: "fs-6",
  style: {"font-style":"italic","font-weight":"200"}
}
const _hoisted_10 = { class: "badge badge-light-info" }
const _hoisted_11 = {
  class: "fs-6",
  style: {"font-style":"italic","font-weight":"200"}
}
const _hoisted_12 = { class: "text-gray-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_space = _resolveComponent("el-space")!

  return (_ctx.policy)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, [
          _createVNode(_component_el_space, {
            size: _ctx.size,
            spacer: _ctx.spacer
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.policy.customer.name), 1)
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.policy.customer.email), 1)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.policy.customer.phone_number), 1)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.policy.main_invoice.merchant_reference), 1)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("span", _hoisted_12, "Sales Agent : " + _toDisplayString(_ctx.policy.policy_sales_agent?.name), 1)
              ])
            ]),
            _: 1
          }, 8, ["size", "spacer"])
        ])
      ]))
    : _createCommentVNode("", true)
}