
import { defineComponent, ref, computed, getCurrentInstance } from 'vue'
import { store } from '@/store';
import { common } from '@/store/stateless'
import Customers from '@/core/services/Callable/Customers'

export default defineComponent({
    props: {
        lead_id: {
            type: Number,
            required: false,
            default: 0
        }
    },

    setup(_) {

        const open = ref<Boolean>(false)
        const add_notes = ref()

        const activeTab = ref<Number>(0)
        const selectedlead = ref()
        

        const leads = computed(() => {
            return store.getters.getLeads(true)
        })

        const loading = computed(() => {
            return store.state.customer.notes_loading
        })

        const notes = computed(() => {
            return store.state.customer.notes
        })

        const activeValue = ref(0);

        const changeValue = () => {
            fetchData(activeValue.value);
        };

        const fetchData = async (value: number) => {
            activeTab.value = value
            await Customers.getCustomerNotesByLeadId({
                lead_id: value
            })
        }
        
        const inst = getCurrentInstance()
        const modal = (value: Boolean) => {
            open.value = value
            const myLead = leads.value.find(x => x.tab_disabled !== true)
            let lead_id = myLead.lead_id
            if(_.lead_id > 0) {
                lead_id = _.lead_id
            }

            if(!value) {
                Customers.resetNotes()
            }
            activeValue.value = lead_id;
            changeValue()

            if(value) {
                setTimeout(() => {
                    const listRef:any = inst?.refs[`lead_${lead_id}`]
                    // listRef.click()
                }, 500);
            }

            

            
        }

        return {
            selectedlead,
            activeTab,
            add_notes,
            loading,
            common,
            leads,
            notes,
            open,
            modal,
            fetchData,
            activeValue,
            changeValue
        }
    },
})
