import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "accordion accordion-custom",
  id: "kt_accordion"
}
const _hoisted_2 = ["id"]
const _hoisted_3 = ["id", "data-bs-target", "aria-controls"]
const _hoisted_4 = ["id", "aria-labelledby"]
const _hoisted_5 = { class: "accordion-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_customer_accordion_data = _resolveComponent("vc-customer-accordion-data")!
  const _component_vc_customer_details = _resolveComponent("vc-customer-details")!
  const _component_vc_customer_task_details = _resolveComponent("vc-customer-task-details")!

  return (_ctx.leads.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.leads, (lead, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "accordion-item mb-5",
            key: index
          }, [
            _createElementVNode("h2", {
              class: "accordion-header",
              id: `kt_accordion_header_${index}`
            }, [
              _createElementVNode("button", {
                class: _normalizeClass(["accordion-button fs-4 fw-bold collapsed", {'accordion-button-blue': lead.highlight}]),
                id: `accordian_${index}`,
                type: "button",
                "data-bs-toggle": "collapse",
                "data-bs-target": `#kt_accordion_body_${index}`,
                "aria-expanded": "false",
                "aria-controls": `kt_accordion_body_${index}`,
                ref: "listRef"
              }, [
                _createVNode(_component_vc_customer_accordion_data, { data: lead }, null, 8, ["data"])
              ], 10, _hoisted_3)
            ], 8, _hoisted_2),
            _createElementVNode("div", {
              id: `kt_accordion_body_${index}`,
              class: "accordion-collapse collapse",
              "aria-labelledby": `kt_accordion_header_${index}`,
              "data-bs-parent": "#kt_accordion"
            }, [
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.getters.getCustomerLeadDetails(lead), (detail, i) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
                    (lead[detail.key].show && !detail.notShow)
                      ? (_openBlock(), _createBlock(_component_vc_customer_details, {
                          key: 0,
                          "is-show-title": detail.showTitle,
                          "is-show-subt-title": detail.showSubTitle,
                          title: detail.title,
                          "is-table": detail.inTable,
                          items: detail.items,
                          section: detail.key,
                          values: lead[detail.key],
                          "interested-in": detail.interestedIn,
                          "interested-policy": lead.interested_policy,
                          lead_id: lead.lead_id,
                          lead: lead,
                          onAction: _ctx.tabAction
                        }, null, 8, ["is-show-title", "is-show-subt-title", "title", "is-table", "items", "section", "values", "interested-in", "interested-policy", "lead_id", "lead", "onAction"]))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 128)),
                _createVNode(_component_vc_customer_task_details, {
                  customer_id: lead.customer_id,
                  agent: lead.agent,
                  lead_id: lead.lead_id,
                  lead_status_id: lead.lead_status_id
                }, null, 8, ["customer_id", "agent", "lead_id", "lead_status_id"])
              ])
            ], 8, _hoisted_4)
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}