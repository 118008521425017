
import { defineComponent, computed, ref, onMounted, getCurrentInstance, watch } from 'vue';
import { store } from "@/store";
import { rule, common, registration } from '@/store/stateless';
import Quote from '@/core/services/Callable/Quote';
import router from '@/router';
import { useRoute } from 'vue-router';
import Etcs from '@/core/services/Callable/Etcs';


export default defineComponent({
    props:{
        isAmend:{
            type:Boolean,
            required:false,
            default:false
        }
    },
    setup(_, context) {
        const currency = registration.currency
        const is_discount_given_disabled = ref(true)
        const route = useRoute()
        const health = computed(() => {
            return store.state.customer.health;
        })
        
        const invoice = computed(() => {
            return store.state.invoice.groupInvoice;
        })

        const invoiceData = computed(() => {
            return store.state.invoice.invoiceData;
        })

        const quotes = computed(() => {
            return store.state.quote.policies;
        })

        watch(() => invoice.value, async (newValue) => {
            // console.log('inside watchs')
            if(newValue) {
                // updatePolicyPrice(invoice.value.orginal_policy_price)
                // updateIcp(invoice.value.icp)
                // updateNonTaxableFee(invoice.value.non_taxable_fee)
                updateInvoiceAmount(invoice.value.policy_price)
            }
        }, { deep: true })

        const amend_quote_ref_no = computed(() => {
            return invoice.value.quote_ref_no + '-AA1';
        })

        
        const agents = ref<any>([]);

        onMounted(async () => {
            const payload = {
                lead_id: route.params.lead_id,
                insurance_type: health.value.insurance_type,
                is_ammend:_.isAmend ? 1 : 0// for sending Basmah Value
            };
            await Quote.getQuotes(payload);
            // console.log(quotes.value);
            await Etcs.getAgentLists();

            const agent = store.state.etc.agents;
            agent.unshift({ label: 'Select sales agent', value: "" });
            agents.value = agent;

            
            invoice.value.basmah = store.state.quote.basmah;

            // console.log('basmah',store.state.etc.basmah)
        })


        const inst = getCurrentInstance()
        const focus = () => {
            const toFocus: any = inst?.refs[rule.customerDetails]
            if (toFocus !== undefined) {
                toFocus.focus()
                window.scrollTo(0, common.getY('last'))
            }
        }

        const isNumber = (evt) => {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        }
        const uploadFile = (event) => {
            // formData = new FormData();
            // rules.file_data.show = false;
            let file = event.target.files[0];
            // if (file == "" && addInvoiceBodyParam.value.payment_type != "1") {
            //     rules.value.file_data.show = false;
            //     isFormValid.value = false;
            // }
            //
            // console.log('file',file);
            invoice.value.file_receipt = file;
            context.emit('uploadFile', file);
        };

        const uploadChequeFile = (event) => {
            
            let file = event.target.files[0];
            
            context.emit('uploadChequeFile', file);
        };

        const updateInvoiceAmount = (e) => {
            // rules.value.policy_price.show = false
            const val = parseFloat(e.toString().replace(',', ''))
            if (isNaN(val)) {
                invoice.value.invoice_amount = 0
            } else {
                invoice.value.invoice_amount = roundUp(val);
                invoice.value.sales_price = roundUp(invoice.value.invoice_amount)
            }
        }

        const updateSalePriceValue = (e) => {
            // rules.value.policy_price.show = false
            const val = parseFloat(e.target.value.toString().replace(',', ''))
            if (isNaN(val)) {
                invoice.value.sales_price = 0
            } else {
                invoice.value.sales_price = roundUp(val) ;
            }
        }

        const calculateDiscountAmount = () => {
            const val = parseFloat(invoice.value.sales_price.toString().replace(',', ''))
            if (invoice.value.policy_price > 0 && val > 0) {

                if (val >= invoice.value.policy_price) {
                    invoice.value.discount_amount = 0;
                    invoice.value.is_discount_given = false;
                }
                else {
                    invoice.value.is_discount_given = true;
                    let discountAmount = invoice.value.policy_price - val;

                    invoice.value.discount_amount = roundUp(discountAmount);
                }

            }

        }

        const roundUp = (num: number) => {
            var p = Math.pow(10, 2);
            return Math.round(num * p) / p;
        }

        const updateSplitPayment = () => {
            if(invoice.value.is_split_payment)
            {
                invoice.value.pending_payment_type = '4';
            }
            else{
                invoice.value.pending_payment_type = null;
            }
        }

        return {
            rule,
            focus,
            health,
            quotes,
            common,
            invoice,
            isNumber,
            uploadFile,
            agents,
            updateInvoiceAmount,
            updateSalePriceValue,
            calculateDiscountAmount,
            is_discount_given_disabled,
            updateSplitPayment,
            uploadChequeFile,
            amend_quote_ref_no,
            invoiceData,
            currency
        }
    },
})
