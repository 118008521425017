import { createApp } from "vue";
import App from "./App.vue";
import Socket from './main-socket.js'

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "@/router/clean";
import { store } from "@/store";
import ElementPlus from "element-plus";
// import i18n from "@/core/plugins/i18n";


//imports for app initialization
import ApiService from "@/core/services/ApiService";
import Auths from "@/core/services/Callable/Auths";
import Etcs from "@/core/services/Callable/Etcs";
import Customers from "@/core/services/Callable/Customers";
import Documents from "@/core/services/Callable/Documents";
import Users from "@/core/services/Callable/Users";
// import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import { initVincentComponents } from '@/core/plugins/vcComponents';
import { initVincentFilters } from '@/core/plugins/vcFilters';
import { initGlobalFunction } from "./core/plugins/vcGlobal";

import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
import "bootstrap";
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/display.css'
import "@/assets/sass/layout/_custom.scss"

// import vcButton from '@/components/reusables/vc.js';



const app = createApp(App); 


if (process.env.NODE_ENV === "production") {
    console.log = () => {
      //
    };
    console.debug = () => {
      //
    };
    console.info = () => {
      //
    };
  }
    console.warn = () => {
      //
    };

app.use(Socket);
app.use(store);
app.use(router);
app.use(ElementPlus);

ApiService.init(app);
Auths.init(app);
Etcs.init(app);
Customers.init(app);
Documents.init(app);
Users.init(app);

// initApexCharts(app);
initVincentComponents(app);
initVincentFilters(app);
initGlobalFunction(app);
initInlineSvg(app);
initVeeValidate();

// app.use(i18n);

router.isReady()
.then(() => {
    app.mount("#app");
})
