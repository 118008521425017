
import { defineComponent, computed, ref, onMounted, getCurrentInstance } from 'vue';
import { store } from "@/store";
import { rule, common } from '@/store/stateless';
import Etcs from '@/core/services/Callable/Etcs';

export default defineComponent({
    setup() {

        const health = computed(() => {
            return store.state.customer.health;
        })

        const agents = ref<any>([]);
        onMounted(async () => {
            await Etcs.getAgentLists();
            const agent = store.state.etc.agents;
            agent.unshift({ label: 'Select sales agent', value: "" });
            agents.value = agent;

        })

        const inst = getCurrentInstance()
        const focus = () => {
            const toFocus: any = inst?.refs[rule.customerDetails]
            if (toFocus !== undefined) {
                toFocus.focus()
                window.scrollTo(0, common.getY('last'))
            }
        }

        return {
            rule,
            focus,
            health,
            agents
        }
    },
})
