
import { defineComponent, computed, onMounted, getCurrentInstance, watch, ref  } from 'vue'
import { store } from "@/store";
import { rule, common, notification } from '@/store/stateless';
import { new_sub_group_columns, group_amend_columns } from '@/core/utils/invoice'
import Validations from '@/core/services/etc/Validations';
import Invoice from '@/core/services/Callable/Invoice';

export default defineComponent({
    props:{
        allDisabled:{
            default:false,
            required:true
        },
        isAmend: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup(props, { emit }) {

        const health = computed(() => {
            return store.state.customer.health;
        })
        
        const invoice = computed(() => {
            return store.state.invoice.groupInvoice;
        })
        
        const dummyinvoice = computed(() => {
            return store.state.invoice.groupInvoice;
        })

        const basmah = computed(() => {
            return store.state.etc.basmah;
        })

        const plans = computed(() => {
            return store.getters.getInsurancePlans;
        })

        const validateLoading = ref(false)

        const vat = computed(() => {
            return store.state.etc.vat  / 100;
        })

        const emirates = computed(() => {
            return store.state.etc.emirates
        })

        onMounted(() => {
           
        })

        watch(() => invoice.value, async (newValue) => {
            // console.log('inside cat watch')
            // console.log(newValue)
            if(newValue) {
                newValue.categories.map(x => {
                    const group_count = Number(x.group_count)
                    const basmahVal = x.visa_emirates == 2 ? Number(basmah.value) : 0;
                    x.basmah = common.roundUp(group_count * basmahVal);
                    x.icp_disabled = x.visa_emirates != 2 ? false : true;
                    if(x.visa_emirates == 20)
                        x.icp = 0;

                    const plan = plans.value.filter(obj => obj.id == x.insurance_plan_id)
                    // console.log(x.visa_emirates, emirates.value)
                    if(plan.length > 0 ){
                        x.insurance_provider_id = plan[0].insurance_provider_id
                        x.insurance_plan = plan[0].label
                    }

                    const emirate = emirates.value.filter(obj => obj.value == x.visa_emirates)
                    if(emirate.length > 0 ){
                        x.visa_emirates_text = emirate[0].label
                    }

                    const category = store.state.etc.employeeCategories?.filter(obj => obj.value == x.name)
                    if(category && category.length > 0 ){
                        x.category_name = category[0].label
                    }

                    


                    if(group_count == 0) {
                        x.amount = 0
                        x.non_taxable_fee = 0
                    }
                    return {
                        ...x,
                        group_count
                    }
                })

                updateBasmah()
                updateNonTaxableFee()
                // updatePolicyPrice()
            }
            // if(newValue) {
            //     invoice.value.categories = invoice.value.categories.map(element => {
            //         const group_count = Number(element.group_count);
            //         const basmahVal = element.visa_emirates == 2 ? Number(basmah.value) : 0;
            //         element.basmah = common.roundUp(group_count * basmahVal);
            //         if(group_count == 0)
            //             // element.amount = 0;
            //         // console.log(element.group_count)
            //         // console.log(element.basmah)
            //         return element;

            //     });

            //     updateBasmah()
            // }
        }, { deep: true })

        const updateBasmah = () => {
            // invoice.value.orginal_policy_price
            const basmah = invoice.value.categories.reduce((sum, obj) => {
                const basmah = Number(obj['basmah']);
                if(isNaN(basmah))
                    return sum + 0
                else
                    return sum + basmah
            }, 0);

            invoice.value.basmah = common.roundUp(basmah)

        }

        const updateIcp = () => {
            // invoice.value.orginal_policy_price
            const icp = invoice.value.categories.reduce((sum, obj) => {
                const icp = Number(obj['icp']);
                if(isNaN(icp))
                    return sum + 0
                else
                    return sum + icp
            }, 0);

            invoice.value.icp = common.roundUp(icp)

        }
        
        const updatePolicyPrice = (val) => {
            // invoice.value.orginal_policy_price
            const amount = invoice.value.categories.reduce((sum, obj) => {
                const amount = Number(obj['amount']);
                if(isNaN(amount))
                    return sum + 0
                else
                    return sum + amount
            }, 0);

            val.amount_vat = common.roundUp(val.amount * vat.value)
            if(isNaN(val.amount_vat)) val.amount_vat = 0

            invoice.value.orginal_policy_price = common.roundUp(amount)

        }
        
        const updateNonTaxableFee = () => {
            // invoice.value.orginal_policy_price
            const amount = invoice.value.categories.reduce((sum, obj) => {
                const amount = Number(obj['non_taxable_fee']);
                if(isNaN(amount))
                    return sum + 0
                else
                    return sum + amount
            }, 0);

            invoice.value.non_taxable_fee = common.roundUp(amount)

        }


        const inst = getCurrentInstance()
        const focus = () => {
            const toFocus:any = inst?.refs[rule.customerDetails]
            if(toFocus !== undefined) {
                toFocus.focus()
                // window.scrollTo(0, common.getY('top'))
            }
        }

        function updateValue(val, obj) {
            if(val[obj] == '' || val[obj] == null) {
                val[obj] = 0
                if(obj == 'group_count') {
                    val.group_count = 0
                    val.basmah = 0
                    val.icp = 0
                    val.non_taxable_fee = 0
                    val.amount = 0
                    val.amount_vat = 0
                }
            } else {
                val[obj] = parseInt(val[obj])
            }
        }

        const columns = computed(() => {
            if(props.isAmend) return group_amend_columns
            else return new_sub_group_columns
        })

        const deleteCategory = (index) => {
            invoice.value.categories.splice(index, 1);
        }

        const validateDuplicates = async () => {
            // const result = await Invoice.validateDuplicates(invoice.value);
            // console.log(result)
            // if(result){
            //     setTimeout(() => {
            //             notification.warning(
            //                 'Duplicate'
            //             );
            //         }, 0);
            // }
            // else {
            //     setTimeout(() => {
            //             notification.success(
            //                 'No Duplicate'
            //             );
            //         }, 0);
            // }
            emit('validate')
        }

        return {
            basmah,
            columns,
            group_amend_columns,
            dummyinvoice,
            updateNonTaxableFee,
            updateValue,
            invoice,
            focus,
            health,
            new_sub_group_columns,
            Validations,
            updatePolicyPrice,
            common,
            updateIcp,
            plans,
            deleteCategory,
            validateLoading,
            validateDuplicates
        }
    },
})
