
import { defineComponent, ref, computed } from 'vue'
import { store } from '@/store';
import Policy from '@/core/services/Callable/Policy';
import { notification } from '@/store/stateless';

export default defineComponent({

    setup() {

        const clickLoading = ref(false)
        const isError = ref(false)

        const transferSale = computed(() => {
            return store.state.policy.transfer_sale;
        })


        const agents = ref<any>([]);
        const newAgent = ref(null);

        const appendAgent = async () => {
            let agent = store.state.etc.agents;
            agent = agent.filter((x) => x.value != transferSale.value.agent)
            // agent.unshift({ label: 'Select Policy Sales Agent', value: "all" });
            agents.value = agent;

            console.log(transferSale.value)
        }

        const closeModal = () => {
            newAgent.value = null;
            isError.value = false;
            clickLoading.value = false;
            transferSale.value.open = false;
            transferSale.value.policy_id = null;
            transferSale.value.agent = null;
            transferSale.value.is_group = false;
        }

        const filter = computed(() => {
            return store.state.policy.filter;
        });

        const submitTransferSales = () => {
            if (!newAgent.value) {
                isError.value = true;
                return;
            }

            clickLoading.value = true;

            const payload = {
                policy_id: transferSale.value.policy_id,
                agent: newAgent.value
            }

            if(!transferSale.value.is_group) {
                Policy.transferSale(payload).then((data) => {
                    notification.success("Policy Transfer", "You have successfully transfer the policy");
                    closeModal()
                    Policy.policyLists(filter.value)
                })

            }
            else 
            {
                Policy.groupTransferSale(payload).then((data) => {
                    notification.success("Policy Transfer", "You have successfully transfer the policy");
                    closeModal()
                    Policy.groupPolicyLists(filter.value)
                })

            }

        }

        return {
            closeModal,
            transferSale,
            agents,
            appendAgent,
            newAgent,
            submitTransferSales,
            isError,
            clickLoading
        }
    },
})
