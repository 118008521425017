
import { defineComponent, computed, ref } from 'vue'
import Invoice from '@/core/services/Callable/Invoice';
import { store } from "@/store";
import { notification, constant } from "@/store/stateless"
import router from '@/router/clean';
interface IUploadForm {
  invoice_id: any;
  invoice_doc: any;
}
export default defineComponent({
  props: {
    invoice: {
      type: Object,
      required: false,
    },
    showAction: {
      type: Boolean,
      required: false,
      default: true,
    }
  },
  setup(_,context) {

    const filter = computed(() => {
            return store.state.invoice.filter
        })

    let uploadFormData = ref<IUploadForm>({
      invoice_id: null,
      invoice_doc: null,
    });

    const isUploadReceipt = ref(false)
    const disableSaveButton = ref(false)
    const error = ref('')

    const permissions = computed(() => {
      return store.getters.getUserPermissions
    })

    const closeModal = () => {
      isUploadReceipt.value = false;
      uploadFormData.value.invoice_id = null;
      uploadFormData.value.invoice_doc = null;
    }

    const uploadReceipt = (invoiceId) => {
      uploadFormData.value.invoice_id = invoiceId;
      isUploadReceipt.value = true;
    }

    const handleFileUpload = (event) => {
      error.value = ''
      uploadFormData.value.invoice_doc = event.target.files[0];
    };

    const uploadInvoiceDocument = async () => {
      if (!uploadFormData.value.invoice_doc || uploadFormData.value.invoice_doc == "") {
        //error message
        error.value = `<p style="color: red;">Please select file to upload</p>`
      }

      if (uploadFormData.value.invoice_doc) {
        //upload a doc
        disableSaveButton.value = true;
        let formData = new FormData();
        formData.append("invoice_id", uploadFormData.value.invoice_id);
        formData.append("invoice_doc", uploadFormData.value.invoice_doc);

        await Invoice.uploadGroupInvoiceDoc(formData).then(
          (data) => {
            uploadFormData.value.invoice_doc = null;
            notification.success("Invoice", "Your receipt has been uploaded successfully!");
            closeModal();
            formData = new FormData();
            disableSaveButton.value = false;
            Invoice.groupInvoiceLists(filter.value)

          }
        );

        
      }

    };

    const invoiceDetails = async () => {
      const data = {
        show: true,
        invoice: _.invoice
      }
      // console.log('invoice',_.invoice?.categories);
      await Invoice.setGroupInvoice(_.invoice)
      await Invoice.setInvoiceDetailsModal(data);
    }
    
    const toPages = (name) => {
      const route = router.resolve({ name: name, params: { invoice_id: _.invoice?.id, lead_id: _.invoice?.lead_id } })
      if (route) {
        window.open(route.href, '_blank')
      }
    }

    const addAmendInvoice = () => {

      const route = router.resolve({ name: 'addGroupAmendInvoice', params: { invoice_id: _.invoice?.id, lead_id: _.invoice?.lead_id } })
      if (route) {
        window.open(route.href, '_blank')
      }
    }

    const subAmendInvoice = () => {

      const route = router.resolve({ name: 'subGroupAmendInvoice', params: { invoice_id: _.invoice?.id, lead_id: _.invoice?.lead_id } })
      if (route) {
        window.open(route.href, '_blank')
      }
    }

    const invoiceLogModal = async () => {
        await Invoice.getGroupInvoiceLog({lead_id: _.invoice?.lead_id , invoice_id: _.invoice?.id});
    }

    return {
      toPages,
      error,
      isUploadReceipt,
      disableSaveButton,
      uploadReceipt,
      handleFileUpload,
      closeModal,
      uploadInvoiceDocument,
      invoiceDetails,
      addAmendInvoice,
      constant,
      subAmendInvoice,
      store,
      permissions,
      invoiceLogModal
    }
  },
})
