import { MutationTree } from 'vuex';

import { State } from './state';
import { DocumentMutationTypes } from '@/store/enums/Document/Mutations';

export type Mutations<S = State> = {
  [DocumentMutationTypes.SET_DOCUMENT_LIST_LOADING](state: S, payload: any): void
  [DocumentMutationTypes.SET_DOCUMENT_LISTS](state: S, payload: any): void
  [DocumentMutationTypes.SET_DOCUMENT_TYPES](state: S, payload: any): void
  [DocumentMutationTypes.SET_DOCUMENT_LOGS](state: S, payload: any): void
}

export const mutations: MutationTree<State> & Mutations = {
  [DocumentMutationTypes.SET_DOCUMENT_LIST_LOADING](state: State, payload: any) {
    state.document_loading = payload
  },
  
  [DocumentMutationTypes.SET_DOCUMENT_LISTS](state: State, payload: any) {
    state.documents = payload
  },
  
  [DocumentMutationTypes.SET_DOCUMENT_TYPES](state: State, payload: any) {
    state.document_types = payload
  },
  
  [DocumentMutationTypes.SET_DOCUMENT_LOGS](state: State, payload: any) {
    state.document_logs = payload
  },

  [DocumentMutationTypes.SET_AMEND_DOCUMENT_LISTS](state: State, payload: any) {
    state.amend_documents = payload
  },

};
