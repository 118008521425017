// TODO: Remove no-shadow eslint disable after fixed: https://github.com/typescript-eslint/typescript-eslint/issues/2484
// eslint-disable-next-line no-shadow
export enum CustomerMutationTypes {
    SET_QUICK_LEAD_DIALOG = 'SET_QUICK_LEAD_DIALOG',
    SET_QUICK_LEAD = 'SET_QUICK_LEAD',
    SET_IMPORT_DIALOG = 'SET_IMPORT_DIALOG',
    SET_CUSTOMER_LOADING = 'SET_CUSTOMER_LOADING',
    SET_CUSTOMER_LISTS = 'SET_CUSTOMER_LISTS',
    SET_CUSTOMER_TRANSFER_DIALOG = 'SET_CUSTOMER_TRANSFER_DIALOG',
    SET_CUSTOMER_LIST_COUNTS = 'SET_CUSTOMER_LIST_COUNTS',
    SET_CUSTOMER_FILTER = 'SET_CUSTOMER_FILTER',
    SET_CUSTOMER_HEALTH = 'SET_CUSTOMER_HEALTH',
    SET_CUSTOMER_HEALTH_LEAD_ID = 'SET_CUSTOMER_HEALTH_LEAD_ID',
    SET_CUSTOMER_HEALTH_DECLARATIONS = 'SET_CUSTOMER_HEALTH_DECLARATIONS',
    SET_CUSTOMER_LEAD_DECLARATIONS = 'SET_CUSTOMER_LEAD_DECLARATIONS',
    SET_CUSTOMER_TRANSFER_CHECKED = 'SET_CUSTOMER_TRANSFER_CHECKED',
    SET_DETAIL_CUSTOMER = 'SET_DETAIL_CUSTOMER',
    SET_VIEW_TASKS = 'SET_VIEW_TASKS',
    SET_DETAIL_CUSTOMER_LOADING = 'SET_DETAIL_CUSTOMER_LOADING',
    SET_VIEW_TASK_LOADING = 'SET_VIEW_TASK_LOADING',
    SET_VIEW_NOTES_LOADING = 'SET_VIEW_NOTES_LOADING',
    SET_VIEW_EMAIL_LOADING = 'SET_VIEW_EMAIL_LOADING',
    SET_CUSTOMER_ACTIVITY_LOG_LOADING = 'SET_CUSTOMER_ACTIVITY_LOG_LOADING',
    SET_CUSTOMER_STATUS_LOG_LOADING = 'SET_CUSTOMER_STATUS_LOG_LOADING',
    SET_LEAD_ACTIVITY_LOG_LOADING = 'SET_LEAD_ACTIVITY_LOG_LOADING',
    SET_LEAD_STATUS_LOG_LOADING = 'SET_LEAD_STATUS_LOG_LOADING',
    SET_VIEW_NOTES = 'SET_VIEW_NOTES',
    SET_CUSTOMER_ACTIVITY_LOG = 'SET_CUSTOMER_ACTIVITY_LOG',
    SET_CUSTOMER_VIEW_ACTIVITY_LOG = 'SET_CUSTOMER_VIEW_ACTIVITY_LOG',
    SET_LEAD_ACTIVITY_LOG = 'SET_LEAD_ACTIVITY_LOG',
    SET_LEAD_VIEW_ACTIVITY_LOG = 'SET_LEAD_VIEW_ACTIVITY_LOG',
    SET_CUSTOMER_STATUS_LOG = 'SET_CUSTOMER_STATUS_LOG',
    SET_LEAD_STATUS_LOG = 'SET_LEAD_STATUS_LOG',
    SET_VIEW_EMAILS = 'SET_VIEW_EMAILS',
    SET_VIEW_SMS_LOADING = 'SET_VIEW_SMS_LOADING',
    SET_VIEW_SMS = 'SET_VIEW_SMS',
    SET_LEAD_FILTER_COUNT = 'SET_LEAD_FILTER_COUNT'
  }
  