
import { defineComponent, ref, computed } from 'vue'
import { rule } from '@/store/stateless'
import { FormInstance } from 'element-plus';
import Customers from '@/core/services/Callable/Customers';
import { store } from '@/store';

export default defineComponent({
    props: {
        customer_id: {
            type: Number,
            required: true
        },
        from: {
            type: String,
            required: true
        },
        to: {
            type: String,
            required: true
        }
    },
    setup(_) {
        const open = ref<Boolean>(false)
        const ruleFormRef = ref<FormInstance>()
        const cd = computed(() => {
            return store.getters.getCustomer
        })

        const email = ref({
            bcc: process.env.VUE_APP_EMAIL_BCC,
            body: '',
            customer_id: _.customer_id,
            email_template_id: process.env.VUE_APP_EMAIL_TEMPLATE_ID,
            from: _.from,
            subject: '',
            to: cd.value.email
        })
        const loading = ref<Boolean>(false)

        

        const submitTask = async (formEl: FormInstance | undefined) => {
            loading.value = true

            if(!formEl) return
            formEl.validate(async (valid, fields: any) => {
                if(valid) {
                    const data = await Customers.addCustomerEmail(email.value)
                    close();
                    loading.value = false
                } else {
                    loading.value = false
                }
            }) 
        }

        const validate = async (formEl: FormInstance | undefined) => {
            if(!formEl) return
            formEl.validate(async (valid, fields: any) => {
                if(valid) {
                    // 
                }
            })
        }

        const close = () => {
            modal(false)
            email.value = {
                bcc: process.env.VUE_APP_EMAIL_BCC,
                body: '',
                customer_id: _.customer_id,
                email_template_id: process.env.VUE_APP_EMAIL_TEMPLATE_ID,
                from: _.from,
                subject: '',
                to: cd.value.email
            }
            ruleFormRef.value?.clearValidate()
        }

        const subRef = ref()

        const modal = (value) => {
            open.value = value
            if(subRef.value && value) {
                email.value.to = cd.value.email
                setTimeout(() => {
                    subRef.value.focus()
                }, 1000);
            }
        }

        return {
            ruleFormRef,
            loading,
            subRef,
            email,
            open,
            rule,
            modal,
            close,
            validate,
            submitTask
        }
    },
})
