
    import {
        defineComponent,
        computed,
        ref,
        getCurrentInstance,
        onMounted,
    } from "vue";
    import { store } from "@/store";
    import moment from "moment";
    import router from "@/router";
    import { common } from "@/store/stateless";
    import { marketing, marketingCounts } from "@/core/utils/dashboard.js";

    export default defineComponent({
        name: "dashboard-overview",
        setup() {

            const graph = computed(() => {
                return store.getters.getStatGraph
            })

            const counts = computed(() => {
                return store.getters.getMarketTotal
            })

            return {
                common,
                marketing,
                marketingCounts,
                counts,
                graph,
            };
        },
    });
