import { MutationTree } from 'vuex';

import { User } from '@/core/types/User';
import { Listing } from '@/core/types/User'

import { State } from './state';
import { UserMutationTypes } from '@/store/enums/User/Mutations';

export type Mutations<S = State> = {
  [UserMutationTypes.SET_USERS](state: S, payload: Listing): void;
  [UserMutationTypes.SET_USER](state: S, payload: any): void;
  [UserMutationTypes.SET_USER_LOADING](state: S, payload: Boolean): void;
  [UserMutationTypes.SET_UNDERWRITERS](state: S, payload: any): void;
  [UserMutationTypes.SET_TEAM_LEADS](state: S, payload: any): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [UserMutationTypes.SET_USERS](state: State, payload: Listing) {
    state.users = payload;
  },
  [UserMutationTypes.SET_USER](state: State, payload: User) {
    state.user = payload;
  },
  [UserMutationTypes.SET_USER_LOADING](state: State, payload: Boolean) {
    state.user_loading = payload;
  },
  [UserMutationTypes.SET_UNDERWRITERS](state: State, payload: any) {
    state.underwriters = payload;
  },
  [UserMutationTypes.SET_TEAM_LEADS](state: State, payload: any) {
    state.team_leads = payload;
  },
};
