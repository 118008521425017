
import { defineComponent, ref } from 'vue'

export default defineComponent({
    props: {
        item: {
            type: Object,
            required: true
        }
    },

    setup() {
        const color = ref<Array<String>>(["success", "primary", "warning", "danger", "info"])

        return {
            color
        }
    },
})
