
import { defineComponent, computed, h, onMounted, getCurrentInstance } from 'vue'
import { ElDivider } from 'element-plus';
import { store } from '@/store';
import Validations from '@/core/services/etc/Validations';
import { registration, rule, activeTab, common } from '@/store/stateless';

export default defineComponent({
    // name: 'policy-edit',

    props:{
        isEnableAllFields: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    setup() {

        const currency = registration.currency;
        const policy = computed(() => {
            return store.state.policy.policy
        })

         const currentTab = computed(() => {
            if(policy.value && policy.value.main_invoice) return policy.value.main_invoice.categories.find((x, index) => index == activeTab.value)

            return {}
        })

        const vat = computed(() => {
            return store.state.etc.vat  / 100;
        })

        const minStartDate = computed(() => {
            const today = new Date()
            const currentDate = new Date()
            currentDate.setDate(currentDate.getDate() - 60)
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth() + 1;
            const day = currentDate.getDate();
            return year + '-' + month + '-' + day;

            // return editPolicyObj.value.status !== 4 ? moment(currentDate).format('YYYY-MM-DD') : moment(today).format('YYYY-MM-DD')
        })

        const maxStartDate = computed(() => {
            const today = new Date()
            const currentDate = new Date()
            currentDate.setDate(currentDate.getDate() + 60)
            const year = currentDate.getFullYear();
            const month = currentDate.getMonth() + 1;
            const day = currentDate.getDate();
            return year + '-' + month + '-' + day;

            // return editPolicyObj.value.status !== 4 ? moment(currentDate).format('YYYY-MM-DD') : moment(today).format('YYYY-MM-DD')
        })

        const minDebitAmount = (amount, amend_type) => {
            if(amend_type != 2) {
                const perc = store.state.policy.accepted_debit_amount_per;
                return amount * (perc / 100)
            } else {
                return 0
            }
        }

        const inst = getCurrentInstance()
        const focus = () => {
            const toFocus: any = inst?.refs[rule.customerDetails]
            if (toFocus !== undefined) {
                toFocus.focus()
            }
        }

        function updateObject(object, val) {
            if(object) {
                if(object[val] && object[val] > 0) object[`${val}_vat`] = common.roundUp(object[val] * vat.value)
            }
        }

        return {
            updateObject,
            common,
            currentTab,
            activeTab,
            policy,
            minStartDate,
            maxStartDate,
            Validations,
            minDebitAmount,
            currency,
            focus
        }
    },
})
