
    import {
        defineComponent,
        computed,
        ref,
        getCurrentInstance,
        onMounted,
    } from "vue";
    import { store } from "@/store";
    import moment from "moment";
    import router from "@/router";
    import { common } from "@/store/stateless";
    import { utm_stats, table_stats } from "@/core/utils/dashboard.js";

    export default defineComponent({
        name: "dashboard-overview",
        setup() {

            const graph = computed(() => {
                return store.getters.getStatGraph
            })

            const tableStat = computed(() => {
                return store.getters.getTableStat
            })

            return {
                common,
                utm_stats,
                table_stats,
                graph,
                tableStat
            };
        },
    });
