
import { defineComponent, computed, onMounted } from 'vue'
import { store } from '@/store';

export default defineComponent({
    setup(_,context) {

        const leads = computed(() => {
            return store.getters.getLeads(false)
        })  
        
        onMounted(() => {
            const listRef:any = document.getElementById('accordian_0')
            if(leads.value.length > 0)
                listRef.click()
        // console.log('leads',leads.value)
        })

        const tabAction = () => {
            console.log('documents')

            context.emit('tab', 'documents')
        }

        return { leads,store,tabAction }
    },
})
