import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("label", {
      class: _normalizeClass(["form-label", _ctx.isRequired ? 'required' : ''])
    }, _toDisplayString(_ctx.label), 3),
    _createVNode(_component_el_form_item, { prop: _ctx.prop }, {
      default: _withCtx(() => [
        _createVNode(_component_el_select, {
          modelValue: _ctx.modelValue,
          onChange: _ctx.updateValue,
          placeholder: _ctx.label,
          filterable: "",
          size: "large",
          ref: "inputRef",
          "no-match-text": "No lead reason found",
          disabled: _ctx.disabled
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reasons, (item, index) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: index,
                label: item.label,
                value: item.value
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue", "onChange", "placeholder", "disabled"])
      ]),
      _: 1
    }, 8, ["prop"])
  ], 64))
}