
import { defineComponent, getCurrentInstance, ref, computed } from 'vue'
import Customers from '@/core/services/Callable/Customers';
import router from '@/router/clean';
import { store } from '@/store';
import { customer_log } from '@/core/utils'

export default defineComponent({
    setup() {
        const open = ref(false)
        const activeTab = ref<Number>(0)

        const inst = getCurrentInstance()
        const modal = async (value) => {
            open.value = value
            if(value) {
                const log = value === true ? 1 : value
                setTimeout(() => {
                    const listRef:any = inst?.refs[`log_${log}`]
                    // if(listRef)
                    //     listRef.click()
                    console.log(listRef)
                    if(listRef !== undefined) Array.isArray(listRef) ? listRef[0].click() : listRef.click()
                }, 500);
            } else {
                Customers.resetCustomerLog()
            }
        }

        const loading = computed(() => {
            const activity_log_loading = store.state.customer.activity_log_loading
            const status_log_loading = store.state.customer.status_log_loading

            if(activity_log_loading || status_log_loading) return true

            return false
        })

        const fetchData = async(value: number) => {
            activeTab.value = value
            if(value === 2) {
                await Customers.getCustomerStatusLog({customer_id: parseInt(router.currentRoute.value.params.customer_id.toString())})
            } else {
                await Customers.getCustomerActivityLog({customer_id: parseInt(router.currentRoute.value.params.customer_id.toString())})
            }
        }


        return {
            customer_log,
            activeTab,
            loading,
            store,
            open,
            modal,
            fetchData
        }
    },
})
