
import { defineComponent } from 'vue'
import { statusModal } from '@/store/stateless/store';

export default defineComponent({
    setup() {
      console.log(statusModal.value)
        return {
            statusModal
        }
    },
})
