import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_leads = _resolveComponent("vc-leads")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, { gutter: 10 }, {
    default: _withCtx(() => [
      _createVNode(_component_el_col, { md: 24 }, {
        default: _withCtx(() => [
          _createVNode(_component_vc_leads, { onLeads: _ctx.leadIds }, null, 8, ["onLeads"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}