
import { defineComponent, ref } from 'vue'

export default defineComponent({
    
    setup() {
        const open = ref(false)

        const task_form = ref()

        const customer = ref<Object | null>({
            customer_id: 0,
            agent: 0,
            lead_id: 0,
            lead_status_id: 0,
        })

        const close = (value) => {
            task_form.value.reset(0)
            modal(value)
        }

        const modal = (value, item = null) => {
            open.value = value

            if(item) customer.value = item
        }
        
        return {
            task_form,
            customer,
            open,
            modal,
            close,
        }
    },
})
