import { store } from "@/store";
import { App } from "vue";
import { DocumentActionTypes } from '@/store/enums/Document/Actions';
import { DocumentMutationTypes } from '@/store/enums/Document/Mutations';

class Documents {
    
    public static vueInstance: App;

    public static init(app: App<Element>) {
        Documents.vueInstance = app;
    }

    public static async getDocuments(payload: any) {
        return await store.dispatch(DocumentActionTypes.DOCUMENT_LISTS, payload);
    }

    public static async getDocumentTypes(payload) {
        return await store.dispatch(DocumentActionTypes.DOCUMENT_TYPES, payload);
    }

    public static async getGroupDocumentTypes(payload) {
        return await store.dispatch(DocumentActionTypes.GROUP_DOCUMENT_TYPES, payload);
    }

    public static async updateDocumentType(payload) {
        return await store.dispatch(DocumentActionTypes.DOCUMENT_UPDATE_TYPE, payload);
    }

    public static async deleteDocumentType(payload) {
        return await store.dispatch(DocumentActionTypes.DOCUMENT_DELETE_TYPE, payload);
    }

    public static async getDocumentLogById(payload) {
        return await store.dispatch(DocumentActionTypes.DOCUMENT_ID_LOGS, payload);
    }

    public static async downloadZip(payload) {
        return await store.dispatch(DocumentActionTypes.DOWNLOAD_ZIP_DOCUMENTS, payload);
    }

    public static async getAmendDocuments(payload: any) {
        return await store.dispatch(DocumentActionTypes.AMEND_DOCUMENT_LISTS, payload);
    }

    public static async updateAmendDocument(payload) {
        return await store.dispatch(DocumentActionTypes.UPDATE_AMEND_DOCUMENTS, payload);
    }

}

export default Documents;