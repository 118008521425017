
import { defineComponent, ref, computed } from 'vue'
import { documents } from '@/core/utils'
import { store } from '@/store';
import Documents from '@/core/services/Callable/Documents';

export default defineComponent({
    props: {
        isView: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup(_) {

        const policy_documents = computed(() => {
            return store.state.policy.policy_documents
        })

        const documents = computed(() => {
            return store.getters.getAllDocuments(2, _.isView)
        })

        

        return {
            documents,
            store,
            policy_documents
        }
    },
})
