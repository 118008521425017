
import { defineComponent, ref, computed } from 'vue'
import Users from '@/core/services/Callable/Users'
import { store } from "@/store";
import router from '@/router/clean';

export default defineComponent({
    setup() {
        const pages = ref([50, 100, 150, 200, 500])

        const filter = computed(() => {
            return store.state.user.user_list_payload
        })

        const selectedPage = async () => {
            getLists(1)
        }
        const userLists = computed(() => {
            return store.state.user.users
        })

        const getLists = async (e: any) => {
            const page = e;
            router.replace({ query: { page } })
                .catch(error => {
                    if (
                        error.name !== 'NavigationDuplicated' &&
                        !error.message.includes('Avoided redundant navigation to current location')
                    ) {
                        console.log(error)
                    }
                });
            filter.value.index = page;

            await Users.getUsers(filter.value);
        }

        return {
            filter,
            pages,
            userLists,
            selectedPage,
            getLists
        }
    },
})
