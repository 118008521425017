
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        text: {
            type: String,
            required: true,
            default: 'Submit'
        },
        className: {
            type: String,
            required: true,
            default: ''
        },
        type: {
            type: String,
            required: false,
            default: 'submit'
        },
        color: {
            type: String,
            required: false,
            default: 'primary'
        },
        size: {
            type: String,
            required: false,
            default: 'default '
        },
        isRegular: {
            type: Boolean,
            required: false,
            default: false
        },
        isIcon: {
            type: Boolean,
            required: false,
            default: false
        },
        icon: {
            type: String,
            required: false,
            default: 'far fa-arrow-alt-circle-left'
        },
        isIconLocal: {
            type: Boolean,
            required: false,
            default: false
        }
    },

})
