
import { computed, defineComponent } from 'vue'
import { customers } from '@/core/utils/users'
import { store } from '@/store'
import { common } from '@/store/stateless'

export default defineComponent({
    setup() {

        const users = computed(() => {
            return store.getters.getUsers
        })
        
        return {
            users,
            common,
            customers
        }
    },
})
