
import { defineComponent, computed, ref } from 'vue';
import Customers from '@/core/services/Callable/Customers';
import { store } from "@/store";

export default defineComponent({
    setup() {

        const filter = computed(() => {
            return store.state.customer.filter
        })
        
        const leadIds = async (e) => {
            filter.value.lead_status_id = e;
            console.log(filter.value);
            await Customers.customerLists(filter.value)
        }

        return {
            filter,
            leadIds,
        }
    },
})
