
import { computed, defineComponent, onMounted, ref } from 'vue'
import { store } from "@/store";
import Etcs from '@/core/services/Callable/Etcs';

interface IPayload {
    isDropdown: boolean;
}

export default defineComponent({
    props: {
        modelValue: {
            type: String,
            required: false,
            default: ''
        }
    },

    setup(props, context) {

        let bodyParam = ref<IPayload>({
            isDropdown: true,
        });

        const relationships = computed(() => {
            return store.state.etc.workType
        })

        onMounted(() => {
            Etcs.getRelationships(bodyParam.value);
        })

        const updateValue = (event) => {
            context.emit("update:modelValue", event)
            context.emit("relationship", event)
        }

        return {
            relationships,
            updateValue
        }
    },
})
