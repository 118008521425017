import { App } from 'vue';
import { FormInstance } from 'element-plus';
import Customers from '@/core/services/Callable/Customers';

import { loading, disable, constant, notification, modal, filter } from '@/store/stateless';

/**
 * Initialize customer details and quicklead
 * @param app vue instance
 * @created vincent carabbacan
 */

export const customer = (app: App<Element>) => {
    const roles = app.config.globalProperties.roles;
    
    app.config.globalProperties.customer = {
        async quicklead(formEl: FormInstance) {
            if(!formEl) return
            formEl.validate(async(valid) => {
                if(valid) {
                    this.quickleadTasks(true);
                    const result = await Customers.quickLead(constant.quicklead);
                    if(result.status == 200) {
                        notification.success('Add Quick Lead', 'Customer successfully added');
                        if(roles.isAdmin()) {
                            filter.quicklead.agent = 'all';
                        } else {
                            filter.quicklead.agent = -1;
                        }
                        await Customers.customerLists(filter.quicklead);
                        this.closeQuicklead(false, formEl);
                        formEl.resetFields()
                    } else {
                        this.quickleadTasks(false);
                        notification.warning('Quick Lead Error', result.data.message)
                    }
                } else {
                    this.quickleadTasks(false);
                }
            })
        },

        quickleadTasks(e: Boolean) {
            loading.quicklead = e;
            loading.leadsAndTasks = e;
            disable.quicklead = e;
        },

        closeQuicklead(e: Boolean, formEl: FormInstance) {
            formEl.resetFields()
            modal.quicklead = e;
            this.quickleadTasks(e);
        }
    }
}