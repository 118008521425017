import { ActionTree, ActionContext } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';
import { State } from './state';
import { Mutations } from './mutations';
import { EtcMutationTypes } from '@/store/enums/Etc/Mutations';
import { EtcActionTypes } from '@/store/enums/Etc/Actions';
import ApiService from "@/core/services/ApiService";
import { Links } from '@/store/enums/Links';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [EtcActionTypes.ROUTES](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<any>;
  [EtcActionTypes.COUNTRY_CODE](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {

  async [EtcActionTypes.ROUTES]({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.GET_ROUTES, payload)
      await commit(EtcMutationTypes.SET_ROUTES, data.data.data.routes);
      await window.localStorage.setItem('myRoute', JSON.stringify(data.data.data.routes))
    }
    catch (err: any) {
      return err;
    }
  },

  async [EtcActionTypes.COUNTRY_CODE]({ commit }) {
    try {
      const data = await ApiService.post_get(Links.GET_COUNTRY_CODE);
      commit(EtcMutationTypes.SET_COUNTRY_CODE, data.data.data.codes)
    }
    catch (err: any) {
      return err;
    }
  },

  async [EtcActionTypes.AGENT_LISTS]({ commit }, payload) {
    try {
      const link = payload.aType == 'dom' ? Links.GET_AGENT_LISTS : Links.GET_ALL_AGENT_LISTS;
      const data = await ApiService.post(link, payload);

      if(payload.aType == 'dom'){
        commit(EtcMutationTypes.SET_AGENT_LISTS, data.data.data.agents)
        return data.data.data.agents
      }
      else{
        commit(EtcMutationTypes.SET_AGENT_LISTS, data.data.data.team_members)
        return data.data.data.team_members

      }

    }
    catch (err: any) {
      return err;
    }
  },

  async [EtcActionTypes.UNDERWRITERS_LISTS]({ commit }) {
    try {
      const data = await ApiService.post_get(Links.GET_UNDERWRITERS_LISTS);
      commit(EtcMutationTypes.SET_UNDERWRITERS_LISTS, data.data.data.underwriters)

      return data.data.data.underwriters
    }
    catch (err: any) {
      return err;
    }
  },



  async [EtcActionTypes.USER_TYPES]({ commit }) {
    try {
      const data = await ApiService.post_get(Links.POST_LIST_USER_TYPES);
      commit(EtcMutationTypes.SET_LIST_USER_TYPES, data.data.data.types)
    }
    catch (err: any) {
      return err;
    }
  },

  async [EtcActionTypes.COUNTRIES]({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.GET_COUNTRIES, payload);
      commit(EtcMutationTypes.SET_COUNTRIES, data.data.data.countries)
    }
    catch (err: any) {
      return err;
    }
  },

  async [EtcActionTypes.RELATIONSHIPS]({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.GET_RELATIONSHIPS, payload);
      commit(EtcMutationTypes.SET_RELATIONSHIPS, data.data.data.relationships);
      commit(EtcMutationTypes.SET_WORK_TYPE, data.data.data.domesticRelationships);
      commit(EtcMutationTypes.SET_PARENT_TYPE, data.data.data.parentsRelationship);
      commit(EtcMutationTypes.SET_OTHER_TYPE, data.data.data.otherRelationship);
    }
    catch (err: any) {
      return err;
    }
  },

  async [EtcActionTypes.EMIRATES]({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.GET_EMIRATES, payload);
      commit(EtcMutationTypes.SET_EMIRATES, data.data.data.emirates)
    }
    catch (err: any) {
      return err;
    }
  },

  async [EtcActionTypes.INSURED_TYPES]({ commit }, payload) {
    try {
      payload.isSkye = true;
      const data = await ApiService.post(Links.GET_INSURED_TYPES, payload);
      commit(EtcMutationTypes.SET_INSURED_TYPES, data.data.data.health_insurance_types)
    }
    catch (err: any) {
      return err;
    }
  },

  async [EtcActionTypes.INSURANCE_PROVIDERS]({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.GET_INSURANCE_PROVIDERS, payload);
      commit(EtcMutationTypes.SET_INSURANCE_PROVIDERS, data.data.data.providers)
    }
    catch (err: any) {
      return err;
    }
  },

  async [EtcActionTypes.INSURANCE_PLANS]({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.GET_INSURANCE_PLANS, payload);
      commit(EtcMutationTypes.SET_INSURANCE_PLANS, data.data.data.policies)
      return data.data.data.policies;
    }
    catch (err: any) {
      return err;
    }
  },

  async [EtcActionTypes.PLANS]({ commit }) {
    try {
      const data = await ApiService.post_get(Links.GET_PLANS);
      commit(EtcMutationTypes.SET_PLANS, data.data.data.plans)
    }
    catch (err: any) {
      return err;
    }
  },

  async [EtcActionTypes.APPS]({ commit }) {
    try {
      const data = await ApiService.post_get(Links.GET_APPS);
      commit(EtcMutationTypes.SET_APPS, data.data.data.apps)
    }
    catch (err: any) {
      return err;
    }
  },

  async [EtcActionTypes.LEAD_SOURCES]({ commit }) {
    try {
      const data = await ApiService.post_get(Links.GET_LEAD_SOURCES);
      commit(EtcMutationTypes.SET_LEAD_SOURCES, data.data.data)
    }
    catch (err: any) {
      return err;
    }
  },

  async [EtcActionTypes.CUSTOMER_ALREADY_EXIST]({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.GET_CUSTOMER_ALREADY_EXIST, payload);
      const result = data.data;
      if(result.is_health == 1 && result.any_health_lead)
        commit(EtcMutationTypes.SET_CUSTOMER_ALREADY_EXIST, data.data)
      
      return result;
      // commit(EtcMutationTypes.SET_CUSTOMER_ALREADY_EXIST, data.data.data)
    }
    catch (err: any) {
      return err;
    }
  },

  async [EtcActionTypes.EMPLOYEE_CATEGORIES]({ commit }) {
    try {
      const data = await ApiService.post_get(Links.GET_EMPLOYEE_CATEGORIES);
      commit(EtcMutationTypes.SET_EMPLOYEE_CATEGORIES, data.data)
    } catch (error) {
      return error
    }
  },

  async [EtcActionTypes.TASK_LISTS]({ commit }) {
    try {
      const data = await ApiService.post_get(Links.GET_ALL_LEAD_TASKS);
      commit(EtcMutationTypes.SET_TASK_LISTS, data.data.data)
    } catch (error) {
      return error
    }
  },

  async [EtcActionTypes.LEAD_REASONS]({ commit }) {
    try {
      const data = await ApiService.post_get(Links.GET_ALL_LEAD_REASONS);
      commit(EtcMutationTypes.SET_LEAD_REASONS, data.data.data)
    } catch (error) {
      return error
    }
  },

  async [EtcActionTypes.ROLES]({ commit }) {
    try {
      const data = await ApiService.post_get(Links.GET_ALL_ROLES);
      commit(EtcMutationTypes.SET_ROLES, data.data.data.roles.map(x => { return { label: x.label, value: x.value } }))
    } catch (error) {
      return error
    }
  },

  async [EtcActionTypes.NOTIFICATIONS]({ commit }) {
    try {
      const data = await ApiService.post_get(Links.GET_NOTIFICATIONS);
      commit(EtcMutationTypes.SET_NOTIFICATIONS, data.data.data.insurance)
    } catch (error) {
      return error
    }
  },

  async [EtcActionTypes.BASMAH]({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.GET_BASMAH, payload)
      commit(EtcMutationTypes.SET_BASMAH, data.data.data.basmah);
      return data.data.data.basmah;
    }
    catch (err: any) {
      return err;
    }
  },

  async [EtcActionTypes.GET_VAT]({ commit }) {
    try {
      const data = await ApiService.post_get(Links.GET_VAT)
      commit(EtcMutationTypes.SET_VAT, data.data.data.vat);
      return data.data.data.vat;
    }
    catch (err: any) {
      return err;
    }
  },

  async [EtcActionTypes.GLOBAL_SEARCH]({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.GLOBAL_SEARCH, payload)
      console.log(data.data.data);
      return data.data.data;
    }
    catch (err: any) {
      return err;
    }
  },

  async [EtcActionTypes.GET_RECENT_NOTIFICATIONS]({ commit },payload) {
    try {
      
      const data = await ApiService.post(Links.GET_RECENT_NOTIFICATIONS + "?page="+payload.index, payload);
      commit(EtcMutationTypes.SET_NOTIFICATION_LIST, data.data.data.notifications)
      return data.data.data.notifications
    } catch (error) {
      return error
    }
  },

  async [EtcActionTypes.UPDATE_NOTIFICATIONS_CLICK]({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.UPDATE_NOTIFICATIONS_CLICK, payload)
    }
    catch (err: any) {
      return err;
    }
  },

  async [EtcActionTypes.READ_NOTIFICATION]({ commit },payload) {
    try {
      const data = await ApiService.post(Links.READ_NOTIFICATION,payload)
      commit(EtcMutationTypes.SET_NOTIFICATION_COUNT, data.data.data.count);
    }
    catch (err: any) {
      return err;
    }
  },

  async [EtcActionTypes.GET_NOTIFICATION_COUNT]({ commit },payload) {
    try {
      const data = await ApiService.post(Links.GET_NOTIFICATION_COUNT,payload)
      commit(EtcMutationTypes.SET_NOTIFICATION_COUNT, data.data.data.count);
    }
    catch (err: any) {
      return err;
    }
  },

  async [EtcActionTypes.GET_ALL_NOTIFICATIONS]({commit} , payload: any) {
    try {
      const data = await ApiService.post(`${Links.GET_ALL_NOTIFICATIONS}?page=${payload.index}`, payload);
      commit(EtcMutationTypes.SET_ALL_NOTIFICATIONS, data.data.data.notifications)
    } catch (err) {
      return err
    }
  },

  async [EtcActionTypes.GET_GROUP_SIZE]({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.GET_GROUP_SIZE, payload);
      commit(EtcMutationTypes.SET_GROUP_SIZE, data.data.data.member_count_list)
    }
    catch (err: any) {
      return err;
    }
  },

  async [EtcActionTypes.GET_HEALTH_SETTING]({ commit },payload) {
    try {

      const url = Links.GET_HEALTH_SETTING + payload.type

      const data = await ApiService.post_get(url)

      const result = {
        type:payload.type,
        value:data.data.data.value
      }

      commit(EtcMutationTypes.SET_HEALTH_SETTING, result);
      return data.data.data.vat;
    }
    catch (err: any) {
      return err;
    }
  },
};
