import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "vc" }
const _hoisted_2 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: _normalizeClass(["form-label", _ctx.isRequired ? 'required' : ''])
    }, _toDisplayString(_ctx.label), 3),
    (_ctx.tooltip)
      ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          class: "fas fa-exclamation-circle ms-1 fs-7",
          "data-bs-toggle": "tooltip",
          title: _ctx.tooltip
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createVNode(_component_el_form_item, { prop: _ctx.prop }, {
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          type: "text",
          size: _ctx.size,
          placeholder: _ctx.placeholder,
          modelValue: _ctx.modelValue,
          maxlength: _ctx.maxlength,
          minlength: _ctx.minlength,
          onInput: _ctx.updateValue,
          onkeydown: _ctx.keydown,
          disabled: _ctx.disabled,
          rules: _ctx.rules,
          onBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('blur'))),
          class: "phone country-code-dropdown",
          ref: "inputRef"
        }, {
          prefix: _withCtx(() => [
            _createVNode(_component_el_select, {
              disabled: _ctx.disabled,
              "model-value": _ctx.code,
              filterable: "",
              style: {"width":"80px"},
              size: "large",
              onChange: _ctx.updateCode
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countryCodes, (item, index) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: index,
                    label: item.label,
                    value: item.value
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["disabled", "model-value", "onChange"])
          ]),
          _: 1
        }, 8, ["size", "placeholder", "modelValue", "maxlength", "minlength", "onInput", "onkeydown", "disabled", "rules"])
      ]),
      _: 1
    }, 8, ["prop"])
  ]))
}