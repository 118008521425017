import { reactive } from "vue";
import Validations from '@/core/services/etc/Validations';

const rule = reactive({
    signin: {
        email: [{validator: Validations.username, trigger: ['blur', 'change']}],
        password: [{validator: Validations.password, trigger: ['blur', 'change']}]
    },
    quicklead: {
        name: [{validator: Validations.names, trigger: ['blur', 'change']}],
        phone_number: [{validator: Validations.phone_number, trigger: ['blur', 'change']}],
        lead_source: [{validator: Validations.lead_source, trigger: ['blur', 'change']}],
    },
    task_details(disposition_id) {
        const data = new Map([
            ['disposition_id', [{validator: Validations.response, trigger: ['blur', 'change']}]],
            ['due_date', [{validator: Validations.follow_up, trigger: ['blur', 'change']}]],
            // ['task_notes', [{validator: Validations.task_notes, trigger: ['blur', 'change']}]],
        ]);

        if([11, 8].includes(disposition_id)) {
            data.set('lost_lead_reason_id', [{validator: Validations.reason, trigger: ['blur', 'change']}])
        }
        if([22].includes(disposition_id)) {
            data.set('converted_leads', [{validator: Validations.converted_leads, trigger: ['blur', 'change']}])
        }
        return Object.fromEntries(data)
    },

    notes: {
        notes: [{validator: Validations.notes, trigger: ['blur', 'change']}]
    },

    emails: {
        from: [{validator: Validations.from, trigger: ['blur', 'change']}],
        to: [{validator: Validations.to, trigger: ['blur', 'change']}],
        subject: [{validator: Validations.subject, trigger: ['blur', 'change']}],
        body: [{validator: Validations.body, trigger: ['blur', 'change']}]
    },

    update_customer: {
        name: [{validator: Validations.names, trigger: ['blur', 'change', 'input']}],
        email: [{validator: Validations.email, trigger: ['blur', 'change', 'input']}],
        phone_number: [{validator: Validations.phone_number, trigger: ['blur', 'change', 'input']}],
        dob: [{ validator: Validations.dob, trigger: ['blur', 'change', 'input'] }],
        gender: [{ validator: Validations.gender, trigger: ['blur', 'change', 'input'] }],
        is_salary: [{ validator: Validations.is_salary, trigger: ['blur', 'change', 'input'] }],
        nationality_id: [{ validator: Validations.nationality, trigger: ['blur', 'change', 'input'] }],
                
    },

    merge: {
        merge_lead_id: [{validator: Validations.merge_lead_id, trigger: ['blur', 'change']}]
    },

    customerDetails: ''
});

export {
    rule
};