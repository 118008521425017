const overview = [
    {
        title: 'Revenue',
        value: 'revenue_made',
        second_value: 'revenue_target',
        icon: 'icon-total-revenue',
        in_between: '/',
        isFormated: true,
        color: '#34bfa3'
    },

    {
        title: 'Deals / Target',
        value: 'total_deals',
        second_value: 'sales_target',
        in_between: '/',
        icon: 'icon-sales-target',
        color: '#f4516c'
    },

    {
        title: 'Variance',
        value: 'variance',
        icon: 'icon-variance',
        isFormated: true,
        color: '#f4516c'
    },

    {
        title: 'Closing Ratio',
        value: 'closing_ratio_total',
        icon: 'icon-closing-ratio',
        color: '#f4516c',
        closingRatio:true
    },

    {
        title: 'New Leads | Quick Leads',
        value: 'new_leads',
        second_value: 'quick_leads',
        in_between: '|',
        icon: 'icon-new-leads',
        color: '#2739c1'
    },
    {
        title: 'Pending',
        value: 'pending_leads',
        icon: 'icon-pending',
        color: '#2739c1'
    },
]

const overview_1 = [
    {
        title: 'Renewals | LLR',
        value: 'renewal_leads',
        second_value: 'llr_leads',
        icon: 'icon-renewal-sales',
        color: '#34bfa3'
    },

    {
        title: 'LR Lost | Lost Leads',
        value: 'lrl_leads',
        second_value: 'lost_leads',
        icon: 'icon-renewal-sales',
        color: '#34bfa3'
    },

    {
        title: 'Open Tasks in this month',
        value: 'current_month_tasks',
        icon: 'icon-this-month',
        color: '#34bfa3'
    },

    {
        title: 'Open Tasks in Previous month',
        value: 'previous_month_tasks',
        icon: 'icon-prev-month',
        color: '#34bfa3'
    },
  
]

const overview_2 = [
    {
        title: 'Refund Amount',
        value: 'total_refund',
        icon: 'icon-total-revenue',
        color: '#34bfa3'
    },

    {
        title: 'Total Refunds',
        value: 'total_refund_count',
        icon: 'icon-refund',
        color: '#34bfa3'
    },

    {
        title: 'Cancellation Amount',
        value: 'cancellation_amount',
        icon: 'icon-total-revenue',
        color: '#34bfa3'
    },

    {
        title: 'Total Cancellations',
        value: 'cancellation_count',
        icon: 'icon-policies-cancelled',
        color: '#34bfa3'
    },
  
]

const sales_dashboard = [
    {
        title: 'Name',
        value: 'first_name',
        cn: 'agent-name'
    },
    {
        title: 'Total Deals',
        value: 'total_deals',
        cn: ''
    },
    {
        title: 'Sales Target',
        value: 'sales_target',
        cn: ''
    },
    {
        title: 'Cancellation / Refund (AED)',
        value: 'cancellation_refund',
        cn: ''
    },
    {
        title: 'Revenue Made',
        value: 'revenue_made',
        isFormated: true,
        cn: ''
    },
    {
        title: 'Revenue Target',
        value: 'revenue_target_k',
        cn: ''
    },
]
export {
    overview,
    overview_1,
    overview_2,
    sales_dashboard
}