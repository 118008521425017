import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    data: _ctx.allInquiry,
    style: {"width":"100%"}
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.inquiries, (inquiry, i) => {
        return (_openBlock(), _createBlock(_component_el_table_column, {
          key: i,
          width: inquiry.width
        }, {
          header: _withCtx(() => [
            _createElementVNode("span", {
              class: "d-block fs-6 fw-bolder",
              innerHTML: inquiry.label
            }, null, 8, _hoisted_1)
          ]),
          default: _withCtx((scope) => [
            _createElementVNode("span", {
              class: "text-truncate",
              innerHTML: scope.row[inquiry.value]
            }, null, 8, _hoisted_2)
          ]),
          _: 2
        }, 1032, ["width"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["data"]))
}