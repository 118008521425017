<template>
    <div class="container container-lead bg-white border-radius-4">
            <div class="card-header p-0">
                <div class="d-flex align-items-center justify-content-between w-100 spacing-b-15">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="form-select-custom form-select-align">
                            <div class="multiselect-dropdown-custom multiselect-simple">
                                <el-date-picker 
                                    unlink-panels
                                    format="DD/MM/YYYY"
                                    value-format="YYYY-MM-DD"
                                    type="daterange"
                                    start-placeholder="Start date"
                                    end-placeholder="End date"
                                    range-separator="-"
                                    :clearable="false"
                                    size="large"
                                    v-model="dates"
                                    style="width: 310px"
                                    @calendar-change="handleChange"
                                />
                            </div>
                        </div>
                        <div class="form-select-custom form-select-align" v-if="showLeadType">
                            <div class="multiselect-dropdown-custom multiselect-simple">
                                <el-select
                                    filterable
                                    v-model="filter.lead_type"
                                    :placeholder="'Lead Type'"
                                    size="large"
                                    style="width: 100%"
                                    @change="handleLeadType"
                                >
                                <el-option
                                    v-for="(item, i) in leadType"
                                    :key="i"
                                    :label="item.label"
                                    :value="item.value"
                                />
                                </el-select>
                            </div>
                        </div>
                        <div class="form-select-custom form-select-align" v-if="showYear">
                            <div class="multiselect-dropdown-custom multiselect-simple">
                                <el-select
                                    filterable
                                    v-model="filter.year"
                                    :placeholder="yearPlaceHolder"
                                    size="large"
                                    style="width: 100%"
                                    @change="handleYear"
                                >
                                <el-option value="" :label="yearPlaceHolder"/>
                                <el-option
                                    v-for="(item, i) in yearsList"
                                    :key="i"
                                    :label="item"
                                    :value="item"
                                />
                                </el-select>
                            </div>
                        </div>
                        <div class="form-select-custom form-select-align" v-if="showPaymentType">
                            <div class="multiselect-dropdown-custom multiselect-simple">
                                <el-select
                                    filterable
                                    v-model="filter.payment_type"
                                    :placeholder="paymentTypePlaceHolder"
                                    size="large"
                                    style="width: 100%"
                                    @change="handlePaymentType"
                                    :multiple="isPaymentTypeMultiple"
                                    
                                    :collapse-tags="isPaymentTypeMultiple"
                                    :collapse-tags-tooltip="isPaymentTypeMultiple"

                                >
                                <!-- <el-option value="" :label="paymentTypePlaceHolder"/> -->
                                <el-option
                                    v-for="(item, i) in payment_type"
                                    :key="i"
                                    :label="item.label"
                                    :value="item.value"
                                />
                                </el-select>
                            </div>
                        </div>
                        <div class="form-select-custom form-select-align" v-if="showAgent">
                            <div class="multiselect-dropdown-custom multiselect-simple">
                                <el-select
                                    filterable
                                    v-model="filter.agent"
                                    :placeholder="agentPlaceHolder"
                                    size="large"
                                    style="width: 100%"
                                    @change="handleAgent"
                                    :multiple="isAgentMultiple"
                                    :collapse-tags="isAgentMultiple"
                                    :collapse-tags-tooltip="isAgentMultiple"
                                >
                                <el-option value="" :label="agentPlaceHolder"/>
                                <el-option
                                    v-for="(item, i) in agents"
                                    :key="i"
                                    :label="item.label"
                                    :value="item.value"
                                />
                                </el-select>
                            </div>
                        </div>
                        <div class="form-select-custom form-select-align" v-if="showPolicyStatus && !isAccountMaster">
                            <div class="multiselect-dropdown-custom multiselect-simple">
                                <el-select
                                    filterable
                                    v-model="filter.policy_status"
                                    :placeholder="policyStatusPlaceHolder"
                                    size="large"
                                    style="width: 100%"
                                    @change="handleStatus"
                                    
                                    :multiple="isPolicyStatusMultiple"
                                    :collapse-tags="isPolicyStatusMultiple"
                                    :collapse-tags-tooltip="isPolicyStatusMultiple"
                                >
                                <!-- <el-option value="" :label="policyStatusPlaceHolder"/> -->
                                <el-option
                                    v-for="(item, i) in policyStatus"
                                    :key="i"
                                    :label="item.label"
                                    :value="item.value"
                                />
                                </el-select>
                            </div>
                        </div>
                        <div class="form-select-custom form-select-align" v-if="showUnderwriter">
                            <div class="multiselect-dropdown-custom multiselect-simple">
                                <el-select
                                    filterable
                                    v-model="filter.underwriter"
                                    :placeholder="underwriterPlaceHolder"
                                    size="large"
                                    style="width: 100%"
                                    @change="handleUnderwriter"
                                    :multiple="isUnderwriterMultiple"
                                    :collapse-tags="isUnderwriterMultiple"
                                    :collapse-tags-tooltip="isUnderwriterMultiple"
                                    >
                                    <el-option value="" :label="underwriterPlaceHolder"/>
                                    <el-option
                                    v-for="item in underwriters"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                    />
                                </el-select>  
                            </div>
                        </div>

                    </div>

                    <!-- <span class="ms-10 mt-10"><a ref="download" :href="exportUrl" target="_blank" >Click to
                                download</a></span> -->

                    <vc-report-export-reset 
                        v-if="showExportResetFirstRow" 
                        v-model="filter.search" 
                        :showTextFilter="showTextFilterFirst" 
                        :textFilterPopUp="textFilterPopUp"
                        @reset="clickEvent" 
                        @export="clickEvent" 
                        @search="handleSearchText" 
                        :showReset="showResetFirst"
                        :showExport="showExportFirst"
                        :loadingExport="loadingExport"
                        :exportUrl="exportUrl"
                    />
                </div>
                <div class="container spacing-b-15">
                    <div class="d-flex align-items-center justify-content-between lead-source-row">
                        <div class="d-flex align-items-center">
                            
                            <div class="form-select-custom form-select-align" v-if="showInvoiceType">
                                <div class="multiselect-dropdown-custom multiselect-simple">
                                    <el-select
                                        filterable
                                        v-model="filter.invoice_type"
                                        :placeholder="invoiceTypePlaceHolder"
                                        size="large"
                                        style="width: 100%"
                                        @change="handleInvoiceType"
                                    >
                                    <el-option value="" :label="invoiceTypePlaceHolder"/>
                                    <el-option
                                        v-for="(item, i) in AllInvoiceTypes"
                                        :key="i"
                                        :label="item.label"
                                        :value="item.value"
                                    />
                                    </el-select>
                                </div>
                            </div>

                            <div class="form-select-custom form-select-align" v-if="showPolicyStatus && isAccountMaster">
                            <div class="multiselect-dropdown-custom multiselect-simple">
                                <el-select
                                    filterable
                                    v-model="filter.policy_status"
                                    :placeholder="policyStatusPlaceHolder"
                                    size="large"
                                    style="width: 100%"
                                    @change="handleStatus"
                                    
                                    :multiple="isPolicyStatusMultiple"
                                    :collapse-tags="isPolicyStatusMultiple"
                                    :collapse-tags-tooltip="isPolicyStatusMultiple"
                                >
                                <!-- <el-option value="" :label="policyStatusPlaceHolder"/> -->
                                <el-option
                                    v-for="(item, i) in policyStatus"
                                    :key="i"
                                    :label="item.label"
                                    :value="item.value"
                                />
                                </el-select>
                            </div>
                        </div>


                        
                        </div>

                        <!-- <div class="d-flex justify-content-end search-block lead-filter-buttons">

                        <div class="export-btn d-flex align-items-center" v-if="[1,11].includes(store.getters.currentRole)">
                            <span class="ms-10 mt-10"><a ref="download" :href="exportUrl" target="_blank" style="visibility: hidden">Click to
                                download</a></span>

                            <button :data-kt-indicator="disableExport ? 'on' : null" @click="exportReport"
                            class="btn btn-info btn-danger" type="button">
                            <span v-if="!disableExport" class="indicator-label">
                                <img src="@/assets/images/export-xls.svg" alt="Export" />
                            </span>
                            <span v-if="disableExport" class="indicator-progress">
                                Please wait...
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                            </button>
                        </div>

                        <div class="search-buttons-block d-flex align-items-center">
                            <div class="bd-highlight">
                            <button type="button" class="btn btn-light-info" @click="resetAndRefreshFilter">
                                Reset
                            </button>
                            </div>

                        </div>
                        </div> -->

                        <vc-report-export-reset 
                            v-if="showExportResetSecondRow" 
                            v-model="filter.search" 
                            :showTextFilter="showTextFilterSecond" 
                            :textFilterPopUp="textFilterPopUp"
                            @reset="clickEvent" 
                            @export="clickEvent" 
                            @search="handleSearchText" 
                            :showReset="showResetSecond"
                            :showExport="showExportSecond"
                            :loadingExport="loadingExport"
                            :exportUrl="exportUrl"

                        />



                    </div>
                </div>

            </div>
            </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, onMounted, watch, watchEffect, computed } from 'vue'
import Report from '@/core/services/Callable/Report'
import { date } from 'yup/lib/locale'
import { store } from '@/store'
import { payment_type, AllInvoiceTypes } from '@/core/utils'

const props = defineProps({
    modelStartDate: {
        type: String,
        required: false
    },
    modelEndDate: {
        type: String,
        required: false
    },
    modelYear: {
        type: Number,
        required: false
    },
    modelLeadType:{
        type: Number,
        required: false
    },
    yearPlaceHolder: {
        type: String,
        required: false,
        default: 'Select year'
    },
    modelPolicyStatus: {
        type: Number,
        required: false
    },
    policyStatusPlaceHolder: {
        type: String,
        required: false,
        default: 'Select Policy Status'
    },
    modelUnderwriter: {
        type: Number,
        required: false
    },
    underwriterPlaceHolder: {
        type: String,
        required: false,
        default: 'Select Underwriter'
    },
    modelSearch: {
        type: String,
        required: false
    },
    textFilterPlaceHolder: {
        type: String,
        required: false,
        default: 'Quote Ref. No'
    },
    textFilterPopUp: {
        type: String,
        required: false,
        default: 'Search using Quote Ref. No'
    },
    modelAgent: {
        type: Number,
        required: false
    },
    agentPlaceHolder: {
        type: String,
        required: false,
        default: 'Select Deleted By Sales Agent'
    },
    modelPaymentType: {
        type: Number,
        required: false
    },
    paymentTypePlaceHolder: {
        type: String,
        required: false,
        default: 'Select Payment Type'
    },
    modelInvoiceType: {
        type: Number,
        required: false
    },
    invoiceTypePlaceHolder: {
        type: String,
        required: false,
        default: 'Select Invoice Type'
    },
    showAgent: {
        type: Boolean,
        required: false,
        default: false
    },
    showPolicyStatus: {
        type: Boolean,
        required: false,
        default: false
    },
    showUnderwriter: {
        type: Boolean,
        required: false,
        default: false
    },
    showYear: {
        type: Boolean,
        required: false,
        default: false
    },
    
    showPaymentType: {
        type: Boolean,
        required: false,
        default: false
    },
    showInvoiceType: {
        type: Boolean,
        required: false,
        default: false
    },
    showExportFirst: {
        type: Boolean,
        required: false,
        default: true
    },
    showResetFirst: {
        type: Boolean,
        required: false,
        default: true
    },
    showExportSecond: {
        type: Boolean,
        required: false,
        default: true
    },
    showResetSecond: {
        type: Boolean,
        required: false,
        default: true
    },
    showTextFilterFirst: {
        type: Boolean,
        required: false,
        default: false
    },
    showTextFilterSecond: {
        type: Boolean,
        required: false,
        default: false
    },
    exportUrl: {
        type: String,
        required: false,
        default: ''
    },
    showExportResetFirstRow: {
        type: Boolean,
        required: false,
        default: false
    },
    showExportResetSecondRow: {
        type: Boolean,
        required: false,
        default: false
    },
    isAccountMaster: {
        type: Boolean,
        required: false,
        default: false
    },
    showLeadType: {
        type: Boolean,
        required: false,
        default: false
    },
    isAgentMultiple: {
        type: Boolean,
        required: false,
        default: false
    },
    isPaymentTypeMultiple:{
        type: Boolean,
        required: false,
        default: false
    },
    loadingExport: {
        type: Boolean,
        required: false,
        default: false
    },
    isPolicyStatusMultiple: {
        type: Boolean,
        required: false,
        default: false
    },
    isUnderwriterMultiple: {
        type: Boolean,
        required: false,
        default: false
    },
    
})

const emit = defineEmits(['update:modelStartDate',
                            'update:modelEndDate',
                            'update:modelLeadType',
                            'update:modelYear',
                            'update:modelAgent',
                            'update:modelPolicyStatus',
                            'update:modelUnderwriter',
                            'update:modelSearch',
                            'update:modelPaymentType',
                            'update:modelInvoiceType',
                            'export',
                            'reset',
                            'change',
                            'handleYears'
                            ])

const dates = ref([])
const policyStatus = ref([])
const underwriters = ref([])
const agents = ref([])
const filter = ref({
    start_date: '',
    end_date: '',
    year: '',
    agent: '',
    policy_status: '',
    underwriter: '',
    search: '',
    payment_type: '',
    invoice_type: '',
    lead_type: 1
})

onMounted(async() => {
    await getPolicyStatuses()
    await getUnderwriters()
    await getAgents()
})

watchEffect(() => {
    filter.value.start_date = props.modelStartDate
    filter.value.end_date = props.modelEndDate
    filter.value.agent = props.modelAgent
    filter.value.policy_status = props.modelPolicyStatus
    filter.value.underwriter = props.modelUnderwriter

    filter.value.search = props.modelSearch
    filter.value.payment_type = props.modelPaymentType
    filter.value.invoice_type = props.modelInvoiceType

    if(filter.value.start_date && filter.value.end_date)
    dates.value = [filter.value.start_date, filter.value.end_date]

})

async function getPolicyStatuses() {
    if(props.showPolicyStatus) {
        let data = {
            linkType: 'policy-statuses'
        }

        if(props.isAccountMaster) {
            data.is_account_master = true
        }

        const response = await Report.getRequestedURL(data)
        policyStatus.value = response
    }
}

async function getUnderwriters() {
    if(props.showUnderwriter) {
        const data = {
            linkType: 'underwriters'
        }
        const response = await Report.getRequestedURL(data)
        underwriters.value = response.underwriters
    }
}

async function getAgents() {
    if(props.showAgent) {
        const data = {
            linkType: 'agents',
            aType: 'dom',
            type: 'Health'
        }
        const response = await Report.getRequestedURL(data)
        agents.value = response.agents
    }
}

function handleStatus() {
    emit('update:modelPolicyStatus', filter.value.policy_status)
    handleChange()
}

function handleUnderwriter() {
    emit('update:modelUnderwriter', filter.value.underwriter)
    handleChange()
}

function handleAgent() {
    emit('update:modelAgent', filter.value.agent)
    handleChange()
}

function handleYear() {
    emit('update:modelYear', filter.value.year)
    emit('handleYears', filter.value.year)
    handleChange()
}

function handleLeadType() {
    emit('update:modelLeadType', filter.value.lead_type)
}

function handleSearchText() {
    emit('update:modelSearch', filter.value.search)
    handleChange()
}

function handlePaymentType() {
    emit('update:modelPaymentType', filter.value.payment_type)
    handleChange()
}

function handleInvoiceType() {
    emit('update:modelInvoiceType', filter.value.invoice_type)
    handleChange()
}

function clickEvent(type) {
    emit(type, type);
}

watch(() => dates.value, newValue => {
    emit('update:modelStartDate', newValue[0])
    emit('update:modelEndDate', newValue[1])
})

let yearsList = computed(() => {
    const year = new Date().getFullYear()
      return Array.from(
        { length: year - 2017 },
        (value, index) => year - index
      );
});

const leadType = [
    {label:'Individual', value: 1},
    {label:'Group', value: 2},
]

function handleChange() {
    emit('change')
}
</script>
