import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!

  return (_openBlock(), _createBlock(_component_el_radio_group, {
    "model-value": _ctx.modelValue,
    ref: "inputRef",
    name: _ctx.statusName,
    disabled: _ctx.disabled,
    onChange: _ctx.updateValue
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customerStatus, (status, index) => {
        return (_openBlock(), _createBlock(_component_el_radio, {
          key: index,
          label: status.value
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(status.label), 1)
          ]),
          _: 2
        }, 1032, ["label"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["model-value", "name", "disabled", "onChange"]))
}