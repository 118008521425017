
import { defineComponent, computed, onMounted, getCurrentInstance, ref, Ref, watch  } from 'vue'
import Etcs from '@/core/services/Callable/Etcs';
import { store } from "@/store";
import { rule, common, notification, leadEmployeeCategoryId, constant } from '@/store/stateless';
import { ElMessage, ElMessageBox } from 'element-plus'
import Customers from '@/core/services/Callable/Customers';
import Validations from '@/core/services/etc/Validations';

export default defineComponent({
    props:{
        isDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    setup() {

        const health = computed(() => {
            return store.state.customer.health;
        })

        const plans = computed(() => {
            return store.getters.getInsurancePlans;
        })

        const deleteCategory = (e: any) => {

            ElMessageBox.confirm(
                '<div class="p-5 fs-4">Do you want delete the category. Continue?</div>',
                'Warning',
                {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    // type: 'warning',
                    buttonSize:'large',
                    customClass:'delete-member-popup',
                    // center:true,
                    dangerouslyUseHTMLString: true,
                }
            )
                .then(() => {
                    health.value.deleted_categories.push(health.value.categories[e])
                    health.value?.categories?.splice(e, 1);
                    notification.success(
                            'Category',
                            'Delete Completed'
                    );
                })
                .catch(() => {
                    // notification.info(
                    //         'Member',
                    //         'Delete Canceled'
                    // );
                    
                })
        }

        const addNewMember = () => {
            health.value?.categories?.push({
                id: null,
                name: null,
                insurance_provider_id: null,
                insurance_providers: store.state.etc.providers,
                insurance_plan_id: null,
                insurance_policies: [],
                group_count: 1,
                members_above_60: 0,
                visa_emirates: null,
                visa_emirates_text: null,
                category_name: null,
                insurance_plan: null,
                amount:0
            })
        }


        onMounted(() => {
            // if(health.value.categories) {
            //     for(const cat of health.value.categories)
            //     {
            //         selectedOptions.value.push(cat.name)
            //         console.log(selectedOptions.value)
            //         // selectProvider(cat)
            //     }
            // }
        })

        
        

        const deleteMember = (e) => {
            health.value?.categories?.splice(e, 1);
        }

        const selectProvider = async (e) => {
            if(e) {
                e.insurance_plan_id = null;
                // const data = await Etcs.getInsurancePolicies({ insurance_provider_id: e.insurance_provider_id ,isDropdown: true });

                const data = store.getters.getInsurancePlans
                console.log(data)
                e.insurance_policies = data;
            }
        }

        const inst = getCurrentInstance()
        const focus = () => {
            const toFocus:any = inst?.refs[rule.customerDetails]
            console.log(toFocus)
            if(toFocus !== undefined) {
                toFocus.focus()
                // window.scrollTo(0, common.getY('top'))
            }
        }

        const { upload_documents } = constant

        const documents = ref()
        async function uploadDocument(lead_employee_category_id: number, lead_id: number, category_name: string, policy_id: number) {

            leadEmployeeCategoryId.value = lead_employee_category_id
            upload_documents.lead_id = lead_id
            upload_documents.policy_id = policy_id
            upload_documents.disabled = true
            upload_documents.group_name = category_name
            

            await Customers.details({ customer_id: health.value.id })
            documents.value.modal(true)
        }

        return {
            leadEmployeeCategoryId,
            uploadDocument,
            documents,
            health,
            addNewMember,
            deleteMember,
            deleteCategory,
            selectProvider,
            focus,
            plans,
            Validations
            }
    },
})
