import { ActionTree, ActionContext } from 'vuex';

// eslint-disable-next-line import/no-cycle
import { RootState } from '@/store';
import { State } from './state';
import { Mutations } from './mutations';
import { Mutations as etcMutation } from '@/store/modules/Etc/mutations';
import { CustomerMutationTypes } from '@/store/enums/Customer/Mutations';
import { EtcMutationTypes } from '@/store/enums/Etc/Mutations';
import { CustomerActionTypes } from '@/store/enums/Customer/Actions';
import ApiService from "@/core/services/ApiService";
import { Links } from '@/store/enums/Links';
import { notification } from '@/store/stateless'
import router from '@/router/clean';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>;
  commit<K extends keyof etcMutation>(
    key: K,
    payload: Parameters<etcMutation[K]>[1],
  ): ReturnType<etcMutation[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
  [CustomerActionTypes.QUICK_LEAD](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<any>;
  [CustomerActionTypes.CUSTOMER_LISTS](
    { commit }: AugmentedActionContext,
    payload: any
  ): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {

  async [CustomerActionTypes.QUICK_LEAD]({ commit }, payload) {
    try {
      const data = await ApiService.post(Links.POST_QUICK_LEAD, payload);
      return data;
    }
    catch (err: any) {
      return err;
    }
  },

  async [CustomerActionTypes.CUSTOMER_LISTS]({ commit }, payload) {
    try {
      commit(CustomerMutationTypes.SET_CUSTOMER_LOADING, true);
      const data = await Promise.all([
        await ApiService.post(Links.POST_CUSTOMER_LIST + `?page=${payload.page}`, payload),
        await ApiService.post(Links.POST_CUSTOMER_LIST_COUNT, payload),
        await ApiService.post(Links.POST_CAR_TASK_DUE_DATE_COUNT, payload),
        await ApiService.post(Links.POST_LEAD_FILTER_COUNT, payload),

      ])
      commit(CustomerMutationTypes.SET_CUSTOMER_LISTS, data[0].data.data)
      commit(CustomerMutationTypes.SET_CUSTOMER_LIST_COUNTS, data[1].data.data)
      commit(EtcMutationTypes.SET_CAR_TASK_DUE_DATE_COUNT, data[2].data.data)
      commit(CustomerMutationTypes.SET_LEAD_FILTER_COUNT, data[3].data.data.count)
      commit(CustomerMutationTypes.SET_CUSTOMER_LOADING, false);
    } catch (err: any) {
      commit(CustomerMutationTypes.SET_CUSTOMER_LOADING, false);
      return err;
    }
  },

  async [CustomerActionTypes.CUSTOMER_LISTS_COUNT]({ commit }, payload) {
    try {
      commit(CustomerMutationTypes.SET_CUSTOMER_LOADING, true);
      const data = await ApiService.post(Links.POST_CUSTOMER_LIST_COUNT, payload)
      commit(CustomerMutationTypes.SET_CUSTOMER_LIST_COUNTS, data.data.data)
      commit(CustomerMutationTypes.SET_CUSTOMER_LOADING, false);

    } catch (err: any) {
      return err;
    }
  },

  async [CustomerActionTypes.HEALTH]({ commit }, payload) {
    commit(CustomerMutationTypes.SET_CUSTOMER_LOADING, true);
    try {
      const data = await ApiService.post(Links.POST_HEALTH, payload);
      commit(CustomerMutationTypes.SET_CUSTOMER_HEALTH_DECLARATIONS, data.data.data.data.lead_information);
      commit(CustomerMutationTypes.SET_CUSTOMER_HEALTH_LEAD_ID, data.data.data.data.lead_id);
      commit(CustomerMutationTypes.SET_CUSTOMER_LOADING, false);
      return data;
    }
    catch (err: any) {
      commit(CustomerMutationTypes.SET_CUSTOMER_LOADING, false);
      return err;
    }
  },

  async [CustomerActionTypes.GET_DECLARATIONS]({ commit }, payload) {
    // commit(CustomerMutationTypes.SET_CUSTOMER_LOADING, true);
    try {
      const data = await ApiService.post(Links.GET_DECLARATIONS, payload);
      commit(CustomerMutationTypes.SET_CUSTOMER_LEAD_DECLARATIONS, data.data.data);
      // commit(CustomerMutationTypes.SET_CUSTOMER_LOADING, false);
      return data;
    }
    catch (err: any) {
      // commit(CustomerMutationTypes.SET_CUSTOMER_LOADING, false);
      return err;
    }
  },

  async [CustomerActionTypes.DECLARATIONS]({ commit }, payload) {
    commit(CustomerMutationTypes.SET_CUSTOMER_LOADING, true);
    try {
      const data = await ApiService.post(Links.POST_HEALTH_DECLARATION, payload);
      commit(CustomerMutationTypes.SET_CUSTOMER_LOADING, false);
      return data;
    }
    catch (err: any) {
      commit(CustomerMutationTypes.SET_CUSTOMER_LOADING, false);
      return err;
    }
  },

  async [CustomerActionTypes.IMPORT_CUSTOMER_LISTS]({ commit }, payload) {
    commit(CustomerMutationTypes.SET_CUSTOMER_LOADING, true);
    try {
      const data = await ApiService.post(Links.POST_IMPORT_CUSTOMER_LIST, payload);
      commit(CustomerMutationTypes.SET_CUSTOMER_LOADING, false);
      return data;
    }
    catch (err: any) {
      commit(CustomerMutationTypes.SET_CUSTOMER_LOADING, false);
      return err;
    }
  },

  async [CustomerActionTypes.TRANSFER_AGENT]({ commit }, payload) {
    commit(CustomerMutationTypes.SET_CUSTOMER_LOADING, true);
    try {
      const data = await ApiService.post(Links.POST_TRANSFER_AGENT, payload);
      commit(CustomerMutationTypes.SET_CUSTOMER_LOADING, false);
      return data;
    }
    catch (err: any) {
      commit(CustomerMutationTypes.SET_CUSTOMER_LOADING, false);
      return err;
    }
  },

  async [CustomerActionTypes.DETAIL_CUSTOMER]({ commit }, payload) {
    if (payload.is_loaded === undefined) {
      commit(CustomerMutationTypes.SET_DETAIL_CUSTOMER_LOADING, true);
    }
    try {
      const data = await ApiService.post(Links.POST_DETAIL_CUSTOMER, payload);
      commit(CustomerMutationTypes.SET_DETAIL_CUSTOMER, data.data.data);
      commit(CustomerMutationTypes.SET_DETAIL_CUSTOMER_LOADING, false);
      return data;
    }
    catch (err: any) {
      commit(CustomerMutationTypes.SET_DETAIL_CUSTOMER_LOADING, false);
      // const href = router.resolve({ name: "CustomerLists" })
      // window.location.href = href.href
      return err;
    }
  },

  async [CustomerActionTypes.DETAIL_CUSTOMER_BY_MAP_ID]({ commit }, payload) {
    commit(CustomerMutationTypes.SET_DETAIL_CUSTOMER_LOADING, true)
    try {
      const data = await ApiService.post(Links.GET_LEAD_DETAILS_BY_MAP_ID, payload);
      const { customer, insured_city, insured_type_id, insurance_type, leads, agent, additional, id, contact_person } = data.data
      // const pd = await personalDetails(leads, customer.name)
      // console.log('call actions')
      const health = {
        id: customer.id,
        name: customer.name,
        email: customer.email,
        contact_person,
        phone_number: customer.phone_number_without_code,
        phone_country_code: customer.phone_country_code,
        other_contact_info:customer.other_contact_info,
        insured_city,
        insured_type_id,
        insured_type_id_prev: insured_type_id,
        insurance_type,
        is_salary: !additional ? null : additional.is_salary === 1 ? true : false,
        expected_insurance_start_date: additional ? additional.expected_insurance_start_date : null,
        customer_source: customer.customer_source,
        is_health: 1,
        utm_source: customer.utm_source,
        utm_medium: customer.utm_medium,
        utm_campaign: customer.utm_campaign,
        utm_content: customer.utm_content,
        dob: additional ? additional.dob : null,
        nationality_id: additional ? additional.nationality_id : null,
        gender: additional ? additional.gender : null,
        is_married: additional ? additional.is_married === 1 ? true : false : false,
        company_name: null,
        is_self: additional && additional.is_self === 1 ? true : false,
        agent: agent ? agent.id : null,
        health_lead_map_id: id,
        members: members(leads, insurance_type),
        categories: [],
        hasDealInvoice: false
      }
      commit(CustomerMutationTypes.SET_CUSTOMER_HEALTH, health);
      commit(CustomerMutationTypes.SET_DETAIL_CUSTOMER_LOADING, false);
      return data
    } catch (error) {
      commit(CustomerMutationTypes.SET_DETAIL_CUSTOMER_LOADING, false);
      return error
    }
  },

  async [CustomerActionTypes.DETAIL_CUSTOMER_BY_LEAD_ID]({ commit }, payload) {
    commit(CustomerMutationTypes.SET_DETAIL_CUSTOMER_LOADING, true)
    try {
      const data = await ApiService.post(Links.GET_LEAD_DETAILS_BY_LEAD_ID, payload);
      const { customer, insured_city, insured_type_id, insurance_type, leads, agent, additional, id, contact_person ,expected_insurance_start_date,is_self , hasDealInvoice, has_pending_invoice, has_pending_group_invoice,is_renewal, is_llr } = data.data
      // console.log(leads);
      // const pd = await personalDetails(leads, customer.name)
      const health = {
        id: customer.id,
        name: customer.name,
        email: customer.email ,
        dummy_email: customer.email ,
        contact_person: data.data.contact_person,
        group_size: data.data.group_size,
        phone_number: customer.phone_number_without_code,
        phone_country_code: customer.phone_country_code,
        other_contact_info:customer.other_contact_info,
        insured_city,
        insured_type_id,
        insured_type_id_prev: insured_type_id,
        insurance_type,
        is_salary: !additional ? null : additional.is_salary === 1 ? true : false,
        expected_insurance_start_date: expected_insurance_start_date ?? null,
        customer_source: customer.customer_source,
        is_health: 1,
        utm_source: customer.utm_source,
        utm_medium: customer.utm_medium,
        utm_campaign: customer.utm_campaign,
        utm_content: customer.utm_content,
        dob: additional ? additional.dob : null,
        nationality_id: additional ? additional.nationality_id : null,
        gender: additional ? additional.gender : null,
        is_married: additional ? additional.is_married === 1 ? true : false : false,
        company_name: null,
        is_self: is_self === 1 ? true : false,
        agent: agent ? agent.id : null,
        health_lead_map_id: id,
        members: members(leads, insurance_type),
        deleted_members: [],
        categories: employee_categories(leads, insurance_type),
        deleted_categories:[],
        hasDealInvoice: hasDealInvoice ?? false, // setting true if invoice is present to disabled edit lead
        has_pending_invoice,
        has_pending_group_invoice,
        // hasDealInvoice: leads.invoices.length > 0 ? true : false 
        is_renewal: is_renewal,
        is_llr:is_llr

      }
      // console.log(health.members)
      commit(CustomerMutationTypes.SET_CUSTOMER_HEALTH, health);
      commit(CustomerMutationTypes.SET_DETAIL_CUSTOMER_LOADING, false);
      return data
    } catch (error) {
      commit(CustomerMutationTypes.SET_DETAIL_CUSTOMER_LOADING, false);
      return error
    }
  },

  async [CustomerActionTypes.CUSTOMER_DELETE_LEAD]({ commit }, payload) {
    commit(CustomerMutationTypes.SET_DETAIL_CUSTOMER_LOADING, true)
    try {
      const data = await ApiService.post(Links.POST_CUSTOMER_LEAD_DELETE, payload)
      commit(CustomerMutationTypes.SET_DETAIL_CUSTOMER_LOADING, false)
      return data.data
    } catch (error) {
      commit(CustomerMutationTypes.SET_DETAIL_CUSTOMER_LOADING, false)
      return error
    }
  },

  async [CustomerActionTypes.UPDATE_CUSTOMER_LEADS]({ commit }, payload) {
    commit(CustomerMutationTypes.SET_CUSTOMER_LOADING, true)
    try {
      const data = await ApiService.post(Links.POST_HEALTH_UPDATE, payload)
      commit(CustomerMutationTypes.SET_CUSTOMER_LOADING, false)
      return data
    } catch (error) {
      commit(CustomerMutationTypes.SET_CUSTOMER_LOADING, false)
      return error
    }
  },

  async [CustomerActionTypes.ADD_LEAD_TASK]({ dispatch }, payload) {
    try {
      const data = await ApiService.post(Links.POST_ADD_LEAD_TASK, payload)
      notification.success('Add Lead Task', 'You have successfully added a task')
      const result = await dispatch(CustomerActionTypes.DETAIL_CUSTOMER, { customer_id: payload.customer_id, is_loaded: false })
      return data
    } catch (error) {
      notification.warning('Add Lead Task',  error.response.data.message)

      return error
    }
  },

  async [CustomerActionTypes.UPDATE_CUSTOMER_DETAILS]({ }, payload) {
    try {
      const data = await ApiService.post(Links.POST_UPDATE_CUSTOMER_DETAILS, payload)
      return data
    } catch (error) {
      return error
    }
  },

  async [CustomerActionTypes.UPDATE_CUSTOMER_CONTACT_INFO]({ }, payload) {
    try {
      const data = await ApiService.post(Links.POST_UPDATE_CUSTOMER_CONTACT_INFO, payload)
      return data
    } catch (error) {
      return error
    }
  },

  async [CustomerActionTypes.CUSTOMER_DETAILS]({ commit }, payload) {
    commit(CustomerMutationTypes.SET_DETAIL_CUSTOMER_LOADING, true)
    try {
      const data = await ApiService.post(Links.GET_CUSTOMER_DETAILS_BY_ID, payload)
      const { customer, additional, agent } = data.data
      const health = {
        id: customer.id,
        name: customer.name,
        email: customer.email,
        contact_person: null,
        phone_number: customer.phone_number_without_code,
        phone_country_code: customer.phone_country_code,
        other_contact_info:customer.other_contact_info,
        insured_city: null,
        insured_type_id: 1,
        insurance_type: 0,
        is_salary: additional.is_salary === 1 ? true : false,
        expected_insurance_start_date: null,
        customer_source: customer.customer_source,
        is_health: 1,
        utm_source: customer.utm_source,
        utm_medium: customer.utm_medium,
        utm_campaign: customer.utm_campaign,
        utm_content: customer.utm_content,
        dob: additional.dob,
        nationality_id: additional.nationality_id,
        gender: additional.gender,
        is_married: additional ? additional.is_married === 1 ? true : false : false,
        company_name: null,
        is_self: additional.is_self === 1 ? true : false,
        agent: agent.id,
        health_lead_map_id: 0,
        members: [{
          id: null,
          member_name: null,
          relationship: null,
          gender: null,
          dob: null,
          nationality_id: null,
          is_salary: null,
          declaration_answers: [],
          is_married: false
        }],
        categories: [],
        hasDealInvoice: false
      }
      commit(CustomerMutationTypes.SET_CUSTOMER_HEALTH, health);
      commit(CustomerMutationTypes.SET_DETAIL_CUSTOMER_LOADING, false);
      return health
    } catch (error) {
      // console.log('error', error);
      commit(CustomerMutationTypes.SET_DETAIL_CUSTOMER_LOADING, false);
      return error
    }
  },

  async [CustomerActionTypes.VIEW_TASKS]({ commit }, payload) {
    commit(CustomerMutationTypes.SET_VIEW_TASK_LOADING, true)
    commit(CustomerMutationTypes.SET_VIEW_TASKS, {})
    try {
      const data = await ApiService.post(Links.POST_VIEW_ALL_TASKS_BY_LEAD_ID, payload)
      commit(CustomerMutationTypes.SET_VIEW_TASKS, data.data.data)
      commit(CustomerMutationTypes.SET_VIEW_TASK_LOADING, false)
      return data
    } catch (error) {
      commit(CustomerMutationTypes.SET_VIEW_TASK_LOADING, false)
      return error
    }
  },

  async [CustomerActionTypes.NOTES]({ commit }, payload) {
    commit(CustomerMutationTypes.SET_VIEW_NOTES_LOADING, true)
    try {
      const data = await ApiService.post(Links.GET_ALL_NOTE_LEAD, payload)
      commit(CustomerMutationTypes.SET_VIEW_NOTES, data.data.data.notes)
      commit(CustomerMutationTypes.SET_VIEW_NOTES_LOADING, false)
      return data
    } catch (err) {
      commit(CustomerMutationTypes.SET_VIEW_NOTES_LOADING, false)
      return err
    }
  },

  async [CustomerActionTypes.ADD_NOTE]({ commit }, payload) {
    commit(CustomerMutationTypes.SET_VIEW_NOTES, [])
    commit(CustomerMutationTypes.SET_VIEW_NOTES_LOADING, true)

    try {
      const data = await ApiService.post(Links.POST_ADD_NOTE_LEAD, payload)
      commit(CustomerMutationTypes.SET_VIEW_NOTES, data.data.data.notes)
      commit(CustomerMutationTypes.SET_VIEW_NOTES_LOADING, false)
      return data
    } catch (err) {
      commit(CustomerMutationTypes.SET_VIEW_NOTES_LOADING, false)
      return err
    }
  },

  async [CustomerActionTypes.EMAILS]({ commit }, payload) {
    commit(CustomerMutationTypes.SET_VIEW_EMAIL_LOADING, true)
    try {
      const data = await ApiService.post(Links.GET_ALL_EMAIL_LEAD, payload)
      commit(CustomerMutationTypes.SET_VIEW_EMAILS, data.data.data.emails)
      commit(CustomerMutationTypes.SET_VIEW_EMAIL_LOADING, false)
    } catch (err) {
      commit(CustomerMutationTypes.SET_VIEW_EMAIL_LOADING, false)
      return err
    }

  },

  async [CustomerActionTypes.SMS]({ commit }, payload) {
    commit(CustomerMutationTypes.SET_VIEW_SMS_LOADING, true)
    try {
      const data = await ApiService.post(Links.GET_ALL_SMS_LEAD, payload)
      commit(CustomerMutationTypes.SET_VIEW_SMS, data.data.data.sms)
      commit(CustomerMutationTypes.SET_VIEW_SMS_LOADING, false)
    } catch (err) {
      commit(CustomerMutationTypes.SET_VIEW_SMS_LOADING, false)
      return err
    }

  },

  async [CustomerActionTypes.ADD_EMAIL]({ commit, dispatch }, payload) {
    commit(CustomerMutationTypes.SET_VIEW_EMAIL_LOADING, true)
    try {
      const { customer_id } = payload
      const data = await ApiService.post(Links.POST_VIEW_ALL_EMAIL_LEAD_SAVE, payload)
      await dispatch(CustomerActionTypes.EMAILS, { customer_id })
      commit(CustomerMutationTypes.SET_VIEW_EMAIL_LOADING, false)
      return data
    } catch (err) {
      commit(CustomerMutationTypes.SET_VIEW_EMAIL_LOADING, false)
      return err
    }
  },

  async [CustomerActionTypes.CUSTOMER_ACTIVITY_LOGS]({ commit }, payload) {
    commit(CustomerMutationTypes.SET_CUSTOMER_ACTIVITY_LOG_LOADING, true)
    try {
      const data = await ApiService.post(Links.GET_ALL_CUSTOMER_ACTIVITY_LOGS, payload)
      commit(CustomerMutationTypes.SET_CUSTOMER_ACTIVITY_LOG, data.data.data.logs)
      commit(CustomerMutationTypes.SET_CUSTOMER_VIEW_ACTIVITY_LOG, data.data.data.logs_viewed)
      commit(CustomerMutationTypes.SET_CUSTOMER_ACTIVITY_LOG_LOADING, false)
      return data
    } catch (err) {
      commit(CustomerMutationTypes.SET_CUSTOMER_ACTIVITY_LOG_LOADING, false)
      return err
    }
  },

  async [CustomerActionTypes.CUSTOMER_STATUS_LOGS]({ commit }, payload) {
    commit(CustomerMutationTypes.SET_CUSTOMER_STATUS_LOG_LOADING, true)
    try {
      const data = await ApiService.post(Links.GET_ALL_CUSTOMER_STATUS_LOGS, payload)
      commit(CustomerMutationTypes.SET_CUSTOMER_STATUS_LOG, data.data.data.logs)
      commit(CustomerMutationTypes.SET_CUSTOMER_STATUS_LOG_LOADING, false)
    } catch (err) {
      commit(CustomerMutationTypes.SET_CUSTOMER_STATUS_LOG_LOADING, false)
      return err
    }
  },

  async [CustomerActionTypes.LEAD_ACTIVITY_LOGS]({ commit }, payload) {
    commit(CustomerMutationTypes.SET_LEAD_ACTIVITY_LOG_LOADING, true)
    try {
      const data = await ApiService.post(Links.GET_ALL_LEAD_ACTIVITY_LOGS, payload)
      commit(CustomerMutationTypes.SET_LEAD_ACTIVITY_LOG, data.data.data.logs)
      commit(CustomerMutationTypes.SET_LEAD_ACTIVITY_LOG_LOADING, false)
      return data
    } catch (err) {
      commit(CustomerMutationTypes.SET_LEAD_ACTIVITY_LOG_LOADING, false)
      return err
    }
  },

  async [CustomerActionTypes.LEAD_STATUS_LOGS]({ commit }, payload) {
    commit(CustomerMutationTypes.SET_LEAD_STATUS_LOG_LOADING, true)
    try {
      const data = await ApiService.post(Links.GET_ALL_LEAD_STATUS_LOGS, payload)
      commit(CustomerMutationTypes.SET_LEAD_STATUS_LOG, data.data.data.logs)
      commit(CustomerMutationTypes.SET_LEAD_STATUS_LOG_LOADING, false)
    } catch (err) {
      commit(CustomerMutationTypes.SET_LEAD_STATUS_LOG_LOADING, false)
      return err
    }
  },

  async [CustomerActionTypes.MERGE_LEAD]({ }, payload) {
    try {
      const data = await ApiService.post(Links.POST_HEALTH_MERGE_QUICK_LEAD, payload)
      return data
    } catch (err) {
      return err
    }
  },

  async [CustomerActionTypes.GET_MEMBERS]({ }, payload) {
    try {
      const data = await ApiService.post(Links.GET_MEMBERS_BY_MAP, payload)
      return data
    } catch (err) {
      return err
    }
  }

};

function personalDetails(leads, name) {
  return leads.find(x => x.lead_information.member_name.toLowerCase() === name.toLowerCase())
}

function members(leads, insurance_type) {
  if (insurance_type === 1) {
    return leads.map(x => {
      const y = x.lead_information;
      // console.log('y',y)
      return {
        id: y.id,
        insured_type_id: y.insured_type_id,
        insured_city: y.insured_city,
        lead_id: y.lead_id,
        member_name: y.is_temp === 0 ? y.member_name : null,
        relationship: y.is_temp === 0 ? y.relationship : null,
        gender: y.is_temp === 0 ? y.gender : null,
        dob: y.is_temp === 0 ? y.dob : null,
        nationality_id: y.is_temp === 0 ? y.nationality_id : null,
        is_salary: y.is_temp === 0 ? y.is_salary === 1 ? true : false : false,
        declaration_answers: y.declaration_answers,
        is_married: y.is_temp === 0 ? y.is_married === 1 ? true : false : false
      }
    }).reverse()
  } else {
    // console.log('else');
    return leads.map(x => {
      return x.lead_informations.map(y => {
        // console.log('y', y);
        if(y.insured_type_id == 0){
          // QL info return empty
          return {
            id: y.id,
            insured_type_id: y.insured_type_id,
            insured_city: y.insured_city,
            lead_id: y.lead_id,
            member_name: null,
            relationship:  null,
            gender: null,
            dob: null,
            nationality_id: null,
            is_salary:  false,
            declaration_answers: y.declaration_answers,
            is_married: false
          }
        } 
        else {
          return {
            id: y.id,
            insured_type_id: y.insured_type_id,
            insured_city: y.insured_city,
            lead_id: y.lead_id,
            member_name: y.is_temp === 0 ? y.member_name : null,
            relationship: y.is_temp === 0 ? y.relationship : null,
            gender: y.is_temp === 0 ? y.gender : null,
            dob: y.is_temp === 0 ? y.dob : null,
            nationality_id: y.is_temp === 0 ? y.nationality_id : null,
            is_salary: y.is_temp === 0 ? y.is_salary === 1 ? true : false : false,
            declaration_answers: y.declaration_answers,
            is_married: y.is_temp === 0 ? y.is_married === 1 ? true : false : false
          }
        }
      })
    }).flat().reverse()


    // console.log('leadsData',leadsData[0]);

    // return leadsData.length > 0 ? leadsData[0] : [];

  }
}

function employee_categories(leads, insurance_type) {

  // console.log('categorys');
  return leads.map(x => {
    // console.log('category', x);

    return x.employee_categories.map((y: any) => {
      // console.log('category', y);

      return {
        id: y.id,
        name: y.name,
        lead_id: y.lead_id,
        insurance_provider_id: y.insurance_provider_id,
        insurance_plan_id: y.insurance_plan_id,
        group_count: y.group_count,
        members_above_60: y.members_above_60,
        visa_emirates: y.visa_emirates,
        category_name: y.category_name,
        visa_emirates_text: y.visa_emirates_text,
        // invoice_based_categories: y.invoice_based_categories ? y.invoice_based_categories : [],
        insurance_plan: y.insurance_plan,
        amount: 0,
        policy_id: x.latest_group_policy_purchase ? x.latest_group_policy_purchase.id : null
      }
    })
  }).flat()

}