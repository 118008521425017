
    import {
        defineComponent,
        computed,
        ref,
        getCurrentInstance,
        onMounted,
    } from "vue";
    import { store } from "@/store";
    import moment from "moment";
    import router from "@/router";
    import { common } from "@/store/stateless";
    import {
        overview,
        overview_1,
        overview_2,
    } from "@/core/utils/sales_dashboard.js";
    import { team_summaries } from '@/core/utils/dashboard'
    export default defineComponent({
        name: "dashboard-overview",
        props: {
            userRoleId : {
                type: Boolean,
                required: true
            }
        },
        setup() {

            const salesOverview = computed(() => {
                return store.getters.getSalesAgentOverviewData
            })

            const salesPerformaceChart = computed(() => {
                return store.getters.getSalesPie
            })

            const hasData = computed(() => {
                return store.getters.getHasSalesPersonPieValue
            })

            const teamSummary = computed(() => {
                return store.getters.getTeamSummary
            })

            const getTextWidth = (text) => {
                const canvas = document.createElement("canvas");
                const context: any = canvas.getContext("2d");

                context.font = getComputedStyle(document.body).font;

                return context.measureText(text).width;
            };

            const getFontSize = (value) => {
                const width = getTextWidth(value);
                return width > 92 ? '22px' : width > 82 ? '24px' : width > 70 ? '26px' : width > 60 ? '28px' : '34px'
            }

            function concatData(data_1, data_2) {
                if(data_2)
                    return `${data_1} / ${data_2}`

                return data_1
            }



            return {
                concatData,
                getTextWidth,
                getFontSize,
                teamSummary,
                common,
                hasData,
                salesOverview,
                overview,
                overview_1,
                overview_2,
                salesPerformaceChart,
                team_summaries
            };
        },
    });
