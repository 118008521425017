import { MutationTree } from 'vuex';

import { State } from './state';
import { InvoiceMutationTypes } from '@/store/enums/Invoice/Mutations';

export type Mutations<S = State> = {
}

export const mutations: MutationTree<State> & Mutations = {
    [InvoiceMutationTypes.SET_INVOICE_FILTER](state: State, payload: any) {
        state.filter = payload;
    },

    [InvoiceMutationTypes.SET_INVOICE_LOADING](state: State, payload: any) {
        state.loading = payload;
    },

    [InvoiceMutationTypes.SET_INVOICE_LISTS](state: State, payload: any) {
        state.invoiceLists = payload;
    },

    [InvoiceMutationTypes.SET_INVOICE_LIST_COUNTS](state: State, payload: any) {
        state.invoiceListCounts = payload;
    },

    [InvoiceMutationTypes.SET_INVOICE_MODAL](state: State,payload:any) {
        state.openInvoiceModal =  payload;
    },

    [InvoiceMutationTypes.SET_INVOICE_MODAL_VALUE](state: State, payload: any) {
        state.openInvoiceModalValue = payload;
    },

    [InvoiceMutationTypes.SET_INTERESTED_POLICY_MODAL](state: State,payload:any) {
        state.openInterestedPolicyModal =  payload;
    },

    [InvoiceMutationTypes.SET_INTERESTED_POLICY_MODAL_VALUE](state: State, payload: any) {
        state.interestedPolicy = payload;
    },

    [InvoiceMutationTypes.SET_INOVICE_APPROVAL_ACTION_MODAL](state: State, payload: any) {
        state.invoiceApprovalActionModal = payload;
    },

    [InvoiceMutationTypes.SET_INVOICE_DETAILS_MODAL](state: State, payload: any) {
        state.invoiceDetails = payload;
    },

    [InvoiceMutationTypes.SET_INVOICE_DETAILS](state: State, payload: any) {
        // console.log(payload)
        state.invoice = payload;
    },

    [InvoiceMutationTypes.SET_INVOICE_DATA](state: State, payload: any) {
        state.invoiceData = payload;
    },

    
    [InvoiceMutationTypes.SET_GROUP_INVOICE_LIST](state: State, payload: any) {
        state.groupInvoiceLists = payload;
    },

    [InvoiceMutationTypes.SET_GROUP_INVOICE_LIST_COUNT](state: State, payload: any) {
        state.groupInvoiceListCounts = payload;
    },

    [InvoiceMutationTypes.SET_GROUP_INVOICE_DETAILS](state: State, payload: any) {
        state.groupInvoice = payload;
    },

    [InvoiceMutationTypes.SET_GROUP_INVOICE_LOG](state: State, payload: any) {
        state.groupInvoiceLog = payload;
    },


};
