<template>
  <div class="card text-left me-0 p-0 border-0">
    <div
      class="card-header boder-0 pt-5 w-100"
      :style="`background-color: ${backgroundColor} !important`"
    >
      <h3 class="card-title align-items-start flex-column">
        <span
          class="card-label fw-bolder fs-3 mb-1"
          :style="`color: ${fontColor} !important`"
        >
          {{ title }}
        </span>
      </h3>
    </div>
    <div class="card-body pie-chart-container" v-if="series && series.length > 0">
      <VueApexCharts
        type="pie"
        :width="chartOptions.chart.width"
        :height="chartOptions.chart.height"
        :options="chartOptions"
        :series="series"
      ></VueApexCharts>
    </div>
    <div class="card-body" v-else>
      <p class="text-center">No Records Available</p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import VueApexCharts from "vue3-apexcharts";

export default defineComponent({
  props: {
    chartOptions: {},
    series: [],
    title: String,
    backgroundColor: {
      type: String,
      required: false,
      default: "#fff"
    },
    fontColor: {
      type: String,
      required: false,
      default: "#000"
    }
  },
  components: {
    VueApexCharts
  }
});
</script>
