
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        customer_id: {
            type: Number,
            required: true
        },
        agent: {
            type: Number,
            required: true
        },
        lead_id: {
            type: Number,
            required: true
        },
        lead_status_id: {
            type: Number,
            required: true
        },

    },

   
})
