
import { defineComponent, computed, onMounted, getCurrentInstance, watch  } from 'vue'
import { store } from "@/store";
import { rule, common, notification } from '@/store/stateless';
import { group_columns, group_amend_columns } from '@/core/utils/invoice'
import Validations from '@/core/services/etc/Validations';

export default defineComponent({
    props:{
        allDisabled:{
            default:false,
            required:true
        },
        isAmend: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    setup(props, {emit}) {

        const health = computed(() => {
            return store.state.customer.health;
        })
        
        const invoice = computed(() => {
            return store.state.invoice.groupInvoice;
        })
        
        const dummyinvoice = computed(() => {
            return store.state.invoice.groupInvoice;
        })

        const basmah = computed(() => {
            return store.state.etc.basmah;
        })

        const vat = computed(() => {
            return store.state.etc.vat  / 100;
        })
        

        // const categories = computed(() => {
        //     const invoice =  store.state.invoice.groupInvoice;
        //     if(invoice)
        //         return invoice.categories;
        //     return [];
        // })
    
        onMounted(() => {
            // invoice.value.categories = health.value.categories;
            if(invoice.value.categories.length == 0){
                // setting categories in make invoice
                invoice.value.categories = store.getters.getGroupInvoiceCategories
                invoice.value.categories = invoice.value.categories.map(element => {
                    element.group_count = 0;
                    element.members_above_60 = 0;
                    return element;
                });
            }
            // updateBasmah()

            // console.log('catsdsdegories',invoice.value.categories)

        })

        watch(() => invoice.value, async (newValue) => {
            // console.log('inside cat watch')
            console.log(newValue)
            if(newValue) {
                newValue.categories.map(x => {
                    const group_count = Number(x.group_count)
                    const basmahVal = x.visa_emirates == 2 ? Number(basmah.value) : 0;
                    x.basmah = common.roundUp(group_count * basmahVal);
                    // if(group_count == 0) {
                    //     x.amount = 0
                    //     x.non_taxable_fee = 0
                    // }
                    return {
                        ...x,
                        group_count
                    }
                })

                updateBasmah()
                updateNonTaxableFee()
                // updatePolicyPrice()
            }
            // if(newValue) {
            //     invoice.value.categories = invoice.value.categories.map(element => {
            //         const group_count = Number(element.group_count);
            //         const basmahVal = element.visa_emirates == 2 ? Number(basmah.value) : 0;
            //         element.basmah = common.roundUp(group_count * basmahVal);
            //         if(group_count == 0)
            //             // element.amount = 0;
            //         // console.log(element.group_count)
            //         // console.log(element.basmah)
            //         return element;

            //     });

            //     updateBasmah()
            // }
        }, { deep: true })

        const updateBasmah = () => {
            // invoice.value.orginal_policy_price
            const basmah = invoice.value.categories.reduce((sum, obj) => {
                const basmah = Number(obj['basmah']);
                if(isNaN(basmah))
                    return sum + 0
                else
                    return sum + basmah
            }, 0);

            invoice.value.basmah = common.roundUp(basmah)

        }

        const updateIcp = () => {
            // invoice.value.orginal_policy_price
            const icp = invoice.value.categories.reduce((sum, obj) => {
                const icp = Number(obj['icp']);
                if(isNaN(icp))
                    return sum + 0
                else
                    return sum + icp
            }, 0);

            invoice.value.icp = common.roundUp(icp)

        }
        
        const updatePolicyPrice = (val) => {
            // invoice.value.orginal_policy_price
            const amount = invoice.value.categories.reduce((sum, obj) => {
                const amount = Number(obj['amount']);
                if(isNaN(amount))
                    return sum + 0
                else
                    return sum + amount
            }, 0);

            val.amount_vat = common.roundUp(val.amount * vat.value)
            if(isNaN(val.amount_vat)) val.amount_vat = 0

            invoice.value.orginal_policy_price = common.roundUp(amount)

        }
        
        const updateNonTaxableFee = () => {
            // invoice.value.orginal_policy_price
            const amount = invoice.value.categories.reduce((sum, obj) => {
                const amount = Number(obj['non_taxable_fee']);
                if(isNaN(amount))
                    return sum + 0
                else
                    return sum + amount
            }, 0);

            invoice.value.non_taxable_fee = common.roundUp(amount)

        }


        const inst = getCurrentInstance()
        const focus = () => {
            const toFocus:any = inst?.refs[rule.customerDetails]
            console.log({toFocus})
            console.log({rule: rule.customerDetails})
            if(toFocus !== undefined) {
                toFocus.focus()
                // window.scrollTo(0, common.getY('top'))
            }
        }

        function updateValue(val, obj, index = 0) {
            if(val[obj] == '' || val[obj] == null || val[obj] == 0) {
                val[obj] = 0
                if(obj == 'group_count') {
                    val.amount = 0
                    val.amount_vat = 0
                    val.members_above_60 = 0
                    val.icp = 0
                    val.basmah = 0
                    val.non_taxable_fee = 0
                    emit('resetValidation', `categories.${index}.amount`)
                }
            } else {
                val[obj] = parseInt(val[obj])
            }
        }

        const columns = computed(() => {
            if(props.isAmend) return group_amend_columns
            else return group_columns
        })

        const selectField = (reference) => {
            const toSelect:any = inst?.refs[reference]
            if(toSelect !== undefined) {
                toSelect.select()
            }
        }

        return {
            selectField,
            basmah,
            columns,
            group_amend_columns,
            dummyinvoice,
            updateNonTaxableFee,
            updateValue,
            invoice,
            focus,
            health,
            group_columns,
            Validations,
            updatePolicyPrice,
            common,
            updateIcp
        }
    },
})
