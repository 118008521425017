import { Document, Logs } from '@/core/types/Document';
import { GENERIC } from '@/core/types/Etc';


export type State = {
  document_loading: Boolean
  documents: Array<Document>
  document_types: Array<GENERIC>
  document_logs: Array<Logs>
  amend_documents: Array<any>
}

export const state: State = {
  document_loading: false,
  documents: [] as Array<Document>,
  document_types: [] as Array<GENERIC>,
  document_logs: [] as Array<Logs>,
  amend_documents:[]
};
