<template>
    <el-dialog 
        v-model="informationModal.open"
        append-to-body
        :title="informationModal.title"
        @close="closeModal"
    >
        <template #default>
            <div class="mx-2">
                <p v-html="informationModal.message" />
            </div>
        </template>
        <template #footer>
            <span class="dialog-footer">
                <div class="text-center">
                <button 
                    type="button" 
                    class="btn btn-info m-2"
                    @click="close"
                >
                &nbsp;&nbsp;&nbsp;Close&nbsp;&nbsp;&nbsp;
                </button>
                
            </div>
            </span>
        </template>
    </el-dialog>
</template>

<script setup>
import { informationModal } from '@/store/stateless'

function close() {
    informationModal.value.open = false
}
</script>
