
import { defineComponent, onMounted, computed, ref } from 'vue'
import { store } from "@/store";
import Etcs from '@/core/services/Callable/Etcs';

export default defineComponent({
    props: {
        modelValue: {
            type: String,
            required: true,
            default: ''
        },
        label: {
            type: String,
            required: true,
            default: 'Select role'
        },
        isMultiple: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    setup(props, context) {

        onMounted(() => {
            Etcs.getAllRoles(); 
        })

        const roles = computed(() => {
            const data = store.state.etc.roles
            const exist = data.find(x => x.value === 0)
            if(!exist)
                data.unshift({ label: props.label, value: 0 })

            return data
        })

        const updateValue = (event) => {
            context.emit("update:modelValue", event)
            context.emit("roles", event)
        }

        const inputRef = ref()
        const focus = () => {
        setTimeout(() => {
                inputRef.value.focus()
            }, 100);
        }

        return {
            focus,
            inputRef,
            roles,
            updateValue
        }
    },
})
