
import { defineComponent, computed, getCurrentInstance } from 'vue'
import { store } from "@/store";
import { common, rule } from "@/store/stateless";
import Customers from '@/core/services/Callable/Customers';
import { ElMessage, ElMessageBox } from 'element-plus'
import { notification } from '@/store/stateless';

export default defineComponent({
    props: {
        isEdit: {
            type: Boolean,
            required: false,
            default: false
        },
        isDisabled: {
            type: Boolean,
            required: false,
            default: false
        },
        isAmend: {
            type: Boolean,
            required: false,
            default: false
        },
        isSubAmend:{
            type: Boolean,
            required: false,
            default: false
        },
    },
    setup(_) {

        const health = computed(() => {
            return store.state.customer.health;
        })

        // console.log(health.value)

        const deleteMember = (e: number, lead_id: number) => {
            ElMessageBox.confirm(
                '<div class="p-5 fs-4">Do you want delete the member. Continue?</div>',
                'Warning',
                {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    // type: 'warning',
                    buttonSize:'large',
                    customClass:'delete-member-popup',
                    // center:true,
                    dangerouslyUseHTMLString: true,
                }
            )
                .then(() => {
                    console.log(health.value)
                    health.value.deleted_members.push(health.value.members[e])
                    health.value?.members?.splice(e, 1);
                    if (lead_id > 0) {
                        // Customers.deleteLead({ lead_id })
                    }
                    notification.success(
                            'Member',
                            'Delete Completed'
                    );
                })
                .catch(() => {
                    // notification.info(
                    //         'Member',
                    //         'Delete Canceled'
                    // );
                    
                })


            
        }

        const disabledDate = (time: any) => {
            return time.getTime() > new Date()
        }

        const isReadyOnly = computed(() => {
            if (health.value?.is_self && health.value?.insured_type_id === 3 && health.value?.members) {
                return true;
            }
            return false;
        })

        const isFamilyInsuredType = computed(() => {
            if (health.value?.insured_type_id === 3) {
                if (_.isEdit) {
                    if (health.value.insurance_type == 2)
                        return true;
                    else
                        return true;
                }
                return true;
            }
            return false;
        })

        const isParentInsuredType = computed(() => {
            if (health.value?.insured_type_id === 5) {
                if (_.isEdit) {
                    if (health.value.insurance_type == 2)
                        return true;
                    else
                        return false;
                }
                return true;
            }
            return false;
        })

        const isEmployeeInsuredType = computed(() => {
            if (health.value?.insured_type_id === 6) {
                if (_.isEdit) {
                    if (health.value.insurance_type == 2)
                        return true;
                    else
                        return false;
                }
                return true;
            }
            return false;
        })

        const updateGender = (member) => {
            // console.log(member)
            if (member.relationship === 'Son' || member.relationship === 'Brother' || member.relationship === 'Father' || member.relationship === 'Father-in-law')
                member.gender = 'MALE';

            else if (member.relationship === 'Daughter' || member.relationship === 'Sister' || member.relationship === 'Mother' || member.relationship === 'Mother-in-law')
                member.gender = 'FEMALE';
            else
                member.gender = null;

            UpdateIsMarried(member);

        }

        const UpdateIsMarried = (member) => {
            // console.log(member);
            member.is_married = false
            if (member.gender === 'MALE') member.is_married = false

            if (member.relationship === 'Spouse' && member.gender === 'FEMALE') member.is_married = true
        }


        const inst = getCurrentInstance()

        const focus = () => {
            const toFocus: any = inst?.refs[rule.customerDetails]
            if (toFocus !== undefined) {
            console.log('in focus')
                toFocus.focus()
                window.scrollTo(0, common.getY('familyRef'))
            }
        }

        return {
            common,
            health,
            disabledDate,
            deleteMember,
            UpdateIsMarried,
            updateGender,
            isReadyOnly,
            isParentInsuredType,
            isFamilyInsuredType,
            isEmployeeInsuredType,
            focus
        }
    },
})
