export function UserTypesMixin(e: number) {
    // 1 New - Not Contacted
    // 2 Return - Not Contacted
    // 3 Return - Follow Up
    // 4 Customer
    // 5 Lost Potential Customer
    // 6 Customer - Not Contacted
    // 7 Customer - Follow Up
    // 8 Partial Customer
    // 9 Lost Potential Customer - Not Contacted 
    // 10 Lost Potential Customer - Follow Up 
    // 11 Lost Customer
    // 12 New - Follow Up
    // 13 Partial Customer - Not Contacted 
    // 14 Partial Customer - Follow Up 
    // 15 Lost Customer - Not Contacted 
    // 16 Lost Customer - Follow Up 
    switch(e) {
        case 1: 
        case 6: 
            return 'color: #000; background-color: #FFDDDD !important'; break;
        case 2: 
            return 'color: #000; background-color: #E79DCE !important'; break;
        case 12: 
        case 7: 
            return 'color: #000; background-color: #FFD881 !important'; break;
        case 3: 
            return 'color: #000; background-color: #FFB822 !important'; break;
        case 4: 
            return 'color: #fff; background-color: #34BFA3 !important'; break;
        case 11: 
        case 15: 
        case 16: 
            return 'color: #fff; background-color: #F4516C !important'; break;
        case 5: 
        case 9: 
        case 10: 
            return 'color: #fff; background-color: #F890A1 !important'; break; 
        case 8: 
        case 13: 
        case 14: 
            return 'color: #000; background-color: #80DCCA !important'; break; 
        default: return 'color: #000; background-color: #5867DD !important'; break;
    }
}

export function leadStatusMixin(e: number) {
    // 1 NEW
    // 2 QUICK LEAD
    // 3 PENDING
    // 4 DEAL
    // 5 LEAD RENEWAL
    // 6 LOST LEAD
    // 7 LOST LEAD RENEWAL
    // 8 LEAD RENEWAL LOST
    // 9 DEAL TRANSFERRED
    // 10 SYS QUICK LEAD
    // 11 FALSE LEAD x
    switch(e) {
        case 1:
            return 'color: #000; background-color: #FFDDDD !important'; break;
        case 2:
            return 'color: #000; background-color: #E79DCE !important'; break;
        case 3:
            return 'color: #000; background-color: #FFB822 !important'; break;
        case 4:
            return 'color: #fff !important; background-color: #34BFA3 !important'; break;
        case 5:
            return 'color: #000; background-color: #9AA4EA !important'; break;
        case 6:
            return 'color: #fff !important; background-color: #F890A1 !important'; break;
        case 7:
            return 'color: #fff !important; background-color: #2162F6 !important'; break;
        case 8:
            return 'color: #fff !important; background-color: #F4516C !important'; break;
        case 9:
            return 'color: #000; background-color: #DDDDDD !important'; break;
        case 10:
            return 'color: #000; background-color: #BFBFBF !important'; break;
        default: return 'color: #000; background-color: #5867DD !important'; break;

    }
}






