
import moment from "moment";
import { defineComponent, nextTick, ref, watch, watchEffect } from "vue";
import { Calendar } from '@element-plus/icons-vue'

export default defineComponent({
    name: "custom-date-picker",
    props: {
        label: {
            type: String,
            required: true,
            default: '',
        },
        prop: {
            type: String,
            required: true,
        },
        modelValue: {
            type: String,
            required: true,
            default: "",
        },
        min: {
            type: String,
            required: false,
            default: "",
        },
        max: {
            type: String,
            required: false,
            default: "",
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        isRequired: {
            type: Boolean,
            required: false,
            default: false
        },
        withProp: {
          type: Boolean,
          required: false,
          default: true
        },
        rules: {
            type: Array,
            required: false,
            default: false,
        },
        defaultValue: {
          type: Object,
          required: false,
          default: new Date()
        }

    },

    setup(props, context) {
    const firstDate = ref("");

    watch(firstDate, (value) => {
      const result = value
        .replace(/^(\d\d)(\d)$/g, "$1/$2")
        .replace(/^(\d\d\/\d\d)(\d+)$/g, "$1/$2")
        .replace(/[^\d/]/g, "");
      nextTick(() => (firstDate.value = result));
    });

    watchEffect(() => {
      if(props.modelValue) {
        firstDate.value = moment(props.modelValue, "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        );
      } else {
        firstDate.value = ''
      }
    })

    const updateValue = () => {
      if (firstDate.value.length == 10) {
        let myDate = moment(firstDate.value, "DD/MM/YYYY").format("YYYY-MM-DD");
        context.emit("update:modelValue", myDate);
      }
    };

    const setDateFormat = () => {
      context.emit(
        "update:modelValue",
        moment(firstDate.value).format("YYYY-MM-DD")
      );
      let setDate = moment(firstDate.value, "YYYY-MM-DD").format("DD/MM/YYYY");
      firstDate.value = setDate;
      context.emit('blur-out', firstDate.value)
    };

    const setDateFormatInput = () => {
      let dateMinEight = moment(firstDate.value, 'YYYYMMDD').format('YYYY-MM-DD')

      if(firstDate.value.length === 8 && dateMinEight != "Invalid date") {
        let setDateEight = moment(dateMinEight, "YYYY-MM-DD").format("DD/MM/YYYY");
        firstDate.value = setDateEight
        context.emit("update:modelValue", dateMinEight);
      }
      context.emit('blur-out', firstDate.value)
    };

    const changeValue = () => {
      let myDate = moment(firstDate.value, "DD/MM/YYYY").format("YYYY-MM-DD");
      const currentDate =
        props.max != ""
          ? props.max
          : new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10);
      const minDate = moment(props.min, "YYYY-MM-DD").format("DD/MM/YYYY");
      const setDate = moment(currentDate, "YYYY-MM-DD").format("DD/MM/YYYY");
      if (firstDate.value.length != 10) {
        firstDate.value = "";
        context.emit("update:modelValue", "");
      }

      if (props.modelValue == "Invalid Date") {
        firstDate.value = "";
        context.emit("update:modelValue", "");
      }

      if (myDate == "NaN-NaN-NaN") {
        firstDate.value = "";
        context.emit("update:modelValue", "");
      }

      if (firstDate.value.length == 10 && myDate != "Invalid date") {
        if (
          props.max != "" &&
          myDate > moment(currentDate).format("YYYY-MM-DD")
        ) {
          firstDate.value = "";
          context.emit("update:modelValue", "");
        } else if (
          props.min != "" &&
          myDate < moment(props.min).format("YYYY-MM-DD")
        ) {
          firstDate.value = "";
          context.emit("update:modelValue", "");
        } else {
          context.emit("update:modelValue", myDate);
        }
      } else {
        firstDate.value = "";
        context.emit("update:modelValue", "");
      }

      context.emit("vcBlur")
      context.emit("blur-out")
    };

    const inputRef = ref()
    const focus = () => {
      setTimeout(() => {
        inputRef.value.focus()
      }, 100);
    }

    const calRef = ref()
    const openCalendar = () => {
      if(!props.disabled) {
        calRef.value.handleOpen()
      }
    }

    const closeCalendar = () => {
        calRef.value.handleClose()
    }

    
    const disabledDate = (time: Date) => {
        if(props.min || props.max) {
            const min = moment(props.min, 'YYYY-MM-DD').valueOf()
            const max = moment(props.max, 'YYYY-MM-DD').valueOf()
            if(props.min && !props.max) return time.getTime() < min
            if(!props.min && props.max) return time.getTime() > max
            if(props.min && props.max) return time.getTime() < min || time.getTime() > max
        }
    }

    return {
      Calendar,
      calRef,
      closeCalendar,
      openCalendar,
      focus,
      inputRef,
      firstDate,
      updateValue,
      changeValue,
      setDateFormat,
      setDateFormatInput,
      disabledDate,
    };
  },
});
